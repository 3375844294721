import { useMarketingMailingControllerFindAllQuery } from 'shared/api/rtk-api';

import { externalPushNotificationToInternalPushNotificationMapper } from '../mappers/external-push-notification-to-internal-push-notification-mapper';
import { InternalPushNotification } from '../types/internal-push-notification';

interface HomeVisitDetailQuery {
  page: number;
  pageSize: number;
  search?: string;
}

export const usePushNotificationListQuery = ({ page, pageSize, search }: HomeVisitDetailQuery) => {
  const { data, ...others } = useMarketingMailingControllerFindAllQuery({
    searchLine: search,
    limit: pageSize,
    offset: (page - 1) * pageSize || undefined,
  });

  const pushNotifications: InternalPushNotification[] = (data?.data ?? []).map(
    externalPushNotificationToInternalPushNotificationMapper,
  );

  return {
    pushNotifications,
    total: data?.total,
    ...others,
  };
};
