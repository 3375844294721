import { type PastHomeVisitFormResponse } from 'shared/api/rtk-api';
import { formatDateDMY, formatPhone } from 'shared/lib/format';

import { InternalPastHomeVisit } from '../types/internal-past-home-visits-list';

export const externalPastHomeVisitToInternalPastHomeVisitMapper = (
  response: PastHomeVisitFormResponse,
): InternalPastHomeVisit => {
  return {
    id: response.id,
    patient: response.patientName,
    phone: formatPhone(response.patientPhone),
    specialization: response.specialization,
    date: formatDateDMY(response.callDate),
    time: response.callTime,
    doctor: response.doctorName ?? '',
    rating: response.rating,
    comment: response.comment,
    doctorId: response.doctorId,
    extraFacilities: response.extraFacilities.join(', '),
  };
};
