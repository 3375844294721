import { type FC } from 'react';

import { BlackScreenWrapper, StyledSpan } from './styles';

type Props = {
  isBlack?: boolean;
};

export const OnlineAppointmentBlackVideoScreen: FC<Props> = ({ isBlack }) => {
  return (
    <BlackScreenWrapper isBlack={isBlack}>
      <StyledSpan>Камера выключена</StyledSpan>
    </BlackScreenWrapper>
  );
};
