import { type FC } from 'react';

import { Space } from 'antd';

import { InternalOnlineAppointmentDetails } from 'entity/online-appointment/types/internal-online-appointment-details';
import { TextRow } from 'shared/ui/row/text-row';

import { StyledCard } from './styles';

type Props = {
  onlineAppointment: InternalOnlineAppointmentDetails;
};

export const OnlineAppointmentPatientInfoHorizontal: FC<Props> = ({ onlineAppointment }) => {
  const { patientName, patientPhone } = onlineAppointment;

  return (
    <StyledCard>
      <Space size={40}>
        <TextRow title="Пациент" text={patientName} />
        <TextRow title="Телефон" text={patientPhone} />
        <TextRow title="Тип приема" text="Онлайн прием" />
      </Space>
    </StyledCard>
  );
};
