import { FC, type ReactNode } from 'react';

import { DropdownProps } from 'antd';

import { OverlayStyle, StyledDropdown, StyledMenu } from 'shared/ui/dropdown-menu/style';

interface Props extends Omit<DropdownProps, 'overlay'> {
  nodeItems: ReactNode[];
}

export const DropDownMenu: FC<Props> = ({ nodeItems, ...props }) => {
  return (
    <StyledDropdown
      {...props}
      overlay={
        <OverlayStyle
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <StyledMenu items={nodeItems.map((el, index) => ({ key: index, label: el }))} />
        </OverlayStyle>
      }
    />
  );
};
