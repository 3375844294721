// eslint-disable-next-line import/no-extraneous-dependencies
import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

const Emitter = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on: (event: string, fn: (payload: any) => void) => eventEmitter.on(event, fn),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  once: (event: string, fn: (payload: any) => void) => eventEmitter.once(event, fn),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  off: (event: string, fn: (payload: any) => void) => eventEmitter.off(event, fn),
  emit: (event: string, payload: unknown) => eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);

export default Emitter;
