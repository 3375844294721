import * as React from 'react';
import { type FC } from 'react';

import type { SegmentedProps } from 'antd/lib/segmented';

import { StyledSegmented } from 'shared/ui/segment/styles';

type SegmentedPropsAll = Omit<SegmentedProps, 'ref'> & React.RefAttributes<HTMLDivElement>;

interface Props extends SegmentedPropsAll {}

export const Segmented: FC<Props> = ({ ...props }) => {
  return <StyledSegmented {...props} />;
};
