import type { InternalDoctor } from 'entity/doctor/types/internal-doctor';
import type { HomeVisitDoctorResponse } from 'shared/api/rtk-api';

export const externalToInternalDoctorMapper = (doctor: HomeVisitDoctorResponse): InternalDoctor => {
  return {
    fullName: doctor.doctorName,
    id: doctor.doctorId,
    specialization: doctor.homeVisitSpecialization,
    image: doctor.image,
  };
};
