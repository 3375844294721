import { Typography } from 'antd';
import styled from 'styled-components';

export const Message = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.default};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-style: normal;
  overflow-wrap: anywhere;
`;

export const MessageTime = styled.div`
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: end;
  color: ${({ theme }) => theme.colors.c5};
  overflow-wrap: normal;
  height: inherit;
`;

export const MessageGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const EmptyDialogWrapper = styled.div`
  position: absolute;
  top: 32%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledDateWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

export const TypographyText = styled(Typography.Text)`
  &&& {
    color: ${({ theme }) => theme.colors.palette.dustblu};
    font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  }
`;

export const StyledMessageListElementContainer = styled.div`
  width: 100%;
`;

export const FileIconWrapper = styled.div`
  cursor: pointer;
`;
