/* eslint react/no-unstable-nested-components: 0 */
import React, { FC } from 'react';

import { Col, Modal, Row } from 'antd';

import { AppButton } from 'shared/ui/button2';
import {
  GeneralModalCancelButton,
  GeneralQuestionModalContainer,
  GeneralQuestionModalTitle,
} from 'shared/ui/general-question-modal/style';

type Props = {
  onOk: (e: React.MouseEvent<HTMLElement>) => void;
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
  visible: boolean;
  question: string;
  okText: string;
  cancelText: string;
};

export const GeneralQuestionModal: FC<Props> = ({ visible, question, okText, cancelText, onOk, onClose }) => {
  return (
    <Modal
      centered
      maskClosable
      destroyOnClose
      closable={false}
      footer={null}
      onCancel={onClose}
      open={visible}
      bodyStyle={{ borderRadius: '20px' }}
      modalRender={() => (
        <GeneralQuestionModalContainer justify="center" align="middle">
          <Col span={16}>
            <GeneralQuestionModalTitle> {question} </GeneralQuestionModalTitle>
          </Col>

          <Col span={24}>
            <Row gutter={[16, 0]} justify="center" align="middle">
              <Col span={12}>
                {/* eslint-disable-next-line deprecation/deprecation */}
                <AppButton onClick={onOk}>{okText}</AppButton>
              </Col>
              <Col span={8}>
                <GeneralModalCancelButton type="text" onClick={onClose}>
                  {cancelText}
                </GeneralModalCancelButton>
              </Col>
            </Row>
          </Col>
        </GeneralQuestionModalContainer>
      )}
      onOk={() => {}}
    />
  );
};
