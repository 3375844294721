import { Input } from 'antd';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  padding: 8px 16px;
  border-radius: 24px;
  height: 40px;

  &&& {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.palette.superSilver : theme.colors.palette.white};
  }

  &.ant-input[disabled] {
    color: ${({ theme }) => theme.colors.palette.deadPixel};
  }
`;
