import { useCallback } from 'react';

import { useOnlineVisitAppointmentControllerAdminRejectRequestToAppointmentMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  id?: number;
  onSuccess?: () => void;
}
export const useOnlineAppointmentRejectAppointmentByAdminMutation = () => {
  const [mutation] = useOnlineVisitAppointmentControllerAdminRejectRequestToAppointmentMutation();
  const action = useCallback(
    ({ id, onSuccess }: MutationHookOptions) => {
      if (!id) {
        errorNotification('Невозможно отклонить онлайн визит');
        return;
      }
      mutation({ internalOnlineVisitAppointmentId: id })
        .unwrap()
        .then(() => {
          successNotification('Онлайн-визит отклонен успешно');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при отклонении онлайн-визита');
        });
    },
    [mutation],
  );
  return [action];
};
