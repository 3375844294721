import { FC } from 'react';

import { List } from 'antd';
import { NavLink } from 'react-router-dom';

import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { useAdminNotificationsQuery } from 'entity/admin-notification/hooks/use-admin-notifications-query';
import { useViewAdminNotificationsMutation } from 'entity/admin-notification/hooks/use-view-admin-notifications-mutation';
import { NotificationBell } from 'entity/notification/ui/notification-bell';
import { NotificationListContainer, StyledListItem } from 'entity/notification/ui/notification-list/styles';
import { ArrowRightIcon } from 'shared/ui/icons';

interface Props extends Partial<CustomIconComponentProps> {
  onClick?: () => void;
}

export const AdminNotificationList: FC<Props> = ({ onClick }) => {
  const { notifications } = useAdminNotificationsQuery();

  const [viewNotifications] = useViewAdminNotificationsMutation();

  return (
    <NotificationListContainer>
      <List
        itemLayout="horizontal"
        dataSource={notifications}
        renderItem={(item, index) => {
          return (
            <NavLink
              to={item.link ?? ''}
              key={index}
              onClick={(e) => {
                if (!item.link) {
                  e.preventDefault();
                }
                viewNotifications({ id: String(item.id) });
                return onClick && onClick();
              }}
            >
              <StyledListItem actions={[<ArrowRightIcon key={1} />]}>
                <List.Item.Meta
                  avatar={<NotificationBell status={item.isViewed ? 'disabled' : 'active'} />}
                  title={item.message}
                  description={item.date}
                />
              </StyledListItem>
            </NavLink>
          );
        }}
      />
    </NotificationListContainer>
  );
};
