import { type FC } from 'react';

import { Typography } from 'antd';

import { EmptyDialogIcon } from 'shared/ui/icons';

import { EmptyDialogWrapper } from './styles';

const { Text } = Typography;

export const EmptyConversation: FC = () => {
  return (
    <EmptyDialogWrapper>
      <EmptyDialogIcon />
      <Text>Пока нет сообщений</Text>
    </EmptyDialogWrapper>
  );
};
