import { notification } from 'antd';

export const errorNotification = (message: string) => {
  notification.error({ message, style: { borderRadius: '16px' }, placement: 'bottomRight' });
};

export const successNotification = (message: string) => {
  notification.success({ message, style: { borderRadius: '16px' }, placement: 'bottomRight' });
};

export const infoNotification = (message: string) => {
  notification.info({ message, style: { borderRadius: '16px' }, placement: 'bottomRight' });
};
