import { useCallback } from 'react';

import { useDoctorNotificationControllerAcceptRequestByFormIdMutation } from 'shared/api/enchance-endpoints';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  homeVisitId?: number;
  onSuccess?: () => void;
  onError?: () => void;
  callTime: string;
}
export const useHomeVisitAcceptByDoctorMutation = () => {
  const [mutation] = useDoctorNotificationControllerAcceptRequestByFormIdMutation();
  const action = useCallback(
    ({ homeVisitId, onSuccess, onError, callTime }: MutationHookOptions) => {
      if (!homeVisitId) {
        errorNotification('Невозможно принять домашний визит');
        return;
      }
      mutation({ formId: homeVisitId, callTime })
        .unwrap()
        .then(() => {
          successNotification('Домашний визит принят доктором');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при принятии домашнего визита доктором');
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
