import styled from 'styled-components';

export const AppTableContainer = styled.div`
  div {
    background-color: #fff;
    border-radius: 16px;
    height: 100%;

    .ant-table-thead,
    .ant-table-cell {
      background-color: inherit;
    }
  }
`;
