import { type FC, useMemo, useState } from 'react';

import moment, { type Moment } from 'moment';

import { useOnlineAppointmentDoctorListQuery } from 'entity/doctor/hooks/use-online-appointment-doctor-list-query';
import type { OnlineAppointmentPickedTimeSlot } from 'entity/doctor-times/types/online-appointment-time-slot';
import { InternalOnlineAppointmentDetails } from 'entity/online-appointment/types/internal-online-appointment-details';
import { useOnlineAppointmentSpecializationListQuery } from 'entity/specialization/hooks/use-online-appointment-specialization-list-query';
import { ReturnToHomeVisitDetailsButton } from 'feature/admin-return-to-home-visit-details/ui/return-to-home-visit-details-button';
import { getEndOfStringDate, getStartOfStringDate } from 'shared/lib/format';
import { Select } from 'shared/ui/select';
import { OnlineAppointmentPatientInfoHorizontal } from 'widgets/online-appointment-patient-info-horisontal/ui';

import { OnlineAppointmentChoiceDoctorTimeWidget } from './choose-doctor-time';
import { StyledDatePicker, StyledFlex, StyledSpace } from './styles';

interface Props {
  handlePickOnlineAppointmentSlot: (timeSlot: OnlineAppointmentPickedTimeSlot) => void;
  hideChangeDoctorTime: () => void;
  onlineAppointment: InternalOnlineAppointmentDetails;
}

const DEFAULT_SPECIALIZATION_VALUE = '';

export const OnlineAppointmentChoseDoctorDateTimeWidget: FC<Props> = ({
  hideChangeDoctorTime,
  handlePickOnlineAppointmentSlot,
  onlineAppointment,
}) => {
  const [pickerDate, setPickerDate] = useState<string>(onlineAppointment.date);
  const [specialization, setSpecialization] = useState<string>(DEFAULT_SPECIALIZATION_VALUE);

  const { specializations } = useOnlineAppointmentSpecializationListQuery();
  const { doctorSlots } = useOnlineAppointmentDoctorListQuery({
    specialization: specialization || undefined,
    fromDateTimeIso: getStartOfStringDate(pickerDate).toISOString(),
    toDateTimeIso: getEndOfStringDate(pickerDate).toISOString(),
  });

  const specializationOptions = useMemo(() => {
    const mappedSpecializations = specializations.map((item) => ({
      value: item,
      label: item,
    }));
    return [{ value: DEFAULT_SPECIALIZATION_VALUE, label: 'Все специализации' }, ...mappedSpecializations];
  }, [specializations]);

  const specializationChange = (newSpecialization: unknown) => {
    if (typeof newSpecialization === 'string') setSpecialization(newSpecialization);
  };

  const handleChangePickerDate = (newDate: Moment) => setPickerDate(newDate.toISOString());

  return (
    <StyledSpace size={32} direction="vertical">
      <ReturnToHomeVisitDetailsButton onClick={hideChangeDoctorTime} />
      <OnlineAppointmentPatientInfoHorizontal onlineAppointment={onlineAppointment} />
      <StyledFlex>
        <Select defaultValue={specialization} onSelect={specializationChange} options={specializationOptions} />
        <div>
          <StyledDatePicker onSelect={handleChangePickerDate} value={moment(pickerDate)} clearIcon={false} />
        </div>
      </StyledFlex>
      <OnlineAppointmentChoiceDoctorTimeWidget
        doctorSlots={doctorSlots}
        handlePickOnlineAppointmentSlot={handlePickOnlineAppointmentSlot}
      />
    </StyledSpace>
  );
};
