import { Typography } from 'antd';
import styled from 'styled-components';

import { InternalHomeVisitStatusEnum } from 'entity/home-visit/types/internal-home-visit-status-enum';
import theme from 'shared/theme';
import { Tag } from 'shared/ui/tag';

const calculateTagTextColor = ({ $type }: { $type: InternalHomeVisitStatusEnum }) => {
  switch ($type) {
    case InternalHomeVisitStatusEnum.New:
      return theme.colors.palette.columnOfOakGreen;
    case InternalHomeVisitStatusEnum.InProgressByCurrentAdmin:
      return theme.colors.palette.clementine;
    case InternalHomeVisitStatusEnum.InProgressByAnotherAdmin:
      return theme.colors.palette.meteorShower;
    case InternalHomeVisitStatusEnum.AcceptByAdmin:
      return theme.colors.palette.dustblu;
    case InternalHomeVisitStatusEnum.AcceptByDoctor:
      return theme.colors.palette.dustblu;
    default:
      throw new Error('Unknown tag type');
  }
};

const calculateTagBackgroundColor = ({ $appointmentStatus }: { $appointmentStatus: InternalHomeVisitStatusEnum }) => {
  switch ($appointmentStatus) {
    case InternalHomeVisitStatusEnum.New:
      return theme.colors.palette.operaGlass;
    case InternalHomeVisitStatusEnum.InProgressByCurrentAdmin:
      return theme.colors.palette.organicFiber;
    case InternalHomeVisitStatusEnum.InProgressByAnotherAdmin:
      return theme.colors.palette.paleLavender;
    case InternalHomeVisitStatusEnum.AcceptByAdmin:
      return theme.colors.palette.whiteSmoke;
    case InternalHomeVisitStatusEnum.AcceptByDoctor:
      return theme.colors.palette.whiteSmoke;
    default:
      throw new Error('Unknown tag type');
  }
};

export interface StyledTagProps {
  $appointmentStatus: InternalHomeVisitStatusEnum;
}

export const StyledTag = styled(Tag)<StyledTagProps>`
  &&& {
    background-color: ${(props) => calculateTagBackgroundColor(props)};

    padding: 4px 8px;
  }
`;

export interface StyledTagTextProps {
  $type: InternalHomeVisitStatusEnum;
}

export const StyledTagText = styled(Typography.Text)<StyledTagTextProps>`
  &&& {
    color: ${(props) => calculateTagTextColor(props)};
    font-size: ${theme.fontSizes.small};
  }
`;
