import styled from 'styled-components';

export const ChatBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 0;
  flex-grow: 1;
  position: relative;

  // style overrides for PubNub MessageList React component
  .pn-msg-list {
    overflow-y: scroll;
    background-color: ${({ theme }) => theme.colors.secondBackground};

    .pn-msg {
      padding: 0 30px 16px 46px;

      &:hover {
        background-color: unset !important;

        .pn-msg__actions {
          display: none;
        }
      }

      .pn-message__custom {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};
        padding: 8px 16px;
        max-width: 600px;
        width: fit-content;
        border-radius: 16px;

        > :last-child {
          align-self: flex-end;
        }
      }

      .pn-message__container {
        display: flex;
      }
    }

    .pn-msg--own {
      & .pn-message__custom {
        background-color: ${({ theme }) => theme.colors.palette.springDew};
      }

      & .pn-message__container {
        justify-content: flex-end;
      }

      & .ant-avatar {
        display: none;
      }
    }

    .pn-msg-list__spacer + .pn-msg {
      padding-top: 20px;
    }

    .pn-msg-list__spinner svg {
      color: ${({ theme }) => theme.colors.palette.springDew};
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;
