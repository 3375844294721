import { type FC, type PropsWithChildren } from 'react';

import { SelectProps } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';

import { TimeIcon } from '../icons';
import { StyledSelect } from './styles';
import { getSelectTimeValues } from './utils';

interface Props extends SelectProps<unknown, DefaultOptionType | BaseOptionType>, PropsWithChildren {}

export const CustomTimePicker: FC<Props> = (props) => {
  return (
    <StyledSelect
      {...props}
      suffixIcon={<TimeIcon />}
      // TODO: investigate about avoiding global styles
      popupClassName="custom-time-picker-popup"
      options={getSelectTimeValues()}
    />
  );
};
