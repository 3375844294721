import { FC, useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';

import {
  ForgotPasswordFormFieldsEnum,
  IForgotPasswordFormFields,
} from 'entity/auth/api/types/forgot-password-form-fields';
import { useSendResetPasswordLinkMutation } from 'entity/auth/hooks/use-send-reset-password-link-mutation';
import {
  ForgotPasswordFormContainer,
  ForgotPasswordFormInput,
  ForgotPasswordSubTitle,
  ForgotPasswordTitle,
} from 'pages/auth/forgot-password/ui/styles';
import { AppAlert } from 'shared/ui/alert';
import { AppButton } from 'shared/ui/button2';

export const ForgotPasswordPage: FC = () => {
  document.title = 'Восстановление пароля';

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForgotPasswordFormFields>();
  const [forgotPasswordRequest, { isLoading, isError, isSuccess }] = useSendResetPasswordLinkMutation();

  const onSubmit: SubmitHandler<IForgotPasswordFormFields> = useCallback(
    (data) => {
      forgotPasswordRequest({ email: data.email });
    },
    [forgotPasswordRequest],
  );

  return isSuccess ? (
    <AppAlert
      message="Вам на почту отправлена ссылка для восстановления пароля."
      type="success"
      closable
      onClose={() => {
        navigate('/auth');
      }}
    />
  ) : (
    <ForgotPasswordFormContainer name="login">
      <Form.Item>
        <ForgotPasswordTitle>Забыли пароль?</ForgotPasswordTitle>
      </Form.Item>
      <Form.Item>
        <ForgotPasswordSubTitle>
          Введите email, который был указан при регистрации Личного кабинета. Вам будет отправлена ссылка для
          восстановления пароля.
        </ForgotPasswordSubTitle>
      </Form.Item>
      <Form.Item
        validateTrigger="onBlur"
        validateStatus={errors?.[ForgotPasswordFormFieldsEnum.EMAIL] ? 'error' : ''}
        help={errors?.[ForgotPasswordFormFieldsEnum.EMAIL] && 'Введите email!'}
      >
        <Controller
          name={ForgotPasswordFormFieldsEnum.EMAIL}
          control={control}
          rules={{ required: true }}
          render={({ field }) => <ForgotPasswordFormInput {...field} size="large" placeholder="email" allowClear />}
        />
      </Form.Item>

      <Form.Item>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises, deprecation/deprecation */}
        <AppButton htmlType="submit" loading={isLoading} disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          Отправить
        </AppButton>
      </Form.Item>

      {isError && <AppAlert message="Что-то пошло не так!" type="error" closable banner />}
    </ForgotPasswordFormContainer>
  );
};
