import { type FC } from 'react';

import { type ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { routePatches } from 'shared/routes';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

interface Props {
  onlineAppointmentId?: number;
}

export const OnlineAppointmentToTakeInEditByAdminDropdownMenuButton: FC<Props & ButtonProps> = ({
  onlineAppointmentId,
  ...props
}) => {
  const navigate = useNavigate();

  const handleClick = () => navigate(routePatches.AdminOnlineAppointmentDetails.path(onlineAppointmentId));

  return (
    <DropdownMenuButton onClick={handleClick} {...props}>
      <TypographyText>Изменить</TypographyText>
    </DropdownMenuButton>
  );
};
