import { FC } from 'react';

import { MarketingReturnToPushNotificationsListButton } from 'feature/marketing-return-to-push-notifications-list/ui/return-button';
import { MarketingReturnToPushNotificationsListButtonWithConfirmation } from 'feature/marketing-return-to-push-notifications-list/ui/return-button-with-confirmation';

type Props = {
  isFormUpdated: boolean;
};

export const ReturnButtonBlock: FC<Props> = ({ isFormUpdated }) => {
  return isFormUpdated ? (
    <MarketingReturnToPushNotificationsListButtonWithConfirmation />
  ) : (
    <MarketingReturnToPushNotificationsListButton />
  );
};
