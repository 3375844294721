import { useMemo } from 'react';

import { useOnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentQuery } from 'shared/api/rtk-api';

import { externalOnlineAppointmentDetailsToInternalOnlineAppointmentDetailsMapper } from '../mappers/external-online-appointment-details-to-internal-online-appointment-details-mapper';

export const useOnlineAppointmentDetailsQuery = (appointmentId?: string) => {
  const id = Number(appointmentId);

  if (!id) throw new Error('id is not defined');

  const { data, ...others } = useOnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentQuery(id);

  const onlineAppointment = useMemo(() => {
    return data ? externalOnlineAppointmentDetailsToInternalOnlineAppointmentDetailsMapper(data) : undefined;
  }, [data]);

  return {
    onlineAppointment,
    ...others,
  };
};
