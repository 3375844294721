import React, { type FC, useState } from 'react';

import { type TableColumnsType } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useUrlStatusesArray } from 'entity/health-assistance/hooks/use-url-statuses-array';
import { HealthAssistanceStatusDropdownCheckList } from 'entity/health-assistance/ui/health-assistance-status-dropdown-check-list/health-assistance-status-dropdown-check-list';
import { homeVisitRejectedByPatientStatusOptions } from 'entity/home-visit/constant';
import { useHomeVisitRejectedByPatientListQuery } from 'entity/home-visit/hooks/use-home-visit-rejected-by-patient-list-query';
import type { InternalHomeVisitRejectedByPatientList } from 'entity/home-visit/types/internal-home-visit-rejected-by-patient-list';
import { InternalHomeVisitStatusRejectedByPatientEnum } from 'entity/home-visit/types/internal-home-visit-status-rejected-by-patient-enum';
import { HomeVisitRejectedByPatientStatusTag } from 'entity/home-visit/ui/rejected-by-patient-home-visit-status-tag';
import { useUrlPagination } from 'shared/hooks/use-url-pagination';
import { routePatches } from 'shared/routes';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';
import { Table } from 'shared/ui/table';
import { HomeVisitRejectedByPatientTableDropdownMenu } from 'widgets/admin-home-visit-rejected-by-patient-table/ui/home-visit-rejected-by-patient-table-dropdown-menu';

const DEFAULT_PAGE_SIZE = 7;
const DEFAULT_PAGE = 1;
export const HomeVisitRejectedByPatientTable: FC = () => {
  const { setPage, page } = useUrlPagination();
  const navigate = useNavigate();
  const [showSucceedApproveModal, setShowSucceedApproveModal] = useState(false);
  const { statuses } = useUrlStatusesArray(homeVisitRejectedByPatientStatusOptions);

  const columns: TableColumnsType<InternalHomeVisitRejectedByPatientList> = [
    {
      title: 'Пациент',
      dataIndex: 'patient',
      ellipsis: true,
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      ellipsis: true,
    },
    {
      title: 'Специализация',
      dataIndex: 'specialization',
      ellipsis: true,
    },
    {
      title: 'Дата Приема',
      dataIndex: 'date',
      ellipsis: true,
    },
    {
      title: 'Время Приема',
      dataIndex: 'time',
      ellipsis: true,
    },
    {
      title: 'Адрес вызова',
      dataIndex: 'address',
      ellipsis: true,
    },
    {
      title: <HealthAssistanceStatusDropdownCheckList items={homeVisitRejectedByPatientStatusOptions} />,
      dataIndex: 'status',
      width: 130,
      render: (_, row) => {
        const { status } = row;

        return <HomeVisitRejectedByPatientStatusTag appointmentStatus={status} />;
      },
    },
    {
      width: 60,
      render: (_, row) => {
        return <HomeVisitRejectedByPatientTableDropdownMenu homeVisit={row} />;
      },
    },
  ];

  const { homeVisits, total } = useHomeVisitRejectedByPatientListQuery({
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    statuses,
  });

  const succeededActionModelHandler = () => {
    setShowSucceedApproveModal(false);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={homeVisits}
        rowKey={(row) => row.id}
        onRow={(record) => {
          return {
            onClick: () => {
              if (record.status === InternalHomeVisitStatusRejectedByPatientEnum.InProgressByAnotherAdmin) {
                setShowSucceedApproveModal(true);
              } else {
                navigate(routePatches.AdminRejectedByPatientHomeVisitDetails.path(record.id));
              }
            },
          };
        }}
        pagination={{
          current: page ?? DEFAULT_PAGE,
          total,
          onChange: (e) => setPage(e),
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
        }}
      />
      <CustomModal
        title="Данная заявка взята в работу другим администратором"
        visible={showSucceedApproveModal}
        onClose={succeededActionModelHandler}
        actionButtons={[
          <CustomButton key={1} onClick={succeededActionModelHandler} $buttonType="submit">
            Закрыть
          </CustomButton>,
        ]}
      />
    </>
  );
};
