import { FC, useState } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { Space, Typography, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { useUploadDocumentByManagerMutation } from 'entity/marketing/hooks/use-upload-document-by-manager-mutation';
import { AddButton } from 'shared/ui/add-button';
import { CustomButton } from 'shared/ui/custom-button';
import { errorNotification } from 'shared/utils/notifications';

import { PushNotificationFormType } from '../../types';
import { StyledCustomModal, WarningWrapper } from './styles';

const { Title, Text } = Typography;

type Props = {
  control: Control<PushNotificationFormType>;
  isRequire: boolean;
  fieldName: keyof PushNotificationFormType;
  setValue: UseFormSetValue<PushNotificationFormType>;
};

const BodyContent: FC = () => {
  return (
    <>
      <Space size="middle" direction="vertical">
        <WarningWrapper>
          Возможно, файл не соответствует требованиям формата и(или) превышает допустимый размер.
        </WarningWrapper>
        <Title level={5}>Ограничения</Title>
      </Space>
      <Space>
        <Text>●</Text>
        <Text> Поддерживаемые типы файлов: .xlsx, .xls.</Text>
      </Space>
    </>
  );
};

export const FormUploader: FC<Props> = ({ control, isRequire, fieldName, setValue }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleCloseModal = () => setIsModalVisible(false);

  const handleOpenModal = () => setIsModalVisible(true);

  const [uploadDocument] = useUploadDocumentByManagerMutation();

  const beforeUpload = async (file: RcFile) => {
    const isExcel =
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    if (!isExcel) {
      handleOpenModal();
      return Upload.LIST_IGNORE;
    }

    const formData = new FormData();
    formData.append('document', file);

    const response = await uploadDocument({ data: formData });

    if (response && !response.ids.length) {
      errorNotification('Неверный формат таблицы файла');
      return Upload.LIST_IGNORE;
    }

    if (response) {
      setValue('fileName', response.name);
      setValue('aggregatedPatientExternalIds', response.ids);
    }

    return false;
  };

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        rules={{ required: isRequire }}
        render={({ field }) => (
          <Upload showUploadList={false} beforeUpload={beforeUpload} {...field}>
            <AddButton>Загрузить список</AddButton>
          </Upload>
        )}
      />
      <StyledCustomModal
        title="Не удалось загрузить файл"
        visible={isModalVisible}
        onClose={handleCloseModal}
        actionButtons={[
          <CustomButton onClick={handleCloseModal} $buttonType="submit">
            Закрыть
          </CustomButton>,
        ]}
        bodyContent={<BodyContent />}
      />
    </>
  );
};
