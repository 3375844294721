import {
  IAdminDoctorInterface,
  IAdminDoctorsCreateReq,
  IAdminDoctorsDeactivateReq,
  IAdminDoctorsReqQueries,
  IAdminDoctorsUpdateReq,
} from 'entity/admin/api/types/doctors';
import { api } from 'shared/api';
import { PaginationList } from 'shared/api/types';

const adminDoctorsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdminDoctorList: build.query<PaginationList<IAdminDoctorInterface>, IAdminDoctorsReqQueries>({
      query: ({ limit, offset, searchLine }: IAdminDoctorsReqQueries) =>
        `admin/doctors?limit=${limit}${offset ? `&offset=${offset}` : ''}${
          searchLine ? `&searchLine=${searchLine}` : ''
        }`,
      providesTags: (result) =>
        result ? [...result.data.map(({ id }) => ({ type: 'Doctor', id }) as const), 'Doctor'] : ['Doctor'],
    }),

    getAdminDoctor: build.query<IAdminDoctorInterface, number>({
      query: (id: number) => `admin/doctors/${id}`,
      providesTags: ['Doctor'],
    }),

    createDoctorAccount: build.mutation<void, IAdminDoctorsCreateReq>({
      query: ({ doctorId, ...body }) => ({
        url: `admin/doctors/${doctorId}/account`,
        method: 'post',
        body,
      }),
      invalidatesTags: ['Doctor'],
    }),

    updateDoctorAccount: build.mutation<void, IAdminDoctorsUpdateReq>({
      query: ({ doctorId, ...body }) => ({
        url: `admin/doctors/${doctorId}/account`,
        method: 'put',
        body,
      }),
      invalidatesTags: ['Doctor'],
    }),

    deactivateDoctorAccount: build.mutation<void, IAdminDoctorsDeactivateReq>({
      query: ({ doctorId }) => ({
        url: `admin/doctors/${doctorId}/account`,
        method: 'delete',
      }),
      invalidatesTags: ['Doctor'],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetAdminDoctorListQuery,
  useCreateDoctorAccountMutation,
  useUpdateDoctorAccountMutation,
  useDeactivateDoctorAccountMutation,
  useGetAdminDoctorQuery,
} = adminDoctorsApi;
