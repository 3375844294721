import { type FC, MouseEventHandler, useRef } from 'react';

import styled from 'styled-components';

const CardLayout = styled.div`
  background-color: inherit;
  margin-top: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  overflow: hidden;
  box-sizing: border-box;
`;

const Card = styled.div`
  transform-style: preserve-3d;
  max-width: 600px;
  height: 80%;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  box-shadow:
    0 20px 20px rgba(0, 0, 0, 0.2),
    0px 0px 50px rgba(0, 0, 0, 0.2);
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const Content = styled.div`
  margin: auto;
  width: 500px;
`;

const ErrorTitle = styled.h1`
  text-align: center;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 7em;
  font-style: bold;
  color: #007336;
  transition: all 0.75s ease-out;
`;

const Title = styled.h2`
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
  margin-bottom: 0.5em;
  transition: all 0.75s ease-out;
`;

const Text = styled.p`
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  color: #b7bac6;
  margin: 0;
  transition: all 0.75s ease-out;
`;

type Props = {
  content: { statusCode: number; title: string; text: string; secondText?: string };
};

// eslint-disable-next-line react/prop-types
export const Dummy: FC<Props> = ({ content: { statusCode, title, text, secondText = '' } }) => {
  document.title = 'Not Found';

  const cardRef = useRef<HTMLDivElement>(null);
  const errorTitleRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const firstTextRef = useRef<HTMLDivElement>(null);
  const secondTextRef = useRef<HTMLDivElement>(null);

  const handleMouseMove: MouseEventHandler = (event) => {
    const xAxis = (window.innerWidth / 2 - event.pageX) / 25;
    const yAxis = (window.innerHeight / 2 - event.pageY) / 25;
    if (cardRef.current) cardRef.current.style.transform = `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`;
  };

  const handleMouseEnter: MouseEventHandler = () => {
    if (cardRef.current && errorTitleRef.current && titleRef.current && firstTextRef.current && secondTextRef.current) {
      cardRef.current.style.transition = 'none';
      errorTitleRef.current.style.transform = 'translateZ(150px)';
      titleRef.current.style.transform = 'translateZ(140px)';
      firstTextRef.current.style.transform = 'translateZ(125px)';
      secondTextRef.current.style.transform = 'translateZ(110px)';
    }
  };

  const handleMouseLeave: MouseEventHandler = () => {
    if (cardRef.current && errorTitleRef.current && titleRef.current && firstTextRef.current && secondTextRef.current) {
      cardRef.current.style.transition = 'all 0.5s ease';
      cardRef.current.style.transform = 'rotateY(0deg) rotateX(0deg)';
      errorTitleRef.current.style.transform = 'translateZ(0)';
      titleRef.current.style.transform = 'translateZ(0)';
      firstTextRef.current.style.transform = 'translateZ(0)';
      secondTextRef.current.style.transform = 'translateZ(0)';
    }
  };

  return (
    <CardLayout>
      <Card ref={cardRef} onMouseMove={handleMouseMove} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <ContentWrapper>
          <Content>
            <ErrorTitle ref={errorTitleRef}>{statusCode}</ErrorTitle>
            <Title ref={titleRef}>{title}</Title>
            <Text ref={firstTextRef}>{text}</Text>
            <Text ref={secondTextRef}>{secondText}</Text>
          </Content>
        </ContentWrapper>
      </Card>
    </CardLayout>
  );
};
