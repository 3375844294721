import React, { type FC } from 'react';

import { ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useChatSupportTakeToOneProgressByAdminMutation } from 'entity/chat-support/hooks/use-chat-support-take-to-one-progress-by-admin-mutation';
import { routePatches } from 'shared/routes';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

interface Props {
  chatSupportId?: string;
}

export const ChatSupportToTakeOnProgressByAdminDropdownMenuButton: FC<Props & ButtonProps> = ({
  chatSupportId,
  ...props
}) => {
  const navigate = useNavigate();

  const [takeToOneMutation] = useChatSupportTakeToOneProgressByAdminMutation({
    id: chatSupportId,
    onSuccess: () => navigate(routePatches.AdminChatSupportDetails.path(chatSupportId)),
  });

  return (
    <DropdownMenuButton onClick={takeToOneMutation} {...props}>
      <TypographyText>Взять в работу</TypographyText>
    </DropdownMenuButton>
  );
};
