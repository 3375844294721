import { useEffect, useState } from 'react';

import Pubnub from 'pubnub';

interface GetPubnubSdkInstanceProps {
  pubNubUserId: string | undefined;
  publishKey: string | undefined;
  subscribeKey: string | undefined;
}

export const UsePubnubSdk = ({ pubNubUserId, publishKey, subscribeKey }: GetPubnubSdkInstanceProps) => {
  const [pubNubSDK, setPubNubSDK] = useState<Pubnub | null>(null);

  useEffect(() => {
    if (!pubNubUserId || !publishKey || !subscribeKey) {
      return;
    }
    const pubnubSDK = new Pubnub({
      publishKey,
      subscribeKey,
      userId: pubNubUserId,
      restore: true,
    });

    setPubNubSDK(pubnubSDK);
  }, [pubNubUserId, publishKey, subscribeKey]);

  return { pubNubSDK };
};
