import styled from 'styled-components';

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.c6};
`;

export const ChatTitleWrapper = styled.div`
  max-width: inherit;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  gap: 10px;
`;

export const ChatTitle = styled.div`
  margin-top: 5px;
  font-size: ${({ theme }) => theme.fontSizes.default};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.small};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

export const StyledCrossButtonContainer = styled.div`
  margin-top: 2px;
  cursor: pointer;
`;
