import { useMemo } from 'react';

import { externalHomeVisitDetailsToInternalHomeVisitDetailsMapper } from 'pages/doctor/home-visit-details/mappers';
import { useHomeVisitControllerGetHomeVisitQuery } from 'shared/api/rtk-api';
import { errorNotification } from 'shared/utils/notifications';

export const useHomeVisitDetailsQueryByDoctor = (homeVisitId?: string) => {
  const id = Number(homeVisitId);
  if (!id) throw new Error('id is not defined');
  const { data: homeVisitForm, ...others } = useHomeVisitControllerGetHomeVisitQuery(Number(homeVisitId));

  const homeVisit = useMemo(() => {
    if (!homeVisitForm) return null;
    return externalHomeVisitDetailsToInternalHomeVisitDetailsMapper(homeVisitForm);
  }, [homeVisitForm]);

  if (others.isError) {
    errorNotification('Не удалось загрузить данные о вызове на дом');
  }

  return {
    homeVisit,
    ...others,
  };
};
