import React, { type FC, useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useHealthAssistanceRejectModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-reject-modal';
import { useHomeVisitDetailsQuery } from 'entity/home-visit/hooks/use-home-visit-details-query';
import { useHomeVisitRejectRequestByAdminMutation } from 'entity/home-visit/hooks/use-home-visit-reject-request-by-admin-mutation';
import { routePatches } from 'shared/routes';
import { Button } from 'shared/ui/button';
import { CloseIcon } from 'shared/ui/icons';

export const HomeVisitRejectRequestByAdminButton: FC = () => {
  const params = useParams();
  const { homeVisit, isLoading } = useHomeVisitDetailsQuery(params.id);
  const [rejectHomeVisit] = useHomeVisitRejectRequestByAdminMutation();
  const navigate = useNavigate();

  const succeededActionModelHandler = useCallback(() => {
    navigate(routePatches.AdminHomeVisitList.path());
  }, [navigate]);

  const onClickHandler = useCallback(() => {
    rejectHomeVisit({ id: homeVisit?.homeVisitId, onSuccess: succeededActionModelHandler });
  }, [rejectHomeVisit, homeVisit?.homeVisitId, succeededActionModelHandler]);
  const { showRejectModal, RejectModal } = useHealthAssistanceRejectModal();

  return (
    <>
      <Button
        loading={isLoading}
        icon={<CloseIcon />}
        disabled={!homeVisit?.canBeRejected}
        onClick={showRejectModal(onClickHandler)}
        $buttonType="decline"
        type="primary"
        size="large"
      >
        Отклонить
      </Button>
      <RejectModal />
    </>
  );
};
