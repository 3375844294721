import { FC, useState } from 'react';

import { useScheduleDeleteSlotByDoctorMutation } from 'entity/schedule/hooks/use-schedule-delete-slot-dy-doctor-mutation';
import { CustomButton } from 'shared/ui/custom-button';

import { DeleteSlotButton, StyledCustomModal } from './styles';

interface Props {
  slotId: number;
}

export const ScheduleDeleteSlotByDoctorButton: FC<Props> = ({ slotId }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleCloseModal = () => setIsModalVisible(false);

  const handleOpenModal = () => setIsModalVisible(true);

  const [deleteSlot] = useScheduleDeleteSlotByDoctorMutation();

  const onSubmit = () => {
    deleteSlot({ slotId });
    handleCloseModal();
  };

  return (
    <>
      <DeleteSlotButton onClick={handleOpenModal}>Удалить слот</DeleteSlotButton>

      <StyledCustomModal
        title="Удалить слот"
        description="Вы уверены что хотите удалить временной слот?"
        visible={isModalVisible}
        onClose={handleCloseModal}
        actionButtons={[
          <CustomButton key={1} onClick={handleCloseModal} $buttonType="declineWhite">
            Отмена
          </CustomButton>,
          <CustomButton key={2} onClick={onSubmit} $buttonType="submit">
            Удалить
          </CustomButton>,
        ]}
      />
    </>
  );
};
