import styled from 'styled-components';

export const ChatPageLayout = styled.div`
  background: ${({ theme }) => theme.colors.c4};
  display: flex;
  height: calc(100vh - 100px);
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
`;

export const LeftAside = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.c6};
  width: 395px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
