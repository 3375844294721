import styled from 'styled-components';

export const StyledButtonsFooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.palette.white};
  height: 80px;
  display: flex;
  justify-content: space-around;
  padding: 16px;
  align-items: center;
`;
