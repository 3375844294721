import { Card, Layout } from 'antd';
import styled from 'styled-components';

export const AuthLayoutContainer = styled(Layout)`
  min-height: 100vh;
  display: flex;
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
`;

export const AuthCard = styled(Card)`
  background-color: #f5f5f5;
  width: 500px;
  min-height: 300px;
`;

export const AuthCardContent = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
