import { Divider } from 'antd';
import styled from 'styled-components';

export const AdminDoctorInfoPageContainer = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
`;

export const AdminDoctorPageInfoDivider = styled(Divider)`
  height: 100%;
`;

export const AdminDoctorPageRecodingTable = styled.div`
  margin-top: 32px;
  width: 100%;
`;

export const AdminDoctorPageRecodingTableTitle = styled.div`
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 700;
`;
