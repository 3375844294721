import { type FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import type { InternalScheduleOnlineAppointmentEvent } from 'entity/schedule/types/internal-schedule-online-appointment-event';
import { routePatches } from 'shared/routes';
import { Button } from 'shared/ui/button';

type Props = {
  event: InternalScheduleOnlineAppointmentEvent;
};

export const ScheduleConnectToOnlineAppointmentByDoctorButton: FC<Props> = ({ event }) => {
  const navigate = useNavigate();

  const { id, start, end } = event;

  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const currentTime = new Date();
      const startTIme = new Date(start);
      const endTime = new Date(end);

      if (currentTime >= startTIme && currentTime <= endTime) setIsButtonVisible(true);
      else setIsButtonVisible(false);
    };

    checkTime();

    const interval = setInterval(checkTime, 1000);

    return () => clearInterval(interval);
  }, [start, end]);

  if (!isButtonVisible) return null;

  const handleClickConnect = () => navigate(routePatches.DoctorOnlineAppointment.path(id));

  return (
    <Button type="text" $buttonType="submit" onClick={handleClickConnect}>
      Подключиться
    </Button>
  );
};
