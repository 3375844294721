import { type FC } from 'react';

import { Avatar, TableColumnsType } from 'antd';

import type { InternalDoctor } from 'entity/doctor/types/internal-doctor';
import type {
  OnlineAppointmentPickedTimeSlot,
  OnlineAppointmentTimeSlot,
  TimeSlot,
} from 'entity/doctor-times/types/online-appointment-time-slot';
import { Tag } from 'shared/ui/tag';
import {
  StyledList,
  StyledTable,
  StyledTagContainer,
  StyledTagsContainer,
} from 'widgets/admin-chose-doctor-date-time-widget/ui/choice-doctor-time/styles';

interface Props {
  handlePickOnlineAppointmentSlot: (timeSlot: OnlineAppointmentPickedTimeSlot) => void;
  doctorSlots: OnlineAppointmentTimeSlot[];
}

export const OnlineAppointmentChoiceDoctorTimeWidget: FC<Props> = ({
  handlePickOnlineAppointmentSlot,
  doctorSlots,
}) => {
  const columns: TableColumnsType<OnlineAppointmentTimeSlot> = [
    {
      title: 'Врач',
      dataIndex: 'doctor',
      ellipsis: true,
      render: (item: InternalDoctor) => {
        return (
          <StyledList
            avatar={<Avatar size={40} src={item.image} />}
            title={item.fullName}
            description={item.specialization}
          />
        );
      },
    },
    {
      title: 'Свободные слоты',
      dataIndex: 'timeSlots',
      ellipsis: true,
      render: (timeSlots: TimeSlot[], row) => {
        return (
          <StyledTagsContainer>
            {timeSlots?.map((timeSlot) => (
              <StyledTagContainer key={timeSlot.id}>
                <Tag
                  $tagSize="small"
                  onClick={() =>
                    handlePickOnlineAppointmentSlot({
                      specialization: row.doctor.specialization,
                      doctorName: row.doctor.fullName,
                      doctorId: row.doctor.id,
                      time: timeSlot.time,
                      date: timeSlot.date,
                      onlineAppointmentSlotId: timeSlot.id,
                    })
                  }
                  $tagType="lightGreen"
                  key={timeSlot.id}
                >
                  {timeSlot.time}
                </Tag>
              </StyledTagContainer>
            ))}
          </StyledTagsContainer>
        );
      },
    },
  ];

  return <StyledTable tableLayout="auto" columns={columns} dataSource={doctorSlots} />;
};
