import { Empty } from 'antd';
import styled from 'styled-components';

export const ScheduleContainer = styled.div`
  background: ${({ theme }) => theme.colors.palette.white};
  height: 100%;
`;

export const StyledEmpty = styled(Empty)`
  margin-top: 100px;
`;
