import React, { type FC } from 'react';

import { StyledSpace } from 'entity/health-assistance/styles';
import { AdminHealthAssistanceDetailsStyledCard } from 'entity/health-assistance/ui/health-assistance-details-container/styles';

interface Props {
  children: React.ReactNode;
}

export const AdminHealthAssistanceDetailsContainer: FC<Props> = ({ children }) => {
  return (
    <AdminHealthAssistanceDetailsStyledCard>
      <StyledSpace direction="vertical" size="middle">
        {children}
      </StyledSpace>
    </AdminHealthAssistanceDetailsStyledCard>
  );
};
