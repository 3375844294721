import { FC } from 'react';

import type { DatePickerProps } from 'antd';

import { CalendarIcon } from 'shared/ui/icons';

import { DatePickerWrapperStyles, StyledDatePicker } from './styles';

export const DatePicker: FC<DatePickerProps> = (props) => {
  return (
    <>
      <DatePickerWrapperStyles />
      <StyledDatePicker placeholder="Выберите дату" {...props} suffixIcon={<CalendarIcon />} />
    </>
  );
};
