import { useCallback } from 'react';

import { useOnlineVisitSlotControllerAddSlotMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  startIsoDateTime: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useScheduleAddSlotByDoctorMutation = () => {
  const [mutation] = useOnlineVisitSlotControllerAddSlotMutation();
  const action = useCallback(
    ({ startIsoDateTime, onSuccess, onError }: MutationHookOptions) => {
      mutation({ startIsoDateTime })
        .unwrap()
        .then(() => {
          successNotification('Новый слот успешно создан');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при создании слота');
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
