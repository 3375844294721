import React, { FC } from 'react';

import { AdminRecordingsAddButton, StyledPlusIcon, StyledTypographyText } from 'shared/ui/add-button/style';

export type AddButtonProps = {
  onClick?: () => void;
  children: React.ReactNode;
};

export const AddButton: FC<AddButtonProps> = (props) => {
  const { onClick, children } = props;
  return (
    <AdminRecordingsAddButton onClick={onClick}>
      <StyledPlusIcon />
      <StyledTypographyText>{children}</StyledTypographyText>
    </AdminRecordingsAddButton>
  );
};
