import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-items: stretch;
  max-height: 100%;
  > div:first-child {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 484px;
    max-height: 100%;
  }
  > div:last-child {
    flex-grow: 1;
    width: 100%;
  }
`;
