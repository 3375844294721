import React, { type FC } from 'react';

import { Space } from 'antd';

import { HealthAssistanceStatusCheckList } from 'entity/health-assistance/ui/health-assistance-status-dropdown-check-list/health-assistance-status-check-list';
import type { SelectItem } from 'shared/ui/check-list';
import { Dropdown } from 'shared/ui/dropdown';
import { FilterIcon } from 'shared/ui/icons';

interface Props {
  items: SelectItem<string>[];
}

export const HealthAssistanceStatusDropdownCheckList: FC<Props> = ({ items }) => {
  return (
    <Dropdown overlay={<HealthAssistanceStatusCheckList items={items} />}>
      <Space size="small">
        Статус
        <FilterIcon />
      </Space>
    </Dropdown>
  );
};
