import { FC, useCallback } from 'react';

import type { InputProps } from 'antd';

import { SearchParams } from 'entity/health-assistance/constants';
import { useUrlSearch } from 'shared/hooks/use-url-search';
import { Cross2Icon, SearchIcon } from 'shared/ui/icons';

import { StyledInput } from './styles';

export const HealthAssistanceSearchInput: FC<InputProps> = (props) => {
  const { search, setSearch } = useUrlSearch(SearchParams.Search);
  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [setSearch],
  );

  return (
    <StyledInput
      defaultValue={search}
      prefix={<SearchIcon />}
      onChange={handleSearch}
      placeholder="Введите ФИО пациента или врача"
      allowClear={{
        clearIcon: <Cross2Icon />,
      }}
      {...props}
    />
  );
};
