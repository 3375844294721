import { FC } from 'react';

import { CustomRow } from 'shared/ui/row/custom-row';
import { StyledText } from 'shared/ui/row/text-row/styles';

export interface TextRowProps {
  title: string;
  text: string;
  editMode?: boolean;
  editHandler?: () => void;
}

export const TextRow: FC<TextRowProps> = ({ title, text, editMode, editHandler }) => {
  return (
    <CustomRow title={title} editMode={editMode} editHandler={editHandler}>
      <StyledText>{text}</StyledText>
    </CustomRow>
  );
};
