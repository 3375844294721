import React from 'react';

import { AdminHealthAssistanceDetailsContainer } from 'entity/health-assistance/ui/health-assistance-details-container/admin-health-assistance-details-container';
import { SymptomTagsRow } from 'entity/health-assistance/ui/symptoms-tag-row';
import type { InternalHomeVisitFormDetails } from 'entity/home-visit/types/internal-home-visit-form-details';
import { InternalHomeVisitStatusEnum } from 'entity/home-visit/types/internal-home-visit-status-enum';
import { formatDateDMAndCallTime } from 'shared/lib/format';
import { InputRow } from 'shared/ui/row/input-row';
import { TextAreaRow } from 'shared/ui/row/text-area-row';
import { TextRow } from 'shared/ui/row/text-row';

interface Props {
  editHandler?: () => void;
  onAddressChange: (data: string) => void;
  homeVisit: InternalHomeVisitFormDetails;
}

export const AdminHomeVisitAppointmentDetailsForm = ({ editHandler, homeVisit, onAddressChange }: Props) => {
  if (!homeVisit) return null;
  const editMode = homeVisit.status === InternalHomeVisitStatusEnum.InProgressByCurrentAdmin;
  return (
    <AdminHealthAssistanceDetailsContainer>
      <TextRow title="Тип приема" text="Вызов на дом" />
      <TextRow title="Пациент" text={homeVisit.patientName} />
      <TextRow title="Телефон" text={homeVisit.patientPhone} />
      <InputRow
        disabled={!editMode}
        onChange={(e) => onAddressChange(e.target.value)}
        title="Адрес вызова"
        defaultValue={homeVisit.address}
      />
      <TextRow editMode={editMode} editHandler={editHandler} title="Специализация" text={homeVisit.specialization} />
      <TextRow editMode={editMode} editHandler={editHandler} title="Врач" text={homeVisit.doctorName} />
      <TextRow
        editMode={editMode}
        editHandler={editHandler}
        title="Дата и время"
        text={formatDateDMAndCallTime(homeVisit.date, homeVisit.time)}
      />
      <TextRow title="Стоимость" text={homeVisit.price} />
      <TextRow title="Статус оплаты" text={homeVisit.isPaid ? 'Оплачено' : 'Не оплачено'} />
      <SymptomTagsRow tags={homeVisit.symptoms} />
      {homeVisit.comments && <TextAreaRow title="Комментарий" text={homeVisit.comments} />}
    </AdminHealthAssistanceDetailsContainer>
  );
};
