/* eslint no-restricted-imports: 0 */

import { ConfigProvider, Empty, Table, TableProps } from 'antd';

import { EmptyIcon } from 'shared/ui/icons';
import { AppTableContainer } from 'shared/ui/table2/styles';

export const AppTable = <T extends object>(props: TableProps<T>) => {
  return (
    <ConfigProvider
      renderEmpty={() => <Empty description="Нет данных" image={<EmptyIcon width={100} height={100} />} />}
    >
      <AppTableContainer>
        <Table<T> {...props} />
      </AppTableContainer>
    </ConfigProvider>
  );
};
