import { FC } from 'react';

import { HealthAssistanceDetailsLayout } from 'entity/health-assistance/ui/health-assistance-details-layout';
import { AdminReturnToPastHomeVisitsListButton } from 'feature/admin-return-to-past-home-visits-list/admin-return-to-past-home-visits-list-button';
import { AdminPastHomeVisitDetails } from 'widgets/admin-past-home-visit-details/ui/admin-past-home-visit-details';

export const AdminPastHomeVisitDetailsPage: FC = () => {
  document.title = 'Домашний визит';

  return (
    <HealthAssistanceDetailsLayout
      ReturnButton={<AdminReturnToPastHomeVisitsListButton />}
      ActionButtons={[]}
      LeftSide={<AdminPastHomeVisitDetails />}
    />
  );
};
