import { Form, Input, Radio } from 'antd';
import styled from 'styled-components';

import { CustomTimePicker } from 'shared/ui/custom-time-picker';
import { DatePicker } from 'shared/ui/data-picker';

const { TextArea } = Input;
const { Item } = Form;
const { Group } = Radio;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const StyledForm = styled(Form)`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StyledTimePicker = styled(CustomTimePicker)`
  &&& .ant-select-selector {
    width: 188px;
    background-color: ${({ theme }) => theme.colors.palette.white};
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 188px;
  border-radius: ${({ theme }) => theme.radii.small};

  &.ant-picker-status-error {
    border: 1px solid #ff4d4f;
  }
`;

export const StyledTextArea = styled(TextArea)<{ resize?: string }>`
  width: 400px;

  .ant-input {
    resize: ${({ resize }) => resize ?? 'none'};
    border-radius: ${({ theme }) => theme.radii.small};
    padding: 8px 16px;
    font-size: ${({ theme }) => theme.fontSizes.default};
  }
`;

export const StyledFormItem = styled(Item)`
  .ant-typography {
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }

  .ant-typography:after {
    content: '*';
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.c3};
  }
`;

export const StyledRadio = styled(Group)`
  .ant-radio-inner:after {
    background-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }

  .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.palette.dustblu};
  }

  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-input:checked + .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio-input,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-checked:after {
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
`;
