import { type FC } from 'react';

import { type ButtonProps } from 'antd';

import { useOnlineAppointmentAssignAppointmentByAdminMutation } from 'entity/online-appointment/hooks/use-online-appointment-assing-by-admin';
import theme from 'shared/theme';
import { Button } from 'shared/ui/button';
import { DocumentTakeTaskIcon } from 'shared/ui/icons';
import { TypographyText } from 'shared/ui/typography';

type Props = {
  onlineAppointmentId: number;
};

export const OnlineAppointmentToTakeInProgressByAdminButton: FC<ButtonProps & Props> = ({
  onlineAppointmentId,
  disabled,
  ...props
}) => {
  const [assignAppointment] = useOnlineAppointmentAssignAppointmentByAdminMutation();

  const handleClick = () => assignAppointment({ id: onlineAppointmentId });

  return (
    <Button
      icon={<DocumentTakeTaskIcon color={disabled ? undefined : theme.colors.palette.columnOfOakGreen} />}
      $buttonType={disabled ? 'submitWhiteDisabled' : 'submitWhite'}
      onClick={handleClick}
      type="primary"
      size="large"
      {...props}
    >
      <TypographyText>Взять в работу</TypographyText>
    </Button>
  );
};
