import { useMemo } from 'react';

import { externalHomeVisitDetailsToInternalHomeVisitDetailsMapper } from 'entity/home-visit/mappers/external-home-visit-details-to-internal-home-visit-details-mapper';
import { useAdminVisitsControllerGetOneHomeVisitQuery } from 'shared/api/rtk-api';

export const useHomeVisitDetailsQuery = (homeVisitId?: string) => {
  const id = Number(homeVisitId);
  if (!id) throw new Error('id is not defined');
  const { data, ...others } = useAdminVisitsControllerGetOneHomeVisitQuery(id);

  const homeVisit = useMemo(() => {
    return data ? externalHomeVisitDetailsToInternalHomeVisitDetailsMapper(data) : undefined;
  }, [data]);

  return {
    homeVisit,
    ...others,
  };
};
