import { Space } from 'antd';
import styled from 'styled-components';

import { DatePicker } from 'shared/ui/data-picker';

export const StyledSpace = styled(Space)`
  width: 100% !important;
`;

export const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 24px;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 228px;
`;
