/* eslint @typescript-eslint/no-misused-promises: "off" */

import { type ChangeEvent, type FC, type KeyboardEvent, useEffect, useRef, useState } from 'react';

import { UserEntity } from '@pubnub/react-chat-components';
import { generateUUID } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import { transliterate } from 'transliteration';

import { useCurrentUserQuery } from 'entity/auth/hooks/use-current-user-query';
import { useUploadDocumentToPatientMutation } from 'entity/doctor/api';
import { useReadChatMutation } from 'entity/doctor-chat/hooks/use-read-chat-mutation';
import { MessageType } from 'entity/pubnub/lib/utils/message-helper-payload';
import { OpenFile, SendMessageIcon } from 'shared/ui/icons';

import { SendMessageButton, SendMessageContainer, SendMessageFileInputContainer, SendMessageTextarea } from './styles';

type TextMessageType = {
  type: MessageType;
  message?: string;
  filename?: string;
  fileId?: string;
};

type Props = {
  selectedChatData: UserEntity;
};

export const SendMessage: FC<Props> = ({ selectedChatData }: Props) => {
  const pubnub = usePubNub();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [textareaValue, setTextareaValue] = useState('');
  const currentDate = new Date();

  const [readChat] = useReadChatMutation();
  const [uploadFile] = useUploadDocumentToPatientMutation();

  const { currentUser } = useCurrentUserQuery();

  const pushToken = currentUser?.pushToken;
  const senderName = currentUser?.name;

  useEffect(() => {
    setTextareaValue('');
  }, [selectedChatData]);

  const handleIconClick = () => fileInputRef.current?.click();

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const handleSendClick = () => textareaValue.trim() && handleEnterPress();

  const handlePublishMessage = (text: TextMessageType) => {
    pubnub.publish(
      {
        channel: selectedChatData.eTag,
        message: {
          createdAt: currentDate,
          id: generateUUID(),
          text,
          pn_fcm: {
            notification: {
              title: 'Медгард',
              body: `${senderName}: ${textareaValue || text.filename}`,
            },
            data: {
              title: 'Медгард',
              body: `${senderName}: ${textareaValue || text.filename}`,
              chatId: selectedChatData.eTag,
            },
            android: { priority: 'high' },
            apns: {
              payload: {
                aps: {
                  content_available: true,
                  sound: 'default',
                },
              },
            },
            pn_exceptions: pushToken ? [pushToken] : [],
          },
          pn_debug: false,
          pn_dry_run: false,
        },
      },
      (status) => {
        if (!status.error) setTextareaValue('');
      },
    );
  };

  const handleEnterPress = () => {
    const messageText = { type: MessageType.TEXT, message: textareaValue };
    handlePublishMessage(messageText);
  };

  const handleTextareaKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && textareaValue.trim()) {
      handleEnterPress();
    }
  };

  const doUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (textareaValue) {
      const messageText = { type: MessageType.TEXT, message: textareaValue };
      handlePublishMessage(messageText);
    }
    const file = event.target.files?.[0];

    if (!file) return;

    const { eTag, id } = selectedChatData;
    const transliterateName = transliterate(file.name);

    const bodyFormData = new FormData();
    bodyFormData.append('document', file, transliterateName);
    bodyFormData.append('userId', id.toString());
    bodyFormData.append('chatId', eTag);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    const uploadedFile: any = await uploadFile(bodyFormData);
    readChat({ chatUUID: eTag });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (uploadedFile.data) {
      const messageText = {
        type: MessageType.FILE,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        filename: uploadedFile.data.name as string,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        fileId: uploadedFile.data.id as string,
      };
      handlePublishMessage(messageText);
    }
  };

  return (
    <SendMessageContainer>
      <SendMessageFileInputContainer onClick={handleIconClick}>
        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={doUpload} />
        <OpenFile />
      </SendMessageFileInputContainer>
      <SendMessageTextarea
        autoSize={{ maxRows: 5 }}
        value={textareaValue}
        onChange={(event) => setTextareaValue(event.target.value)}
        onKeyDown={handleTextareaKeyDown}
        placeholder="Сообщение"
      />
      <SendMessageButton onClick={handleSendClick}>
        <SendMessageIcon />
      </SendMessageButton>
    </SendMessageContainer>
  );
};
