import moment, { Moment } from 'moment/moment';

export function formatDate(ISO: string): string {
  return new Date(ISO).toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
  });
}

export function formatDateDMAndCallTime(ISO: string, callTime?: string): string {
  const timePart = callTime ? `, ${callTime}` : '';
  return `${formatDate(ISO)}${timePart}`;
}

export function formatDateTime(ISO: string): string {
  return new Date(ISO).toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
}

export function formatTimestamp(timestamp: number): string {
  return new Date(timestamp).toLocaleString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
}

export function formatDateDMY(ISO: string): string {
  return new Date(ISO).toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
}

export const timeConvertor = (createdAt: string) => {
  return moment(createdAt).format('HH:mm');
};

export function formatPrice(price: number): string {
  return `${price} ₽`;
}

export function getDayWord(n: number): string {
  const textForms = ['день', 'дня', 'дней'];
  const round = Math.abs(n) % 100;
  const n1 = round % 10;
  if (round > 10 && round < 20) return textForms[2];
  if (n1 > 1 && n1 < 5) return textForms[1];
  if (n1 === 1) return textForms[0];
  return textForms[2];
}

// +79277143251 or 89277143251 => +7 (792) 771-43-25
export function formatPhone(phone: string): string {
  return phone.replace(/\+?(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
}

// 'YYYY-MM-DD' or UTC string date to 'YYYY-MM-DD'
export const formatDateYMD = (date: string): string => moment(date).format('YYYY-MM-DD');

// Get start of the current week (e.g. today is tuesday 2024-07-16, func().format() => 2024-07-14T00:00:00Z)
export const getStartOfTheCurrentWeek = () => moment().startOf('week');

// Get last millisecond of the current week (e.g. today is tuesday 2024-07-16, func().format() => 2024-07-20T23:59:59.999Z)
export const getEndOfTheCurrentWeek = () => moment().endOf('week');

// format Moment date to "DD.MM"
export const formatDateDM = (date: Moment): string => date.format('DD.MM');

// Add amount of days to the date without mutation
export const addDaysToDate = (date: Moment, amount: number) => date.clone().add(amount, 'days');

// Subtract amount of days to the date without mutation
export const subtractDaysToDate = (date: Moment, amount: number) => date.clone().subtract(amount, 'days');

// set hours and minutes to ISO
export const setHoursAndMinutes = (ISO: string, hours: number, minutes: number) => moment(ISO).set({ hours, minutes });

// Get start of the date
export const getStartOfTheDate = (date: Moment) => moment(date).startOf('day').format();

// Get time from ISOdate in format "HH.mm" (e.g. 02.50, 10.30, 23.00)
export const getTimeFromDate = (ISO: string) => moment(ISO).format('HH.mm');

// Get start of the ISO date or string date (e.g. 24.08.2024/ISO date => start of the Moment date)
export const getStartOfStringDate = (date: string) => moment(date).startOf('date');

// Get end of the ISO date or string date (e.g. 24.08.2024/ISO date => end of the Moment date)
export const getEndOfStringDate = (date: string) => moment(date).endOf('date');

/**
 * Converts a given date to ISO 8601 string format.
 * This function accepts either a string representing a date or a Moment object,
 * and converts it to an ISO 8601 formatted string.
 *
 * @param {string | Moment} date - The date to be converted. It can be a string or a Moment object.
 * @return {string} The date formatted as an ISO 8601 string.
 *
 * @example
 * // Convert a date string to ISO format
 * convertToISO('2024-09-02'); // Returns '2024-09-02T00:00:00.000Z'
 *
 * @example
 * // Convert a Moment object to ISO format
 * const momentDate = moment('2024-09-02');
 * convertToISO(momentDate); // Returns '2024-09-02T00:00:00.000Z'
 */
export const convertToISO = (date: string | Moment) => moment(date).toISOString();

/**
 * Adds one second to a given ISO 8601 date string.
 * This function takes an ISO 8601 formatted date string, adds one second to it,
 * and returns the resulting date in ISO 8601 format.
 *
 * @param {string} ISO - The date string in ISO 8601 format to which one second will be added.
 * @return {string} The updated date, with one second added, formatted as an ISO 8601 string.
 *
 * @example
 * // Add one second to an ISO date string
 * addSecondToDate('2024-09-02T14:37:09Z'); // Returns '2024-09-02T14:37:10Z'
 *
 * @example
 * // Add one second to an ISO date string with milliseconds
 * addSecondToDate('2024-09-02T14:37:09.123Z'); // Returns '2024-09-02T14:37:10.123Z'
 */
export const addSecondToDate = (ISO: string) => moment(ISO).add(1, 'second').toISOString();

/**
 * Checks if the first date is after the second date.
 * This function compares two dates (either in string format or as Moment objects)
 * and determines if the first date occurs after the second date.
 *
 * @param {string | Moment} firstDate - The first date to compare, either as a string or a Moment object.
 * @param {string | Moment} secondDate - The second date to compare, either as a string or a Moment object.
 * @return {boolean} Returns `true` if the first date is after the second date, otherwise `false`.
 *
 * @example
 * // Check if one date is after another
 * isFirstDateAfter('2024-09-06', '2024-09-05'); // Returns true
 *
 * @example
 * // Check if one date is after another using Moment objects
 * isFirstDateAfter(moment('2024-09-06'), moment('2024-09-05')); // Returns true
 */
export const isFirstDateAfter = (firstDate: string | Moment, secondDate: string | Moment) =>
  moment(firstDate).isAfter(secondDate);

/**
 * Calculates the duration between two dates.
 * This function takes two dates (either in string format or as Moment objects)
 * and returns the duration between them as a Moment.js duration object.
 *
 * @param {string | Moment} firstDate - The first date to compare, either as a string or a Moment object.
 * @param {string | Moment} secondDate - The second date to compare, either as a string or a Moment object.
 * @return {Duration} The duration between the two dates as a Moment.js duration object.
 *
 * @example
 * const duration = getDurationBetweenDates('2024-09-06T11:30:00Z', '2024-09-05T10:15:00Z');
 * console.log(duration); // Outputs a Moment.js duration object
 */
export const getDurationBetweenDates = (firstDate: string | Moment, secondDate: string | Moment) =>
  moment.duration(moment(firstDate).diff(moment(secondDate)));
