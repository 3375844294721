import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Col, Form, Row, Typography } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import moment, { Moment } from 'moment';

import { BackButtonSubheader } from 'feature/navigation/ui/back-button-subheader';
import { Button } from 'shared/ui/button';
import { CustomRow } from 'shared/ui/row/custom-row';

import { ContentWrapper, FormLine, StyledDatePicker, StyledFormItem, StyledSelectRow, StyledTextArea } from './styles';
// import { PDFViewer, pdf } from "@react-pdf/renderer";
// import { ProtocolPDFTemplate } from "./ui/pdf-template";
const { Title, Paragraph } = Typography;

const mockCities = [
  { value: 'minsk', label: 'Минск' },
  { value: 'warsaw', label: 'Варшава' },
  { value: 'berlin', label: 'Берлин' },
  { value: 'london', label: 'Лондон' },
];

type ProtocolForm = {
  field1: string;
  field2: Moment;
  field3: string;
  field4: string;
  field5: string;
  field6: string;
  field7: string;
  field8: string;
  field9: string;
};

// TODO: fix comments during the integration
export const ProtocolPage: FC = () => {
  document.title = 'Добавить протокол';

  const {
    // handleSubmit,
    control,
    formState: { errors },
    // reset,
  } = useForm<ProtocolForm>();

  // const onSubmit: SubmitHandler<ProtocolForm> = async () => {
  //   const blob = await pdf(<ProtocolPDFTemplate />).toBlob();
  //   reset();
  // };

  const disabledDate: RangePickerProps<Moment>['disabledDate'] = (current) =>
    current && current > moment().startOf('day');

  return (
    <ContentWrapper>
      {/* <PDFViewer width="100%" height="555px">
        <ProtocolPDFTemplate />
      </PDFViewer> */}

      <BackButtonSubheader title="Онлайн прием" />
      <Row align="top">
        <Col span={20}>
          <Title level={4}>Создание протокола</Title>
          <br />
          <Form name="protocol-form">
            <Row>
              <Col span={7}>
                <Paragraph>
                  <FormLine strong>Врач</FormLine>
                </Paragraph>

                <FormLine>Баженова Анна Ивановна</FormLine>
              </Col>
              <Col span={7}>
                <Paragraph>
                  <FormLine strong>Пациент</FormLine>
                </Paragraph>

                <FormLine>Белова Анна Ивановна</FormLine>
              </Col>
              <Col span={7}>
                <Form.Item>
                  <Controller
                    name="field1"
                    control={control}
                    render={({ field }) => (
                      <StyledSelectRow
                        {...field}
                        title="Город пациента"
                        placeholder="Выберите город"
                        options={mockCities}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <Paragraph>
                  <FormLine strong>Дата и время</FormLine>
                </Paragraph>
                <FormLine>20 июля, 08:00-08:30</FormLine>
              </Col>
              <Col span={7}>
                <Form.Item>
                  <Controller
                    name="field2"
                    control={control}
                    render={({ field }) => (
                      <CustomRow title="Дата рождения пациента">
                        <StyledDatePicker {...field} placeholder="Выберите дату" disabledDate={disabledDate} />
                      </CustomRow>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={21}>
                <StyledFormItem validateStatus={errors.field3 ? 'error' : ''}>
                  <Controller
                    name="field3"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomRow title="Жалобы">
                        <StyledTextArea {...field} placeholder="Введите описание" autoSize />
                      </CustomRow>
                    )}
                  />
                </StyledFormItem>
              </Col>
            </Row>
            <Row>
              <Col span={21}>
                <StyledFormItem validateStatus={errors.field4 ? 'error' : ''}>
                  <Controller
                    name="field4"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomRow title="Анамнез">
                        <StyledTextArea {...field} placeholder="Введите описание" autoSize />
                      </CustomRow>
                    )}
                  />
                </StyledFormItem>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <StyledFormItem validateStatus={errors.field5 ? 'error' : ''}>
                  <Controller
                    name="field5"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomRow title="Диагноз">
                        <StyledTextArea {...field} placeholder="Введите описание" autoSize />
                      </CustomRow>
                    )}
                  />
                </StyledFormItem>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <StyledFormItem validateStatus={errors.field6 ? 'error' : ''}>
                  <Controller
                    name="field6"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomRow title="Рекомендации">
                        <StyledTextArea {...field} placeholder="Введите описание" autoSize />
                      </CustomRow>
                    )}
                  />
                </StyledFormItem>
              </Col>
              <Col span={10} offset={1}>
                <StyledFormItem validateStatus={errors.field7 ? 'error' : ''}>
                  <Controller
                    name="field7"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <StyledSelectRow
                        {...field}
                        title="Рекомендованные услуги"
                        placeholder="Выберите рекомендованные услуги"
                        options={mockCities}
                      />
                    )}
                  />
                </StyledFormItem>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <StyledFormItem validateStatus={errors.field8 ? 'error' : ''}>
                  <Controller
                    name="field8"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomRow title="Направление">
                        <StyledTextArea {...field} placeholder="Введите описание" autoSize />
                      </CustomRow>
                    )}
                  />
                </StyledFormItem>
              </Col>
              <Col span={10} offset={1}>
                <StyledFormItem validateStatus={errors.field9 ? 'error' : ''}>
                  <Controller
                    name="field9"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <StyledSelectRow {...field} title="Услуги" placeholder="Выберите услуги" options={mockCities} />
                    )}
                  />
                </StyledFormItem>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={4}>
          <Button
            type="text"
            $buttonType="submit"
            htmlType="submit"
            loading={false}
            // onClick={handleSubmit(onSubmit)}
          >
            Сохранить
          </Button>
        </Col>
      </Row>
    </ContentWrapper>
  );
};
