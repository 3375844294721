import { List } from 'antd';
import styled from 'styled-components';

import { StyledScrollableContainer } from 'shared/ui/scrollable/styles';

export const NotificationListContainer = styled(StyledScrollableContainer)`
  max-height: 60vh;
`;

export const StyledListItem = styled(List.Item)`
  align-items: stretch;
  .ant-list-item-meta-content {
    width: auto;
  }
`;
