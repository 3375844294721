import { useMemo } from 'react';

import { useChatControllerGetUniqueSpecializationsQuery } from 'shared/api/rtk-api';

export const useSpecializationListQuery = () => {
  const { data, ...others } = useChatControllerGetUniqueSpecializationsQuery();
  const specializations = useMemo(() => (data ?? []).map((item) => item), [data]);
  return {
    specializations,
    ...others,
  };
};
