import React, { type FC, PropsWithChildren } from 'react';

import { Outlet } from 'react-router-dom';

import { Container, ContentWrapper } from './styles';

// eslint-disable-next-line react/prop-types
export const AppLayout: FC<PropsWithChildren> = () => {
  return (
    <Container>
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
    </Container>
  );
};
