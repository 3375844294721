import { type FC } from 'react';

import { type ButtonProps } from 'antd';

import { useHealthAssistanceApproveModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-approve-modal';
import { useOnlineAppointmentAcceptAppointmentByAdminMutation } from 'entity/online-appointment/hooks/use-online-appointment-accept-by-admin';
import type { InternalOnlineAppointment } from 'entity/online-appointment/types/internal-online-appointment';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

interface Props {
  onlineAppointmentData: InternalOnlineAppointment;
}

export const OnlineAppointmentApproveByAdminDropdownMenuButton: FC<Props & ButtonProps> = ({
  onlineAppointmentData,
  ...props
}) => {
  const [acceptAppointment] = useOnlineAppointmentAcceptAppointmentByAdminMutation();

  const { id, doctorId, onlineAppointmentSlotId } = onlineAppointmentData;

  const onClickHandler = () => {
    acceptAppointment({
      id,
      doctorId,
      onlineVisitSlotId: onlineAppointmentSlotId,
    });
  };

  const { showApproveModal, ApproveModal } = useHealthAssistanceApproveModal();

  return (
    <>
      <DropdownMenuButton onClick={showApproveModal(onClickHandler)} {...props}>
        <TypographyText>Подтвердить</TypographyText>
      </DropdownMenuButton>
      <ApproveModal />
    </>
  );
};
