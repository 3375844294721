import React from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { AppGlobalStyles } from 'app/global-styles';
import { Routes } from 'app/router';
import { store } from 'app/store-setup';
import { config } from 'shared/config';
import theme from 'shared/theme';

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppGlobalStyles />
        <BrowserRouter basename={config.basename}>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
