import { FC } from 'react';

import { HealthAssistanceDetailsLayout } from 'entity/health-assistance/ui/health-assistance-details-layout';
import { AdminReturnToPastOnlineAppointmentsListButton } from 'feature/admin-return-to-past-online-appointments-list/admin-return-to-past-online-appointments-list-button';
import { AdminPastOnlineAppointmentDetails } from 'widgets/admin-past-online-appointment-details/ui/admin-past-online-appointment-details';

export const AdminPastOnlineAppointmentDetailsPage: FC = () => {
  document.title = 'Онлайн-прием';

  return (
    <HealthAssistanceDetailsLayout
      ReturnButton={<AdminReturnToPastOnlineAppointmentsListButton />}
      ActionButtons={[]}
      LeftSide={<AdminPastOnlineAppointmentDetails />}
    />
  );
};
