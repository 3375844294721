export enum MenuUrlPaths {
  HomeVisit = 'home-visit',
  OnlineAppointment = 'online-appointment',
  ChatSupport = 'chat-support',
}

export enum SearchParams {
  Search = 'search',
  Date = 'date',
  Status = 'status',
}
