import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';

import theme from 'shared/theme';

export type StyledButtonPropsType = 'submit' | 'decline' | 'disabled' | 'declineWhite';

const calculateColor = ({ $buttonType }: { $buttonType: StyledButtonPropsType }) => {
  switch ($buttonType) {
    case 'submit':
      return theme.colors.palette.white;
    case 'decline':
      return theme.colors.palette.purehearted;
    case 'disabled':
      return theme.colors.palette.white;
    case 'declineWhite':
      return theme.colors.palette.columnOfOakGreen;
    default:
      return undefined;
  }
};

const calculateBackgroundColor = ({ $buttonType }: { $buttonType: StyledButtonPropsType }) => {
  switch ($buttonType) {
    case 'submit':
      return theme.colors.palette.columnOfOakGreen;
    case 'decline':
      return theme.colors.palette.aquarelleRed;
    case 'declineWhite':
      return theme.colors.palette.white;
    case 'disabled':
      return theme.colors.palette.dustblu;
    default:
      return undefined;
  }
};

const calculateBorderColor = ({ $buttonType }: { $buttonType: StyledButtonPropsType }) => {
  switch ($buttonType) {
    case 'declineWhite':
      return theme.colors.palette.columnOfOakGreen;
    default:
      return undefined;
  }
};

export interface CustomButtonProps extends ButtonProps {
  $buttonType: StyledButtonPropsType;
}

export const StyledCustomButton = styled(Button)<CustomButtonProps>`
  &&&& {
    color: ${(props) => calculateColor(props)};
    background-color: ${(props) => calculateBackgroundColor(props)};
    border-color: ${(props) => calculateBorderColor(props)};
    font-size: 16px;
    min-width: 180px;
    min-height: 48px;
  }
`;
