import { FC } from 'react';

import { Typography } from 'antd';

import { CustomRow } from 'shared/ui/row/custom-row';
import { StyledCard } from 'shared/ui/row/text-area-row/styles';

const { Text } = Typography;

interface TextAreaRowProps {
  title: string;
  text: string;
}

export const TextAreaRow: FC<TextAreaRowProps> = ({ title, text }) => {
  return (
    <CustomRow title={title}>
      <StyledCard>
        <Text>{text}</Text>
      </StyledCard>
    </CustomRow>
  );
};
