import { HomeVisitDetails } from 'pages/doctor/home-visit-details/types';
import { AvailabilityForDoctor, type DoctorUpcomingHomeVisitsResponse } from 'shared/api/rtk-api';
import { formatDate, formatPrice } from 'shared/lib/format';

export function externalHomeVisitDetailsToInternalHomeVisitDetailsMapper(
  homeVisitForm: DoctorUpcomingHomeVisitsResponse,
): HomeVisitDetails {
  const isFinished = homeVisitForm.availabilityForDoctor === AvailabilityForDoctor.NotAvailable;

  return {
    homeVisitId: homeVisitForm.id,
    patientName: homeVisitForm.patientName,
    date: formatDate(homeVisitForm.callDate),
    address: homeVisitForm.patientAddress,
    price: formatPrice(homeVisitForm.cost),
    symptoms: homeVisitForm.tags,
    timeRanges: isFinished ? [homeVisitForm.callTime] : ['08:00 - 12:00', '12:00 - 16:00', '16:00 - 20:00'],
    comments: homeVisitForm.comment,
    availabilityForDoctor: homeVisitForm.availabilityForDoctor,
  };
}
