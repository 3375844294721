import { type FC, PropsWithChildren, useCallback, useState } from 'react';

import type { BaseOptionType, DefaultOptionType, SelectProps } from 'antd/lib/select';

import type { BaseSelectProps } from 'rc-select/lib/BaseSelect';
import { StyledSelect, StyledSelectWrapperStyles } from 'shared/ui/select/styles';

import { DownArrowIcon, UpArrowIcon } from '../icons';

interface Props extends SelectProps<unknown, DefaultOptionType | BaseOptionType>, PropsWithChildren {}
// TODO: resolve inline styles
export const Select: FC<Props> = ({ ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ArrowIcon = isOpen ? (
    <DownArrowIcon style={{ pointerEvents: 'none' }} />
  ) : (
    <UpArrowIcon style={{ pointerEvents: 'none' }} />
  );

  const onDropdownVisibleChangeHandler: BaseSelectProps['onDropdownVisibleChange'] = (open) => {
    setIsOpen(open);
  };
  const memorizedOnDropdownVisibleChangeHandler = useCallback(onDropdownVisibleChangeHandler, []);
  return (
    <>
      <StyledSelectWrapperStyles />
      <StyledSelect
        suffixIcon={ArrowIcon}
        onDropdownVisibleChange={memorizedOnDropdownVisibleChangeHandler}
        {...props}
      />
    </>
  );
};
