import React, { type FC } from 'react';

import { MenuProps } from 'antd';

import { StyledMenu } from './styles';

export interface UnderlinedMenuProps extends MenuProps {
  onClick?: MenuProps['onClick'];
  selectedKeys?: MenuProps['selectedKeys'];
  items: MenuProps['items'];
}

export const UnderlinedMenu: FC<UnderlinedMenuProps> = ({ onClick, selectedKeys, items }) => {
  return <StyledMenu onClick={onClick} selectedKeys={selectedKeys} mode="horizontal" items={items} disabledOverflow />;
};
