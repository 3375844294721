import { useDoctorNotificationControllerGetUnreadNotificationCountQuery } from 'shared/api/rtk-api';

export const useHasUnreadDoctorNotificationsQuery = () => {
  const { data, ...others } = useDoctorNotificationControllerGetUnreadNotificationCountQuery(undefined, {
    pollingInterval: 10000,
  });

  return {
    hasUnreadNotifications: data,
    ...others,
  };
};
