import { useNotificationAdminControllerHasUnreadMessagesQuery } from 'shared/api/rtk-api';

export const useHasUnreadAdminNotificationsQuery = () => {
  const { data, ...others } = useNotificationAdminControllerHasUnreadMessagesQuery(undefined, {
    pollingInterval: 10000,
  });

  return {
    hasUnreadNotifications: data,
    ...others,
  };
};
