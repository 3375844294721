import React, { FC, useState } from 'react';

import { useHomeVisitAcceptByDoctorMutation } from 'entity/home-visit/hooks/use-home-visit-accept-by-doctor-mutation';
import { HomeVisitDetails } from 'pages/doctor/home-visit-details/types';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';

interface Props {
  appointment: HomeVisitDetails;
  timeIndex: number | null;
}

export const HomeVisitApplyByDoctorButton: FC<Props> = ({ appointment, timeIndex }) => {
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [showApplySuccessModal, setShowApplySuccessModal] = useState(false);
  const [showDeclineSuccessModal, setShowDeclineSuccessModal] = useState(false);
  const applyButtonHandler = () => setShowApplyModal(true);
  const confirmApplySuccessButtonHandler = () => setShowApplySuccessModal(false);
  const confirmRejectSuccessButtonHandler = () => setShowDeclineSuccessModal(false);
  const rejectApplyButtonHandler = () => setShowApplyModal(false);

  const [acceptRequest] = useHomeVisitAcceptByDoctorMutation();

  const confirmApplyButtonHandler = () => {
    setShowApplyModal(false);
    const callTime = appointment?.timeRanges[timeIndex ?? 0];
    if (!callTime) {
      throw new Error('Time range is not selected');
    }

    acceptRequest({
      homeVisitId: appointment.homeVisitId,
      callTime,
      onSuccess: () => setShowApplySuccessModal(true),
      onError: () => setShowDeclineSuccessModal(true),
    });
  };

  return (
    <>
      <CustomButton onClick={applyButtonHandler} disabled={timeIndex === null} $buttonType="submit">
        Принять
      </CustomButton>
      <CustomModal
        title="Вы уверены, что хотите принять заявку?"
        visible={showApplyModal}
        onClose={rejectApplyButtonHandler}
        actionButtons={[
          <CustomButton key={1} onClick={rejectApplyButtonHandler} $buttonType="declineWhite">
            Отмена
          </CustomButton>,
          <CustomButton key={2} onClick={confirmApplyButtonHandler} $buttonType="submit">
            Принять
          </CustomButton>,
        ]}
      />
      <CustomModal
        title="Вы приняли заявку на вызов на дом"
        description="Посмотреть детали вызова вы можете на странице “Календарь” или в разделе “Вызовы” в мобильном приложении."
        visible={showApplySuccessModal}
        onClose={confirmApplySuccessButtonHandler}
        actionButtons={[
          <CustomButton key={1} onClick={confirmApplySuccessButtonHandler} $buttonType="submit">
            Понятно
          </CustomButton>,
        ]}
      />
      <CustomModal
        title="Данная заявка принята другим врачом"
        visible={showDeclineSuccessModal}
        onClose={confirmRejectSuccessButtonHandler}
        actionButtons={[
          <CustomButton key={1} onClick={confirmRejectSuccessButtonHandler} $buttonType="submit">
            Понятно
          </CustomButton>,
        ]}
      />
    </>
  );
};
