import { FC } from 'react';

import { HealthAssistanceSearchInput } from 'entity/health-assistance/ui/health-assistance-search-input';
import { HealthAssistanceNavigationMenu } from 'feature/health-assistance-navigation/ui/health-assistance-navigation-menu';
import { UrlBasedDatePicker } from 'feature/url-based-data-picker';
import { ListLayout } from 'shared/ui/list-layout';
import { ChatSupportTable } from 'widgets/admin-chat-support-table/ui/chat-support-table';

export const AdminChatSupportListPage: FC = () => {
  return (
    <ListLayout
      title="Записи"
      Menu={HealthAssistanceNavigationMenu}
      SearchInput={HealthAssistanceSearchInput}
      DataPicker={UrlBasedDatePicker}
      Table={ChatSupportTable}
    />
  );
};
