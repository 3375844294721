import { FC, PropsWithChildren } from 'react';

import { ButtonProps } from 'antd';

import { NotificationBell } from 'entity/notification/ui/notification-bell';
import { StyledButton } from 'entity/notification/ui/notification-bell-button/styles';

interface NotificationBellProps extends ButtonProps {
  status: 'active' | 'inactive' | 'disabled';
}
export const NotificationBellButton: FC<PropsWithChildren<NotificationBellProps>> = ({ children, ...other }) => {
  return (
    <StyledButton type="text">
      <NotificationBell {...other} />
    </StyledButton>
  );
};
