import { useEffect, useState } from 'react';

import { config } from 'shared/config';

interface Props {
  address: string;
}

interface Result {
  coordinates: string | null;
  loading: boolean;
  error: string | null;
  longitude: number;
  latitude: number;
}

const cache = new Map<string, string>();

const useGeocoder = ({ address }: Props): Result => {
  const [coordinates, setCoordinates] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [longitude, latitude] = (coordinates?.split(' ') ?? []).map((coordinate) => parseFloat(coordinate));

  const encodedAddress = encodeURIComponent(address);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    (async () => {
      setLoading(true);
      try {
        const cachedCoordinates = cache.get(encodedAddress);
        if (cachedCoordinates) {
          setCoordinates(cachedCoordinates);
        } else {
          const response = await fetch(
            `https://geocode-maps.yandex.ru/1.x/?apikey=${config.yandexMapsApiKey}&format=json&geocode=${encodedAddress}`,
            { signal },
          );
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const data = await response.json();
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-shadow
          const coordinates = data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          cache.set(encodedAddress, coordinates);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setCoordinates(coordinates);
        }
        // eslint-disable-next-line @typescript-eslint/no-shadow
      } catch (error: unknown) {
        if (error instanceof Error && error.name !== 'AbortError') {
          setError(error.message);
        }
      } finally {
        setLoading(false);
      }
      // eslint-disable-next-line no-console
    })().catch(console.error);

    return () => {
      abortController.abort();
    };
  }, [encodedAddress]);

  return { coordinates, loading, error, longitude, latitude };
};

export default useGeocoder;
