import { Typography } from 'antd';
import styled from 'styled-components';

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.c6};
`;

export const ChatTitleWrapper = styled.div`
  max-width: inherit;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  gap: 10px;
`;

export const ChatTitle = styled.div`
  margin-top: 5px;
  font-size: ${({ theme }) => theme.fontSizes.default};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.lineHeights.small};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;

export const StyledCrossButtonContainer = styled.div`
  margin-top: 2px;
  cursor: pointer;
`;

export const ChatBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 0;
  flex-grow: 1;
  position: relative;

  // Style overrides for PubNub MessageList React component
  .pn-msg-list {
    overflow-y: scroll;
    background-color: ${({ theme }) => theme.colors.secondBackground};

    .pn-msg {
      padding: 0 30px 16px 46px;

      &:hover {
        background-color: unset !important;

        .pn-msg__actions {
          display: none;
        }
      }

      .pn-message__custom {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};
        padding: 8px 16px;
        max-width: 600px;
        width: fit-content;
        border-radius: 16px;

        > :last-child {
          align-self: flex-end;
        }
      }

      .pn-message__container {
        display: flex;
      }
    }

    .pn-msg--own {
      & .pn-message__custom {
        background-color: ${({ theme }) => theme.colors.palette.springDew};
      }

      & .pn-message__container {
        justify-content: flex-end;
      }

      & .ant-avatar {
        display: none;
      }
    }

    .pn-msg-list__spacer + .pn-msg {
      padding-top: 20px;
    }

    .pn-msg-list__spinner svg {
      color: ${({ theme }) => theme.colors.palette.springDew};
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export const Message = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.default};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-style: normal;
  overflow-wrap: anywhere;
`;

export const MessageTime = styled.div`
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: end;
  color: ${({ theme }) => theme.colors.c5};
  overflow-wrap: normal;
  height: inherit;
`;

export const MessageGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const EmptyDialogWrapper = styled.div`
  position: absolute;
  top: 32%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledDateWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

export const TypographyText = styled(Typography.Text)`
  &&& {
    color: ${({ theme }) => theme.colors.palette.dustblu};
    font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  }
`;

export const StyledMessageListElementContainer = styled.div`
  width: 100%;
`;

export const FileIconWrapper = styled.div`
  cursor: pointer;
`;
