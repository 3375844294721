import React, { type FC, useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useChatSupportDetailsQuery } from 'entity/chat-support/hooks/use-chat-support-details-query';
import { useChatSupportRejectRequestByAdminMutation } from 'entity/chat-support/hooks/use-chat-support-reject-request-by-admin-mutation';
import { useHealthAssistanceRejectModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-reject-modal';
import { routePatches } from 'shared/routes';
import { Button } from 'shared/ui/button';
import { CloseIcon } from 'shared/ui/icons';

export const ChatSupportRejectRequestByAdminButton: FC = () => {
  const params = useParams();
  const { chatSupport, isLoading } = useChatSupportDetailsQuery(params.id);
  const [rejectHomeVisit] = useChatSupportRejectRequestByAdminMutation();

  const navigate = useNavigate();

  const succeededActionModelHandler = useCallback(() => {
    navigate(routePatches.AdminHomeVisitList.path());
  }, [navigate]);

  const onClickHandler = useCallback(() => {
    rejectHomeVisit({ id: chatSupport?.id, onSuccess: succeededActionModelHandler });
  }, [rejectHomeVisit, chatSupport?.id, succeededActionModelHandler]);
  const { showRejectModal, RejectModal } = useHealthAssistanceRejectModal();

  return (
    <>
      <Button
        loading={isLoading}
        icon={<CloseIcon />}
        disabled={!chatSupport?.canBeRejected}
        onClick={showRejectModal(onClickHandler)}
        $buttonType="decline"
        type="primary"
        size="large"
      >
        Отклонить
      </Button>
      <RejectModal />
    </>
  );
};
