import React, { type FC, useCallback, useState } from 'react';

import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';

export const useHealthAssistanceSuccessModal = () => {
  const [showSuccessApproveModal, setShowSuccessApproveModal] = useState(false);
  const [action, setAction] = useState<() => void>(() => async () => {});
  const showSuccessModal = useCallback((newAction: () => void) => {
    return () => {
      setAction(() => newAction);
      setShowSuccessApproveModal(true);
    };
  }, []);

  const approveActionHandler = useCallback(() => {
    setShowSuccessApproveModal(false);
    action();
  }, [action]);

  const SuccessModal: FC = () => (
    <CustomModal
      title="Запись подтверждена"
      visible={showSuccessApproveModal}
      onClose={approveActionHandler}
      actionButtons={[
        <CustomButton key={1} onClick={approveActionHandler} $buttonType="submit">
          Закрыть
        </CustomButton>,
      ]}
    />
  );
  return { showSuccessModal, SuccessModal };
};
