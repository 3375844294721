import { type FC } from 'react';

import type { InputProps } from 'antd';

import { SearchParams } from 'entity/health-assistance/constants';
import { useUrlSearch } from 'shared/hooks/use-url-search';
import { Cross2Icon, SearchIcon } from 'shared/ui/icons';

import { StyledSearchInput } from './styles';

export const MarketingPushNotificationsSearchInput: FC<InputProps> = (props) => {
  const { search, setSearch } = useUrlSearch(SearchParams.Search);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <StyledSearchInput
      defaultValue={search}
      prefix={<SearchIcon />}
      onChange={handleSearch}
      placeholder="Введите название"
      allowClear={{
        clearIcon: <Cross2Icon />,
      }}
      {...props}
    />
  );
};
