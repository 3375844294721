import { configureStore } from '@reduxjs/toolkit';

import { errorHandlerMiddleware } from 'app/store-setup/error-handler';
import { authReducer } from 'entity/auth/store';
import { chatReducer } from 'entity/pubnub/store';
import { api } from 'shared/api';
import { config } from 'shared/config';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    pubnubChat: chatReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: {
        extraArgument: {
          config,
        },
      },
    }).concat([api.middleware, errorHandlerMiddleware]),
});
