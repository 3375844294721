import { Input } from 'antd';
import styled from 'styled-components';

export const SearchInput = styled(Input)`
  position: sticky;
  padding: 8px 16px;
  border-radius: 24px;
  margin: 8px 30px 16px;
  width: auto;
  background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};

  &:hover {
    border-color: ${({ theme }) => theme.colors.c1};
    box-shadow: 0 0 0 2px rgb(81 161 98 / 20%);
  }

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.c2};
    box-shadow: 0 0 0 2px rgb(81 161 98 / 20%);
  }

  .ant-input {
    background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};

    &::placeholder {
      color: ${({ theme }) => theme.colors.palette.dustblu};
    }
  }
`;
