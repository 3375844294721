import { Button, Form, Row } from 'antd';
import styled from 'styled-components';

export const AdminDoctorEditPageContainer = styled.div`
  width: 320px;
`;

export const AdminDoctorEditCancelButton = styled(Button)`
  color: #969aa0;
`;

export const AdminDoctorEditForm = styled(Form)`
  margin-top: 41px;
  margin-bottom: 8px;
`;

export const AdminDoctorEditFormButtonsRow = styled(Row)`
  margin-top: 20px;
`;
