import { FC } from 'react';

import { Typography } from 'antd';

import { StyledIcon, StyledText } from 'shared/ui/row/custom-row/styles';

export interface CustomRowProps {
  title: string;
  children: React.ReactNode;
  // TODO Change to title render
  editMode?: boolean;
  editHandler?: () => void;
}

export const CustomRow: FC<CustomRowProps> = ({ title, children, editMode, editHandler }) => {
  return (
    <>
      <Typography.Title level={5} style={{ display: 'flex', alignItems: 'center' }}>
        {title}
        {editMode && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <span onClick={editHandler}>
            <StyledIcon />
            <StyledText>Изменить</StyledText>
          </span>
        )}
      </Typography.Title>
      {children}
    </>
  );
};
