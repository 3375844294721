import { type FC } from 'react';

import { Avatar } from 'antd';

import theme from 'shared/theme';
import { AvatarIcon, CrossIcon } from 'shared/ui/icons';

import { ChatHeader, ChatTitle, ChatTitleWrapper, StyledCrossButtonContainer } from './styles';

const AvatarContent: FC = () => {
  const avatarBackground = theme.colors.mainBackground;

  return <AvatarIcon fill={avatarBackground} />;
};

type Props = {
  conversationName: string;
  onCloseChat?: () => void;
};

export const DoctorConversationHeaderWidget: FC<Props> = ({ onCloseChat, conversationName }) => {
  return (
    <ChatHeader>
      <Avatar size="large" icon={<AvatarContent />} />
      <ChatTitleWrapper>
        <ChatTitle>{conversationName}</ChatTitle>
        {onCloseChat && (
          <StyledCrossButtonContainer onClick={onCloseChat}>
            <CrossIcon />
          </StyledCrossButtonContainer>
        )}
      </ChatTitleWrapper>
    </ChatHeader>
  );
};
