import { Layout } from 'antd';
import styled from 'styled-components';

const { Content } = Layout;

export const ContentWrapper = styled(Content)``;

export const Container = styled(Layout)`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  z-index: 0;
  color: ${({ theme }) => theme.colors.mainText};
  background: ${({ theme }) => theme.colors.mainBackground};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.c1};
  }

  a:visited {
    text-decoration: none;
  }

  a:focus {
    outline: none;
  }

  h1,
  h2,
  h3 {
    font-weight: 400;
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.mainText};
    -webkit-transition: all 0s 50000s;
  }
`;
