import { HomeVisitAppointmentResponseDto } from 'shared/api/rtk-api';
import { setHoursAndMinutes } from 'shared/lib/format';

import { ScheduleEventType } from '../constants';
import { InternalScheduleHomeVisitEvent } from '../types/internal-schedule-home-visit-event';

export const externalHomeVisitEventToInternalHomeVisitEventMapper = (
  event: HomeVisitAppointmentResponseDto,
): InternalScheduleHomeVisitEvent => {
  const [startTime, endTime] = event.callTime.split('-');
  const [startHours, startMinutes] = startTime.split(':');
  const [endHours, endMinutes] = endTime.split(':');

  const startIsoDateTime = setHoursAndMinutes(event.callIsoDate, +startHours, +startMinutes).toISOString();

  const endIsoDateTime = setHoursAndMinutes(event.callIsoDate, +endHours, +endMinutes).toISOString();

  return {
    id: event.id,
    start: startIsoDateTime,
    end: endIsoDateTime,
    patientId: event.patientId,
    patientName: event.patientName,
    doctorId: event.doctorId,
    doctorName: event.doctorName,
    documents: event.documents,
    canAddProtocol: event.canAddProtocol,
    type: ScheduleEventType.HOME,
  };
};
