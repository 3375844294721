import { useEffect, useMemo, useState } from 'react';

import Pubnub from 'pubnub';
import { usePubNub } from 'pubnub-react';

import { useDoctorChatsQuery } from 'entity/doctor-chat/hooks/use-doctor-list-query';
import { PUBNUB_LAG_SAFE_TIME, PUBNUB_TIME_MULTIPLIER } from 'entity/pubnub/constants';
import { errorNotification } from 'shared/utils/notifications';

type Channels = Pubnub.FetchMessagesResponse['channels'];

export const useMessageCounts = () => {
  const { doctorChats } = useDoctorChatsQuery();
  const [countOfUnreadChatsMessages, setCountOfUnreadChatsMessages] = useState<Record<string, number>>({});
  const [doctorChatsMessages, setDoctorChatsMessages] = useState<Channels | null>(null);

  const chatListItemsInfo = useMemo(() => {
    return Object.entries(doctorChatsMessages ?? {}).map(([channel, messages]) => {
      const lastMessage = messages.at(-1);

      const unreadMessagesCount = countOfUnreadChatsMessages[channel] ?? 0;
      return {
        channel,
        lastMessage,
        unreadMessagesCount,
      };
    });
  }, [doctorChatsMessages, countOfUnreadChatsMessages]);

  const chatMap = useMemo(() => {
    return new Map(doctorChats.map((chat) => [chat.eTag, chat]));
  }, [doctorChats]);

  const chatUUIDs = useMemo(() => {
    return doctorChats.map((chat) => chat.eTag);
  }, [doctorChats]);

  const pubnub = usePubNub();

  useEffect(() => {
    if (chatUUIDs.length === 0) {
      return;
    }
    pubnub.fetchMessages({ channels: chatUUIDs }, (status, { channels }) => {
      if (status.error) {
        return;
      }
      setDoctorChatsMessages(channels);
    });
  }, [chatUUIDs, pubnub]);

  useEffect(() => {
    if (chatUUIDs.length === 0) {
      return;
    }
    const channelTimetokens = chatUUIDs.map((chatUUID) => {
      const lastChatReadByDoctorTimestamp = chatMap.get(chatUUID)?.lastChatReadByDoctorTimestamp ?? 0;
      return (Number(lastChatReadByDoctorTimestamp) + PUBNUB_LAG_SAFE_TIME) * PUBNUB_TIME_MULTIPLIER;
    });
    pubnub.messageCounts(
      {
        channels: chatUUIDs,
        channelTimetokens,
      },
      (status, channels) => {
        if (!status.error) {
          setCountOfUnreadChatsMessages(channels.channels);
        } else {
          errorNotification('Не удалось получить количество непрочитанных сообщений');
        }
      },
    );
  }, [chatMap, chatUUIDs, pubnub]);

  return { countOfUnreadChatsMessages, chatListItemsInfo, doctorChatsMessages, doctorChats, chatMap };
};
