import React, { FC, useCallback } from 'react';

import { useHealthAssistanceEditModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-admin-health-assistance-edit-modal';
import { useHomeVisitEditByAdminMutation } from 'entity/home-visit/hooks/use-home-visit-edit-by-admin-mutation';
import type { InternalHomeVisitFormDetails } from 'entity/home-visit/types/internal-home-visit-form-details';
import { formatDateYMD } from 'shared/lib/format';
import { Button } from 'shared/ui/button';
import { CheckMarkIcon } from 'shared/ui/icons';

interface Props {
  homeVisitData: InternalHomeVisitFormDetails;
  isUpdated: boolean;
  onUpdate: () => void;
}

export const HomeVisitEditByAdminButton: FC<Props> = ({ homeVisitData, isUpdated, onUpdate }) => {
  const [editHomeVisit] = useHomeVisitEditByAdminMutation();

  const applyButtonHandler = useCallback(() => {
    editHomeVisit({
      homeVisitId: homeVisitData?.homeVisitId,
      time: homeVisitData?.time,
      date: formatDateYMD(homeVisitData?.date),
      patientAddress: homeVisitData?.address,
      doctorId: homeVisitData?.doctorId,
    });
    onUpdate();
  }, [onUpdate, editHomeVisit, homeVisitData]);

  const { showEditModal, EditModal } = useHealthAssistanceEditModal();

  const isDisabled =
    !homeVisitData?.doctorId || !homeVisitData?.date || !homeVisitData?.time || !homeVisitData?.address || !isUpdated;
  return (
    <>
      <Button
        icon={<CheckMarkIcon />}
        onClick={showEditModal(applyButtonHandler)}
        $buttonType="submit"
        type="primary"
        size="large"
        disabled={isDisabled}
      >
        Сохранить
      </Button>
      <EditModal />
    </>
  );
};
