import { Button, Dropdown, Menu } from 'antd';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)`
  border-radius: 8px;
  padding: 16px 0;

  .ant-dropdown-menu-item {
    padding: 0;
  }
`;

export const StyledDropdown = styled(Dropdown)``;

export const OverlayStyle = styled.div` {
  
} `;

export const StyledDropdownButton = styled(Button)`
  padding: 0 16px;
  height: 32px;
  :hover {
    background-color: transparent;
  }
`;
