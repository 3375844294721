import { type FC } from 'react';

import { Space } from 'antd';

import { DoctorOnlineAppointmentConversationButton } from './conversation-button';
import { DoctorOnlineAppointmentLeaveButton } from './leave-button';
import { DoctorOnlineAppointmentMicrophoneButton } from './microphone-button';
import { DashboardWrapper } from './styles';
import { DoctorOnlineAppointmentVideoButton } from './video-button';

export const DoctorOnlineAppointmentDashboardWidget: FC = () => {
  return (
    <DashboardWrapper justify="center" align="middle">
      <Space direction="horizontal" size={20}>
        <DoctorOnlineAppointmentMicrophoneButton />
        <DoctorOnlineAppointmentVideoButton />
        <DoctorOnlineAppointmentConversationButton />
        <DoctorOnlineAppointmentLeaveButton />
      </Space>
    </DashboardWrapper>
  );
};
