import { type FC } from 'react';

import { Chat, MessageEnvelope, MessageList } from '@pubnub/react-chat-components';

import type {
  ConversationParticipant,
  DoctorConversationInfo,
} from 'entity/doctor-chat/types/doctor-conversation-info';
import { useConversationMessages } from 'widgets/doctor-conversation-details-widget/hooks/use-chat-messages';
import type { ConversationMessageType } from 'widgets/doctor-conversation-details-widget/types';
import { DoctorConversationHeaderWidget } from 'widgets/doctor-conversation-header-widget/ui';
import { SendMessage } from 'widgets/send-message';

import { ConversationMessage } from '../conversation-message';
import { EmptyConversation } from '../empty-conversation';
import { ChatBody } from './styles';

const renderMessage = (
  message: MessageEnvelope,
  conversationMessages: ConversationMessageType[],
  participants: Map<string, ConversationParticipant>,
) => (
  <ConversationMessage
    key={message.timetoken}
    currentMessage={message}
    conversationMessages={conversationMessages}
    participants={participants}
  />
);

type Props = {
  conversationId: number;
  conversationInfo: DoctorConversationInfo;
  onCloseConversation?: () => void;
};

export const ConversationContent: FC<Props> = ({ conversationInfo, conversationId, onCloseConversation }) => {
  const { pubNubChannelName, conversationName, participants } = conversationInfo;

  const { chatMessages, isChatEmpty, isChatLoading } = useConversationMessages({
    conversationId,
    pubNubChannelName,
  });

  if (isChatLoading) return null;

  const localParticipantChatData = participants.find((participant) => participant.isCurrentUser);

  if (!localParticipantChatData) return null;

  const normalizedParticipants = new Map(
    participants.map((participant) => [participant.participantPubnubUuid, participant]),
  );

  return (
    <>
      <DoctorConversationHeaderWidget onCloseChat={onCloseConversation} conversationName={conversationName} />
      <ChatBody>
        <Chat users={participants} currentChannel={pubNubChannelName}>
          <MessageList
            fetchMessages={25}
            messageRenderer={({ message }) => renderMessage(message, chatMessages, normalizedParticipants)}
          />

          {!isChatLoading && isChatEmpty && <EmptyConversation />}

          <SendMessage selectedChatData={localParticipantChatData} />
        </Chat>
      </ChatBody>
    </>
  );
};
