import { FC, useCallback } from 'react';

import type { DatePickerProps } from 'antd';
import moment from 'moment';

import { SearchParams } from 'entity/health-assistance/constants';
import { StyledDatePicker } from 'feature/url-based-data-picker/styles';
import { useUrlSearch } from 'shared/hooks/use-url-search';

export const UrlBasedDatePicker: FC<DatePickerProps> = (props) => {
  const { search, setSearch } = useUrlSearch(SearchParams.Date);
  const handleDateChange: DatePickerProps['onChange'] = useCallback(
    (_date: unknown, dateString: string) => {
      setSearch(dateString);
    },
    [setSearch],
  );

  return <StyledDatePicker onChange={handleDateChange} value={search ? moment(search) : undefined} {...props} />;
};
