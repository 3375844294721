import { useOnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentListQuery } from 'shared/api/rtk-api';
import { getEndOfStringDate, getStartOfStringDate } from 'shared/lib/format';

import { externalOnlineAppointmentToInternalOnlineAppointmentListMapper } from '../mappers/external-online-appointment-to-internal-online-appointment-mapper';
import { getExternalOnlineAppointmentStatus } from '../mappers/internal-online-appointment-status-to-external-online-appointment-status-mapper';
import { InternalOnlineAppointmentStatusEnum } from '../types/internal-online-appointment-status-enum';

interface OnlineAppointmentListQuery {
  page: number;
  pageSize: number;
  statuses: InternalOnlineAppointmentStatusEnum[];
  date?: string;
  search?: string;
}

export const useOnlineAppointmentQuery = ({ page, pageSize, statuses, date, search }: OnlineAppointmentListQuery) => {
  const { data, ...others } = useOnlineVisitAppointmentControllerGetAdminOnlineVisitAppointmentListQuery({
    search,
    fromStartDateTimeIso: date && getStartOfStringDate(date).toISOString(),
    toStartDateTimeIso: date && getEndOfStringDate(date).toISOString(),
    statuses: statuses.map((status) => getExternalOnlineAppointmentStatus(status)),
    limit: pageSize,
    offset: (page - 1) * pageSize || undefined,
  });

  const onlineAppointments = data?.data.map(externalOnlineAppointmentToInternalOnlineAppointmentListMapper);
  return {
    onlineAppointments,
    total: data?.total,
    ...others,
  };
};
