import { type FC } from 'react';

import { type TableColumnsType } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { useOnlineAppointmentQuery } from 'entity/online-appointment/hooks/use-online-appointment-list-query';
import type { InternalOnlineAppointment } from 'entity/online-appointment/types/internal-online-appointment';
import { InternalOnlineAppointmentStatusEnum } from 'entity/online-appointment/types/internal-online-appointment-status-enum';
import { useUrlPagination } from 'shared/hooks/use-url-pagination';
import { routePatches } from 'shared/routes';
import { Table } from 'shared/ui/table';

const DEFAULT_PAGE_SIZE = 7;
const DEFAULT_PAGE = 1;
const DEFAULT_STATUSES = [
  InternalOnlineAppointmentStatusEnum.FinishedForAdmin,
  InternalOnlineAppointmentStatusEnum.DoctorAbsent,
];

export const AdminPastOnlineAppointmentsTable: FC = () => {
  const navigate = useNavigate();

  const { setPage, page } = useUrlPagination();

  const { onlineAppointments, total } = useOnlineAppointmentQuery({
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    statuses: DEFAULT_STATUSES,
  });

  const columns: TableColumnsType<InternalOnlineAppointment> = [
    {
      title: 'Пациент',
      dataIndex: 'patient',
      ellipsis: true,
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      ellipsis: true,
    },
    {
      title: 'Специализация',
      dataIndex: 'specialization',
      ellipsis: true,
    },
    {
      title: 'Дата Приема',
      dataIndex: 'date',
      ellipsis: true,
    },
    {
      title: 'Время Приема',
      dataIndex: 'time',
      ellipsis: true,
    },
    {
      title: 'Врач',
      dataIndex: 'doctor',
      ellipsis: true,
      render: (_, row) => (
        <Link onClick={(e) => e.stopPropagation()} to={`${routePatches.AdminDoctorsPage.path(row.doctorId)}`}>
          {row.doctor}
        </Link>
      ),
    },
    {
      title: 'Оценка',
      dataIndex: 'rating',
      ellipsis: true,
    },
  ];

  const handleClickRow = (id: number) => navigate(routePatches.AdminPastOnlineAppointmentDetails.path(id));

  return (
    <Table
      columns={columns}
      dataSource={onlineAppointments}
      rowKey={(row) => row.id}
      onRow={(record) => {
        return {
          onClick: () => handleClickRow(record.id),
        };
      }}
      pagination={{
        current: page ?? DEFAULT_PAGE,
        total,
        onChange: (e) => setPage(e),
        pageSize: DEFAULT_PAGE_SIZE,
        showSizeChanger: false,
      }}
    />
  );
};
