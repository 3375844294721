import { InternalPushNotificationStatusEnum } from '../types/internal-push-notification';

export const statusTitles = {
  [InternalPushNotificationStatusEnum.Sended]: 'Отправлено',
  [InternalPushNotificationStatusEnum.WaitingSending]: 'Ожидает отправки',
};

export const buttonTitles = {
  [InternalPushNotificationStatusEnum.Sended]: 'Переиспользовать',
  [InternalPushNotificationStatusEnum.WaitingSending]: 'Изменить',
};

export const statusQueries = {
  [InternalPushNotificationStatusEnum.Sended]: 'REUSE',
  [InternalPushNotificationStatusEnum.WaitingSending]: 'EDIT',
};

export const MAX_TITLE_LENGTH = 50;
export const MAX_DESCRIPTION_LENGTH = 150;

export enum RecipientType {
  MANUAL = 'MANUAL',
  LIST = 'LIST',
}

export enum SexOptions {
  ALL = 'ALL',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum ChildrenOptionsOptions {
  ALL = 'ALL',
  YES = 'YES',
  NO = 'NO',
}

export enum AgeOptionsOptions {
  ALL = 'ALL',
  EIGHTEEN = '1',
  TWENTY = '2',
  THIRTY = '3',
  FIFTY = '4',
  SIXTY = '5',
}

export const PushNotificationStatuses = {
  [InternalPushNotificationStatusEnum.Sended]: 'Отправлено',
  [InternalPushNotificationStatusEnum.WaitingSending]: 'Ожидает отправки',
};
