import React, { FC, PropsWithChildren } from 'react';

import { Outlet } from 'react-router-dom';

import { StyledDiv } from 'app/layouts/admin/styles';
import { GeneralLayout } from 'shared/ui/general-layout';
import { AdminHeader } from 'widgets/admin-header/ui/admin-header';

export const NewAdminLayout: FC<PropsWithChildren> = () => {
  return (
    <GeneralLayout
      headerSlot={<AdminHeader />}
      pageSlot={
        <StyledDiv>
          <Outlet />
        </StyledDiv>
      }
    />
  );
};
