import { type FC, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { HealthAssistanceDetailsLayout } from 'entity/health-assistance/ui/health-assistance-details-layout';
import { useOnlineAppointmentDetailsQuery } from 'entity/online-appointment/hooks/use-online-appointment-details-query';
import type { InternalOnlineAppointmentDetails } from 'entity/online-appointment/types/internal-online-appointment-details';
import { AdminReturnToOnlineAppointmentListButton } from 'feature/admin-return-to-online-appointment-list/ui/admin-return-to-online-appointment-list-button';
import { AdminReturnToOnlineAppointmentListButtonWithConfirmation } from 'feature/admin-return-to-online-appointment-list/ui/admin-return-to-online-appointment-list-button-with-confirmation';
import { OnlineAppointmentApproveByAdminButton } from 'feature/online-appointment-approve-by-admin/ui/online-appointment-approve-by-admin-button';
import { OnlineAppointmentCancelAppointmentByAdminButton } from 'feature/online-appointment-cancel-appointment-by-admin/ui/online-appointment-cancel-appointment-by-admin-button';
import { OnlineAppointmentEditByAdminButton } from 'feature/online-appointment-edit-by-admin/ui/online-appointment-edit-by-admin-button';
import { OnlineAppointmentRejectAppointmentByAdminButton } from 'feature/online-appointment-reject-appointment-by-admin/online-appointment-reject-appointment-by-admin-button';
import { OnlineAppointmentToTakeInEditByAdminButton } from 'feature/online-appointment-to-take-in-edit-by-admin/online-appointment-to-take-in-edit-by-admin-button';
import { OnlineAppointmentToTakeInProgressByAdminButton } from 'feature/online-appointment-to-take-in-progress-by-admin/online-appointment-to-take-in-progress-by-admin-button';
import { routePatches } from 'shared/routes';
import { errorNotification } from 'shared/utils/notifications';
import { OnlineAppointmentChoseDoctorDateTimeWidget } from 'widgets/admin-online-appointment-choose-doctor-date-time-widget/ui';

import { AdminOnlineAppointmentDetailsForm } from './admin-online-appointment-details-form';

export const AdminOnlineAppointmentDetailsPage: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { onlineAppointment, isLoading, isError } = useOnlineAppointmentDetailsQuery(id);

  const [onlineAppointmentData, setOnlineAppointmentData] = useState<InternalOnlineAppointmentDetails>();
  const [showChangeDoctorTime, setShowChangeDoctorTime] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (isError) {
      errorNotification('Ошибка при загрузке заявки!');
      navigate(routePatches.AdminOnlineAppointmentList.path());
    }
    setOnlineAppointmentData(onlineAppointment);
  }, [onlineAppointment, isError, navigate]);

  const handlePickOnlineAppointmentSlot = (updated: Partial<InternalOnlineAppointmentDetails>) => {
    setShowChangeDoctorTime(false);
    if (!onlineAppointmentData) return;

    setOnlineAppointmentData((prev): typeof prev => {
      if (!prev) return prev;

      return {
        ...prev,
        ...updated,
      };
    });
    setIsUpdated(true);
  };

  const hideChangeDoctorTime = () => setShowChangeDoctorTime(false);
  const editHandler = () => setShowChangeDoctorTime(true);
  const onUpdate = () => setIsUpdated(false);
  const handleClickEditButton = () => setEditMode((prev) => !prev);

  if (isLoading) return null;

  if (!onlineAppointmentData) return <div>not found</div>;

  const {
    canBeAccepted,
    canBeCanceled,
    canBeRejected,
    canBeAssigned,
    canBeChanged,
    id: appointmentId,
    onlineAppointmentSlotId,
  } = onlineAppointmentData;

  const canBeApproved = !canBeCanceled && (isUpdated || canBeAccepted);
  const canBeUpdated = canBeAccepted && canBeCanceled;

  if (showChangeDoctorTime) {
    return (
      <OnlineAppointmentChoseDoctorDateTimeWidget
        onlineAppointment={onlineAppointmentData}
        handlePickOnlineAppointmentSlot={handlePickOnlineAppointmentSlot}
        hideChangeDoctorTime={hideChangeDoctorTime}
      />
    );
  }

  return (
    <HealthAssistanceDetailsLayout
      ReturnButton={
        isUpdated ? (
          <AdminReturnToOnlineAppointmentListButtonWithConfirmation key={1} />
        ) : (
          <AdminReturnToOnlineAppointmentListButton key={2} />
        )
      }
      ActionButtons={[
        canBeAssigned && <OnlineAppointmentToTakeInProgressByAdminButton key={3} onlineAppointmentId={appointmentId} />,
        canBeRejected && (
          <OnlineAppointmentRejectAppointmentByAdminButton key={4} onlineAppointmentId={appointmentId} />
        ),

        canBeChanged && <OnlineAppointmentToTakeInEditByAdminButton key={5} onClickHandler={handleClickEditButton} />,

        canBeCanceled && (
          <OnlineAppointmentCancelAppointmentByAdminButton key={6} onlineAppointmentId={appointmentId} />
        ),

        canBeApproved && (
          <OnlineAppointmentApproveByAdminButton
            key={7}
            onlineAppointmentData={onlineAppointmentData}
            disabled={!onlineAppointmentSlotId}
          />
        ),

        canBeUpdated && (
          <OnlineAppointmentEditByAdminButton
            key={8}
            onlineAppointmentData={onlineAppointmentData}
            onUpdate={onUpdate}
            disabled={!onlineAppointmentSlotId || !isUpdated}
          />
        ),
      ]}
      LeftSide={
        <AdminOnlineAppointmentDetailsForm
          key={9}
          editHandler={editHandler}
          editMode={editMode}
          onlineAppointment={onlineAppointmentData}
        />
      }
    />
  );
};
