import { Typography } from 'antd';
import styled from 'styled-components';

import { InternalChatSupportStatusEnum } from 'entity/chat-support/types/internal-chat-support-status-enum';
import theme from 'shared/theme';
import { Tag } from 'shared/ui/tag';

const calculateTagTextColor = ({ $type }: { $type: InternalChatSupportStatusEnum }) => {
  switch ($type) {
    case InternalChatSupportStatusEnum.New:
      return theme.colors.palette.columnOfOakGreen;
    case InternalChatSupportStatusEnum.InProgressByCurrentAdmin:
      return theme.colors.palette.clementine;
    case InternalChatSupportStatusEnum.InProgressByAnotherAdmin:
      return theme.colors.palette.meteorShower;
    case InternalChatSupportStatusEnum.AcceptByAdmin:
      return theme.colors.palette.dustblu;
    default:
      throw new Error('Unknown tag type');
  }
};

const calculateTagBackgroundColor = ({ $appointmentStatus }: { $appointmentStatus: InternalChatSupportStatusEnum }) => {
  switch ($appointmentStatus) {
    case InternalChatSupportStatusEnum.New:
      return theme.colors.palette.operaGlass;
    case InternalChatSupportStatusEnum.InProgressByCurrentAdmin:
      return theme.colors.palette.organicFiber;
    case InternalChatSupportStatusEnum.InProgressByAnotherAdmin:
      return theme.colors.palette.paleLavender;
    case InternalChatSupportStatusEnum.AcceptByAdmin:
      return theme.colors.palette.whiteSmoke;
    default:
      throw new Error('Unknown tag type');
  }
};

export interface StyledTagProps {
  $appointmentStatus: InternalChatSupportStatusEnum;
}

export const StyledTag = styled(Tag)<StyledTagProps>`
  &&& {
    background-color: ${(props) => calculateTagBackgroundColor(props)};
    padding: 4px 8px;
  }
`;

export interface StyledTagTextProps {
  $type: InternalChatSupportStatusEnum;
}

export const StyledTagText = styled(Typography.Text)<StyledTagTextProps>`
  &&& {
    color: ${(props) => calculateTagTextColor(props)};
    font-size: ${theme.fontSizes.small};
  }
`;
