import styled from 'styled-components';

import { StarFilled, StarOutlined } from '@ant-design/icons';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.palette.white};
  border-radius: ${({ theme }) => theme.radii.small};

  .ant-typography {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const StyledStar = styled(StarFilled)`
  color: #facf54;
  font-size: ${({ theme }) => theme.fontSizes.default};
  margin-right: 5px;
`;

export const StyledStarOutlined = styled(StarOutlined)`
  color: #facf54;
  font-size: ${({ theme }) => theme.fontSizes.default};
  margin-right: 5px;
`;
