import { Typography } from 'antd';
import styled from 'styled-components';

export const StyledTitle = styled(Typography.Title)`
  &&& {
    margin-top: 32px;
    font-size: ${({ theme }) => theme.fontSizes.extraLarge};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;
