import React, { FC, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { useHealthAssistanceApproveModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-approve-modal';
import { useHealthAssistanceSuccessModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-success-modal';
import { useHomeVisitAcceptByAdminMutation } from 'entity/home-visit/hooks/use-home-visit-accept-by-admin-mutation';
import type { InternalHomeVisitFormDetails } from 'entity/home-visit/types/internal-home-visit-form-details';
import { formatDateYMD } from 'shared/lib/format';
import { routePatches } from 'shared/routes';
import { Button } from 'shared/ui/button';
import { CheckMarkIcon } from 'shared/ui/icons';

interface Props {
  homeVisitData: InternalHomeVisitFormDetails;
}

export const HomeVisitApproveByAdminButton: FC<Props> = ({ homeVisitData }) => {
  const [acceptHomeVisit] = useHomeVisitAcceptByAdminMutation();
  const navigate = useNavigate();

  const succeededActionModelHandler = useCallback(() => {
    navigate(routePatches.AdminHomeVisitList.path());
  }, [navigate]);
  const { showSuccessModal, SuccessModal } = useHealthAssistanceSuccessModal();

  const applyButtonHandler = useCallback(() => {
    acceptHomeVisit({
      homeVisitId: homeVisitData?.homeVisitId,
      time: homeVisitData?.time,
      date: formatDateYMD(homeVisitData?.date),
      patientAddress: homeVisitData?.address,
      doctorId: homeVisitData?.doctorId,
      onSuccess: showSuccessModal(succeededActionModelHandler),
    });
  }, [acceptHomeVisit, homeVisitData, showSuccessModal, succeededActionModelHandler]);

  const { showApproveModal, ApproveModal } = useHealthAssistanceApproveModal();

  const isDisabled =
    !homeVisitData?.doctorId || !homeVisitData?.date || !homeVisitData?.time || !homeVisitData?.address;
  return (
    <>
      <Button
        icon={<CheckMarkIcon />}
        onClick={showApproveModal(applyButtonHandler)}
        $buttonType="submit"
        type="primary"
        size="large"
        disabled={isDisabled}
      >
        Подтвердить
      </Button>
      <ApproveModal />
      <SuccessModal />
    </>
  );
};
