import { Modal, Space, Typography } from 'antd';
import styled from 'styled-components';

export const StyledCustomModal = styled(Modal)`
  border-radius: ${({ theme }) => theme.radii.default};
  width: fit-content !important;
  .ant-modal-header {
    border-radius: ${({ theme }) => theme.radii.default};
    border-bottom: none;
    padding: 0;
  }

  .ant-modal-content {
    border-radius: ${({ theme }) => theme.radii.default};
    padding: 24px;
    width: 416px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    border-radius: ${({ theme }) => theme.radii.default};
    border-top: none;
    margin-top: 32px;
    padding: 0;
  }
`;

export const StyledCustomModalTitle = styled(Typography.Title)`
  &&& {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

export const StyledCustomModalText = styled(Typography.Text)`
  &&& {
    font-size: ${({ theme }) => theme.fontSizes.default};
  }
`;

export const StyledCrossButtonContainer = styled.div`
  cursor: pointer;
`;

export const StyledHeaderWrapper = styled(Space)`
  justify-content: space-between;
  width: 100%;
  align-items: normal;
`;

export const StyledSpace = styled(Space)`
  justify-content: space-between;
  width: 100%;
`;
