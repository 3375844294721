import * as process from 'process';

const getFromEnvOrError = (key: string, defaultValue?: string) => {
  const value = process.env[key] ?? defaultValue;
  if (!value) {
    throw new Error(`Missing required env variable ${key}`);
  }
  return value;
};

export const config = {
  apiBaseUrl: getFromEnvOrError('REACT_APP_BASE_API_URL'),
  baseUrl: process.env.REACT_APP_BASE_API_URL,
  basename: process.env.REACT_APP_BASE_NAME,
  yandexMapsApiKey: '771a5eb1-4fca-45e6-b5d5-54f048241bac',
  localStorageKey: {
    userEmail: 'email',
    messages: 'messages',
  },
  validationPatterns: {
    password: /^(?=.*[a-z])(?=.*\d)(?=.*[A-Z])[A-Za-z\d]{8,}$/,
  },
};
