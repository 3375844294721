// Get date in format 'DD.MM.YYYY' or return 'сегодня' or 'вчера'
import moment from 'moment';

import { PUBNUB_TIME_MULTIPLIER } from '../../constants';

export function formatDateRelative(pubnubTimeToken: string | number): string {
  const timestamp = Number(pubnubTimeToken) / PUBNUB_TIME_MULTIPLIER;

  const isToday = moment().isSame(moment(timestamp), 'day');
  if (isToday) return 'Сегодня';
  const isYesterday = moment().subtract(1, 'days').isSame(moment(timestamp), 'day');
  if (isYesterday) return 'Вчера';

  return moment(timestamp).format('DD.MM.YYYY');
}
