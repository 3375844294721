import { type FC, type PropsWithChildren } from 'react';

import { Outlet } from 'react-router-dom';

import { ConversationPubNubWrapper } from '../conversation-pub-nub';
import { DailyWrapper } from '../daily';

export const OnlineAppointmentLayout: FC<PropsWithChildren> = () => {
  return (
    <ConversationPubNubWrapper>
      <DailyWrapper>
        <Outlet />
      </DailyWrapper>
    </ConversationPubNubWrapper>
  );
};
