import { type FC } from 'react';

import type { ButtonProps } from 'antd/lib/button/button';

import { ReturnButton } from 'shared/ui/return-button';

interface Props extends ButtonProps {
  onClick?: () => void;
}

export const ReturnToHomeVisitDetailsButton: FC<Props> = ({ onClick, ...props }) => {
  return (
    <div>
      <ReturnButton {...props} onClick={onClick}>
        Детали записи
      </ReturnButton>
    </div>
  );
};
