import { ResponseMarketingMailingStatusType } from 'shared/api/rtk-api';

import { InternalPushNotificationStatusEnum } from '../types/internal-push-notification';

const statusMap = new Map<ResponseMarketingMailingStatusType, InternalPushNotificationStatusEnum>([
  [ResponseMarketingMailingStatusType.Sended, InternalPushNotificationStatusEnum.Sended],
  [ResponseMarketingMailingStatusType.WaitingSending, InternalPushNotificationStatusEnum.WaitingSending],
]);
export const getInternalPushNotificationStatus = (
  status: ResponseMarketingMailingStatusType,
): InternalPushNotificationStatusEnum => {
  const internalStatus = statusMap.get(status);
  if (!internalStatus) throw new Error(`Unknown status: ${status}`);
  return internalStatus;
};
