import { Segmented } from 'antd';
import styled from 'styled-components';

export const StyledSegmented = styled(Segmented)`
  &&& {
    .ant-segmented-item,
    .segmented-item-selected,
    .ant-segmented,
    .ant-segmented-item-selected,
    .ant-segmented-thumb {
      border-radius: 40px;
    }
    &.ant-segmented {
      padding: 0;
    }
    .ant-segmented-item {
      font-size: ${({ theme }) => theme.fontSizes.default};
      color: ${({ theme }) => theme.colors.palette.dustblu};
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-segmented-item-selected {
      box-shadow: 0 0 16px ${({ theme }) => theme.colors.palette.jupiter};
      color: ${({ theme }) => theme.colors.palette.deadPixel};
    }
    .ant-segmented-item-label {
      margin-top: 2px;
      display: flex;
      align-items: center;
      min-width: 100px;
      justify-content: center;
      padding: 0;
    }
    &.ant-segmented-block {
      min-height: 36px;
    }
  }
` as typeof Segmented;
