import type { DoctorConnectToConversationResponseDto } from 'shared/api/rtk-api';

import type { DoctorConversationInfo } from '../types/doctor-conversation-info';

export const externalDoctorConversationInfoToInternalDoctorConversationInfoMapper = (
  response: DoctorConnectToConversationResponseDto,
): DoctorConversationInfo => {
  return {
    conversationId: response.internalConversationId,
    pubNubChannelName: response.pubnubChannelName,
    conversationName: response.conversationName,
    participants: response.participants.map((participant) => ({
      id: participant.internalParticipantId.toString(),
      eTag: response.pubnubChannelName,
      updated: '',
      isCurrentUser: participant.isCurrentUser,
      avatar: participant.avatar,
      participantPubnubUuid: participant.participantPubnubUuid,
    })),
  };
};
