import type { OnlineAppointmentTimeSlot } from 'entity/doctor-times/types/online-appointment-time-slot';
import type { OnlineVisitDoctorSlotListResponseDtoData } from 'shared/api/rtk-api';
import { getTimeFromDate } from 'shared/lib/format';

export const externalOnlineAppointmentDoctorSlotToInternalTimeSlotAppointmentMapper = (
  doctorSlots: OnlineVisitDoctorSlotListResponseDtoData,
): OnlineAppointmentTimeSlot => {
  return {
    doctor: {
      fullName: doctorSlots.fullName,
      id: doctorSlots.internalOnlineVisitDoctorId,
      specialization: doctorSlots.onlineVisitSpecialization ?? '',
      image: doctorSlots.imageUrl ?? '',
    },
    timeSlots: doctorSlots.slots
      .filter((slot) => slot.isAvailable)
      .map((slot) => ({
        id: slot.internalOnlineSlotId,
        time: `${getTimeFromDate(slot.startDateTimeIso)} - ${getTimeFromDate(slot.endDateTimeIso)}`,
        date: slot.startDateTimeIso,
      })),
  };
};
