import { useCallback } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const useUrlPagination = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname.split('/').slice(0, 4).join('/');
  const { page = 1 } = useParams<{
    page: string;
  }>();

  const getTargetUrl = useCallback(
    (targetPage: number) => {
      return `${pathname}/page/${targetPage}${location.search ?? ''}`;
    },
    [pathname, location.search],
  );

  return {
    setPage: (targetPage: number) => {
      const targetUrl = getTargetUrl(targetPage);
      navigate(targetUrl);
    },
    getTargetUrl,
    page: Number(page),
  };
};
