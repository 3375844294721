import { AgeOptionsOptions, ChildrenOptionsOptions, RecipientType, SexOptions } from 'entity/marketing/constants';
import { AgeType, ChildType, GenderApiType } from 'shared/api/rtk-api';

import { PushNotificationFormType } from '../types';

export const requestSexOptions: Record<string, GenderApiType[]> = {
  [SexOptions.ALL]: [],
  [SexOptions.MALE]: [GenderApiType.Male],
  [SexOptions.FEMALE]: [GenderApiType.Female],
};

export const requestChildrenOptions: Record<string, ChildType[]> = {
  [ChildrenOptionsOptions.ALL]: [],
  [ChildrenOptionsOptions.YES]: [ChildType.Yes],
  [ChildrenOptionsOptions.NO]: [ChildType.No],
};

export const requestAgeOptions: Record<string, AgeType[]> = {
  [AgeOptionsOptions.ALL]: [],
  [AgeOptionsOptions.EIGHTEEN]: [AgeType.$1825],
  [AgeOptionsOptions.TWENTY]: [AgeType.$2635],
  [AgeOptionsOptions.THIRTY]: [AgeType.$3655],
  [AgeOptionsOptions.FIFTY]: [AgeType.$5665],
  [AgeOptionsOptions.SIXTY]: [AgeType.$66],
};

export const initialValues: PushNotificationFormType = {
  title: undefined,
  description: undefined,
  date: undefined,
  time: undefined,
  recipients: RecipientType.MANUAL,
  sex: SexOptions.ALL,
  age: AgeOptionsOptions.ALL,
  children: ChildrenOptionsOptions.ALL,
  file: undefined,
  aggregatedPatientExternalIds: undefined,
  fileName: undefined,
};

export const sexOptions = [
  { value: SexOptions.ALL, label: 'Все' },
  { value: SexOptions.FEMALE, label: 'Женщины' },
  { value: SexOptions.MALE, label: 'Мужчины' },
];

export const ageOptions = [
  { value: AgeOptionsOptions.ALL, label: 'Все' },
  { value: AgeOptionsOptions.EIGHTEEN, label: '18-25' },
  { value: AgeOptionsOptions.TWENTY, label: '26-35' },
  { value: AgeOptionsOptions.THIRTY, label: '36-55' },
  { value: AgeOptionsOptions.FIFTY, label: '56-65' },
  { value: AgeOptionsOptions.SIXTY, label: '65+' },
];

export const childrenOptions = [
  { value: ChildrenOptionsOptions.ALL, label: 'Все' },
  { value: ChildrenOptionsOptions.YES, label: 'Да' },
  { value: ChildrenOptionsOptions.NO, label: 'Нет' },
];

export const recipientsOptions = [
  { value: RecipientType.MANUAL, label: 'Сегментировать' },
  { value: RecipientType.LIST, label: 'Загрузить список' },
];
