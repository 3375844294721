import { type FC } from 'react';

import { type TableColumnsType, Typography } from 'antd';

import { PushNotificationStatuses } from 'entity/marketing/constants';
import { usePushNotificationListQuery } from 'entity/marketing/hooks/use-push-notification-list-query';
import { InternalPushNotification } from 'entity/marketing/types/internal-push-notification';
import { useUrlPagination } from 'shared/hooks/use-url-pagination';
import { useUrlSearch } from 'shared/hooks/use-url-search';
import { Table } from 'shared/ui/table';

import { PushNotificationsListDropdownMenu } from './push-notifications-list-dropdown-menu';

const { Text } = Typography;

const DEFAULT_PAGE_SIZE = 7;
const DEFAULT_PAGE = 1;

export const MarketingPushNotificationsTable: FC = () => {
  const { setPage, page } = useUrlPagination();
  const { search } = useUrlSearch('search');

  const { pushNotifications, total } = usePushNotificationListQuery({
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    search,
  });

  const columns: TableColumnsType<InternalPushNotification> = [
    {
      title: 'Название',
      dataIndex: 'title',
      ellipsis: true,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      ellipsis: true,
      render: (_, row) => <Text>{PushNotificationStatuses[row.status]}</Text>,
    },
    {
      title: 'Дата отправки',
      dataIndex: 'date',
      ellipsis: true,
    },
    {
      title: 'Время отправки',
      dataIndex: 'time',
      ellipsis: true,
    },
    {
      title: 'Число получателей',
      dataIndex: 'amount',
      ellipsis: true,
    },

    {
      width: 60,
      render: (_, row) => {
        return <PushNotificationsListDropdownMenu status={row.status} notificationId={row.id} />;
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={pushNotifications}
      rowKey={(row) => row.id}
      pagination={{
        current: page ?? DEFAULT_PAGE,
        total,
        onChange: (e) => setPage(e),
        pageSize: DEFAULT_PAGE_SIZE,
        showSizeChanger: false,
      }}
    />
  );
};
