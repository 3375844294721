import { Checkbox, Menu } from 'antd';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)`
  border-radius: 8px;
  min-height: 96px;
  .ant-dropdown-menu-item {
    padding: 2px 0 !important;
  }
  padding: 8px;
  .ant-dropdown-menu-item-disabled:hover,
  .ant-dropdown-menu-submenu-title-disabled:hover {
    cursor: pointer !important;
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: transparent;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked:after {
    border: 1px solid ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
`;
