import React, { type FC, useCallback, useState } from 'react';

import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';

export const useHealthAssistanceApproveModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState<() => void>(() => async () => {});
  const rejectActionHandler = () => setShowModal(false);
  const showApproveModal = useCallback((newAction: () => void) => {
    return () => {
      setAction(() => newAction);
      setShowModal(true);
    };
  }, []);

  const approveActionHandler = useCallback(() => {
    setShowModal(false);
    action();
  }, [action]);

  const ApproveModal: FC = () => (
    <CustomModal
      title="Подтвердить запись"
      description="Вы уверены, что хотите подтвердить запись?"
      visible={showModal}
      onClose={rejectActionHandler}
      actionButtons={[
        <CustomButton key={1} onClick={rejectActionHandler} $buttonType="declineWhite">
          Не подтверждать
        </CustomButton>,
        <CustomButton key={2} onClick={approveActionHandler} $buttonType="submit">
          Подтвердить
        </CustomButton>,
      ]}
    />
  );
  return { showApproveModal, ApproveModal };
};
