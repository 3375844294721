import styled from 'styled-components';

import { CustomModal } from 'shared/ui/custom-modal';

export const StyledCustomModal = styled(CustomModal)`
  .ant-modal-content {
    width: 416px;
  }

  .ant-modal-footer {
    text-align: center;
  }
`;
