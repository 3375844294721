import React, { type FC } from 'react';

import { SplitScreen } from 'shared/ui/split-screen';

import { StyledDiv, StyledSpace } from './styles';

interface Props {
  ReturnButton: React.ReactNode;
  LeftSide: React.ReactNode;
  RightSide?: React.ReactNode;
  ActionButtons: React.ReactNode[];
}

export const HealthAssistanceDetailsLayout: FC<Props> = ({ ReturnButton, LeftSide, RightSide, ActionButtons }) => {
  return (
    <>
      <StyledDiv>
        {ReturnButton}
        <StyledSpace size="large">
          {ActionButtons.map((ActionButton) => (
            <>{ActionButton}</>
          ))}
        </StyledSpace>
      </StyledDiv>
      <SplitScreen Left={LeftSide} Right={RightSide} />
    </>
  );
};
