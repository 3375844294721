import { useOnlineVisitAppointmentControllerDoctorConnectAppointmentQuery } from 'shared/api/rtk-api';

export const useDoctorConnectToOnlineAppointmentQuery = (appointmentId?: string) => {
  const onlineAppointmentId = Number(appointmentId);

  if (!onlineAppointmentId) throw new Error('onlineAppointmentId is not defined');

  const { data, ...response } = useOnlineVisitAppointmentControllerDoctorConnectAppointmentQuery(onlineAppointmentId, {
    pollingInterval: 10000,
  });

  return {
    conversationId: data?.internalConversationId,
    dailyChannel: data?.dailyChannel,
    ...response,
  };
};
