import { Input } from 'antd';
import styled from 'styled-components';

export const SendMessageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 10px 30px 30px;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.palette.white};
  border-radius: ${({ theme }) => theme.radii.extraSmall};
`;

export const SendMessageFileInputContainer = styled.div`
  cursor: pointer;
`;

export const SendMessageTextarea = styled(Input.TextArea)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};
  border-radius: 24px;
  cursor: pointer;

  &&&.ant-input {
    padding: 14px 16px;
    border: 1px solid ${({ theme }) => theme.colors.c6};
    font-size: ${({ theme }) => theme.fontSizes.default};
    line-height: ${({ theme }) => theme.lineHeights.default};

    &:focus {
    }
  }
`;

export const SendMessageButton = styled.div`
  cursor: pointer;
`;
