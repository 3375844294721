import { type FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { useDaily } from '@daily-co/daily-react';
import { routePatches } from 'shared/routes';
import { OnlineAppointmentEndCallIcon } from 'shared/ui/icons';

import { EndCallActionButton } from './styles';

export const DoctorOnlineAppointmentLeaveButton: FC = () => {
  const navigate = useNavigate();
  const callFrame = useDaily();

  const handleLeaveCall = () => {
    callFrame?.leave();
    navigate(routePatches.DoctorSchedule.path());
  };

  return (
    <EndCallActionButton onClick={handleLeaveCall}>
      <OnlineAppointmentEndCallIcon />
    </EndCallActionButton>
  );
};
