import { type FC } from 'react';

import { MarketingCreateNewPushNotificationButton } from 'feature/marketing-create-new-push-notification/ui';
import { ListLayout } from 'shared/ui/list-layout';
import { MarketingPushNotificationsTable } from 'widgets/marketing-push-notifications-table/ui/push-notifications-table';

import { MarketingPushNotificationsSearchInput } from './search-input';

export const MarketingPushNotificationsListPage: FC = () => {
  document.title = 'Пуш-уведомления';

  return (
    <ListLayout
      title="Пуш-уведомления"
      SearchInput={MarketingPushNotificationsSearchInput}
      AddButton={MarketingCreateNewPushNotificationButton}
      Table={MarketingPushNotificationsTable}
    />
  );
};
