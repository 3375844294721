import React, { FC } from 'react';

import { HealthAssistanceDetailsLayout } from 'entity/health-assistance/ui/health-assistance-details-layout';
import { AdminReturnToHomeVisitRejectedByClientListButton } from 'feature/admin-return-to-home-visit-rejected-by-admin-list/ui/admin-return-to-home-visit-rejected-by-client-list-button';
import { HomeVisitRejectedByPatientToTakeOnByAdminButton } from 'feature/home-visit-rejected-by-client-to-take-on-by-admin/ui/home-visit-rejected-by-patient-take-on-by-admin-button';
import { HomeVisitRejectedByPatientApproveByAdminButton } from 'feature/home-visit-rejected-by-patient-approve-by-admin/ui/home-visit-rejected-by-patient-approve-by-admin-button';
import { AdminHomeVisitRejectedByPatientDetailsForm } from 'widgets/admin-home-visit-rejected-by-patient-details';

export const AdminHomeVisitRejectedByPatientDetailsPage: FC = () => {
  return (
    <HealthAssistanceDetailsLayout
      ReturnButton={<AdminReturnToHomeVisitRejectedByClientListButton />}
      ActionButtons={[
        <HomeVisitRejectedByPatientToTakeOnByAdminButton key={1} />,
        <HomeVisitRejectedByPatientApproveByAdminButton key={2} />,
      ]}
      LeftSide={<AdminHomeVisitRejectedByPatientDetailsForm />}
    />
  );
};
