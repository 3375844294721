import { Button, Row } from 'antd';
import styled from 'styled-components';

export const ConversationDetailsWrapper = styled.div`
  position: absolute;
  right: 40px;
  bottom: 60px;
  top: 60px;
  width: 550px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.c4};
  border-radius: ${({ theme }) => theme.radii.extraSmall};
`;

export const ActionButton = styled(Button)`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
`;

export const EndCallActionButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.palette.purehearted};
  width: 88px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: ${({ theme }) => theme.radii.large};

  &.ant-btn {
    background-color: ${({ theme }) => theme.colors.palette.purehearted};
  }
`;

export const DashboardWrapper = styled(Row)`
  height: 96px;
`;
