import { useCallback } from 'react';

import {
  CreateMarketingMailingByIdsRequestDto,
  useMarketingMailingControllerCreateMarketingMailingWithIdsMutation,
} from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  requestDto: CreateMarketingMailingByIdsRequestDto;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useCreatePushNotificationByManagerWithListMutation = () => {
  const [mutation] = useMarketingMailingControllerCreateMarketingMailingWithIdsMutation();
  const action = useCallback(
    ({ requestDto, onSuccess, onError }: MutationHookOptions) => {
      mutation(requestDto)
        .unwrap()
        .then(() => {
          successNotification('Пуш-уведомление успешно создано');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при создании пуш-уведомления');
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
