import { FC } from 'react';

import type { InputProps } from 'antd';

import { Input } from 'shared/ui/input';
import { CustomRow } from 'shared/ui/row/custom-row';

interface TextAreaRowProp extends InputProps {
  title: string;
}

export const InputRow: FC<TextAreaRowProp> = ({ title, ...props }) => {
  return (
    <CustomRow title={title}>
      <Input {...props} />
    </CustomRow>
  );
};
