import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import { AuthCard, AuthCardContent, AuthLayoutContainer } from './styles';

export const AuthLayout: FC = () => {
  document.title = 'Авторизация';

  return (
    <AuthLayoutContainer>
      <AuthCard bordered={false}>
        <AuthCardContent>
          <Outlet />
        </AuthCardContent>
      </AuthCard>
    </AuthLayoutContainer>
  );
};
