import { FC } from 'react';

import { HealthAssistanceRecordsNavigationMenu } from 'feature/health-assistance-records-navigation/ui/health-assistance-records-navigation';
import { ListLayout } from 'shared/ui/list-layout';
import { AdminPastOnlineAppointmentsTable } from 'widgets/admin-past-online-appointments-table/ui/admin-past-online-appointments-table';

export const AdminPastOnlineAppointmentsListPage: FC = () => {
  document.title = 'Онлайн-приемы';

  return (
    <ListLayout
      title="История приемов"
      Menu={HealthAssistanceRecordsNavigationMenu}
      Table={AdminPastOnlineAppointmentsTable}
    />
  );
};
