import { Button, Typography } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  ${({ theme }) => theme.colors.palette.dustblu};
` as typeof Button;

export const StyledTypographyText = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.palette.dustblu};
  font-size: ${({ theme }) => theme.fontSizes.small};
` as typeof Typography.Text;
