import { useUrlSearch } from 'shared/hooks/use-url-search';
import type { SelectItem } from 'shared/ui/check-list';

export function useUrlStatusesArray<T extends string>(
  items: SelectItem<T>[],
): { statuses?: T[]; setStatuses: (value: T[]) => void } {
  const { search, setSearch } = useUrlSearch('status');

  const selectedValues = search ? search.split(',') : items.map((item) => item.value);
  const handleStatusChange = (value: T[]) => {
    setSearch(value.join(','));
  };

  const statuses = selectedValues.length === items.length ? undefined : (selectedValues as T[] | undefined);

  return { statuses, setStatuses: handleStatusChange };
}
