import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};
  gap: 10px;
`;

export const LeftContainer = styled.div`
  flex-basis: 50%;
  flex-shrink: 1;
  display: flex;
  justify-content: left;
`;

export const RightContainer = styled.div`
  justify-content: right;
  flex-basis: 50%;
  flex-shrink: 1;
  display: flex;
`;

export const MiddleContainer = styled.div`
  flex-basis: 0;
  flex-shrink: 0;
`;
