import { useMemo } from 'react';

import {
  type AdminNotification,
  HealthAssistanceType,
  useNotificationAdminControllerGetNotificationsForCurrentAdminQuery,
} from 'shared/api/rtk-api';
import { formatTimestamp } from 'shared/lib/format';
import { routePatches } from 'shared/routes';

function getLink(notification: AdminNotification) {
  if (notification.healthAssistanceType === HealthAssistanceType.HomeVisit && notification.entityId) {
    return routePatches.AdminHomeVisitDetails.path(notification.entityId);
  }
  if (notification.healthAssistanceType === HealthAssistanceType.HomeVisitRefuse && notification.entityId) {
    return routePatches.AdminRejectedByPatientHomeVisitDetails.path(notification.entityId);
  }
  if (notification.healthAssistanceType === HealthAssistanceType.ChatAssisting && notification.entityId) {
    return routePatches.AdminChatSupportDetails.path(notification.entityId);
  }
  return null;
}

const mapExternalAdminNotificationToInternalAdminNotification = (notification: AdminNotification) => {
  const link = getLink(notification);
  return {
    id: notification.id,
    isViewed: notification.isViewed,
    message: notification.message,
    date: formatTimestamp(notification.create_at),
    link,
  };
};

export const useAdminNotificationsQuery = () => {
  const { data, ...others } = useNotificationAdminControllerGetNotificationsForCurrentAdminQuery(undefined, {
    pollingInterval: 10000,
  });

  const notifications = useMemo(() => {
    return data?.map(mapExternalAdminNotificationToInternalAdminNotification);
  }, [data]);

  return {
    notifications,
    ...others,
  };
};
