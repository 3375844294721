import { type FC } from 'react';

import { Row, Typography } from 'antd';

import { Tag } from '../tag';
import { ContentWrapper, StyledStar, StyledStarOutlined } from './styles';

const { Text } = Typography;

interface UserFeedbackProps {
  tagTitle: 'oнлайн-прием' | 'вызов на дом';
  rating: number;
  review: string | null;
}

export const UserFeedback: FC<UserFeedbackProps> = ({ rating, review, tagTitle }) => {
  return (
    <ContentWrapper>
      <div>
        <Tag $tagType="grey" $tagSize="small">
          {tagTitle}
        </Tag>
      </div>
      <Row>
        {Array.from({ length: 5 }, (_, ind) => ind).map((el) => (
          <div key={el}>{el < rating ? <StyledStar /> : <StyledStarOutlined />}</div>
        ))}
      </Row>
      {review && <Text>{review}</Text>}
    </ContentWrapper>
  );
};
