import { Button, Row } from 'antd';
import styled from 'styled-components';

export const GeneralQuestionModalContainer = styled(Row)`
  border-radius: 24px;
  width: 400px;
  min-height: 212px;
  background-color: #fff;
  pointer-events: auto;
`;

export const GeneralQuestionModalTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
`;

export const GeneralModalCancelButton = styled(Button)`
  font-size: '16px';
  height: '48px';
`;
