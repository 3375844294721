import { type FC, useState } from 'react';

import type { ButtonProps } from 'antd/lib/button/button';
import { useNavigate } from 'react-router-dom';

import { routePatches } from 'shared/routes';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';
import { ReturnButton } from 'shared/ui/return-button';

interface Props extends ButtonProps {}

export const MarketingReturnToPushNotificationsListButtonWithConfirmation: FC<Props> = ({ ...props }) => {
  const navigate = useNavigate();

  const [showSaveModal, setShowSaveModal] = useState(false);

  const onClickHandler = () => setShowSaveModal(true);

  const rejectActionHandler = () => setShowSaveModal(false);

  const confirmActionHandler = () => {
    navigate(routePatches.ManagerPushNotificationList.path());
  };

  return (
    <>
      <ReturnButton {...props} onClick={onClickHandler}>
        Пуш-уведомления
      </ReturnButton>
      <CustomModal
        title="Вы уверены, что хотите покинуть эту страницу?"
        description="Внесенные изменения не сохранятся."
        visible={showSaveModal}
        onClose={rejectActionHandler}
        actionButtons={[
          <CustomButton key={1} onClick={confirmActionHandler} $buttonType="declineWhite">
            Выйти
          </CustomButton>,
          <CustomButton key={2} onClick={rejectActionHandler} $buttonType="submit">
            Отменить
          </CustomButton>,
        ]}
      />
    </>
  );
};
