import { InternalHomeVisitStatusEnum } from 'entity/home-visit/types/internal-home-visit-status-enum';
import { InternalHomeVisitStatusRejectedByPatientEnum } from 'entity/home-visit/types/internal-home-visit-status-rejected-by-patient-enum';
import type { SelectItem } from 'shared/ui/check-list';

export const homeVisitStatusOptions: SelectItem<InternalHomeVisitStatusEnum>[] = [
  { label: 'Новая', value: InternalHomeVisitStatusEnum.New },
  { label: 'В работе', value: InternalHomeVisitStatusEnum.InProgressByAnotherAdmin },
  { label: 'В обработке', value: InternalHomeVisitStatusEnum.InProgressByCurrentAdmin },
  { label: 'Подтверждена', value: InternalHomeVisitStatusEnum.AcceptByAdmin },
  { label: 'Принята врачом', value: InternalHomeVisitStatusEnum.AcceptByDoctor },
];

export const homeVisitRejectedByPatientStatusOptions: SelectItem<InternalHomeVisitStatusRejectedByPatientEnum>[] = [
  { label: 'Новая', value: InternalHomeVisitStatusRejectedByPatientEnum.New },
  { label: 'В работе', value: InternalHomeVisitStatusRejectedByPatientEnum.InProgressByAnotherAdmin },
  { label: 'В обработке', value: InternalHomeVisitStatusRejectedByPatientEnum.InProgressByCurrentAdmin },
];
