import { type FC } from 'react';

import { type ButtonProps } from 'antd';

import theme from 'shared/theme';
import { Button } from 'shared/ui/button';
import { DocumentTakeTaskIcon } from 'shared/ui/icons';
import { TypographyText } from 'shared/ui/typography';

type Props = {
  onClickHandler: () => void;
};

export const OnlineAppointmentToTakeInEditByAdminButton: FC<ButtonProps & Props> = ({ onClickHandler, ...props }) => {
  return (
    <Button
      icon={<DocumentTakeTaskIcon color={theme.colors.palette.columnOfOakGreen} />}
      $buttonType="submitWhite"
      onClick={onClickHandler}
      type="primary"
      size="large"
      {...props}
    >
      <TypographyText>Изменить</TypographyText>
    </Button>
  );
};
