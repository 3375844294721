import { getInternalHomeVisitStatus } from 'entity/home-visit/mappers/external-home-visit-status-to-internal-home-visit-status-mapper';
import type { InternalHomeVisitFormDetails } from 'entity/home-visit/types/internal-home-visit-form-details';
import { InternalHomeVisitStatusEnum } from 'entity/home-visit/types/internal-home-visit-status-enum';
import { AdminHomeVisitStatusEnum, type HomeVisitFormResponse } from 'shared/api/rtk-api';
import { formatPhone, formatPrice } from 'shared/lib/format';

export function externalHomeVisitDetailsToInternalHomeVisitDetailsMapper(
  homeVisitForm: HomeVisitFormResponse,
): InternalHomeVisitFormDetails {
  const internalHomeVisitStatus = getInternalHomeVisitStatus(homeVisitForm.status);
  const inProgress =
    internalHomeVisitStatus === InternalHomeVisitStatusEnum.InProgressByCurrentAdmin ||
    internalHomeVisitStatus === InternalHomeVisitStatusEnum.InProgressByAnotherAdmin;
  const { isAccepted } = homeVisitForm;

  const canBeRejected = !isAccepted && internalHomeVisitStatus !== InternalHomeVisitStatusEnum.InProgressByAnotherAdmin;
  const canBeCanceled = isAccepted && internalHomeVisitStatus !== InternalHomeVisitStatusEnum.InProgressByAnotherAdmin;
  const canBeTakenInProgress = homeVisitForm.status === AdminHomeVisitStatusEnum.New;
  const canBeTakenInEdit = isAccepted && !inProgress;

  return {
    homeVisitId: homeVisitForm.id,
    patientName: homeVisitForm.patientName,
    patientPhone: formatPhone(homeVisitForm.patientPhone),
    address: homeVisitForm.patientAddress,
    price: formatPrice(homeVisitForm.cost),
    date: homeVisitForm.callDate,
    time: homeVisitForm.callTime,
    specialization: homeVisitForm.specialization,
    doctorName: homeVisitForm.doctorName ?? '',
    doctorId: homeVisitForm.doctorId,
    status: internalHomeVisitStatus,
    canBeTakenInProgress,
    cityId: homeVisitForm.cityId,
    symptoms: homeVisitForm.tags,
    comments: homeVisitForm.comment,
    canBeRejected,
    canBeCanceled,
    isAccepted,
    canBeTakenInEdit,
    isPaid: homeVisitForm.isPaid,
  };
}
