import styled from 'styled-components';

export const ScrollableContainer = styled.div`
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  overflow-y: scroll;
  height: 100%;
`;

export const StyledScrollableContainer = styled.div`
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.palette.decemberSky};
    border-radius: 8px;
    border: 0 solid ${({ theme }) => theme.colors.palette.whiteSmoke};
  }
`;
