import { Space, Typography } from 'antd';
import styled from 'styled-components';

import { PlusIcon } from 'shared/ui/icons';

export const StyledPlusIcon = styled(PlusIcon)`
  &&& {
    display: block;
    height: 24px;
  }
`;

export const AdminRecordingsAddButton = styled(Space)`
  cursor: pointer;
  width: max-content;
  align-content: center;
`;
export const StyledTypographyText = styled(Typography.Text)`
  &&& {
    font-size: ${({ theme }) => theme.fontSizes.default};
    color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
    cursor: pointer;
  }
`;
