import React, { FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { SearchOutlined } from '@ant-design/icons';
import { useGetPastPatientsQuery } from 'entity/doctor/api';
import type { IPatientInfo } from 'entity/doctor/api/types';
import {
  PatientsPageContainer,
  PatientsPageTitle,
  PatientsSearchInput,
  PatientsTableContainer,
} from 'pages/doctor/patient-list/ui/styles';
import { PatientsTableData } from 'pages/doctor/patient-list/ui/table-data';
import { AppTable } from 'shared/ui/table2';

export const PatientsPage: FC = () => {
  document.title = 'Пациенты';
  const navigate = useNavigate();
  const { data: patients, isLoading } = useGetPastPatientsQuery();
  const [patientList, setPatientList] = useState<IPatientInfo[]>();

  const doSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (patients) {
      const character = event.target.value.toLowerCase();
      if (character.trim() && patients.length) {
        const foundMembers = patients.filter((patient) => {
          return patient.patientName?.toLowerCase()?.includes(character);
        });
        setPatientList(foundMembers);
      } else {
        setPatientList(patients);
      }
    }
  };

  useEffect(() => {
    if (patients) {
      // eslint-disable-next-line no-console
      console.log(patients);
      setPatientList(patients);
    }
  }, [patients]);

  return (
    <PatientsPageContainer>
      <PatientsSearchInput
        prefix={<SearchOutlined style={{ color: '#200E32', marginRight: 10 }} />}
        size="large"
        allowClear
        placeholder="Введите ФИО"
        onChange={doSearch}
      />
      <PatientsPageTitle>Пациенты</PatientsPageTitle>

      <PatientsTableContainer>
        <AppTable
          loading={isLoading}
          columns={PatientsTableData}
          dataSource={patientList}
          rowKey={({ patientId }) => patientId}
          pagination={{
            pageSize: 7,
            position: ['bottomLeft'],
            showSizeChanger: false,
            size: 'small',
          }}
          rowClassName="patient-table-row"
          onRow={({ patientId }) => ({
            onClick: () => {
              navigate(patientId.toString());
            },
          })}
        />
      </PatientsTableContainer>
    </PatientsPageContainer>
  );
};
