import { useMemo } from 'react';

import { useOnlineVisitDoctorControllerGetOnlineVisitDoctorSlotListQuery } from 'shared/api/rtk-api';

import { externalOnlineAppointmentDoctorSlotToInternalTimeSlotAppointmentMapper } from '../mappers/external-online-appoitment-doctor-slot-to-internal-time-slot-appointment-mapper';

interface OnlineAppointmentDoctorsListQuery {
  specialization?: string;
  fromDateTimeIso: string;
  toDateTimeIso: string;
}

export const useOnlineAppointmentDoctorListQuery = ({
  specialization,
  fromDateTimeIso,
  toDateTimeIso,
}: OnlineAppointmentDoctorsListQuery) => {
  const { data, ...others } = useOnlineVisitDoctorControllerGetOnlineVisitDoctorSlotListQuery(
    {
      specialization,
      fromDateTimeIso,
      toDateTimeIso,
    },
    { refetchOnMountOrArgChange: true },
  );

  const doctorSlots = useMemo(
    () =>
      data?.data
        .map(externalOnlineAppointmentDoctorSlotToInternalTimeSlotAppointmentMapper)
        .filter((doctor) => doctor.timeSlots.length) ?? [],
    [data],
  );

  return {
    doctorSlots,
    ...others,
  };
};
