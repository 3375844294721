/* eslint react/no-unstable-nested-components: 0 */
import { type FC, useMemo } from 'react';

import { MemberList, UserEntity } from '@pubnub/react-chat-components';
import { useNavigate } from 'react-router-dom';

import { useMessageCounts } from 'entity/pubnub/hook/use-message-count';
import { messageHelperByChannel } from 'entity/pubnub/lib/utils/message-helper-payload';
import { routePatches } from 'shared/routes';
import theme from 'shared/theme';
import { AvatarIcon } from 'shared/ui/icons';

import {
  ChatLastMessageTime,
  ChatMemberAvatar,
  ChatMemberContent,
  ChatMemberInfo,
  ChatMemberLastMessage,
  ChatMemberName,
  ChatUnreadMessage,
  Member,
  MemberListContent,
} from './styles';

type Props = {
  searchValue: string;
  selectedChat: string | undefined;
};

export const Chats: FC<Props> = ({ searchValue, selectedChat }) => {
  const navigate = useNavigate();

  const { countOfUnreadChatsMessages, doctorChatsMessages, doctorChats, chatMap } = useMessageCounts();

  const filteredChats = useMemo(() => {
    return doctorChats.filter((chat) => chat.name.toLowerCase().includes(searchValue.toLowerCase()));
  }, [doctorChats, searchValue]);

  const onChatClick = (user: UserEntity) => navigate(routePatches.DoctorChats.path(user.eTag));

  if (!filteredChats) {
    return null;
  }

  return (
    <MemberListContent>
      {doctorChatsMessages && (
        <MemberList
          sort={(a, b) => {
            const timetokenA = doctorChatsMessages[a.eTag]?.at(-1)?.timetoken ?? null;
            const timetokenB = doctorChatsMessages[b.eTag]?.at(-1)?.timetoken ?? null;
            if (timetokenA && timetokenB) {
              return timetokenB > timetokenA ? 1 : -1;
            }
            if (timetokenA) {
              return -1;
            }
            if (timetokenB) {
              return 1;
            }

            const dataOfCreatingChatA = chatMap.get(a.eTag)?.create_at ?? 0;
            const dataOfCreatingChatB = chatMap.get(b.eTag)?.create_at ?? 0;

            return dataOfCreatingChatA < dataOfCreatingChatB ? 1 : -1;
          }}
          members={filteredChats}
          memberRenderer={(chat) => {
            const chatUUID = chat.eTag;
            const { userMessage, fileName, time } = messageHelperByChannel(doctorChatsMessages[chatUUID]);
            const displayMessage = userMessage === '' ? fileName ?? 'Пока нет сообщений' : userMessage;
            const displayTime = time ?? '';
            const chatName = chat.name;
            const countOfUnreadChatMessages = countOfUnreadChatsMessages[chatUUID];
            const isSelectedChat = selectedChat === chatUUID;

            return (
              <Member
                key={chatUUID}
                onClick={() => onChatClick(chat)}
                style={{
                  background: isSelectedChat ? theme.colors.palette.whiteSmoke : theme.colors.secondBackground,
                }}
              >
                <ChatMemberAvatar size="large" icon={<AvatarIcon fill={theme.colors.mainBackground} />} />
                <ChatMemberContent>
                  <ChatMemberName ellipsis>{chatName}</ChatMemberName>
                  <ChatMemberLastMessage ellipsis>{displayMessage}</ChatMemberLastMessage>
                </ChatMemberContent>
                <ChatMemberInfo>
                  <ChatLastMessageTime>{displayTime}</ChatLastMessageTime>
                  {countOfUnreadChatMessages > 0 && !isSelectedChat && (
                    <ChatUnreadMessage>{countOfUnreadChatMessages}</ChatUnreadMessage>
                  )}
                </ChatMemberInfo>
              </Member>
            );
          }}
        />
      )}
    </MemberListContent>
  );
};
