import { FC } from 'react';

import { DropdownProps } from 'antd';

import { StyledDropdown } from './style';

// eslint-disable-next-line deprecation/deprecation
export const Dropdown: FC<DropdownProps> = ({ overlay, ...props }) => {
  return (
    <StyledDropdown
      {...props}
      overlayStyle={{
        cursor: 'pointer',
      }}
      overlay={overlay}
    />
  );
};
