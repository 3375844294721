import { useCallback } from 'react';

import { useDocumentControllerUploadAndParseManagerDocumentMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  data: FormData;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useUploadDocumentByManagerMutation = () => {
  const [mutation] = useDocumentControllerUploadAndParseManagerDocumentMutation();
  const action = useCallback(
    async ({ data, onSuccess, onError }: MutationHookOptions) => {
      return mutation(data)
        .unwrap()
        .then((res) => {
          successNotification('Документ успешно загружен');
          onSuccess?.();
          return res;
        })
        .catch(() => {
          errorNotification('Ошибка при загрузке документа');
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
