export const getMainScreenParticipantId = (
  remoteParticipantIds: string[],
  localParticipantId: string,
  activeSpeakerId: string | null,
) => {
  const isLocalParticipantActive = activeSpeakerId === localParticipantId;

  if (activeSpeakerId && !isLocalParticipantActive) return activeSpeakerId;

  if (remoteParticipantIds.length) return remoteParticipantIds[0];

  return null;
};
