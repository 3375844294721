import { Navigate, RouteObject } from 'react-router-dom';

import { NewAdminLayout } from 'app/layouts/admin/new-admin-layout';
import { AuthLayout } from 'app/layouts/auth';
import { DoctorLayout } from 'app/layouts/doctor';
import { OnlineAppointmentLayout } from 'app/layouts/doctor/online-appointment';
import { EmptyLayout } from 'app/layouts/empty';
import { AppLayout } from 'app/layouts/main';
import { MarketingLayout } from 'app/layouts/marketing';
import { ProtectedRoute } from 'app/router/routes/protected-route';
import { IAuthUserPayload, UserRolesEnum } from 'entity/auth/api/types';
import { AdminChatSupportDetailsPage } from 'pages/admin/admin-chat-support-details/ui/admin-chat-support-details-page';
import { AdminChatSupportListPage } from 'pages/admin/admin-chat-support-list/ui/admin-chat-support-list-page';
import { AdminHomeVisitDetailsPage } from 'pages/admin/admin-home-visit-details/ui/admin-home-visit-details-page';
import { AdminHomeVisitListPage } from 'pages/admin/admin-home-visit-list/ui/admin-home-visit-list-page';
import { AdminHomeVisitRejectedByPatientDetailsPage } from 'pages/admin/admin-home-visit-rejected-by-patient-details/ui/admin-home-visit-details-page';
import { AdminHomeVisitRejectedByPatientListPage } from 'pages/admin/admin-home-visit-rejected-by-patient-list/ui/admin-home-visit-rejected-by-patient-list-page';
import { AdminOnlineAppointmentDetailsPage } from 'pages/admin/admin-online-appointment-details/ui/admin-online-appointment-details-page';
import { AdminOnlineAppointmentListPage } from 'pages/admin/admin-online-appointment-list/ui/admin-online-appointment-list-page';
import { AdminOnlineAppointmentRejectedByPatientDetailsPage } from 'pages/admin/admin-online-appointment-rejected-by-patient-details/ui/admin-online-appointment-rejected-by-patient-details-page';
import { AdminOnlineAppointmentRejectedByPatientListPage } from 'pages/admin/admin-onlne-appointment-rejected-by-patient-list/ui/admin-online-appointment-rejected-by-patient-list-page';
import { AdminPastHomeVisitDetailsPage } from 'pages/admin/admin-past-home-visit-details/ui/admin-past-home-visit-details';
import { AdminPastOnlineAppointmentDetailsPage } from 'pages/admin/admin-past-online-appointment-details/ui/admin-past-online-appointment-details';
import { AdminPastOnlineAppointmentsListPage } from 'pages/admin/admin-past-online-appointments-list/ui/admin-past-online-appointments-list';
import { AdminPastHomeVisitsListPage } from 'pages/admin/admin-psat-home-visits-list/ui/admin-past-home-visits-list';
import { AdminChangePasswordPage } from 'pages/admin/change-password/ui';
import { AdminDoctorPage } from 'pages/admin/doctor/ui';
import { EditAdminDoctorPage } from 'pages/admin/doctor/ui/edit';
import { AdminDoctorInfoPage } from 'pages/admin/doctor/ui/info';
import { AdminDoctorsPage } from 'pages/admin/doctors/ui';
import { ForgotPasswordPage } from 'pages/auth/forgot-password/ui';
import { LoginPage } from 'pages/auth/login/ui';
import { ResetPasswordPage } from 'pages/auth/reset-password/ui';
import { ChatsPage } from 'pages/doctor/chats/ui';
import { HomeVisitDetailsPage } from 'pages/doctor/home-visit-details/ui/home-visit-details/home-visit-details-page';
import { DoctorOnlineAppointmentPage } from 'pages/doctor/online-appointment';
import { PatientPage } from 'pages/doctor/patient/ui';
import { PatientsPage } from 'pages/doctor/patient-list/ui';
import { SchedulePage } from 'pages/doctor/schedule/ui';
import { MarketingPushNotificationFormPage } from 'pages/marketing/push-notification-form/ui';
import { MarketingPushNotificationsListPage } from 'pages/marketing/push-notifications-list/ui';
import { NotFound } from 'pages/not-found/ui';
import { ProtocolPage } from 'pages/protocol';
import { routePatches } from 'shared/routes';

import { BasePaths } from '../enum/base-paths.enum';
import { PublicComponent } from './public-component';

export const routes = (_isLogin: boolean, user: IAuthUserPayload | null): RouteObject[] => [
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/schedule" replace />,
      },
      {
        element: <ProtectedRoute allowedRoles={[UserRolesEnum.DOCTOR]} />,
        children: [
          {
            element: <DoctorLayout />,
            children: [
              {
                path: 'schedule',
                children: [
                  {
                    index: true,
                    element: <SchedulePage canCreateOnlineVisitSlot={user?.canCreateOnlineVisitSlot} />,
                  },
                  {
                    path: 'appointment-details/:homeVisitId',
                    element: <HomeVisitDetailsPage />,
                  },

                  {
                    path: 'protocol',
                    element: <ProtocolPage />,
                  },
                ],
              },
              {
                path: 'patients',
                children: [
                  {
                    index: true,
                    element: <PatientsPage />,
                  },
                  {
                    path: ':patientId',
                    element: <PatientPage />,
                  },
                ],
              },
              {
                path: routePatches.DoctorChats.path(),
                children: [
                  {
                    index: true,
                    element: <ChatsPage />,
                  },
                  {
                    path: routePatches.DoctorChats.path(':chatId'),
                    element: <ChatsPage />,
                  },
                ],
              },
            ],
          },
          {
            path: routePatches.DoctorOnlineAppointment.path(),
            element: <OnlineAppointmentLayout />,
            children: [
              {
                path: routePatches.DoctorOnlineAppointment.path(':appointmentId'),
                element: <DoctorOnlineAppointmentPage />,
              },
              {
                path: '*',
                index: true,
                element: <Navigate to={routePatches.DoctorSchedule.path()} replace />,
              },
            ],
          },
        ],
      },
      {
        path: 'user',
        children: [
          {
            index: true,
            element: <PublicComponent component={NotFound} layout={EmptyLayout} />,
          },
          {
            element: <ProtectedRoute />,
            children: [
              {
                path: 'change-password',
                element: user?.role === UserRolesEnum.DOCTOR ? <DoctorLayout /> : <NewAdminLayout />,
                children: [
                  {
                    index: true,
                    element: <AdminChangePasswordPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: BasePaths[UserRolesEnum.ADMIN],
        element: <ProtectedRoute allowedRoles={[UserRolesEnum.ADMIN]} />,
        children: [
          {
            element: <NewAdminLayout />,
            children: [
              {
                path: 'appointment',
                children: [
                  {
                    path: routePatches.AdminHomeVisitList.path('page?/:page?'),
                    element: <AdminHomeVisitListPage />,
                  },
                  {
                    path: routePatches.AdminChatSupportList.path('page?/:page?'),
                    element: <AdminChatSupportListPage />,
                  },
                  {
                    path: routePatches.AdminHomeVisitDetails.path(':id'),
                    element: <AdminHomeVisitDetailsPage />,
                  },
                  {
                    path: routePatches.AdminChatSupportDetails.path(':id'),
                    element: <AdminChatSupportDetailsPage />,
                  },

                  {
                    path: routePatches.AdminOnlineAppointmentList.path('page?/:page?'),
                    element: <AdminOnlineAppointmentListPage />,
                  },
                  {
                    path: routePatches.AdminOnlineAppointmentDetails.path(':id'),
                    element: <AdminOnlineAppointmentDetailsPage />,
                  },
                  {
                    path: '*',
                    index: true,
                    element: <Navigate to={routePatches.AdminHomeVisitList.path()} replace />,
                  },
                ],
              },
              {
                path: 'reject-applications',
                children: [
                  {
                    path: routePatches.AdminRejectedByPatientHomeVisitList.path('page?/:page?'),
                    element: <AdminHomeVisitRejectedByPatientListPage />,
                  },
                  {
                    path: routePatches.AdminRejectedByPatientHomeVisitDetails.path(':id'),
                    element: <AdminHomeVisitRejectedByPatientDetailsPage />,
                  },

                  {
                    path: routePatches.AdminRejectedByPatientOnlineAppointmentList.path('page?/:page?'),
                    element: <AdminOnlineAppointmentRejectedByPatientListPage />,
                  },
                  {
                    path: routePatches.AdminRejectedByPatientOnlineAppointmentDetails.path(':id'),
                    element: <AdminOnlineAppointmentRejectedByPatientDetailsPage />,
                  },

                  {
                    path: '*',
                    index: true,
                    element: <Navigate to={routePatches.AdminRejectedByPatientHomeVisitList.path()} replace />,
                  },
                ],
              },
              {
                path: 'recordings',
                children: [
                  {
                    path: routePatches.AdminPastOnlineAppointmentsList.path('page?/:page?'),
                    element: <AdminPastOnlineAppointmentsListPage />,
                  },
                  {
                    path: routePatches.AdminPastHomeVisitsList.path('page?/:page?'),
                    element: <AdminPastHomeVisitsListPage />,
                  },
                  {
                    path: routePatches.AdminPastOnlineAppointmentDetails.path(':id'),
                    element: <AdminPastOnlineAppointmentDetailsPage />,
                  },
                  {
                    path: routePatches.AdminPastHomeVisitDetails.path(':id'),
                    element: <AdminPastHomeVisitDetailsPage />,
                  },
                  {
                    path: '*',
                    index: true,
                    element: <Navigate to={routePatches.AdminPastHomeVisitsList.path()} replace />,
                  },
                ],
              },
              {
                path: 'doctors',
                children: [
                  {
                    index: true,
                    element: <AdminDoctorsPage />,
                  },
                  {
                    path: ':doctorId',
                    element: <AdminDoctorPage />,
                    children: [
                      {
                        index: true,
                        element: <AdminDoctorInfoPage />,
                      },
                      {
                        path: 'edit',
                        element: <EditAdminDoctorPage />,
                      },
                    ],
                  },
                ],
              },

              {
                index: true,
                element: <Navigate to={routePatches.AdminHomeVisitList.path()} replace />,
              },
            ],
          },
        ],
      },
      {
        path: BasePaths[UserRolesEnum.MANAGER],
        element: <ProtectedRoute allowedRoles={[UserRolesEnum.MANAGER]} />,
        children: [
          {
            element: <MarketingLayout />,
            children: [
              {
                path: 'marketing',
                children: [
                  {
                    path: routePatches.ManagerPushNotificationList.path('page?/:page?'),
                    element: <MarketingPushNotificationsListPage />,
                  },
                  {
                    path: routePatches.ManagerPushNotificationForm.path(),
                    element: <MarketingPushNotificationFormPage />,
                    children: [
                      {
                        path: ':id',
                        element: <MarketingPushNotificationFormPage />,
                      },
                    ],
                  },
                  {
                    path: '*',
                    index: true,
                    element: <Navigate to={routePatches.ManagerPushNotificationList.path()} replace />,
                  },
                ],
              },
              {
                path: '*',
                index: true,
                element: <Navigate to={routePatches.ManagerPushNotificationList.path()} replace />,
              },
            ],
          },
          {
            index: true,
            element: <Navigate to={routePatches.ManagerPushNotificationList.path()} replace />,
          },
        ],
      },
    ],
  },
  {
    path: 'auth',
    element: !user ? <AuthLayout /> : <Navigate to={BasePaths[user.role]} replace />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
      { path: 'forgot-password', element: <ForgotPasswordPage /> },
      { path: 'reset-password', element: <ResetPasswordPage /> },
    ],
  },
  {
    path: BasePaths[UserRolesEnum.ADMIN],
    children: [],
  },
  {
    path: '*',
    element: <PublicComponent component={NotFound} layout={EmptyLayout} />,
  },
];
