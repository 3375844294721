import { FC } from 'react';

import { HealthAssistanceRecordsNavigationMenu } from 'feature/health-assistance-records-navigation/ui/health-assistance-records-navigation';
import { ListLayout } from 'shared/ui/list-layout';
import { AdminPastHomeVisitsTable } from 'widgets/admin-past-home-visits-table/ui/admin-past-home-visits-table';

export const AdminPastHomeVisitsListPage: FC = () => {
  document.title = 'Домашние визиты';

  return (
    <ListLayout title="История приемов" Menu={HealthAssistanceRecordsNavigationMenu} Table={AdminPastHomeVisitsTable} />
  );
};
