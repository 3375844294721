/* eslint no-restricted-imports: 0 */

import { config } from 'shared/config';

export const downloadPatientAnalysis = async (
  userId: number | undefined,
  fileId: number | undefined,
): Promise<Blob> => {
  const response = await fetch(
    `${config.apiBaseUrl}doctor-panel/download-analysis-files?userId=${userId}&fileId=${fileId}`,
    {
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error('Failed to download file');
  }

  return response.blob();
};

export const downloadPatientFiles = async (userId: number, fileId: number): Promise<Blob> => {
  const response = await fetch(
    `${config.apiBaseUrl}doctor-panel/download-patient-file?userId=${userId}&fileId=${fileId}`,
    {
      credentials: 'include',
    },
  );

  if (!response.ok) {
    throw new Error('Failed to download file');
  }

  return response.blob();
};

export const downloadChatFile = async (url: string): Promise<Blob> => {
  return (await fetch(url)).blob();
};
