import { type FC } from 'react';

import { type ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useHealthAssistanceApproveModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-approve-modal';
import { useOnlineAppointmentAcceptAppointmentByAdminMutation } from 'entity/online-appointment/hooks/use-online-appointment-accept-by-admin';
import type { InternalOnlineAppointmentDetails } from 'entity/online-appointment/types/internal-online-appointment-details';
import { routePatches } from 'shared/routes';
import { Button } from 'shared/ui/button';
import { CheckMarkIcon } from 'shared/ui/icons';

interface Props {
  onlineAppointmentData: InternalOnlineAppointmentDetails;
}

export const OnlineAppointmentApproveByAdminButton: FC<Props & ButtonProps> = ({ onlineAppointmentData, ...props }) => {
  const navigate = useNavigate();

  const [acceptAppointment] = useOnlineAppointmentAcceptAppointmentByAdminMutation();

  const { id, doctorId, onlineAppointmentSlotId } = onlineAppointmentData;

  const successHandler = () => navigate(routePatches.AdminOnlineAppointmentList.path());

  const { showApproveModal, ApproveModal } = useHealthAssistanceApproveModal();

  const onClickHandler = () => {
    acceptAppointment({
      id,
      doctorId,
      onlineVisitSlotId: onlineAppointmentSlotId,
      onSuccess: successHandler,
    });
  };

  return (
    <>
      <Button
        icon={<CheckMarkIcon />}
        onClick={showApproveModal(onClickHandler)}
        $buttonType="submit"
        type="primary"
        size="large"
        {...props}
      >
        Подтвердить
      </Button>
      <ApproveModal />
    </>
  );
};
