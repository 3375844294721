import { FC, PropsWithChildren } from 'react';

import { ButtonProps } from 'antd';

import { StyledButton } from 'shared/ui/button2/styles';

/**
 * @deprecated. Use Shared Button instead
 */
export const AppButton: FC<PropsWithChildren<ButtonProps>> = ({ children, ...other }) => (
  <StyledButton type="primary" shape="round" size="large" {...other}>
    {children}
  </StyledButton>
);
