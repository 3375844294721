import { FC } from 'react';

import type { AppointmentStatusLabel } from 'entity/health-assistance/types/appointment-status-label';
import { InternalHomeVisitStatusRejectedByPatientEnum } from 'entity/home-visit/types/internal-home-visit-status-rejected-by-patient-enum';

import { StyledTag, StyledTagText } from './styles';

const calculateText = (type?: InternalHomeVisitStatusRejectedByPatientEnum): AppointmentStatusLabel => {
  switch (type) {
    case InternalHomeVisitStatusRejectedByPatientEnum.New:
      return 'Новая';
    case InternalHomeVisitStatusRejectedByPatientEnum.InProgressByCurrentAdmin:
      return 'В обработке';
    case InternalHomeVisitStatusRejectedByPatientEnum.InProgressByAnotherAdmin:
      return 'В работе';
    default:
      throw new Error('Unknown tag type');
  }
};

export interface TagProps {
  appointmentStatus: InternalHomeVisitStatusRejectedByPatientEnum;
}

export const HomeVisitRejectedByPatientStatusTag: FC<TagProps> = ({ appointmentStatus }) => {
  const text = calculateText(appointmentStatus);
  return (
    <StyledTag $appointmentStatus={appointmentStatus}>
      <StyledTagText $type={appointmentStatus}>{text}</StyledTagText>
    </StyledTag>
  );
};
