import { FC, PropsWithChildren } from 'react';

import type { BaseOptionType, DefaultOptionType, SelectProps } from 'antd/lib/select';

import { CustomRow } from 'shared/ui/row/custom-row';
import { Select } from 'shared/ui/select';

interface Props extends SelectProps<unknown, DefaultOptionType | BaseOptionType>, PropsWithChildren {
  title: string;
}

export const SelectRow: FC<Props> = ({ title, ...props }) => {
  return (
    <CustomRow title={title}>
      <Select {...props} />
    </CustomRow>
  );
};
