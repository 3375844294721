import { useCallback } from 'react';

import { useAdminVisitsControllerAcceptChatAssistingMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  chatId?: string;
  duration?: number;
  specialization?: string;
  onSuccess?: () => void;
}
export const useChatSupportApproveRequestByAdminMutation = () => {
  const [mutation] = useAdminVisitsControllerAcceptChatAssistingMutation();
  const action = useCallback(
    ({ chatId, onSuccess, specialization, duration }: MutationHookOptions) => {
      if (!chatId || !duration || !specialization) {
        errorNotification('Невозможно принять домашний визит');
        return;
      }
      mutation({ chatId, duration, specialization })
        .unwrap()
        .then(() => {
          successNotification('Домашний визит принят администратором');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при принятии домашнего визита администратором');
        });
    },
    [mutation],
  );
  return [action];
};
