import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Alert, Form } from 'antd';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { IResetPasswordFormFields, ResetPasswordFormFieldsEnum } from 'entity/auth/api/types';
import { useChangePasswordViaCodeMutation } from 'entity/auth/hooks/use-change-password-via-code-mutation';
import {
  PasswordResetFormContainer,
  PasswordResetFormInput,
  PasswordResetTitle,
} from 'pages/auth/reset-password/ui/styles';
import { config } from 'shared/config';
import { AppButton } from 'shared/ui/button2';

export const ResetPasswordPage: FC = () => {
  document.title = 'Восстановление пароля';
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') ?? '';
  const email = searchParams.get('email') ?? '';

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IResetPasswordFormFields>({ defaultValues: { code, email } });
  const [changePasswordMutation, { isLoading, isError, isSuccess }] = useChangePasswordViaCodeMutation();
  const { password } = watch();

  const onSubmit: SubmitHandler<IResetPasswordFormFields> = (values) => {
    changePasswordMutation({
      ...values,
      onSuccess: () => {
        navigate('/auth');
      },
    });
  };

  return isSuccess ? (
    <Navigate to="/auth" replace />
  ) : (
    <PasswordResetFormContainer name="reset-password">
      <Form.Item>
        <PasswordResetTitle>Укажите новый пароль</PasswordResetTitle>
      </Form.Item>

      <Form.Item
        validateTrigger="onBlur"
        validateStatus={errors?.[ResetPasswordFormFieldsEnum.PASSWORD] ? 'error' : ''}
        help={
          errors?.[ResetPasswordFormFieldsEnum.PASSWORD] &&
          'Пароль должен состоять из цифр и латинских букв верхнего и нижнего регистра !'
        }
      >
        <Controller
          name={ResetPasswordFormFieldsEnum.PASSWORD}
          control={control}
          rules={{ pattern: config.validationPatterns.password }}
          render={({ field }) => (
            <PasswordResetFormInput {...field} size="large" placeholder="новый пароль" allowClear />
          )}
        />
      </Form.Item>
      <Form.Item
        validateTrigger="onBlur"
        validateStatus={errors?.[ResetPasswordFormFieldsEnum.CONFIRM_PASSWORD] ? 'error' : ''}
        help={errors?.[ResetPasswordFormFieldsEnum.CONFIRM_PASSWORD] && 'Пароли не совпадают!'}
      >
        <Controller
          name={ResetPasswordFormFieldsEnum.CONFIRM_PASSWORD}
          control={control}
          rules={{ required: true, validate: (value) => value === password }}
          render={({ field }) => (
            <PasswordResetFormInput {...field} size="large" placeholder="повторите пароль" allowClear />
          )}
        />
      </Form.Item>
      <Form.Item>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises, deprecation/deprecation */}
        <AppButton htmlType="submit" loading={isLoading} disabled={isLoading} onClick={handleSubmit(onSubmit)}>
          Восстановить
        </AppButton>
      </Form.Item>

      {isError && <Alert message="Что-то пошло не так!" type="error" closable banner />}
    </PasswordResetFormContainer>
  );
};
