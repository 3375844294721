/* eslint no-restricted-imports: 0 */

import { FC, PropsWithChildren } from 'react';

import { Collapse } from 'antd';

import {
  AppExpandPanelContainer,
  AppExpandPanelContent,
  AppExpandPanelHeadButton,
  AppExpandPanelHeadTitle,
} from 'shared/ui/expand-panel/styles';
import { DocumentPlusIcon } from 'shared/ui/icons';

type Props = PropsWithChildren<{
  title: string;
  name: string;
  extraButtonText?: string;
  extraButtonHandler?: () => void;
}>;

export const AppExpandPanel: FC<Props> = ({
  extraButtonHandler,
  title,
  extraButtonText = 'Добавить',
  name,
  children,
}) => {
  return (
    <AppExpandPanelContainer bordered={false} expandIconPosition="end">
      <Collapse.Panel
        header={<AppExpandPanelHeadTitle>{title}</AppExpandPanelHeadTitle>}
        extra={
          extraButtonHandler ? (
            <AppExpandPanelHeadButton
              onClick={(e) => {
                e.stopPropagation();
                extraButtonHandler();
              }}
              type="text"
              shape="round"
              icon={<DocumentPlusIcon />}
            >
              <span>{extraButtonText}</span>
            </AppExpandPanelHeadButton>
          ) : null
        }
        key={name}
      >
        <AppExpandPanelContent>{children}</AppExpandPanelContent>
      </Collapse.Panel>
    </AppExpandPanelContainer>
  );
};
