import { useCallback } from 'react';

import { saveAs } from 'file-saver';

import { useDocumentControllerUserUploadedFileByIdMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  fileId: string;
  fileName?: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDownloadChatFileQuery = () => {
  const [mutation] = useDocumentControllerUserUploadedFileByIdMutation();
  const action = useCallback(
    ({ fileId, fileName, onSuccess, onError }: MutationHookOptions) => {
      if (!fileId) {
        errorNotification('Невозможно скачать файл');
        onError?.();
        return;
      }
      mutation(Number(fileId))
        .unwrap()
        .then((payload) => {
          saveAs(payload, fileName);
          successNotification('Файл успешно скачан');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при скачивании файла');
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
