import { Select } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export const StyledSelect = styled(Select)`
  width: 100%;
  &.ant-select {
    background-color: transparent;
  }

  &&& {
    .ant-select-selection-item {
      align-self: center;
      color: ${({ theme }) => theme.colors.palette.deadPixel};
    }
    .ant-select-selector {
      background-color: ${({ disabled, theme }) =>
        disabled ? theme.colors.palette.superSilver : theme.colors.palette.white};

      height: 40px;
      &:hover {
        border-color: ${({ theme, disabled }) =>
          disabled ? 'transparent' : theme.colors.palette.columnOfOakGreen} !important;
      }
    }
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${({ theme }) => theme.radii.small} !important;
    border-color: transparent;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
    //Default
    //box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0;
  }

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
`;

export const StyledSelectWrapperStyles = createGlobalStyle`
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
        background-color: ${({ theme }) => theme.colors.palette.operaGlass}
`;
