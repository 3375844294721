import { useMemo } from 'react';

import { externalChatSupportToInternalChatSupportMapper } from 'entity/chat-support/mappers/external-chat-support-to-internal-chat-support-mapper';
import { useAdminVisitsControllerGetOneChatAssistingVisitQuery } from 'shared/api/rtk-api';

export const useChatSupportDetailsQuery = (id?: string) => {
  if (!id) throw new Error('id is not defined');
  const { data, ...others } = useAdminVisitsControllerGetOneChatAssistingVisitQuery(id);

  const chatSupport = useMemo(() => {
    return data ? externalChatSupportToInternalChatSupportMapper(data) : undefined;
  }, [data]);

  return {
    chatSupport,
    ...others,
  };
};
