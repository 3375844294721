import { ClinicDoctorAppointmentResponseDto } from 'shared/api/rtk-api';

import { ScheduleEventType } from '../constants';
import { InternalScheduleClinicAppointmentEvent } from '../types/internal-schedule-clinic-appointment-event';

export const externalClinicAppointmentEventToInternalClinicAppointmentEventMapper = (
  event: ClinicDoctorAppointmentResponseDto,
): InternalScheduleClinicAppointmentEvent => {
  return {
    id: event.id,
    start: event.recordDate,
    type: ScheduleEventType.CLINIC,
  };
};
