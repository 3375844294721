import { type FC, useState } from 'react';

import type { ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useOnlineAppointmentAcceptCancellationByAdminMutation } from 'entity/online-appointment/hooks/use-online-appointment-accept-cancellation-by-admin';
import { routePatches } from 'shared/routes';
import { Button } from 'shared/ui/button';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';
import { CheckMarkIcon } from 'shared/ui/icons';

interface Props {
  onlineAppointmentId: number;
}

export const OnlineAppointmentRejectedByPatientApproveByAdminButton: FC<ButtonProps & Props> = ({
  onlineAppointmentId,
  ...props
}) => {
  const navigate = useNavigate();

  const [showApproveModal, setShowApproveModal] = useState(false);

  const [acceptCancellation] = useOnlineAppointmentAcceptCancellationByAdminMutation();

  const applyButtonHandler = () => setShowApproveModal(true);

  const rejectActionHandler = () => setShowApproveModal(false);

  const successHandler = () => navigate(routePatches.AdminRejectedByPatientOnlineAppointmentList.path());

  const confirmActionHandler = () => {
    setShowApproveModal(false);
    acceptCancellation({ id: onlineAppointmentId, onSuccess: successHandler });
  };

  return (
    <>
      <Button
        icon={<CheckMarkIcon />}
        onClick={applyButtonHandler}
        $buttonType="submit"
        type="primary"
        size="large"
        {...props}
      >
        Подтвердить отмену
      </Button>
      <CustomModal
        title="Подтвердить запись"
        description="Вы уверены, что хотите подтвердить заявку на отмену?"
        visible={showApproveModal}
        onClose={rejectActionHandler}
        actionButtons={[
          <CustomButton key={1} onClick={rejectActionHandler} $buttonType="declineWhite">
            Отмена
          </CustomButton>,
          <CustomButton key={2} onClick={confirmActionHandler} $buttonType="submit">
            Подтвердить
          </CustomButton>,
        ]}
      />
    </>
  );
};
