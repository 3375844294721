import { FC, useCallback, useState } from 'react';

import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { useHasUnreadDoctorNotificationsQuery } from 'entity/doctor-notification/hooks/use-has-unread-doctor-notifications-query';
import { NotificationBellButton } from 'entity/notification/ui/notification-bell-button';
import { StyledPopover, StyledTypographyTitle } from 'entity/notification/ui/notification-popover/styles';
import { DoctorNotificationList } from 'widgets/doctor-notification/ui/doctor-notification-list';

type Props = Partial<CustomIconComponentProps>;

export const DoctorNotificationsWidget: FC<Props> = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const onClickHandler = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);
  const { hasUnreadNotifications } = useHasUnreadDoctorNotificationsQuery();

  return (
    <StyledPopover
      overlayInnerStyle={{ width: '400px' }}
      content={<DoctorNotificationList onClick={() => setIsActive(!isActive)} />}
      showArrow={false}
      open={isActive}
      title={<StyledTypographyTitle level={5}>Уведомления</StyledTypographyTitle>}
      placement="bottomRight"
      trigger="click"
    >
      <NotificationBellButton status={hasUnreadNotifications ? 'active' : 'inactive'} onClick={onClickHandler} />
    </StyledPopover>
  );
};
