import { useMemo } from 'react';

import { useConversationControllerDoctorConnectToConversationQuery } from 'shared/api/rtk-api';

import { externalDoctorConversationInfoToInternalDoctorConversationInfoMapper } from '../mappers/external-doctor-conversation-info-to-internal-doctor-conversation-info-mapper';

export const useDoctorConnectToConversationQuery = (conversationId: number) => {
  const { data, ...others } = useConversationControllerDoctorConnectToConversationQuery(conversationId);

  const conversationInfo = useMemo(() => {
    return data ? externalDoctorConversationInfoToInternalDoctorConversationInfoMapper(data) : undefined;
  }, [data]);

  return {
    conversationInfo,
    ...others,
  };
};
