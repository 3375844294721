import { FC } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { useGetAdminDoctorListQuery } from 'entity/admin/api';
import { IAdminDoctorInterface } from 'entity/admin/api/types/doctors';
import {
  AdminDoctorsPageContainer,
  AdminDoctorsPageTitle,
  AdminDoctorsSearchInput,
  AdminDoctorsTableContainer,
  AvatarLoader,
} from 'pages/admin/doctors/ui/styles';
import { AdminDoctorTableData } from 'pages/admin/doctors/ui/table-data';
import { useSearch } from 'shared/hooks/use-search';
import { AppTable } from 'shared/ui/table2';

type SearchForm = {
  searchLine: string;
  limit: number;
  offset: number;
};

export const AdminDoctorsPage: FC = () => {
  document.title = 'Врачи';

  const { searchTerm, searchValue, setSearchValue, setSearchTerm } = useSearch<SearchForm>({
    searchLine: '',
    limit: 7,
    offset: 0,
  });

  const {
    data: response,
    isLoading,
    isFetching,
  } = useGetAdminDoctorListQuery(
    {
      limit: searchTerm.limit,
      offset: searchTerm.offset,
      searchLine: searchTerm.searchLine,
    },
    { skip: !searchTerm.limit },
  );

  // eslint-disable-next-line
  const handleTableChange = ({ pageSize, current }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    setSearchTerm((prev) => ({ ...prev, limit: pageSize, offset: pageSize * (current - 1) }));
  };

  return (
    <AdminDoctorsPageContainer>
      <AdminDoctorsSearchInput
        prefix={<SearchOutlined style={{ color: '#200E32', marginRight: 10 }} />}
        size="large"
        allowClear
        placeholder="Введите ФИО врача"
        value={searchValue.searchLine}
        onChange={(e) => {
          setSearchValue((prev) => ({ ...prev, searchLine: e.target.value, limit: 7, offset: 0 }));
        }}
      />
      <AdminDoctorsPageTitle>Врачи</AdminDoctorsPageTitle>
      <AdminDoctorsTableContainer>
        <AppTable<IAdminDoctorInterface>
          columns={AdminDoctorTableData}
          loading={{ indicator: <AvatarLoader />, spinning: isLoading || isFetching }}
          dataSource={response?.data}
          rowKey={(doctor) => doctor.id}
          pagination={{
            total: response?.total,
            pageSize: 7,
            position: ['bottomLeft'],
            current: 1 + (searchTerm.offset ? Math.floor(searchTerm.offset / 7) : 0),
            showSizeChanger: false,
            size: 'small',
          }}
          onChange={handleTableChange}
        />
      </AdminDoctorsTableContainer>
    </AdminDoctorsPageContainer>
  );
};
