export enum ChangePasswordFormFieldsEnum {
  PREV_PASSWORD = 'prevPassword',
  NEW_PASSWORD = 'newPassword',
  NEW_PASSWORD_CONFIRM = 'newPasswordConfirm',
}

export interface IChangePasswordFormFields {
  [ChangePasswordFormFieldsEnum.PREV_PASSWORD]: string;
  [ChangePasswordFormFieldsEnum.NEW_PASSWORD]: string;
  [ChangePasswordFormFieldsEnum.NEW_PASSWORD_CONFIRM]: string;
}
