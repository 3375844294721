import { useCallback } from 'react';

import { useDoctorPanelControllerReadChatMutation } from 'shared/api/rtk-api';
import { errorNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  chatUUID: string;
  timeToken?: number;
}

export const useReadChatMutation = () => {
  const [mutation] = useDoctorPanelControllerReadChatMutation();
  const action = useCallback(
    ({ chatUUID }: MutationHookOptions) => {
      mutation({ chatUUID })
        .unwrap()
        .catch(() => {
          errorNotification('Ошибка при прочтении сообщений');
        });
    },
    [mutation],
  );
  return [action];
};
