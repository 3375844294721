import { ConfigProvider, Empty, List, Pagination, Typography } from 'antd';

import { AppExpandPanel } from 'shared/ui/expand-panel';
import { DocumentDownloadIcon, DocumentIcon, EmptyIcon } from 'shared/ui/icons';

export const PatientMedicalRecords = () => {
  const data: { title: string }[] = [];

  return (
    <AppExpandPanel name="medical-records" title="Назначения врача" extraButtonHandler={() => {}}>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description="На текущее время назначения врача отсутствуют."
            image={<EmptyIcon height={90} width={90} />}
          />
        )}
      >
        <List
          itemLayout="horizontal"
          dataSource={data}
          footer={
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            data.length ? <Pagination pageSize={3} size="small" total={data.length} onChange={(page) => {}} /> : null
          }
          renderItem={(item) => (
            <List.Item
              actions={[
                // eslint-disable-next-line react/jsx-key
                <DocumentDownloadIcon />,
              ]}
            >
              <List.Item.Meta
                avatar={<DocumentIcon />}
                title={<Typography.Text style={{ fontWeight: 400 }}>{item.title}</Typography.Text>}
              />
            </List.Item>
          )}
        />
      </ConfigProvider>
    </AppExpandPanel>
  );
};
