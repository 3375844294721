import { type FC } from 'react';

import { useRoutes } from 'react-router-dom';

import { useCurrentUserQuery } from 'entity/auth/hooks/use-current-user-query';

import { routes } from './routes';

export const Routes: FC = () => {
  const { currentUser, isLoading } = useCurrentUserQuery();

  const isLogin = !!currentUser;
  const router = useRoutes(routes(isLogin, currentUser));
  if (isLoading) {
    return <></>;
  }

  return router;
};
