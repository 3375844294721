import { Table } from 'antd';
import styled from 'styled-components';

import { IAdminRecordingInterface } from 'entity/admin/api/types/recordings';

export const AdminRecordingsTableContainer = styled.div`
  background-color: #fff;
  border-radius: 16px;
  max-height: 60vh;
  padding: 8px;

  .ant-table-thead,
  .ant-table-cell {
    background-color: inherit;
  }
`;

export const AdminRecordingsTable = styled(Table<IAdminRecordingInterface>)`
  background-color: #fff;
  border-radius: 16px;
  height: 100%;
`;

export const AdminRecordingsTableHeaderText = styled.span`
  font-weight: 700;
`;
