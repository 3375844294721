import { InternalHomeVisitStatusRejectedByPatientEnum } from 'entity/home-visit/types/internal-home-visit-status-rejected-by-patient-enum';
import { RejectByPatientEnum } from 'shared/api/rtk-api';

export const externalHomeVisitStatusToInternalHomeVisitStatusMapper = new Map<
  RejectByPatientEnum,
  InternalHomeVisitStatusRejectedByPatientEnum
>([
  [RejectByPatientEnum.New, InternalHomeVisitStatusRejectedByPatientEnum.New],
  [RejectByPatientEnum.InProgressByCurrentAdmin, InternalHomeVisitStatusRejectedByPatientEnum.InProgressByCurrentAdmin],
  [RejectByPatientEnum.InProgressByAnotherAdmin, InternalHomeVisitStatusRejectedByPatientEnum.InProgressByAnotherAdmin],
]);

export const getInternalHomeVisitRejectedByPatientStatus = (
  status: RejectByPatientEnum,
): InternalHomeVisitStatusRejectedByPatientEnum => {
  const internalStatus = externalHomeVisitStatusToInternalHomeVisitStatusMapper.get(status);
  if (!internalStatus) throw new Error(`Unknown status: ${status}`);
  return internalStatus;
};
