import { type FC, useState } from 'react';

import { type TableColumnsType } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useUrlStatusesArray } from 'entity/health-assistance/hooks/use-url-statuses-array';
import { HealthAssistanceStatusDropdownCheckList } from 'entity/health-assistance/ui/health-assistance-status-dropdown-check-list/health-assistance-status-dropdown-check-list';
import { onlineAppointmentRejectedByPatientStatusOptions } from 'entity/online-appointment/constants';
import { useOnlineAppointmentQuery } from 'entity/online-appointment/hooks/use-online-appointment-list-query';
import type { InternalOnlineAppointment } from 'entity/online-appointment/types/internal-online-appointment';
import { InternalOnlineAppointmentStatusEnum } from 'entity/online-appointment/types/internal-online-appointment-status-enum';
import { OnlineAppointmentRejectedByPatientStatusTag } from 'entity/online-appointment/ui/rejected-by-patient-online-appointment-status-tag';
import { useUrlPagination } from 'shared/hooks/use-url-pagination';
import { routePatches } from 'shared/routes';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';
import { Table } from 'shared/ui/table';

import { OnlineAppointmentRejectedByPatientTableDropdownMenu } from './online-appointment-rejected-by-patient-table-dropdown-menu';

const DEFAULT_PAGE_SIZE = 7;
const DEFAULT_PAGE = 1;
const DEFAULT_STATUSES = [
  InternalOnlineAppointmentStatusEnum.PendingCancellation,
  InternalOnlineAppointmentStatusEnum.ProcessingByAnotherAdmin,
  InternalOnlineAppointmentStatusEnum.ProcessingByCurrentAdmin,
];

export const OnlineAppointmentRejectedByPatientTable: FC = () => {
  const navigate = useNavigate();
  const { setPage, page } = useUrlPagination();
  const [showSucceedApproveModal, setShowSucceedApproveModal] = useState(false);

  const { statuses } = useUrlStatusesArray(onlineAppointmentRejectedByPatientStatusOptions);

  const columns: TableColumnsType<InternalOnlineAppointment> = [
    {
      title: 'Пациент',
      dataIndex: 'patient',
      ellipsis: true,
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      ellipsis: true,
    },
    {
      title: 'Специализация',
      dataIndex: 'specialization',
      ellipsis: true,
    },
    {
      title: 'Дата Приема',
      dataIndex: 'date',
      ellipsis: true,
    },
    {
      title: 'Время Приема',
      dataIndex: 'time',
      ellipsis: true,
    },
    {
      title: <HealthAssistanceStatusDropdownCheckList items={onlineAppointmentRejectedByPatientStatusOptions} />,
      dataIndex: 'status',
      width: 130,
      render: (_, row) => {
        const { status } = row;

        return <OnlineAppointmentRejectedByPatientStatusTag appointmentStatus={status} />;
      },
    },
    {
      width: 60,
      render: (_, row) => {
        return <OnlineAppointmentRejectedByPatientTableDropdownMenu onlineAppointment={row} />;
      },
    },
  ];

  const { onlineAppointments, total } = useOnlineAppointmentQuery({
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    statuses: statuses ?? DEFAULT_STATUSES,
  });

  const succeededActionModelHandler = () => setShowSucceedApproveModal(false);

  return (
    <>
      <Table
        columns={columns}
        dataSource={onlineAppointments}
        rowKey={(row) => row.id}
        onRow={({ isInUseByAnotherAdmin, id }) => ({
          onClick: () => {
            if (isInUseByAnotherAdmin) {
              setShowSucceedApproveModal(true);
            } else {
              navigate(routePatches.AdminRejectedByPatientOnlineAppointmentDetails.path(id));
            }
          },
        })}
        pagination={{
          current: page ?? DEFAULT_PAGE,
          total,
          onChange: (e) => setPage(e),
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
        }}
      />
      <CustomModal
        title="Данная заявка взята в работу другим администратором"
        visible={showSucceedApproveModal}
        onClose={succeededActionModelHandler}
        actionButtons={[
          <CustomButton key={1} onClick={succeededActionModelHandler} $buttonType="submit">
            Закрыть
          </CustomButton>,
        ]}
      />
    </>
  );
};
