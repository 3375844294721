import { ConversationParticipant } from 'entity/doctor-chat/types/doctor-conversation-info';

export const getParticipantAvatar = (
  participants: Map<string, ConversationParticipant>,
  participantPubNubId: string | undefined,
) => {
  if (!participantPubNubId) return undefined;

  return participants.get(participantPubNubId)?.avatar;
};
