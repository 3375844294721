import { AdminHealthAssistanceDetailsContainer } from 'entity/health-assistance/ui/health-assistance-details-container/admin-health-assistance-details-container';
import type { InternalOnlineAppointmentDetails } from 'entity/online-appointment/types/internal-online-appointment-details';
import { formatDateDMAndCallTime } from 'shared/lib/format';
import { TextRow } from 'shared/ui/row/text-row';

interface Props {
  editHandler?: () => void;
  editMode: boolean;
  onlineAppointment: InternalOnlineAppointmentDetails;
}

export const AdminOnlineAppointmentDetailsForm = ({ editHandler, editMode, onlineAppointment }: Props) => {
  if (!onlineAppointment) return null;

  const { patientName, patientPhone, specialization, doctorName, price, date, time } = onlineAppointment;

  return (
    <AdminHealthAssistanceDetailsContainer>
      <TextRow title="Тип приема" text="Онлайн-прием" />
      <TextRow title="Пациент" text={patientName} />
      <TextRow title="Телефон" text={patientPhone} />
      <TextRow editMode={editMode} editHandler={editHandler} title="Специализация" text={specialization} />
      <TextRow editMode={editMode} editHandler={editHandler} title="Врач" text={doctorName} />
      <TextRow
        editMode={editMode}
        editHandler={editHandler}
        title="Дата и время"
        text={formatDateDMAndCallTime(date, time)}
      />
      <TextRow title="Стоимость" text={price} />
    </AdminHealthAssistanceDetailsContainer>
  );
};
