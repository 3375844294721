import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { config } from 'shared/config';

const baseQuery = fetchBaseQuery({
  baseUrl: config.apiBaseUrl,
  prepareHeaders: (headers) => {
    // TODO: change localStorage to secure place
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const token = localStorage.getItem('refreshToken');
    if (token) {
      const response = await fetch(`${config.apiBaseUrl}internal/auth/refresh`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
        const { accessToken, refreshToken } = await response.json();
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
        localStorage.setItem('token', accessToken);
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
        localStorage.setItem('refreshToken', refreshToken);
        return baseQuery(args, api, extraOptions);
      }
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      window.location.href = '/auth';
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: 'api',
  tagTypes: ['Doctor', 'Notification'],
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
});
