import { useCallback } from 'react';

import { useOnlineVisitAppointmentControllerDoctorAddSpecialistForAppointmentMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  internalOnlineVisitAppointmentId: number;
  internalOnlineVisitDoctorId: number;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useScheduleAddSpecialistByDoctorMutation = () => {
  const [mutation] = useOnlineVisitAppointmentControllerDoctorAddSpecialistForAppointmentMutation();
  const action = useCallback(
    ({ internalOnlineVisitAppointmentId, internalOnlineVisitDoctorId, onSuccess, onError }: MutationHookOptions) => {
      mutation({
        internalOnlineVisitAppointmentId,
        internalOnlineVisitDoctorId,
      })
        .unwrap()
        .then(() => {
          successNotification('Узкий специалист успешно добавлен');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при добавлении узкого специалиста');
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
