import { Form, Input } from 'antd';
import styled from 'styled-components';

import { AppButton } from 'shared/ui/button2';
import { LinkButton } from 'shared/ui/link-button';

export const LoginTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 28.67px;
  color: #3b3a3a;
`;

export const LoginFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginFormInputPassword = styled(Input.Password)`
  border-radius: 24px;
  width: 320px;
  height: 40px;
`;

export const LoginFormForgotPasswordLink = styled(LinkButton)`
  text-align: right;
  padding-right: 16px;
  font-weight: 400;
  font-size: 14;
`;

/* eslint-disable-next-line deprecation/deprecation */
export const LoginFormButton = styled(AppButton)`
  margin-top: 18px;
`;
