import { FC, PropsWithChildren } from 'react';

import { Dropdown } from 'antd';

import { DropdownMenu } from 'shared/ui/dropdown2/style';

type Props = PropsWithChildren<{
  // eslint-disable-next-line deprecation/deprecation
  items: JSX.Element[];
}>;

export const AppDropDown: FC<Props> = ({ children, items }) => {
  return (
    <Dropdown
      overlay={
        <DropdownMenu style={{ borderRadius: '8px' }} items={items.map((el, index) => ({ key: index, label: el }))} />
      }
      placement="bottomLeft"
    >
      {children}
    </Dropdown>
  );
};
