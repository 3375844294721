import React, { type FC, JSX } from 'react';

import type { InternalHomeVisitList } from 'entity/home-visit/types/internal-home-visit-list';
import { HomeVisitCancelAppointmentByAdminDropdownMenuButton } from 'feature/home-visit-cancel-appointment-by-admin/ui/home-visit-cancel-appointment-by-admin-dropdown-menu-button';
import { HomeVisitRejectRequestByAdminDropdownMenuButton } from 'feature/home-visit-reject-request-by-admin/ui/home-visit-reject-request-by-admin-dropdown-menu-button';
import { HomeVisitToTakeOnEditByAdminDropdownMenuButton } from 'feature/home-visit-to-take-on-edit-by-admin/ui/home-visit-to-take-on-edit-by-admin-dropdown-menu-button';
import { HomeVisitToTakeOnProgressByAdminDropdownMenuButton } from 'feature/home-visit-to-take-on-progress-by-admin/ui/home-visit-to-take-on-progress-by-admin-dropdown-menu-button';
import { DropDownMenu } from 'shared/ui/dropdown-menu';
import { VerticalDotsIcon } from 'shared/ui/icons';

interface Props {
  homeVisit: InternalHomeVisitList;
}

export const HomeVisitTableDropdownMenu: FC<Props> = ({ homeVisit }) => {
  const getNodeItems = (): JSX.Element[] => {
    const buttonsArray = [];
    if (homeVisit.canBeCanceled) {
      buttonsArray.push(<HomeVisitCancelAppointmentByAdminDropdownMenuButton homeVisitId={homeVisit.id} key={1} />);
    }
    if (homeVisit.canBeRejected) {
      buttonsArray.push(<HomeVisitRejectRequestByAdminDropdownMenuButton homeVisitId={homeVisit.id} key={2} />);
    }
    if (homeVisit.canBeTakenInProgress) {
      buttonsArray.push(<HomeVisitToTakeOnProgressByAdminDropdownMenuButton homeVisitId={homeVisit.id} key={3} />);
    }
    if (homeVisit.canBeTakenInEdit) {
      buttonsArray.push(<HomeVisitToTakeOnEditByAdminDropdownMenuButton homeVisitId={homeVisit.id} key={4} />);
    }
    return buttonsArray;
  };

  const nodeItems = getNodeItems();
  return nodeItems.length > 0 ? (
    <DropDownMenu placement="bottomRight" nodeItems={nodeItems}>
      <VerticalDotsIcon />
    </DropDownMenu>
  ) : null;
};
