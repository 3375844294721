import React, { type FC, JSX } from 'react';

import type { InternalChatSupport } from 'entity/chat-support/types/internal-chat-support';
import { ChatSupportRejectRequestByAdminDropdownMenuButton } from 'feature/chat-support-reject-request-by-admin/ui/chat-support-reject-request-by-admin-dropdown-menu-button';
import { ChatSupportToTakeOnProgressByAdminDropdownMenuButton } from 'feature/chat-support-to-take-on-progress-by-admin/ui/chat-support-to-take-on-progress-by-admin-dropdown-menu-button';
import { DropDownMenu } from 'shared/ui/dropdown-menu';
import { VerticalDotsIcon } from 'shared/ui/icons';

interface Props {
  chatSupport: InternalChatSupport;
}

export const ChatSupportTableDropdownMenu: FC<Props> = ({ chatSupport }) => {
  const getNodeItems = (): JSX.Element[] => {
    const buttonsArray = [];
    if (chatSupport.canBeRejected) {
      buttonsArray.push(<ChatSupportRejectRequestByAdminDropdownMenuButton chatSupportId={chatSupport.id} key={1} />);
    }
    if (chatSupport.canBeTakenInProgress) {
      buttonsArray.push(
        <ChatSupportToTakeOnProgressByAdminDropdownMenuButton chatSupportId={chatSupport.id} key={2} />,
      );
    }
    return buttonsArray;
  };

  const nodeItems = getNodeItems();
  return nodeItems.length > 0 ? (
    <DropDownMenu placement="bottomRight" nodeItems={nodeItems}>
      <VerticalDotsIcon />
    </DropDownMenu>
  ) : null;
};
