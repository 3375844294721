import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  &&& {
    color: ${({ theme }) => theme.colors.palette.dustblu};
    font-size: ${({ theme }) => theme.fontSizes.default};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
