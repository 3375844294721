import { Button, Collapse } from 'antd';
import styled from 'styled-components';

export const AppExpandPanelContainer = styled(Collapse)`
  margin-top: 16px;
  background-color: #fff;
  width: '100%';
  font-size: 16px;
  border-radius: 16px;
`;

export const AppExpandPanelHeadTitle = styled.div`
  line-height: 32px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

export const AppExpandPanelHeadButton = styled(Button)`
  color: #007336;
  font-size: 16px;
  line-height: 22px;
  span {
    vertical-align: top;
  }
`;

export const AppExpandPanelContent = styled.div`
  background-color: #fff;
  width: '100%';
  height: 230px;
  border-radius: 16px;
  padding: 18px;
  overflow: hidden;
`;
