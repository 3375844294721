export enum InternalOnlineAppointmentStatusEnum {
  NewForAdmin = 'NewForAdmin',
  AssignedByCurrentAdmin = 'AssignedByCurrentAdmin',
  AssignedByAnotherAdmin = 'AssignedByAnotherAdmin',
  PendingCancellation = 'PendingCancellation',
  ProcessingByCurrentAdmin = 'ProcessingByCurrentAdmin',
  ProcessingByAnotherAdmin = 'ProcessingByAnotherAdmin',
  AcceptedByAdmin = 'AcceptedByAdmin',
  FinishedForAdmin = 'FinishedForAdmin',
  DoctorAbsent = 'DoctorAbsent',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
}
