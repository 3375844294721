import { FC, PropsWithChildren } from 'react';

import { StyledDropdownButton } from 'shared/ui/dropdown-menu/style';

export const DropdownMenuButton: FC<PropsWithChildren> = ({ children, ...props }) => {
  return (
    <StyledDropdownButton type="text" {...props}>
      {children}
    </StyledDropdownButton>
  );
};
