import { type FC } from 'react';

import { Col, Row } from 'antd';

import { ScheduleEventTypeIcons, ScheduleEventTypeTitle } from 'entity/schedule/constants';
import type { InternalScheduleOnlineAppointmentEvent } from 'entity/schedule/types/internal-schedule-online-appointment-event';
import { getFormattedNameFromFullName } from 'entity/schedule/utils';
import { getTimeFromDate } from 'shared/lib/format';
import { DoctorScheduleEventDetailsPopover } from 'widgets/doctor-schedule-event-card-details-widget';

import { ScheduleSlotContainer, SlotLine } from './styles';

type Props = {
  event: InternalScheduleOnlineAppointmentEvent;
};

export const OnlineAppointmentEventCard: FC<Props> = ({ event }) => {
  const { start, end, patientName } = event;

  return (
    <ScheduleSlotContainer>
      <DoctorScheduleEventDetailsPopover event={event}>
        <Row>
          <Col span={4}>{ScheduleEventTypeIcons.ONLINE}</Col>
          <Col span={20}>
            <SlotLine strong>{ScheduleEventTypeTitle.ONLINE}</SlotLine>
            <SlotLine>{getFormattedNameFromFullName(patientName)}</SlotLine>
            <SlotLine>
              {getTimeFromDate(start)}-{getTimeFromDate(end)}
            </SlotLine>
          </Col>
        </Row>
      </DoctorScheduleEventDetailsPopover>
    </ScheduleSlotContainer>
  );
};
