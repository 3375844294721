import { type FC, type ReactNode } from 'react';

import { DailyProvider, useCallObject } from '@daily-co/daily-react';

type Props = {
  children: ReactNode;
};

export const DailyWrapper: FC<Props> = ({ children }) => {
  const callObject = useCallObject({});

  return <DailyProvider callObject={callObject}>{children}</DailyProvider>;
};
