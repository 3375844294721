import React, { type FC, useCallback } from 'react';

import { ButtonProps } from 'antd';

import { useHomeVisitRejectedByPatientAcceptByAdminMutation } from 'entity/home-visit/hooks/use-home-visit-rejected-by-patient-accept-by-admin-mutation';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

interface Props {
  homeVisitId?: number;
}

export const HomeVisitRejectedByPatientApproveByAdminDropdownMenuButton: FC<Props & ButtonProps> = ({
  homeVisitId,
  ...props
}) => {
  const [takeToOneMutation] = useHomeVisitRejectedByPatientAcceptByAdminMutation();

  const handleTakeToOneMutation = useCallback(() => {
    takeToOneMutation({ homeVisitId });
  }, [takeToOneMutation, homeVisitId]);

  return (
    <DropdownMenuButton onClick={handleTakeToOneMutation} {...props}>
      <TypographyText>Подтвердить отмену</TypographyText>
    </DropdownMenuButton>
  );
};
