import { Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import styled from 'styled-components';

export const StyledTitle = styled(Title)`
  &&&& {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

export const HealthAssistanceDetailsAsideContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  height: calc(100vh - 100px);
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.palette.snowflake};
`;

export const ShadowBox = styled.div`
  position: absolute;
  height: 100%;
  right: -10px;
  z-index: 1000;
  width: 10px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2);
`;

export const StyledSpace = styled(Space)`
  width: 100% !important;
`;

export const HealthAssistanceDetailsContainer = styled.div`
  &&& {
    height: calc(100vh - 100px);
    background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};
    border-style: hidden;
  }
`;
