import styled from 'styled-components';

export const SecondaryVideoWrapper = styled.div`
  position: relative;
  width: 340px;
  height: 190px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radii.extraSmall};
`;

export const ParticipantTitleWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(59, 58, 58, 0.5);
  border-radius: ${({ theme }) => theme.radii.large};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
`;

export const MicrophoneIconWrapper = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 10px;
  right: 10px;
  background-color: ${({ theme }) => theme.colors.c4};
  border-radius: ${({ theme }) => theme.radii.large};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16p;
  }
`;

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.palette.white};
  font-size: ${({ theme }) => theme.fontSizes.default};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;
