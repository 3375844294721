/* eslint react/no-unstable-nested-components: 0 */

import { type FC, useEffect, useState } from 'react';

import { Chat, MessageList } from '@pubnub/react-chat-components';
import { Avatar, Typography } from 'antd';
import FileSaver from 'file-saver';
import Pubnub from 'pubnub';
import { usePubNub } from 'pubnub-react';

import { useCurrentUserQuery } from 'entity/auth/hooks/use-current-user-query';
import { useReadChatMutation } from 'entity/doctor-chat/hooks/use-read-chat-mutation';
import { useDownloadChatFileQuery } from 'entity/files/files/use-download-chat-file-query';
import { useMessageCounts } from 'entity/pubnub/hook/use-message-count';
import { formatDateRelative } from 'entity/pubnub/lib/format';
import { messageHelperByChannel } from 'entity/pubnub/lib/utils/message-helper-payload';
import Emitter from 'shared/lib/emitter';
import theme from 'shared/theme';
import { AvatarIcon, CrossIcon, EmptyDialogIcon, FileIcon } from 'shared/ui/icons';
import { downloadChatFile } from 'widgets/doctor-analysis-results/download-file-api';
import { SendMessage } from 'widgets/send-message';

import {
  ChatBody,
  ChatHeader,
  ChatTitle,
  ChatTitleWrapper,
  EmptyDialogWrapper,
  FileIconWrapper,
  Message,
  MessageGroup,
  MessageTime,
  StyledCrossButtonContainer,
  StyledDateWrapper,
  StyledMessageListElementContainer,
  TypographyText,
} from './styles';

type Props = {
  selectedChat: string;
  onCloseChat?: () => void;
};

export const ChatDetails: FC<Props> = ({ selectedChat, onCloseChat }: Props) => {
  const [chatMessages, setChatMessages] = useState<{ id: string; data: string }[]>([]);
  const { chatMap } = useMessageCounts();

  const { currentUser } = useCurrentUserQuery();
  const [readChat] = useReadChatMutation();
  const pubnub = usePubNub();
  const [isEmptyChat, setIsEmptyChat] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false);

  const [downloadFileByFileId] = useDownloadChatFileQuery();

  useEffect(() => {
    setIsChatLoading(true);

    pubnub.fetchMessages({ channels: [selectedChat] }, (status, data) => {
      setIsChatLoading(false);

      if (status.error) {
        setIsEmptyChat(true);
        return;
      }
      setIsEmptyChat(!data.channels?.[selectedChat]?.[0]?.message);

      const messages = (data.channels?.[selectedChat] ?? []).map((message) => {
        const formatedDateOfCurrentMessage = formatDateRelative(message.timetoken);
        return {
          id: String(message.timetoken),
          data: formatedDateOfCurrentMessage,
        };
      });
      setChatMessages(messages);
    });
  }, [pubnub, selectedChat]);

  function doDownloadFile(url: string, fileName: string | undefined) {
    /* eslint-disable-next-line  @typescript-eslint/no-floating-promises */
    downloadChatFile(url).then((file) => {
      // eslint-disable-next-line deprecation/deprecation
      FileSaver.saveAs(file, fileName);
    });
  }

  const doDownloadFileByFileId = (id: string, fileName: string) => downloadFileByFileId({ fileId: id, fileName });

  useEffect(() => {
    readChat({ chatUUID: selectedChat });
  }, [readChat, selectedChat]);

  useEffect(() => {
    const func = (event: Pubnub.MessageEvent) => {
      if (selectedChat === event.channel) {
        readChat({ chatUUID: event.channel });
        setIsEmptyChat(false);
      }
    };
    // TODO: add type save emitter
    Emitter.on('newMessage', func);
    return () => {
      Emitter.off('newMessage', func);
    };
  }, [currentUser?.pubNubUserId, readChat, selectedChat]);

  const selectedChatData = chatMap.get(selectedChat);

  if (!selectedChatData || isChatLoading) return null;

  return (
    <>
      <ChatHeader>
        <Avatar size="large" icon={<AvatarIcon fill={theme.colors.mainBackground} />} />
        <ChatTitleWrapper>
          <ChatTitle>{selectedChatData.name}</ChatTitle>
          {onCloseChat && (
            <StyledCrossButtonContainer onClick={onCloseChat}>
              <CrossIcon />
            </StyledCrossButtonContainer>
          )}
        </ChatTitleWrapper>
      </ChatHeader>
      <ChatBody>
        <Chat users={[selectedChatData]} currentChannel={selectedChat}>
          <MessageList
            fetchMessages={25}
            messageRenderer={({ message }) => {
              const formatedDateOfCurrentMessage = formatDateRelative(message.timetoken);
              const messagesWithEqualDate = chatMessages.filter((m) => m.data === formatedDateOfCurrentMessage);
              const sortedById = messagesWithEqualDate.slice().sort((a, b) => (a.id > b.id ? 1 : -1));
              const firstMessageInDate = sortedById[0];
              const isCurrentElementFirstInThatDate =
                messagesWithEqualDate.length < 2 || firstMessageInDate?.id === String(message.timetoken);

              const { userMessage, time, fileName, url, fileId } = messageHelperByChannel(message.message);

              return (
                <StyledMessageListElementContainer>
                  {isCurrentElementFirstInThatDate ? (
                    <StyledDateWrapper>
                      <TypographyText>{formatedDateOfCurrentMessage}</TypographyText>
                    </StyledDateWrapper>
                  ) : null}
                  <div className="pn-message__container">
                    <Avatar size="large" icon={<AvatarIcon fill={theme.colors.mainBackground} />} />
                    <div className="pn-message__custom">
                      <MessageGroup>
                        {url && (
                          <FileIconWrapper onClick={() => doDownloadFile(url, fileName)}>
                            <FileIcon />
                          </FileIconWrapper>
                        )}
                        {fileId && (
                          <FileIconWrapper onClick={() => doDownloadFileByFileId(fileId, userMessage)}>
                            <FileIcon />
                          </FileIconWrapper>
                        )}
                        {fileName && <Message>{fileName}</Message>}
                        <Message>{userMessage}</Message>
                      </MessageGroup>
                      <MessageTime>{time}</MessageTime>
                    </div>
                  </div>
                </StyledMessageListElementContainer>
              );
            }}
          />

          {!isChatLoading && isEmptyChat ? (
            <EmptyDialogWrapper>
              <EmptyDialogIcon />
              <Typography.Text>Пока нет сообщений</Typography.Text>
            </EmptyDialogWrapper>
          ) : null}

          <SendMessage selectedChatData={selectedChatData} />
        </Chat>
      </ChatBody>
    </>
  );
};
