import React, { type FC, type ReactNode } from 'react';

import { HeaderContainer, LayoutContainer, LayoutContentContainer } from 'shared/ui/general-layout/styles';

interface Props {
  headerSlot: ReactNode;
  pageSlot: ReactNode;
}

export const GeneralLayout: FC<Props> = ({ headerSlot, pageSlot }) => {
  return (
    <LayoutContainer>
      <HeaderContainer>{headerSlot}</HeaderContainer>
      <LayoutContentContainer>{pageSlot}</LayoutContentContainer>
    </LayoutContainer>
  );
};
