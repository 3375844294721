import { type FC } from 'react';

import { useParams } from 'react-router-dom';

import { HealthAssistanceDetailsLayout } from 'entity/health-assistance/ui/health-assistance-details-layout';
import { useOnlineAppointmentDetailsQuery } from 'entity/online-appointment/hooks/use-online-appointment-details-query';
import { AdminReturnToOnlineAppointmentRejectedByClientListButton } from 'feature/admin-return-to-online-appointment-rejected-by-patient-list/ui/admin-return-to-online-appointment-rejected-by-patient-list-button';
import { OnlineAppointmentRejectedByPatientApproveByAdminButton } from 'feature/online-appointment-rejected-by-patient-approve-by-admin/ui/online-appointment-rejected-by-patient-approve-by-admin-button';
import { OnlineAppointmentRejectedByPatientToTakeOnByAdminButton } from 'feature/online-appointment-rejected-by-patient-to-take-on-by-admin/ui/online-appointment-rejected-by-patient-to-take-on-by-admin-button';

import { AdminOnlineAppointmentRejectedByPatientDetailsForm } from './admin-online-appointment-rejected-by-patient-details-form';

export const AdminOnlineAppointmentRejectedByPatientDetailsPage: FC = () => {
  const { id } = useParams();

  const { onlineAppointment, isLoading } = useOnlineAppointmentDetailsQuery(id);

  if (isLoading) return null;

  if (!onlineAppointment) return <div>not found</div>;

  const { id: onlineAppointmentId, canBeCanceled, canBeProcessed } = onlineAppointment;

  return (
    <HealthAssistanceDetailsLayout
      ReturnButton={<AdminReturnToOnlineAppointmentRejectedByClientListButton />}
      ActionButtons={[
        canBeProcessed && (
          <OnlineAppointmentRejectedByPatientToTakeOnByAdminButton onlineAppointmentId={onlineAppointmentId} key={1} />
        ),
        canBeCanceled && (
          <OnlineAppointmentRejectedByPatientApproveByAdminButton onlineAppointmentId={onlineAppointmentId} key={2} />
        ),
      ]}
      LeftSide={<AdminOnlineAppointmentRejectedByPatientDetailsForm onlineAppointment={onlineAppointment} />}
    />
  );
};
