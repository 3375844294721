import React, { type FC } from 'react';

import { Dummy } from 'shared/ui/errors/dummy';

export const NotFound: FC = () => {
  document.title = 'Not Found';

  return (
    <Dummy
      content={{
        title: 'Page not found',
        statusCode: 404,
        text: "Sorry, we couldn't find the page you were looking for.",
        secondText: 'We suggest that you return to main home page',
      }}
    />
  );
};
