import { useMemo } from 'react';

import { useAdminVisitsControllerGetPastHomeVisitQuery } from 'shared/api/rtk-api';

import { externalPastHomeVisitDetailsToInternalPastHomeVisitDetailsMapper } from '../mappers/external-past-home-visit-details-to-internal-past-home-visit-details-mapper';

export const usePastHomeVisitDetailsQuery = (homeVisitId?: string) => {
  const id = Number(homeVisitId);
  if (!id) throw new Error('id is not defined');
  const { data, ...others } = useAdminVisitsControllerGetPastHomeVisitQuery(id);

  const pastHomeVisit = useMemo(() => {
    return data ? externalPastHomeVisitDetailsToInternalPastHomeVisitDetailsMapper(data) : undefined;
  }, [data]);

  return {
    pastHomeVisit,
    ...others,
  };
};
