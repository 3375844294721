// Color names from https://colornamer.robertcooper.me/
const palette = {
  white: '#FFFFFF',
  whiteSmoke: '#F5F5F5',
  snowflake: '#F0F0F0',
  superSilver: '#EEEEEE',
  decemberSky: '#D5D7D9',
  dustblu: '#969AA0',
  deadPixel: '#3B3A3A',
  columnOfOakGreen: '#007336',
  springDew: '#D6EEE1',
  operaGlass: '#E5F1EB',
  clementine: '#ED6E00',
  organicFiber: '#FDEEE0',
  aquarelleRed: '#FCDCDD',
  paleLavender: '#DCD2FF',
  meteorShower: '#5529FF',
  purehearted: '#E8686D',
  mercury: '#EBEBEB',
  jupiter: '#E1E1E1',
  black: '#000000',
};

const theme = {
  colors: {
    c1: palette.columnOfOakGreen,
    c2: palette.mercury,
    c3: '#FF0000',
    c4: palette.white,
    c5: palette.dustblu,
    c6: '#EBEDF1',
    mainText: palette.deadPixel,
    mainBackground: palette.whiteSmoke,
    secondBackground: palette.white,
    palette,
  },
  fontSizes: {
    theSmallest: '10px',
    extraSmall: '12px',
    small: '14px',
    default: '16px',
    large: '20px',
    extraLarge: '28px',
  },
  fontWeights: {
    regular: 400,
    bold: 700,
  },
  lineHeights: {
    small: 1,
    default: 1.3,
  },
  radii: {
    extraSmall: '8px',
    small: '16px',
    default: '24px',
    large: '40px',
  },
};

export type ThemeInterface = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends ThemeInterface {}
}

export default theme;
