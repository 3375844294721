import { FC } from 'react';

import { Space } from 'antd';

import { CustomRow } from 'shared/ui/row/custom-row';
import { Tag } from 'shared/ui/tag';

export interface SymptomsTagsRowProps {
  tags: string[];
}

export const SymptomTagsRow: FC<SymptomsTagsRowProps> = ({ tags }) => {
  return (
    <CustomRow title="Симптомы">
      <Space wrap>
        {tags.map((text) => (
          <Tag $tagType="grey" key={text}>
            {text}
          </Tag>
        ))}
      </Space>
    </CustomRow>
  );
};
