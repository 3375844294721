export enum LoginFormFieldsEnum {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export interface ILoginFormFields {
  [LoginFormFieldsEnum.EMAIL]: string;
  [LoginFormFieldsEnum.PASSWORD]: string;
}

export interface IUpdateDoctorFormFields {
  [LoginFormFieldsEnum.EMAIL]: string;
}
