import { FC } from 'react';

import { Avatar, Col, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { AvatarIcon, ChatIcon, VideoIcon } from 'shared/ui/icons';
import { PatientInfoButton, PatientInfoRow } from 'widgets/doctor-patient-info/styles';

const { Text } = Typography;

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentPatient: any;
};

export const PatientInfo: FC<Props> = ({ currentPatient }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/chats', { state: { key: 'user_3c4400761cba4b65b77b6cae55fc21eb' }, replace: true });
  };

  return (
    <PatientInfoRow gutter={[16, 10]} justify="space-between" align="middle">
      <Col flex="40px">
        <Avatar icon={<AvatarIcon />} size={40} />
      </Col>
      <Col flex="auto">
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
        <Text strong>{currentPatient.patientName}</Text>
        <br />
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
        <Text>{currentPatient.patientPhone}</Text>
      </Col>
      <Col flex="160px">
        <PatientInfoButton type="primary">
          <VideoIcon />
          Видеозвонок
        </PatientInfoButton>
      </Col>
      <Col flex="92px">
        <PatientInfoButton type="primary" onClick={() => handleClick()}>
          <ChatIcon width={24} /> Чат
        </PatientInfoButton>
      </Col>
    </PatientInfoRow>
  );
};
