import { MarketingMailingResponseDto } from 'shared/api/rtk-api';
import { getTimeFromDate } from 'shared/lib/format';

import { getDateFromISODate } from '../lib/format';
import { InternalPushNotification } from '../types/internal-push-notification';
import { getInternalPushNotificationStatus } from './external-push-hotification-statuses-to-internal-push-notfication-statuses-mapper';

export const externalPushNotificationToInternalPushNotificationMapper = (
  notification: MarketingMailingResponseDto,
): InternalPushNotification => {
  return {
    id: notification.id,
    title: notification.name,
    status: getInternalPushNotificationStatus(notification.status),
    date: getDateFromISODate(notification.isoSendDateTime),
    time: getTimeFromDate(notification.isoSendDateTime),
    amount: notification.recipientsNumber,
  };
};
