import React, { type FC, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { useUrlStatusesArray } from 'entity/health-assistance/hooks/use-url-statuses-array';
import { useUrlPagination } from 'shared/hooks/use-url-pagination';
import { CheckList, type SelectItem } from 'shared/ui/check-list';

interface Props<T> {
  items: SelectItem<T>[];
}

/**
 * Component for displaying a list of statuses with checkboxes.
 *
 * Limit to 1 url parameter 'status', the rest will be removed.
 */
export const HealthAssistanceStatusCheckList: FC<Props<string>> = ({ items }) => {
  const { statuses } = useUrlStatusesArray(items);
  const { getTargetUrl } = useUrlPagination();
  const navigate = useNavigate();

  const setStatusesAndPage = useCallback(
    (newStatuses: string[]) => {
      const newUrl = getTargetUrl(1);
      const urlWithoutStatus = newUrl.split('?')[0];
      const newSearch = newStatuses.length ? `?status=${newStatuses.join(',')}` : '';
      const url = `${urlWithoutStatus}${newSearch}`;
      navigate(url);
    },
    [getTargetUrl, navigate],
  );
  return <CheckList items={items} defaultChecked={statuses} onChange={setStatusesAndPage} />;
};
