import { type FC } from 'react';

import { type Moment } from 'moment';

import type { InternalScheduleEvent } from 'entity/schedule/types';
import { EmptyIcon } from 'shared/ui/icons';
import { ScheduleChooseDateWidget } from 'widgets/doctor-schedule-choose-date-widget/ui';
import { ScheduleGridWidget } from 'widgets/doctor-schedule-widget/ui/grid';
import { ScheduleContainer, StyledEmpty } from 'widgets/doctor-schedule-widget/ui/styles';

type Props = {
  startDate: Moment;
  data: InternalScheduleEvent[];
};

export const ScheduleWidget: FC<Props> = ({ startDate, data }) => {
  return (
    <ScheduleContainer>
      <ScheduleChooseDateWidget startDate={startDate} />

      {data.length ? (
        <ScheduleGridWidget data={data} startDate={startDate} />
      ) : (
        <StyledEmpty image={<EmptyIcon width={200} height={200} />} description={false} />
      )}
    </ScheduleContainer>
  );
};
