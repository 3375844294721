import { type FC } from 'react';

import { Col, Row } from 'antd';

import { ScheduleEventTypeIcons, ScheduleEventTypeTitle } from 'entity/schedule/constants';
import type { InternalScheduleSlotEvent } from 'entity/schedule/types/internal-schedule-slot-event';
import { ScheduleDeleteSlotByDoctorButton } from 'feature/schedule-delete-slot-by-doctor/ui';
import { getTimeFromDate } from 'shared/lib/format';

import { ScheduleSlotContainer, SlotLine } from './styles';

type Props = {
  event: InternalScheduleSlotEvent;
};

export const SlotEventCard: FC<Props> = ({ event }) => {
  const { start, end, id } = event;

  return (
    <ScheduleSlotContainer isSlot>
      <Row>
        <Col span={4}>{ScheduleEventTypeIcons.SLOT}</Col>
        <Col span={20}>
          <SlotLine strong>{ScheduleEventTypeTitle.SLOT}</SlotLine>
          <SlotLine>
            {getTimeFromDate(start)}-{getTimeFromDate(end)}
          </SlotLine>
          <ScheduleDeleteSlotByDoctorButton slotId={id} />
        </Col>
      </Row>
    </ScheduleSlotContainer>
  );
};
