import { type FC } from 'react';

import { useGetOnlineAppointmentParticipantIds } from 'entity/daily/hooks/use-get-online-appointment-participant-ids';

import { OnlineAppointmentMainScreenContent } from '../main-screen-content';
import { OnlineAppointmentSecondaryScreen } from '../secondary-screen';
import { OnlineAppointmentWaitScreen } from '../wait-screen';
import { MainVideoWrapper, SecondaryVideosBlockWrapper } from './styles';

export const OnlineAppointmentMainScreen: FC = () => {
  const { mainScreenParticipantId, secondaryScreenParticipantIds } = useGetOnlineAppointmentParticipantIds();

  return (
    <MainVideoWrapper>
      {!mainScreenParticipantId && <OnlineAppointmentWaitScreen />}

      {mainScreenParticipantId && <OnlineAppointmentMainScreenContent participantId={mainScreenParticipantId} />}

      <SecondaryVideosBlockWrapper>
        {secondaryScreenParticipantIds.map((participantId) => (
          <OnlineAppointmentSecondaryScreen key={participantId} participantId={participantId} />
        ))}
      </SecondaryVideosBlockWrapper>
    </MainVideoWrapper>
  );
};
