import { useCallback } from 'react';

import { useInternalAuthControllerChangePasswordViaCodeMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  code?: string;
  email?: string;
  password?: string;
  onSuccess?: () => void;
}
export const useChangePasswordViaCodeMutation = (): [
  ({ code, email }: MutationHookOptions) => unknown,
  {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
  },
] => {
  const [mutation, { isLoading, isError, isSuccess }] = useInternalAuthControllerChangePasswordViaCodeMutation();
  const action = useCallback(
    ({ code, email, password, onSuccess }: MutationHookOptions) => {
      if (!code || !email || !password) {
        errorNotification('Не указаны данные для восстановления пароля');
        return;
      }
      mutation({ code, email, password })
        .unwrap()
        .then(() => {
          successNotification('Пароль успешно изменен');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при изменении пароля');
        });
    },
    [mutation],
  );
  return [action, { isLoading, isError, isSuccess }];
};
