import moment from 'moment';

import { PushNotificationFormType } from 'pages/marketing/push-notification-form/types';
import { MarketingMailingFindOneResponseDto } from 'shared/api/rtk-api';
import { getTimeFromDate } from 'shared/lib/format';

import { AgeOptionsOptions, ChildrenOptionsOptions, RecipientType, SexOptions } from '../constants';
import { getInternalPushNotificationAgeOptions } from './external-age-options-to-internal-age-options';
import { getInternalPushNotificationChildrenOptions } from './external-children-options-to-internal-children-options';
import { getInternalPushNotificationSexOptions } from './external-sex-options-to-internal-sex-options';

export const externalPushNotificationToInternalPushNotificationFormMapper = (
  notification: MarketingMailingFindOneResponseDto,
): PushNotificationFormType => {
  return {
    id: notification.id,
    title: notification.name,
    description: notification.description,
    date: moment(notification.sendDateTime),
    time: getTimeFromDate(notification.sendDateTime),
    recipients: notification.fileName ? RecipientType.LIST : RecipientType.MANUAL,
    sex: notification.genderFilter?.length
      ? getInternalPushNotificationSexOptions(notification.genderFilter[0])
      : SexOptions.ALL,
    age: notification.ageFilter?.length
      ? getInternalPushNotificationAgeOptions(notification.ageFilter[0])
      : AgeOptionsOptions.ALL,
    children: notification.childFilter?.length
      ? getInternalPushNotificationChildrenOptions(notification.childFilter[0])
      : ChildrenOptionsOptions.ALL,
    aggregatedPatientExternalIds: notification.gatewayUserIds ?? undefined,
    fileName: notification.fileName ?? undefined,
  };
};
