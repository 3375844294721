import type { FC, PropsWithChildren } from 'react';

import { Space } from 'antd';

import { Navigation } from 'feature/navigation/ui';
import { HeaderLayout } from 'shared/ui/header-layout';
import { LogoIcon } from 'shared/ui/icons';
import { Link } from 'shared/ui/link';
import { AdminNotificationsWidget } from 'widgets/admin-notification';
import { UserMenu } from 'widgets/user-menu';

interface Props {}

export const AdminHeader: FC<PropsWithChildren<Props>> = () => {
  return (
    <HeaderLayout
      left={
        <Link to="/admin/appointment/home-visit-list">
          <LogoIcon />
        </Link>
      }
      middle={
        <Navigation
          pages={[
            { path: '/admin/appointment/', name: 'Записи' },
            { path: '/admin/reject-applications', name: 'Заявки' },
            { path: '/admin/doctors', name: 'Врачи' },
            { path: '/admin/recordings', name: 'История' },
          ]}
        />
      }
      right={
        <Space size="large">
          <AdminNotificationsWidget />
          <UserMenu />
        </Space>
      }
    />
  );
};
