import { FC } from 'react';

import { Col, Typography } from 'antd';

import { MarketingRemovePushNotificationRecipientsFileButton } from 'feature/marketing-remove-push-notification-recipients-file/ui';

import { StyledRow } from './styles';

const { Text } = Typography;

type Props = {
  onRemoveFile: () => void;
  fileName: string;
};
export const UploadedFile: FC<Props> = ({ onRemoveFile, fileName }) => {
  return (
    <StyledRow gutter={25}>
      <Col>
        <Text>{fileName}</Text>
      </Col>
      <Col>
        <MarketingRemovePushNotificationRecipientsFileButton onRemoveFile={onRemoveFile} />
      </Col>
    </StyledRow>
  );
};
