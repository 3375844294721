import React, { type FC, useState } from 'react';

import { TableColumnsType } from 'antd';
import { useNavigate } from 'react-router-dom';

import { chatSupportStatusOptions } from 'entity/chat-support/constant';
import { useChatSupportListQuery } from 'entity/chat-support/hooks/use-chat-support-list-query';
import type { InternalChatSupport } from 'entity/chat-support/types/internal-chat-support';
import { InternalChatSupportStatusEnum } from 'entity/chat-support/types/internal-chat-support-status-enum';
import { ChatSupportStatusTag } from 'entity/chat-support/ui/chat-support-status-tag';
import { SearchParams } from 'entity/health-assistance/constants';
import { useUrlStatusesArray } from 'entity/health-assistance/hooks/use-url-statuses-array';
import { HealthAssistanceStatusDropdownCheckList } from 'entity/health-assistance/ui/health-assistance-status-dropdown-check-list/health-assistance-status-dropdown-check-list';
import { useUrlPagination } from 'shared/hooks/use-url-pagination';
import { useUrlSearch } from 'shared/hooks/use-url-search';
import { routePatches } from 'shared/routes';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';
import { Table } from 'shared/ui/table';
import { ChatSupportTableDropdownMenu } from 'widgets/admin-chat-support-table/ui/chat-support-table-dropdown-menu';

const DEFAULT_PAGE_SIZE = 7;
const DEFAULT_PAGE = 1;

export const ChatSupportTable: FC = () => {
  const { setPage, page } = useUrlPagination();
  const navigate = useNavigate();
  const [showInProgressModal, setShowInProgressModal] = useState(false);

  const { search: name } = useUrlSearch(SearchParams.Search);
  const { search: date } = useUrlSearch(SearchParams.Date);
  const { statuses } = useUrlStatusesArray(chatSupportStatusOptions);

  const { chatSupports, total } = useChatSupportListQuery({
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    name,
    date,
    statuses,
  });

  const columns: TableColumnsType<InternalChatSupport> = [
    {
      title: 'Пациент',
      dataIndex: 'patient',
      ellipsis: true,
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      ellipsis: true,
    },
    {
      title: 'Специализация',
      dataIndex: 'specialization',
      ellipsis: true,
    },
    {
      title: 'Врач',
      dataIndex: 'doctor',
      ellipsis: true,
    },
    {
      title: 'Период доступности',
      dataIndex: 'firstAvailability',
      ellipsis: true,
    },
    {
      title: 'Стоимость, ₽',
      dataIndex: 'cost',
      ellipsis: true,
    },
    {
      title: 'Оплачено',
      dataIndex: 'isPaid',
      ellipsis: true,
      render: (isPaid: boolean) => (isPaid ? 'Да' : 'Нет'),
    },
    {
      title: <HealthAssistanceStatusDropdownCheckList items={chatSupportStatusOptions} />,
      dataIndex: 'status',
      width: 130,
      render: (_, row) => {
        const { status } = row;
        return <ChatSupportStatusTag appointmentStatus={status} />;
      },
    },
    {
      width: 60,
      render: (_, record) => {
        return <ChatSupportTableDropdownMenu chatSupport={record} />;
      },
    },
  ];

  const inProgressModalHandler = () => {
    setShowInProgressModal(false);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={chatSupports}
        rowKey={(row) => row.id}
        onRow={(record) => {
          return {
            onClick: () => {
              if (record.status === InternalChatSupportStatusEnum.InProgressByAnotherAdmin) {
                setShowInProgressModal(true);
              } else {
                navigate(routePatches.AdminChatSupportDetails.path(record.id));
              }
            },
          };
        }}
        pagination={{
          current: page ?? DEFAULT_PAGE,
          total: total ?? 0,
          onChange: (e) => setPage(e),
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
        }}
      />
      <CustomModal
        title="Данная заявка взята в работу другим администратором"
        visible={showInProgressModal}
        onClose={inProgressModalHandler}
        actionButtons={[
          <CustomButton key={1} onClick={inProgressModalHandler} $buttonType="submit">
            Закрыть
          </CustomButton>,
        ]}
      />
    </>
  );
};
