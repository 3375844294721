import React from 'react';

import { useParams } from 'react-router-dom';

import { AdminHealthAssistanceDetailsContainer } from 'entity/health-assistance/ui/health-assistance-details-container/admin-health-assistance-details-container';
import { useHomeVisitRejectedByPatientDetailsQuery } from 'entity/home-visit/hooks/use-home-visit-rejected-by-patient-details-query';
import { TextAreaRow } from 'shared/ui/row/text-area-row';
import { TextRow } from 'shared/ui/row/text-row';

interface Props {
  editHandler?: () => void;
}

export const AdminHomeVisitRejectedByPatientDetailsForm = ({ editHandler }: Props) => {
  const params = useParams();
  const { homeVisit, isLoading } = useHomeVisitRejectedByPatientDetailsQuery(params.id);
  if (isLoading) return <div>loading...</div>;
  if (!homeVisit) return <div>not found</div>;

  return (
    <AdminHealthAssistanceDetailsContainer>
      <TextRow title="Тип приема" text="Вызов на дом" />
      <TextRow title="Пациент" text={homeVisit.patientName} />
      <TextRow title="Телефон" text={homeVisit.patientPhone} />
      <TextRow title="Адрес вызова" text={homeVisit.patientAddress} />
      <TextRow editHandler={editHandler} title="Специализация" text={homeVisit.specialization} />
      <TextRow editHandler={editHandler} title="Врач" text={homeVisit.doctor} />
      <TextRow editHandler={editHandler} title="Дата и время" text={homeVisit.dateAndTime} />
      <TextAreaRow title="Комментарий" text={homeVisit.comments} />
    </AdminHealthAssistanceDetailsContainer>
  );
};
