import { FC } from 'react';

import { Col, List, Row, Skeleton, Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { useGetAdminDoctorQuery } from 'entity/admin/api';
import {
  AdminDoctorInfoPageContainer,
  AdminDoctorPageInfoDivider,
  AdminDoctorPageRecodingTable,
  AdminDoctorPageRecodingTableTitle,
} from 'pages/admin/doctor/ui/info/styles';
import { AdminDoctorAvatar, AvatarLoader } from 'pages/admin/doctors/ui/styles';
import { EditIcon } from 'shared/ui/icons';
import { LinkButton } from 'shared/ui/link-button';
import { RecordingsTable } from 'widgets/recordings-table';

export const AdminDoctorInfoPage: FC = () => {
  const { doctorId } = useParams();
  const { data, isLoading } = useGetAdminDoctorQuery(Number(doctorId));

  return (
    <>
      {isLoading ? (
        <Skeleton.Button block size="large" shape="round" />
      ) : (
        <AdminDoctorInfoPageContainer>
          <Row gutter={[25, 0]}>
            <Col span={7}>
              <Row justify="start" align="middle">
                <Col span={4}>
                  <AdminDoctorAvatar
                    width={40}
                    height={40}
                    src={data?.image}
                    placeholder={<Spin size="large" style={{ background: '#FFF' }} indicator={<AvatarLoader />} />}
                    fallback="https://samara.medguard.ru/local/templates/medguard/images/default.png"
                  />
                </Col>
                <Col span={20}>
                  <List.Item.Meta title={data?.name} description={data?.specialization} />
                </Col>
              </Row>
            </Col>
            <Col>
              <AdminDoctorPageInfoDivider type="vertical" />
            </Col>

            <Col span={14}>
              {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
              <List.Item.Meta title="Email" description={data?.account?.email || '-'} />
            </Col>
            <Col>
              <AdminDoctorPageInfoDivider type="vertical" />
            </Col>
            <Col span={1}>
              <LinkButton to="edit" state={data}>
                <EditIcon />
              </LinkButton>
            </Col>
          </Row>
        </AdminDoctorInfoPageContainer>
      )}

      <AdminDoctorPageRecodingTable>
        <AdminDoctorPageRecodingTableTitle> Записи</AdminDoctorPageRecodingTableTitle>
        <RecordingsTable invisibleFields={['doctor']} />
      </AdminDoctorPageRecodingTable>
    </>
  );
};
