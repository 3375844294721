import React, { FC, useCallback, useEffect } from 'react';

import type { Moment } from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import { HealthAssistanceDetailsLayout } from 'entity/health-assistance/ui/health-assistance-details-layout';
import { useHomeVisitDetailsQuery } from 'entity/home-visit/hooks/use-home-visit-details-query';
import type { InternalHomeVisitFormDetails } from 'entity/home-visit/types/internal-home-visit-form-details';
import { AdminReturnToHomeVisitListButton } from 'feature/admin-return-to-home-visit-list/ui/admin-return-to-home-visit-list-button';
import { AdminReturnToHomeVisitListButtonWithConfirmation } from 'feature/admin-return-to-home-visit-list/ui/admin-return-to-home-visit-list-button-with-confirmation';
import { HomeVisitApproveByAdminButton } from 'feature/home-visit-approve-by-admin/ui/home-visit-approve-by-admin-button';
import { HomeVisitCancelAppointmentByAdminButton } from 'feature/home-visit-cancel-appointment-by-admin/ui/home-visit-cancel-appointment-by-admin-button';
import { HomeVisitEditByAdminButton } from 'feature/home-visit-edit-by-admin/ui/home-visit-edit-by-admin-button';
import { HomeVisitRejectRequestByAdminButton } from 'feature/home-visit-reject-request-by-admin/ui/home-visit-reject-request-by-admin-button';
import { HomeVisitToTakeOnEditByAdminButton } from 'feature/home-visit-to-take-on-edit-by-admin/ui/home-visit-to-take-on-edit-by-admin-button';
import { HomeVisitToTakeOnProgressByAdminButton } from 'feature/home-visit-to-take-on-progress-by-admin/ui/home-visit-to-take-on-progress-by-admin-button';
import { AdminHomeVisitAppointmentDetailsForm } from 'pages/admin/admin-home-visit-details/ui/admin-home-visit-appointment-details-form';
import { routePatches } from 'shared/routes';
import { errorNotification } from 'shared/utils/notifications';
import { ChoseDoctorDateTimeWidget } from 'widgets/admin-chose-doctor-date-time-widget/ui/chose-doctor-date-time-widget';

export const AdminHomeVisitDetailsPage: FC = () => {
  const params = useParams();
  const { homeVisit, isLoading, isError } = useHomeVisitDetailsQuery(params.id);
  const [homeVisitData, setHomeVisitData] = React.useState<InternalHomeVisitFormDetails>();
  const [showChangeDoctorTime, setShowChangeDoctorTime] = React.useState(false);
  const [isUpdated, setIsUpdated] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      errorNotification('Ошибка при загрузке заявки!');
      navigate(routePatches.AdminHomeVisitList.path());
    }
    setHomeVisitData(homeVisit);
  }, [homeVisit, isError, navigate]);

  const isAcceptedAtLestOnce = homeVisit?.isAccepted;

  const editHandler = useCallback(() => {
    setShowChangeDoctorTime(true);
  }, []);

  const handleSlotPick = useCallback(
    (updated: Partial<InternalHomeVisitFormDetails>) => {
      setShowChangeDoctorTime(false);
      if (!homeVisitData) return;
      setHomeVisitData((prev): typeof prev => {
        if (!prev) return prev;
        return {
          ...prev,
          ...updated,
        };
      });
      setIsUpdated(true);
    },
    [homeVisitData],
  );

  const setDate = useCallback((newDate: Moment) => {
    setHomeVisitData((prev) => {
      if (!prev) return prev;
      const ISO = newDate.toISOString();
      return {
        ...prev,
        date: ISO,
      };
    });
    setIsUpdated(true);
  }, []);

  const onAddressChange = useCallback((address: string) => {
    setHomeVisitData((prev) => {
      if (!prev) return prev;

      return {
        ...prev,
        address,
      };
    });
    setIsUpdated(true);
  }, []);

  const hideChangeDoctorTime = useCallback(() => {
    setShowChangeDoctorTime(false);
  }, [setShowChangeDoctorTime]);

  const onUpdate = useCallback(() => setIsUpdated(false), []);

  if (isLoading) {
    return null;
  }
  if (!homeVisitData) {
    return <div>not found</div>;
  }

  if (showChangeDoctorTime) {
    return (
      <ChoseDoctorDateTimeWidget
        date={homeVisitData.date}
        setDate={setDate}
        handleSlotPick={handleSlotPick}
        hideChangeDoctorTime={hideChangeDoctorTime}
      />
    );
  }

  return (
    <HealthAssistanceDetailsLayout
      ReturnButton={
        isUpdated ? <AdminReturnToHomeVisitListButtonWithConfirmation /> : <AdminReturnToHomeVisitListButton />
      }
      ActionButtons={[
        isAcceptedAtLestOnce ? (
          <HomeVisitCancelAppointmentByAdminButton key={1} />
        ) : (
          <HomeVisitRejectRequestByAdminButton key={1} />
        ),
        isAcceptedAtLestOnce ? (
          <HomeVisitToTakeOnEditByAdminButton key={2} />
        ) : (
          <HomeVisitToTakeOnProgressByAdminButton key={2} />
        ),
        isAcceptedAtLestOnce ? (
          <HomeVisitEditByAdminButton homeVisitData={homeVisitData} isUpdated={isUpdated} onUpdate={onUpdate} key={3} />
        ) : (
          <HomeVisitApproveByAdminButton homeVisitData={homeVisitData} key={3} />
        ),
      ]}
      LeftSide={
        <AdminHomeVisitAppointmentDetailsForm
          onAddressChange={onAddressChange}
          editHandler={editHandler}
          homeVisit={homeVisitData}
        />
      }
    />
  );
};
