import { useActiveSpeakerId, useLocalSessionId, useParticipantIds } from '@daily-co/daily-react';

import { getMainScreenParticipantId } from '../utils';

export const useGetOnlineAppointmentParticipantIds = () => {
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });
  const localParticipantId = useLocalSessionId();
  const activeSpeakerId = useActiveSpeakerId();

  const mainScreenParticipantId = getMainScreenParticipantId(remoteParticipantIds, localParticipantId, activeSpeakerId);

  const participantIds = useParticipantIds();

  const secondaryScreenParticipantIds = participantIds.filter(
    (participantId) => participantId !== mainScreenParticipantId,
  );

  return { mainScreenParticipantId, secondaryScreenParticipantIds };
};
