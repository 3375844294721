import { Avatar, Typography } from 'antd';
import styled from 'styled-components';

export const MemberListContent = styled.div`
  overflow-x: auto;
  display: flex;
`;

export const Member = styled.div`
  display: flex;
  padding: 16px 30px;
  gap: 8px;

  line-height: ${({ theme }) => theme.lineHeights.small};
`;

export const ChatMemberAvatar = styled(Avatar)`
  flex-shrink: 0;
`;

export const ChatMemberContent = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 8px;
`;

export const ChatMemberName = styled(Typography.Text)`
  width: 235px;
  font-size: ${({ theme }) => theme.fontSizes.default};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const ChatMemberLastMessage = styled(Typography.Text)`
  width: 235px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.c5};
`;

export const ChatLastMessageTime = styled.div`
  min-width: 40px;
  margin-top: 3px;
`;

export const ChatMemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  color: ${({ theme }) => theme.colors.c5};
`;

export const ChatUnreadMessage = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.c1};
  color: ${({ theme }) => theme.colors.c4};
  width: 20px;
  height: 20px;
  align-items: center;
  margin-top: 5px;
`;
