import { type FC, useState } from 'react';

import { Typography } from 'antd';

import { useScheduleRemoveSpecialistByDoctorMutation } from 'entity/schedule/hooks/use-remove-specialist-by-doctor-mutation';
import type { InternalScheduleOnlineAppointmentEvent } from 'entity/schedule/types/internal-schedule-online-appointment-event';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';

import { SpecialistActionButton } from './styles';

const { Text } = Typography;

type Props = {
  event: InternalScheduleOnlineAppointmentEvent;
};

export const ScheduleRemoveSpecialistByDoctorButton: FC<Props> = ({ event }) => {
  const [removeConfirmation, setRemoveConfirmation] = useState(false);

  const [removeSpecialist] = useScheduleRemoveSpecialistByDoctorMutation();

  const { specialistId, id } = event;

  const handleRemoveSpecialist = () => {
    if (specialistId)
      removeSpecialist({
        internalOnlineVisitAppointmentId: id,
        internalOnlineVisitDoctorId: specialistId,
      });
  };

  const handleOpenRemoveConfirmationModal = () => setRemoveConfirmation(true);
  const handleRemoveConfirmationModal = () => setRemoveConfirmation(false);

  return (
    <>
      <SpecialistActionButton onClick={handleOpenRemoveConfirmationModal}>
        <Text>Удалить специалиста</Text>
      </SpecialistActionButton>
      <CustomModal
        title="Вы уверены, что хотите удалить специалиста?"
        visible={removeConfirmation}
        onClose={handleRemoveConfirmationModal}
        actionButtons={[
          <CustomButton key={1} onClick={handleRemoveSpecialist} $buttonType="declineWhite">
            Удалить
          </CustomButton>,
          <CustomButton key={2} onClick={handleRemoveConfirmationModal} $buttonType="submit">
            Отменить
          </CustomButton>,
        ]}
      />
    </>
  );
};
