import { type FC } from 'react';

import { type ButtonProps } from 'antd';

import { useHealthAssistanceRejectModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-reject-modal';
import { useOnlineAppointmentRejectAppointmentByAdminMutation } from 'entity/online-appointment/hooks/use-online-appointment-reject-by-admin-mutation';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

interface Props {
  onlineAppointmentId: number;
}

export const OnlineAppointmentRejectAppointmentByAdminDropdownMenuButton: FC<Props & ButtonProps> = ({
  onlineAppointmentId,
  ...props
}) => {
  const [rejectAppointment] = useOnlineAppointmentRejectAppointmentByAdminMutation();

  const handleOnclick = () =>
    rejectAppointment({
      id: onlineAppointmentId,
    });

  const { showRejectModal, RejectModal } = useHealthAssistanceRejectModal();

  return (
    <>
      <DropdownMenuButton onClick={showRejectModal(handleOnclick)} {...props}>
        <TypographyText>Отклонить</TypographyText>
      </DropdownMenuButton>
      <RejectModal />
    </>
  );
};
