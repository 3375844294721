import { type FC } from 'react';

import { type TableColumnsType } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { usePastHomeVisitsListQuery } from 'entity/home-visit/hooks/use-past-home-visits-list-query';
import { InternalPastHomeVisit } from 'entity/home-visit/types/internal-past-home-visits-list';
import { useUrlPagination } from 'shared/hooks/use-url-pagination';
import { routePatches } from 'shared/routes';
import { Table } from 'shared/ui/table';

const DEFAULT_PAGE_SIZE = 7;
const DEFAULT_PAGE = 1;

export const AdminPastHomeVisitsTable: FC = () => {
  const navigate = useNavigate();

  const { setPage, page } = useUrlPagination();

  const columns: TableColumnsType<InternalPastHomeVisit> = [
    {
      title: 'Пациент',
      dataIndex: 'patient',
      ellipsis: true,
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      ellipsis: true,
    },
    {
      title: 'Специализация',
      dataIndex: 'specialization',
      ellipsis: true,
    },
    {
      title: 'Дата Приема',
      dataIndex: 'date',
      ellipsis: true,
    },
    {
      title: 'Время Приема',
      dataIndex: 'time',
      ellipsis: true,
    },
    {
      title: 'Врач',
      dataIndex: 'doctor',
      ellipsis: true,
      render: (_, row) => (
        <Link onClick={(e) => e.stopPropagation()} to={routePatches.AdminDoctorsPage.path(row.doctorId)}>
          {row.doctor}
        </Link>
      ),
    },
    {
      title: 'Доп. услуги',
      dataIndex: 'extraFacilities',
      ellipsis: true,
    },
    {
      title: 'Оценка',
      dataIndex: 'rating',
      ellipsis: true,
    },
  ];

  const { pastHomeVisits, total } = usePastHomeVisitsListQuery({
    page,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const handleClickRow = (id: number) => navigate(routePatches.AdminPastHomeVisitDetails.path(id));

  return (
    <Table
      columns={columns}
      dataSource={pastHomeVisits}
      rowKey={(row) => row.id}
      onRow={(record) => {
        return {
          onClick: () => handleClickRow(record.id),
        };
      }}
      pagination={{
        current: page ?? DEFAULT_PAGE,
        total,
        onChange: (e) => setPage(e),
        pageSize: DEFAULT_PAGE_SIZE,
        showSizeChanger: false,
      }}
    />
  );
};
