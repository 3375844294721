import React, { type FC, JSX } from 'react';

import type { InternalHomeVisitRejectedByPatientList } from 'entity/home-visit/types/internal-home-visit-rejected-by-patient-list';
import { InternalHomeVisitStatusRejectedByPatientEnum } from 'entity/home-visit/types/internal-home-visit-status-rejected-by-patient-enum';
import { HomeVisitRejectedByPatientToTakeOnByAdminDropdownMenuButton } from 'feature/home-visit-rejected-by-client-to-take-on-by-admin/ui/home-visit-rejected-by-patient-to-take-on-by-admin-dropdown-menu-button';
import { HomeVisitRejectedByPatientApproveByAdminDropdownMenuButton } from 'feature/home-visit-rejected-by-patient-approve-by-admin/ui/home-visit-rejected-by-patient-approve-by-admin-dropdown-menu-button';
import { DropDownMenu } from 'shared/ui/dropdown-menu';
import { VerticalDotsIcon } from 'shared/ui/icons';

interface Props {
  homeVisit: InternalHomeVisitRejectedByPatientList;
}

export const HomeVisitRejectedByPatientTableDropdownMenu: FC<Props> = ({ homeVisit }) => {
  const getNodeItems = (): JSX.Element[] => {
    switch (homeVisit.status) {
      case InternalHomeVisitStatusRejectedByPatientEnum.New:
        return [
          <HomeVisitRejectedByPatientToTakeOnByAdminDropdownMenuButton homeVisitId={homeVisit.id} key={1} />,
          <HomeVisitRejectedByPatientApproveByAdminDropdownMenuButton homeVisitId={homeVisit.id} key={2} />,
        ];
      case InternalHomeVisitStatusRejectedByPatientEnum.InProgressByCurrentAdmin:
        return [<HomeVisitRejectedByPatientApproveByAdminDropdownMenuButton homeVisitId={homeVisit.id} key={1} />];
      case InternalHomeVisitStatusRejectedByPatientEnum.InProgressByAnotherAdmin:
        return [];
      default:
        return [];
    }
  };

  const nodeItems = getNodeItems();
  return nodeItems.length > 0 ? (
    <DropDownMenu placement="bottomRight" nodeItems={nodeItems}>
      <VerticalDotsIcon />
    </DropDownMenu>
  ) : null;
};
