import { useCallback } from 'react';

import { useAdminVisitsControllerSetChatAssistingInProgressMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  id?: string;
  onSuccess?: () => void;
}

export const useChatSupportTakeToOneProgressByAdminMutation = ({ id, onSuccess }: MutationHookOptions) => {
  const [setInProgress] = useAdminVisitsControllerSetChatAssistingInProgressMutation();
  const action = useCallback(() => {
    if (!id) {
      errorNotification('Невозможно взять в работу чат-сопровождение');
      return;
    }
    setInProgress({ id })
      .unwrap()
      .then(() => {
        successNotification('Запись взята в работу чат-сопровождение');
        onSuccess?.();
      })
      .catch(() => {
        errorNotification('Ошибка при взятии в работу чат-сопровождение');
      });
  }, [id, setInProgress, onSuccess]);
  return [action];
};
