import { OnlineVisitForAdminStatusEnum } from 'shared/api/rtk-api';

import { InternalOnlineAppointmentStatusEnum } from '../types/internal-online-appointment-status-enum';

export const externalOnlineAppointmentStatusToInternalOnlineAppointmentStatusMapper = new Map<
  OnlineVisitForAdminStatusEnum,
  InternalOnlineAppointmentStatusEnum
>([
  [OnlineVisitForAdminStatusEnum.AcceptedByAdmin, InternalOnlineAppointmentStatusEnum.AcceptedByAdmin],
  [OnlineVisitForAdminStatusEnum.AssignedByAnotherAdmin, InternalOnlineAppointmentStatusEnum.AssignedByAnotherAdmin],
  [OnlineVisitForAdminStatusEnum.AssignedByCurrentAdmin, InternalOnlineAppointmentStatusEnum.AssignedByCurrentAdmin],
  [OnlineVisitForAdminStatusEnum.Cancelled, InternalOnlineAppointmentStatusEnum.Cancelled],
  [OnlineVisitForAdminStatusEnum.DoctorAbsent, InternalOnlineAppointmentStatusEnum.DoctorAbsent],
  [OnlineVisitForAdminStatusEnum.FinishedForAdmin, InternalOnlineAppointmentStatusEnum.FinishedForAdmin],
  [OnlineVisitForAdminStatusEnum.NewForAdmin, InternalOnlineAppointmentStatusEnum.NewForAdmin],
  [OnlineVisitForAdminStatusEnum.PendingCancellation, InternalOnlineAppointmentStatusEnum.PendingCancellation],
  [
    OnlineVisitForAdminStatusEnum.ProcessingByAnotherAdmin,
    InternalOnlineAppointmentStatusEnum.ProcessingByAnotherAdmin,
  ],
  [
    OnlineVisitForAdminStatusEnum.ProcessingByCurrentAdmin,
    InternalOnlineAppointmentStatusEnum.ProcessingByCurrentAdmin,
  ],
  [OnlineVisitForAdminStatusEnum.Rejected, InternalOnlineAppointmentStatusEnum.Rejected],
]);

export const getInternalOnlineAppointmentStatus = (
  status: OnlineVisitForAdminStatusEnum,
): InternalOnlineAppointmentStatusEnum => {
  const internalStatus = externalOnlineAppointmentStatusToInternalOnlineAppointmentStatusMapper.get(status);
  if (!internalStatus) throw new Error(`Unknown status: ${status}`);
  return internalStatus;
};
