import { createSlice } from '@reduxjs/toolkit';

import { ChatInfo } from '../../doctor-chat/hooks/use-doctor-list-query';

type ChatState = {
  chats: ChatInfo[] | null;
  lastMessageData: {
    eTag: string;
    lastMessage: string;
    lastMessageTime: string;
  };
};

const ChatInitialState: ChatState = {
  chats: null,
  lastMessageData: {
    eTag: '',
    lastMessage: '',
    lastMessageTime: '',
  },
};

const pubnubSlice = createSlice({
  name: 'pubnub',
  initialState: ChatInitialState,
  reducers: {
    setChats: (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,no-param-reassign
      state.chats = action.payload;
    },
    setLastMessage: (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,no-param-reassign
      state.lastMessageData = action.payload;
    },
  },
});

export const { setChats, setLastMessage } = pubnubSlice.actions;

export const chatReducer = pubnubSlice.reducer;
