import { type FC } from 'react';

import { ButtonProps } from 'antd';

import { useOnlineAppointmentAssignCancellationByAdminMutation } from 'entity/online-appointment/hooks/use-online-appointment-assing-cancellation-by-admin';
import theme from 'shared/theme';
import { Button } from 'shared/ui/button';
import { DocumentTakeTaskIcon } from 'shared/ui/icons';
import { TypographyText } from 'shared/ui/typography';

type Props = {
  onlineAppointmentId: number;
};

export const OnlineAppointmentRejectedByPatientToTakeOnByAdminButton: FC<ButtonProps & Props> = ({
  disabled,
  onlineAppointmentId,
  ...props
}) => {
  const [assignCancellation] = useOnlineAppointmentAssignCancellationByAdminMutation();

  const handleClick = () => assignCancellation({ id: onlineAppointmentId });

  return (
    <Button
      icon={<DocumentTakeTaskIcon color={disabled ? undefined : theme.colors.palette.columnOfOakGreen} />}
      $buttonType={disabled ? 'submitWhiteDisabled' : 'submitWhite'}
      onClick={handleClick}
      disabled={disabled}
      type="primary"
      size="large"
      {...props}
    >
      <TypographyText>Взять в работу</TypographyText>
    </Button>
  );
};
