import React, { type FC } from 'react';

import { ButtonProps } from 'antd';
import { useParams } from 'react-router-dom';

import { useHomeVisitRejectedByPatientDetailsQuery } from 'entity/home-visit/hooks/use-home-visit-rejected-by-patient-details-query';
import { useHomeVisitTakeToOneProgressByAdminMutation } from 'entity/home-visit/hooks/use-home-visit-take-to-one-progress-by-admin-mutation';
import theme from 'shared/theme';
import { Button } from 'shared/ui/button';
import { DocumentTakeTaskIcon } from 'shared/ui/icons';
import { TypographyText } from 'shared/ui/typography';

export const HomeVisitRejectedByPatientToTakeOnByAdminButton: FC<ButtonProps> = ({ ...props }) => {
  const params = useParams();
  const { homeVisit, isLoading } = useHomeVisitRejectedByPatientDetailsQuery(params.id);
  const disabled = !homeVisit?.canBeTakenIntoProcessing;
  const [onClickHandler] = useHomeVisitTakeToOneProgressByAdminMutation({ id: homeVisit?.id });

  return (
    <Button
      loading={isLoading}
      icon={<DocumentTakeTaskIcon color={disabled ? undefined : theme.colors.palette.columnOfOakGreen} />}
      $buttonType={disabled ? 'submitWhiteDisabled' : 'submitWhite'}
      onClick={onClickHandler}
      disabled={disabled}
      type="primary"
      size="large"
      {...props}
    >
      <TypographyText>Взять в работу</TypographyText>
    </Button>
  );
};
