import { List } from 'antd';
import styled from 'styled-components';

import { Table } from 'shared/ui/table';

export const StyledTable = styled(Table)`
  .ant-table-tbody > tr > td {
    padding: 6px 16px;
  }
  .ant-list-item-meta-title {
    margin-bottom: 0;
    line-height: 16px;
  }
  .ant-list-item-meta-description {
    line-height: 16px;
  }
  .ant-list-item-meta-content {
    min-width: 180px;
  }
` as typeof Table;

export const StyledList = styled(List.Item.Meta)`
  align-items: center;
`;

export const StyledTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledTagContainer = styled.span`
  padding: 4px 0;
`;
