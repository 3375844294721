import styled from 'styled-components';

export const ModalBodyContainer = styled.div`
  border-radius: 24px;
  min-height: 212px;
  width: fit-content;
  background-color: #fff;
  pointer-events: auto;
  padding: 40px;
`;
