import { Input } from 'antd';
import styled from 'styled-components';

export const PatientsPageContainer = styled.div`
  padding: 30px;
`;

export const PatientsSearchInput = styled(Input)`
  padding: 8px 16px;
  border-radius: 24px;
  width: 320px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.c1};
    box-shadow: 0 0 0 2px rgb(81 161 98 / 20%);
  }

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.c2};
    box-shadow: 0 0 0 2px rgb(81 161 98 / 20%);
  }
`;

export const PatientsTableContainer = styled.div`
  background-color: #fff;
  border-radius: 16px;
  min-height: 60vh;
  .ant-table-content {
    min-height: 440px;
    border-bottom: 1px solid #f0f0f0;
  }
  .patient-table-row {
    cursor: pointer;
  }
`;

export const PatientsTableHeadText = styled.div`
  font-weight: 700;
`;

export const PatientsPageTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  margin-top: 32px;
  margin-bottom: 24px;
`;
