import { useMemo } from 'react';

import { useDoctorPanelControllerChatMembersQuery } from 'shared/api/rtk-api';

export interface ChatInfo {
  userId: string;
  id: string; // patientId
  eTag: string; // chatId
  updated: string; // last message time
  name: string; // patient name
  lastChatReadByDoctorTimestamp: number | null;
  create_at: number;
}

export const useDoctorChatsQuery = () => {
  const { data, ...others } = useDoctorPanelControllerChatMembersQuery();
  const doctorChats = useMemo(() => {
    return (data ?? []).map(
      (chat): ChatInfo => ({
        name: chat.chatName,
        updated: chat.updated,
        eTag: chat.chatId,
        id: chat.patientId,
        userId: chat.chatId,
        create_at: chat.create_at,
        lastChatReadByDoctorTimestamp: Number(chat.lastChatReadByDoctorTimestamp),
      }),
    );
  }, [data]);

  return {
    doctorChats,
    ...others,
  };
};
