/* eslint no-restricted-imports: 0 */

import { FC, useEffect, useMemo, useState } from 'react';

import { Button, Col, Row } from 'antd';
import { Moment } from 'moment';

import {
  addDaysToDate,
  formatDateDM,
  getEndOfTheCurrentWeek,
  getStartOfTheCurrentWeek,
  subtractDaysToDate,
} from 'shared/lib/format';
import { LeftIcon, RightIcon, ScheduleIcon } from 'shared/ui/icons';
import { WeekPickerContainer, WeekPickerValue } from 'shared/ui/week-picker/styles';

export type Week = {
  firstDay: Moment;
  lastDay: Moment;
};

type FormatWeek = {
  firstDay: string;
  lastDay: string;
};

type Props = {
  onChange: (values: Week) => void;
};

export const WeekPicker: FC<Props> = ({ onChange }) => {
  const [week, setWeek] = useState<Week>({
    firstDay: getStartOfTheCurrentWeek(),
    lastDay: getEndOfTheCurrentWeek(),
  });

  const formatDates = useMemo<FormatWeek>(
    () => ({
      firstDay: formatDateDM(week.firstDay),
      lastDay: formatDateDM(week.lastDay),
    }),
    [week],
  );

  useEffect(() => {
    onChange(week);
  }, [week, onChange]);

  const addHandler = () => {
    setWeek(({ firstDay, lastDay }) => ({
      firstDay: addDaysToDate(firstDay, 7),
      lastDay: addDaysToDate(lastDay, 7),
    }));
  };

  const subtractHandler = () => {
    setWeek(({ firstDay, lastDay }) => ({
      firstDay: subtractDaysToDate(firstDay, 7),
      lastDay: subtractDaysToDate(lastDay, 7),
    }));
  };

  return (
    <WeekPickerContainer>
      <Row gutter={[8, 0]} justify="center" align="middle">
        <Col>
          <Button disabled type="text" icon={<ScheduleIcon />} />
        </Col>
        <Col>
          <WeekPickerValue>
            {formatDates.firstDay} – {formatDates.lastDay}
          </WeekPickerValue>
        </Col>
        <Col>
          <Button type="text" icon={<LeftIcon />} onClick={subtractHandler} />
          <Button type="text" icon={<RightIcon />} onClick={addHandler} />
        </Col>
      </Row>
    </WeekPickerContainer>
  );
};
