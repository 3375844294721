import { externalHomeVisitListToInternalHomeVisitListMapper } from 'entity/home-visit/mappers/external-home-visit-list-to-internal-home-visit-list-mapper';
import { useAdminVisitsControllerGetHomeVisitsQuery } from 'shared/api/rtk-api';

interface HomeVisitDetailQuery {
  page: number;
  pageSize: number;
  statuses?: ('New' | 'InProgressByCurrentAdmin' | 'AcceptByDoctor' | 'AcceptByAdmin' | 'InProgressByAnotherAdmin')[];
  date?: string;
  name?: string;
}

export const useHomeVisitListQuery = ({ page, pageSize, statuses, date, name }: HomeVisitDetailQuery) => {
  const { data, ...others } = useAdminVisitsControllerGetHomeVisitsQuery({
    name,
    date,
    statuses,
    limit: pageSize,
    offset: (page - 1) * pageSize || undefined,
  });

  const homeVisits = data?.data.map(externalHomeVisitListToInternalHomeVisitListMapper);
  return {
    homeVisits,
    total: data?.total,
    ...others,
  };
};
