import { type FC } from 'react';

import type { ButtonProps } from 'antd/lib/button/button';

import { ArrowLeftIcon } from 'shared/ui/icons';

import { StyledButton, StyledTypographyText } from './styles';

interface Props extends ButtonProps {
  children?: string;
}

export const ReturnButton: FC<Props> = ({ children, ...props }) => {
  return (
    <StyledButton icon={<ArrowLeftIcon />} type="link" {...props}>
      <StyledTypographyText>{children}</StyledTypographyText>
    </StyledButton>
  );
};
