import { FC } from 'react';

import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import {
  NotificationBellActiveIcon,
  NotificationBellDisabledIcon,
  NotificationBellInactiveIcon,
} from 'shared/ui/icons';

type Props = Partial<CustomIconComponentProps>;

interface NotificationBellProps extends Props {
  status: 'active' | 'inactive' | 'disabled';
}

const statusToComponent = {
  active: NotificationBellActiveIcon,
  inactive: NotificationBellInactiveIcon,
  disabled: NotificationBellDisabledIcon,
};

export const NotificationBell: FC<NotificationBellProps> = (props) => {
  const { status, ...otherProps } = props;
  const Component = statusToComponent[status] || NotificationBellInactiveIcon;
  return <Component {...otherProps} />;
};
