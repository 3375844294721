import { useCalendarControllerGetEventListQuery } from 'shared/api/rtk-api';

import { externalClinicAppointmentEventToInternalClinicAppointmentEventMapper } from '../mappers/external-schedule-clinic-appointment-event-to-internal-schedule-clinic-appointment-event-mapper';
import { externalHomeVisitEventToInternalHomeVisitEventMapper } from '../mappers/external-schedule-home-visit-event-to-internal-schedule-home-visit-event-mapper';
import { externalOnlineAppointmentEventToInternalOnlineAppointmentEventMapper } from '../mappers/external-schedule-online-appointment-event-to-internal-schedule-online-appointment-event-mapper';
import { externalSlotEventToInternalSlotEventMapper } from '../mappers/external-schedule-slot-event-to-internal-slot-event-mapper';

type Props = { fromDateTimeIso: string; toDateTimeIso: string };

export const useScheduleEventsQuery = ({ fromDateTimeIso, toDateTimeIso }: Props) => {
  const { data, ...others } = useCalendarControllerGetEventListQuery({
    fromDateTimeIso,
    toDateTimeIso,
  });

  const mappedClinicAppointmentEvents =
    data?.clinicDoctorAppointments.map(externalClinicAppointmentEventToInternalClinicAppointmentEventMapper) ?? [];

  const mappedHomeVisitEvens = data?.homeVisits.map(externalHomeVisitEventToInternalHomeVisitEventMapper) ?? [];

  const mappedSlotEvents = data?.onlineVisitSlots.map(externalSlotEventToInternalSlotEventMapper) ?? [];

  const mappedOnlineAppointmentEvents =
    data?.onlineVisits.map(externalOnlineAppointmentEventToInternalOnlineAppointmentEventMapper) ?? [];

  const scheduleEvents = [
    ...mappedClinicAppointmentEvents,
    ...mappedHomeVisitEvens,
    ...mappedSlotEvents,
    ...mappedOnlineAppointmentEvents,
  ];

  return {
    scheduleEvents,
    ...others,
  };
};
