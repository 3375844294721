export enum InternalPushNotificationStatusEnum {
  WaitingSending = 'waiting_sending',
  Sended = 'sended',
}

export type InternalPushNotification = {
  id: number;
  title: string;
  status: InternalPushNotificationStatusEnum;
  date: string;
  time: string;
  amount: number;
};
