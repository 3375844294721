import { Typography } from 'antd';
import styled from 'styled-components';

import theme from 'shared/theme';
import { PencilIcon } from 'shared/ui/icons';

export const StyledText = styled(Typography.Text)`
  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  color: ${theme.colors.palette.columnOfOakGreen};
`;

export const StyledIcon = styled(PencilIcon)`
  margin-left: 16px;
  cursor: pointer;
`;
