import React, { FC, useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useChatSupportApproveRequestByAdminMutation } from 'entity/chat-support/hooks/use-chat-support-approve-request-by-admin-mutation';
import { useChatSupportDetailsQuery } from 'entity/chat-support/hooks/use-chat-support-details-query';
import { useHealthAssistanceApproveModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-approve-modal';
import { useHealthAssistanceSuccessModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-success-modal';
import { routePatches } from 'shared/routes';
import { Button } from 'shared/ui/button';
import { CheckMarkIcon } from 'shared/ui/icons';

interface Props {
  duration: number;
  specialization: string;
}

export const ChatSupportApproveRequestByAdminButton: FC<Props> = ({ specialization, duration }) => {
  const params = useParams();
  const navigate = useNavigate();

  const { chatSupport, isLoading } = useChatSupportDetailsQuery(params.id);
  const [approveChatSupportRequest] = useChatSupportApproveRequestByAdminMutation();

  const succeededActionModelHandler = useCallback(() => {
    navigate(routePatches.AdminHomeVisitList.path());
  }, [navigate]);
  const { showSuccessModal, SuccessModal } = useHealthAssistanceSuccessModal();

  const applyButtonHandler = useCallback(() => {
    approveChatSupportRequest({
      chatId: chatSupport?.id,
      duration,
      specialization,
      onSuccess: showSuccessModal(succeededActionModelHandler),
    });
  }, [
    approveChatSupportRequest,
    chatSupport?.id,
    showSuccessModal,
    succeededActionModelHandler,
    duration,
    specialization,
  ]);

  const { showApproveModal, ApproveModal } = useHealthAssistanceApproveModal();

  const disabled = !chatSupport?.canBeAccepted || !specialization || !duration;

  return (
    <>
      <Button
        loading={isLoading}
        icon={<CheckMarkIcon />}
        onClick={showApproveModal(applyButtonHandler)}
        $buttonType="submit"
        disabled={disabled}
        type="primary"
        size="large"
      >
        Подтвердить
      </Button>
      <ApproveModal />
      <SuccessModal />
    </>
  );
};
