import { type FC } from 'react';

import { type ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useOnlineAppointmentAssignAppointmentByAdminMutation } from 'entity/online-appointment/hooks/use-online-appointment-assing-by-admin';
import { routePatches } from 'shared/routes';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

interface Props {
  onlineAppointmentId: number;
}

export const OnlineAppointmentToTakeInProgressByAdminDropdownMenuButton: FC<Props & ButtonProps> = ({
  onlineAppointmentId,
  ...props
}) => {
  const navigate = useNavigate();

  const successHandler = () => navigate(routePatches.AdminOnlineAppointmentDetails.path(onlineAppointmentId));

  const [assignAppointment] = useOnlineAppointmentAssignAppointmentByAdminMutation();

  const handleClick = () => assignAppointment({ id: onlineAppointmentId, onSuccess: successHandler });

  return (
    <DropdownMenuButton onClick={handleClick} {...props}>
      <TypographyText>Взять в работу</TypographyText>
    </DropdownMenuButton>
  );
};
