import React, { type FC, useCallback, useState } from 'react';

import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';

export const useHealthAssistanceRejectModal = () => {
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [action, setAction] = useState<() => void>(() => async () => {});
  const rejectActionHandler = () => setShowApproveModal(false);
  const showRejectModal = useCallback((newAction: () => void) => {
    return () => {
      setAction(() => newAction);
      setShowApproveModal(true);
    };
  }, []);

  const approveActionHandler = useCallback(() => {
    setShowApproveModal(false);
    action();
  }, [action]);

  const RejectModal: FC = () => (
    <CustomModal
      title="Вы уверены, что хотите отменить запись?"
      visible={showApproveModal}
      onClose={rejectActionHandler}
      actionButtons={[
        <CustomButton key={1} onClick={rejectActionHandler} $buttonType="declineWhite">
          Нет
        </CustomButton>,
        <CustomButton key={2} onClick={approveActionHandler} $buttonType="submit">
          Да
        </CustomButton>,
      ]}
    />
  );
  return { showRejectModal, RejectModal };
};
