import { FC, PropsWithChildren } from 'react';

import { CustomButtonProps, StyledCustomButton } from 'shared/ui/custom-button/styles';

export const CustomButton: FC<PropsWithChildren<CustomButtonProps>> = ({ $buttonType, children, ...props }) => {
  return (
    <StyledCustomButton
      $buttonType={props.disabled ? 'disabled' : $buttonType}
      type="text"
      danger
      shape="round"
      size="large"
      {...props}
    >
      {children}
    </StyledCustomButton>
  );
};
