import { FC } from 'react';

import type { InputProps } from 'antd';

import { StyledInput } from './styles';

interface Props extends InputProps {}

export const Input: FC<Props> = (props) => {
  return <StyledInput {...props} />;
};
