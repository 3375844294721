import { type FC } from 'react';

import { DailyAudio, DailyVideo } from '@daily-co/daily-react';
import { useParticipantAudioVideoTrack } from 'entity/daily/hooks/use-participant-audio-video-track';
import { MutedOnlineAppointmentMicrophoneIcon } from 'shared/ui/icons';

import { OnlineAppointmentBlackVideoScreen } from '../black-screen';
import { MainParticipantTitleWrapper, MicrophoneIconWrapper, StyledSpan } from './styles';

type Props = {
  participantId: string;
};

export const OnlineAppointmentMainScreenContent: FC<Props> = ({ participantId }) => {
  const { mutedVideo, mutedAudio } = useParticipantAudioVideoTrack({
    participantId,
  });

  return (
    <>
      {mutedVideo && <OnlineAppointmentBlackVideoScreen />}

      {!mutedVideo && <DailyVideo sessionId={participantId} type="video" fit="cover" mirror />}

      {mutedAudio && (
        <MicrophoneIconWrapper>
          <MutedOnlineAppointmentMicrophoneIcon />
        </MicrophoneIconWrapper>
      )}

      <DailyAudio />

      <MainParticipantTitleWrapper>
        <StyledSpan>Белова Анна Анатольевна</StyledSpan>
      </MainParticipantTitleWrapper>
    </>
  );
};
