import { OnlineVisitAppointmentResponseDto } from 'shared/api/rtk-api';

import { ScheduleEventType } from '../constants';
import { InternalScheduleOnlineAppointmentEvent } from '../types/internal-schedule-online-appointment-event';

export const externalOnlineAppointmentEventToInternalOnlineAppointmentEventMapper = (
  event: OnlineVisitAppointmentResponseDto,
): InternalScheduleOnlineAppointmentEvent => {
  return {
    id: event.id,
    start: event.startIsoDateTime,
    end: event.endIsoDateTime,
    patientId: event.patientId,
    patientName: event.patientName,
    mainDoctorId: event.mainDoctorId,
    mainDoctorName: event.mainDoctorName,
    specialistId: event.specialistId,
    specialistName: event.specialistName,
    documents: event.documents,
    canAddProtocol: event.canAddProtocol,
    canUseChat: event.canUseChat,
    canAddSpecialist: event.canAddSpecialist,
    type: ScheduleEventType.ONLINE,
  };
};
