import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  padding: 0;
  border: none;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
`;
