import { IFiles, IPatientInfo } from 'entity/doctor/api/types';
import { api } from 'shared/api';

const doctorApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPastPatients: build.query<IPatientInfo[], void | string>({
      query: (patientName?: string) => ({
        url: 'doctor-panel/patients',
        method: 'get',
        params: { patientName },
        enabled: !!patientName,
      }),
    }),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getPatient: build.query<IPatientInfo, any>({
      query: (patientId?: number) => ({
        url: 'doctor-panel/current-patient',
        method: 'get',
        params: { patientId },
        enabled: !!patientId,
      }),
    }),

    getPatientAnalysisResult: build.query({
      query: (userId: number, limit?: number, offset?: number) => ({
        url: 'doctor-panel/analysis-result',
        method: 'get',
        params: {
          userId,
          limit,
          offset,
        },
        enabled: !!userId && !!limit && !!offset,
      }),
    }),

    getPatientChatFiles: build.query<IFiles, { userId: number; ids: number[]; limit?: number; offset?: number }>({
      query: ({ userId, ids }) => ({
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        url: `doctor-panel/uploaded-patient-files?userId=${userId}&ids=${ids}`,
        method: 'get',
        enabled: !!userId && ids?.length,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    doDownloadPatientFile: build.query<any, { userId: number; id: number }>({
      query: ({ userId, id }) => ({
        url: 'doctor-panel/download-patient-file',
        method: 'get',
        params: {
          userId,
          id,
        },
        enabled: !!userId && !!id,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uploadDocumentToPatient: build.mutation<void, any>({
      query: (body) => {
        return {
          url: 'doctor-panel/upload',
          method: 'post',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          body,
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetPastPatientsQuery,
  useGetPatientQuery,
  useGetPatientAnalysisResultQuery,
  useGetPatientChatFilesQuery,
  useDoDownloadPatientFileQuery,
  useUploadDocumentToPatientMutation,
} = doctorApi;
