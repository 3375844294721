import { ReactNode } from 'react';

import { Row } from 'antd';

export const getSelectTimeValues = () => {
  const times: { value: string; label: ReactNode }[] = [];
  for (let i = 0; i < 24; i += 1) {
    let hour = `${i}`;
    if (i < 10) {
      hour = `0${i}`;
    }
    times.push(
      {
        value: `${hour}.00`,
        label: (
          <Row justify="space-around">
            <span>{hour}</span>
            <span>00</span>
          </Row>
        ),
      },
      {
        value: `${hour}.30`,
        label: (
          <Row justify="space-around">
            <span>{hour}</span>
            <span>30</span>
          </Row>
        ),
      },
    );
  }
  return times;
};
