import { useMemo } from 'react';

import { OptionKeyEnum, useOptionsControllerGetOptionsQuery } from 'shared/api/rtk-api';

enum ConfigKeyEnum {
  INTERNAL_PUBNUB_SUBSCRIBE_KEY = 'INTERNAL_PUBNUB_SUBSCRIBE_KEY',
  INTERNAL_PUBNUB_PUBLISH_KEY = 'INTERNAL_PUBNUB_PUBLISH_KEY',
  INTERNAL_FEATURE_CALENDAR = 'INTERNAL_FEATURE_CALENDAR',
  INTERNAL_FEATURE_ONLINE_VISIT = 'INTERNAL_FEATURE_ONLINE_VISIT',
}

export const useConfig = () => {
  const { data, ...others } = useOptionsControllerGetOptionsQuery([
    OptionKeyEnum.PubnubSubscribeKey,
    OptionKeyEnum.PubnubPublishKey,
    OptionKeyEnum.FeatureCalendar,
    OptionKeyEnum.FeatureOnlineVisit,
  ]);

  const config = useMemo((): Record<ConfigKeyEnum, string> | null => {
    if (!data) {
      return null;
    }

    const configMap = new Map<string, string>(data.data.map((option) => [option.key, option.value]));

    const getOrThrow = (key: OptionKeyEnum) => {
      const value = configMap.get(key);

      if (value == null) {
        throw new Error(`Missing required config value ${key}`);
      }
      return value;
    };

    return {
      [ConfigKeyEnum.INTERNAL_PUBNUB_SUBSCRIBE_KEY]: getOrThrow(OptionKeyEnum.PubnubSubscribeKey),
      [ConfigKeyEnum.INTERNAL_PUBNUB_PUBLISH_KEY]: getOrThrow(OptionKeyEnum.PubnubPublishKey),
      [ConfigKeyEnum.INTERNAL_FEATURE_CALENDAR]: getOrThrow(OptionKeyEnum.FeatureCalendar),
      [ConfigKeyEnum.INTERNAL_FEATURE_ONLINE_VISIT]: getOrThrow(OptionKeyEnum.FeatureOnlineVisit),
    };
  }, [data]);

  return {
    config,
    ...others,
  };
};
