import { FC } from 'react';

import { Col, Row, Space } from 'antd';

import { Title } from 'shared/ui/title';

interface Props {
  Menu?: FC;
  SearchInput?: FC;
  DataPicker?: FC;
  AddButton?: FC;
  Table: FC;
  title: string;
}

export const ListLayout: FC<Props> = ({ Menu, Table, SearchInput, DataPicker, AddButton, title }) => {
  return (
    <Row>
      <Space size="large">
        {SearchInput && <SearchInput />}
        {DataPicker && <DataPicker />}
      </Space>

      <Space size="middle" direction="vertical">
        <Row justify="space-between" align="bottom">
          <Col>
            <Space size="middle" direction="vertical">
              <Title>{title}</Title>
              {Menu && <Menu />}
            </Space>
          </Col>
          <Col>{AddButton && <AddButton />}</Col>
        </Row>
        <Table />
      </Space>
    </Row>
  );
};
