import { type ChangeEvent, type FC, useState } from 'react';

import { useParams } from 'react-router-dom';

import { DoctorChatSearch } from 'entity/doctor-chat/ui/doctor-chat-search';
import { useMessageCounts } from 'entity/pubnub/hook/use-message-count';
import { ChatPageLayout, Content, LeftAside } from 'pages/doctor/chats/ui/styles';
import { ChatDetails, ChatDetailsNoActiveChats } from 'widgets/chat-details';
import { Chats } from 'widgets/chats';

export const ChatsPage: FC = () => {
  const { chatId } = useParams();

  const [searchValue, setSearchValue] = useState<string>('');
  const { doctorChats } = useMessageCounts();

  const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const character = event.target.value.toLowerCase();
    setSearchValue(character);
  };

  return (
    <ChatPageLayout>
      <LeftAside>
        <DoctorChatSearch disabled={doctorChats.length === 0} searchValue={searchValue} searchHandler={searchHandler} />
        <Chats searchValue={searchValue} selectedChat={chatId} />
      </LeftAside>

      <Content>
        {doctorChats.length === 0 && <ChatDetailsNoActiveChats />}
        {chatId && <ChatDetails selectedChat={chatId} />}
      </Content>
    </ChatPageLayout>
  );
};
