import { Input } from 'antd';
import styled from 'styled-components';

export const AppInput = styled(Input)`
  border-radius: 24px;
  width: 320px;
  height: 40px;
`;

export const AppInputPassword = styled(Input.Password)`
  border-radius: 24px;
  width: 320px;
  height: 40px;
`;
