import { Tag, Typography } from 'antd';
import styled from 'styled-components';

export type TagType = 'darkGreen' | 'lightGreen' | 'white' | 'grey';
export type TagSize = 'small' | 'middle' | 'large';

export const StyledTag = styled(Tag)<{ $tagType?: TagType; $tagSize?: TagSize }>`
  &&& {
    border-radius: 16px;
    border: 0;
    padding: 10px 12px;
    font-size: ${({ theme }) => theme.fontSizes.default};
  }
  &&& {
    padding: ${({ $tagSize }) => {
      switch ($tagSize) {
        case 'small':
          return '5px 8px';
        case 'large':
        default:
          return '10px 12px';
      }
    }};
    background-color: ${({ theme, $tagType }) => {
      switch ($tagType) {
        case 'darkGreen':
          return theme.colors.palette.columnOfOakGreen;
        case 'white':
          return theme.colors.palette.white;
        case 'lightGreen':
          return theme.colors.palette.springDew;
        case 'grey':
          return theme.colors.palette.superSilver;
        default:
          return undefined;
      }
    }};
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  }
`;

export const StyledText = styled(Typography.Text)<{ $tagType?: TagType; $tagSize?: TagSize }>`
  &&& {
    font-size: ${({ $tagSize }) => {
      switch ($tagSize) {
        case 'small':
          return '14px';
        case 'large':
        default:
          return undefined;
      }
    }};
    color: ${({ theme, $tagType }) => {
      switch ($tagType) {
        case 'darkGreen':
          return theme.colors.palette.white;
        case 'white':
          return theme.colors.mainText;
        case 'lightGreen':
          return theme.colors.palette.deadPixel;
        case 'grey':
          return theme.colors.mainText;
        default:
          return undefined;
      }
    }};
  }
`;
