import { OnlineVisitForAdminStatusEnum } from 'shared/api/rtk-api';

import { InternalOnlineAppointmentStatusEnum } from '../types/internal-online-appointment-status-enum';

export const internalOnlineAppointmentStatusToExternalOnlineAppointmentStatusMapper = new Map<
  InternalOnlineAppointmentStatusEnum,
  OnlineVisitForAdminStatusEnum
>([
  [InternalOnlineAppointmentStatusEnum.AcceptedByAdmin, OnlineVisitForAdminStatusEnum.AcceptedByAdmin],
  [InternalOnlineAppointmentStatusEnum.AssignedByAnotherAdmin, OnlineVisitForAdminStatusEnum.AssignedByAnotherAdmin],
  [InternalOnlineAppointmentStatusEnum.AssignedByCurrentAdmin, OnlineVisitForAdminStatusEnum.AssignedByCurrentAdmin],
  [InternalOnlineAppointmentStatusEnum.Cancelled, OnlineVisitForAdminStatusEnum.Cancelled],
  [InternalOnlineAppointmentStatusEnum.DoctorAbsent, OnlineVisitForAdminStatusEnum.DoctorAbsent],
  [InternalOnlineAppointmentStatusEnum.FinishedForAdmin, OnlineVisitForAdminStatusEnum.FinishedForAdmin],
  [InternalOnlineAppointmentStatusEnum.NewForAdmin, OnlineVisitForAdminStatusEnum.NewForAdmin],
  [InternalOnlineAppointmentStatusEnum.PendingCancellation, OnlineVisitForAdminStatusEnum.PendingCancellation],
  [
    InternalOnlineAppointmentStatusEnum.ProcessingByAnotherAdmin,
    OnlineVisitForAdminStatusEnum.ProcessingByAnotherAdmin,
  ],
  [
    InternalOnlineAppointmentStatusEnum.ProcessingByCurrentAdmin,
    OnlineVisitForAdminStatusEnum.ProcessingByCurrentAdmin,
  ],
  [InternalOnlineAppointmentStatusEnum.Rejected, OnlineVisitForAdminStatusEnum.Rejected],
]);

export const getExternalOnlineAppointmentStatus = (
  status: InternalOnlineAppointmentStatusEnum,
): OnlineVisitForAdminStatusEnum => {
  const internalStatus = internalOnlineAppointmentStatusToExternalOnlineAppointmentStatusMapper.get(status);
  if (!internalStatus) throw new Error(`Unknown status: ${status}`);
  return internalStatus;
};
