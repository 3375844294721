import { useEffect, useState } from 'react';

import Pubnub from 'pubnub';
import { usePubNub } from 'pubnub-react';

import { useCurrentUserQuery } from 'entity/auth/hooks/use-current-user-query';
import { useDoctorReadConversationMutation } from 'entity/doctor-chat/hooks/use-doctor-read-conversation';
import { formatDateRelative } from 'entity/pubnub/lib/format';
import Emitter from 'shared/lib/emitter';

import type { ConversationMessageType } from '../types';

type Props = {
  pubNubChannelName: string;
  conversationId: number;
};

export const useConversationMessages = ({ pubNubChannelName, conversationId }: Props) => {
  const pubnub = usePubNub();

  const [chatMessages, setChatMessages] = useState<ConversationMessageType[]>([]);
  const [isChatEmpty, setIsChatEmpty] = useState<boolean>(false);
  const [isChatLoading, setIsChatLoading] = useState<boolean>(false);

  const { currentUser } = useCurrentUserQuery();

  const [readConversation] = useDoctorReadConversationMutation();

  useEffect(() => {
    setIsChatLoading(true);

    pubnub.fetchMessages({ channels: [pubNubChannelName] }, (status, data) => {
      setIsChatLoading(false);

      if (status.error) return setIsChatEmpty(true);

      const currentChannel = data.channels?.[pubNubChannelName];

      setIsChatEmpty(!currentChannel?.[0]?.message);

      const messages = (currentChannel ?? []).map((message) => {
        const formattedDateOfCurrentMessage = formatDateRelative(message.timetoken);
        return {
          id: String(message.timetoken),
          date: formattedDateOfCurrentMessage,
        };
      });
      setChatMessages(messages);
    });
  }, [pubnub, pubNubChannelName]);

  useEffect(() => {
    readConversation({ chatUUID: conversationId });
  }, [readConversation, pubNubChannelName, conversationId]);

  useEffect(() => {
    const newMessageEvent = (event: Pubnub.MessageEvent) => {
      if (pubNubChannelName === event.channel) {
        readConversation({ chatUUID: conversationId });
        setIsChatEmpty(false);
      }
    };
    Emitter.on('newMessage', newMessageEvent);
    return () => {
      Emitter.off('newMessage', newMessageEvent);
    };
  }, [currentUser?.pubNubUserId, readConversation, pubNubChannelName, conversationId]);

  return { chatMessages, isChatEmpty, isChatLoading };
};
