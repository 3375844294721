import React, { type FC } from 'react';

import { SearchEmptyIcon } from 'shared/ui/icons';
import { StyledSearchContainer } from 'shared/ui/table/styles';

export const SearchEmpty: FC = () => {
  return (
    <StyledSearchContainer>
      <SearchEmptyIcon />
      <span>Поиск не дал результатов</span>
    </StyledSearchContainer>
  );
};
