import { useMemo } from 'react';

import { type DocumentResponse, useDocumentControllerUserUploadedFilesByPatientIdQuery } from 'shared/api/rtk-api';

const mapDoctorChatFileToInternalDoctorChatFile = (file: DocumentResponse) => {
  return {
    id: file.id,
    name: file.name,
  };
};

interface DoctorFilesQuery {
  patientId: number;
}

export const useDoctorChatFilesQuery = ({ patientId }: DoctorFilesQuery) => {
  const { data, ...others } = useDocumentControllerUserUploadedFilesByPatientIdQuery({ patientId });

  const doctorChatFiles = useMemo(() => {
    return (data?.data ?? []).map(mapDoctorChatFileToInternalDoctorChatFile);
  }, [data?.data]);

  return {
    data: {
      doctorChatFiles,
      total: data?.total,
    },
    ...others,
  };
};
