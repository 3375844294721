import styled from 'styled-components';

export const SpecialistActionButton = styled.div`
  margin-bottom: 10px;

  cursor: pointer;

  span {
    color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
`;
