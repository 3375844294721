import { FC, type ReactNode } from 'react';

import { StyledContainer } from 'shared/ui/split-screen/styles';

interface SplitScreenProps {
  Left: ReactNode;
  Right?: ReactNode;
}

export const SplitScreen: FC<SplitScreenProps> = ({ Left, Right }) => {
  return (
    <StyledContainer>
      {Left}
      {Right ?? <div />}
    </StyledContainer>
  );
};
