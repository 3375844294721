export enum ResetPasswordFormFieldsEnum {
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
  CODE = 'code',
  EMAIL = 'email',
}

export interface IResetPasswordFormFields {
  [ResetPasswordFormFieldsEnum.PASSWORD]: string;
  [ResetPasswordFormFieldsEnum.CONFIRM_PASSWORD]: string;
  [ResetPasswordFormFieldsEnum.CODE]: string;
  [ResetPasswordFormFieldsEnum.EMAIL]: string;
}
