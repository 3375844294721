import React, { FC } from 'react';

import { Space } from 'antd';

import {
  StyledCrossButtonContainer,
  StyledCustomModal,
  StyledCustomModalText,
  StyledCustomModalTitle,
  StyledHeaderWrapper,
  StyledSpace,
} from 'shared/ui/custom-modal/style';
import { CrossIcon } from 'shared/ui/icons';

type Props = {
  visible: boolean;
  title: string;
  actionButtons: React.ReactNode[];
  description?: string;
  onClose?: () => void;
  bodyContent?: React.ReactNode;
};

export const CustomModal: FC<Props> = ({ visible, title, description, actionButtons, bodyContent, ...props }) => {
  const footer = (
    <Space size="middle">
      {actionButtons.map((button, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>{button}</div>
      ))}
    </Space>
  );
  return (
    <StyledCustomModal
      {...props}
      centered
      maskClosable
      destroyOnClose
      footer={footer}
      visible={visible}
      mask
      closable={false}
      onCancel={props.onClose}
    >
      <StyledSpace direction="vertical" size="middle">
        <StyledHeaderWrapper size="middle">
          <StyledCustomModalTitle level={5}> {title} </StyledCustomModalTitle>
          <StyledCrossButtonContainer onClick={props.onClose}>
            <CrossIcon />
          </StyledCrossButtonContainer>
        </StyledHeaderWrapper>
        {description && <StyledCustomModalText>{description}</StyledCustomModalText>}
        {bodyContent}
      </StyledSpace>
    </StyledCustomModal>
  );
};
