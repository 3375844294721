import { FC } from 'react';

import { HealthAssistanceRejectedByPatientNavigationMenu } from 'feature/health-assistance-rejected-by-patient-navigation';
import { ListLayout } from 'shared/ui/list-layout';
import { HomeVisitRejectedByPatientTable } from 'widgets/admin-home-visit-rejected-by-patient-table/ui/home-visit-rejected-by-patient-table';

export const AdminHomeVisitRejectedByPatientListPage: FC = () => {
  return (
    <ListLayout
      title="Заявки на отмену приема"
      Menu={HealthAssistanceRejectedByPatientNavigationMenu}
      Table={HomeVisitRejectedByPatientTable}
    />
  );
};
