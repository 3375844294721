import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  &&& {
    background-color: ${({ theme }) => theme.colors.palette.superSilver};
    border-radius: 24px;
    border-color: ${({ theme }) => theme.colors.palette.decemberSky};
    .ant-card-body {
      padding: 16px 24px;
    }
    min-width: max-content;
  }
`;
