import { Image } from 'antd';

import { StyledLogo } from 'shared/ui/logo/styles';

export const Logo = () => {
  return (
    <StyledLogo>
      <Image preview={false} src="/images/logo.png" />
    </StyledLogo>
  );
};
