import { AdminHealthAssistanceDetailsContainer } from 'entity/health-assistance/ui/health-assistance-details-container/admin-health-assistance-details-container';
import type { InternalOnlineAppointmentDetails } from 'entity/online-appointment/types/internal-online-appointment-details';
import { formatDateDMAndCallTime } from 'shared/lib/format';
import { TextAreaRow } from 'shared/ui/row/text-area-row';
import { TextRow } from 'shared/ui/row/text-row';

interface Props {
  editHandler?: () => void;
  onlineAppointment: InternalOnlineAppointmentDetails;
}

export const AdminOnlineAppointmentRejectedByPatientDetailsForm = ({ editHandler, onlineAppointment }: Props) => {
  if (!onlineAppointment) return null;

  const { patientName, patientPhone, specialization, doctorName, date, time, cancelReason } = onlineAppointment;

  return (
    <AdminHealthAssistanceDetailsContainer>
      <TextRow title="Тип приема" text="Вызов на дом" />
      <TextRow title="Пациент" text={patientName} />
      <TextRow title="Телефон" text={patientPhone} />
      <TextRow editHandler={editHandler} title="Специализация" text={specialization} />
      <TextRow editHandler={editHandler} title="Врач" text={doctorName} />
      <TextRow editHandler={editHandler} title="Дата и время" text={formatDateDMAndCallTime(date, time)} />
      {cancelReason && <TextAreaRow title="Комментарий" text={cancelReason} />}
    </AdminHealthAssistanceDetailsContainer>
  );
};
