import { Navigation } from 'feature/navigation/ui';
import { routePatches } from 'shared/routes';

export const DoctorNavigation = () => {
  return (
    <Navigation
      pages={[
        { path: '/schedule', name: 'Календарь' },
        { path: '/patients', name: 'Пациенты' },
        { path: routePatches.DoctorChats.path(), name: 'Чаты' },
      ]}
    />
  );
};
