import { Avatar, Collapse, List } from 'antd';
import styled from 'styled-components';

export const UserMenuArrow = styled(Avatar)`
  background: none;
  font-weight: 700;
  font-size: 16px;
  color: #000;
`;
export const UserMenuContainer = styled(Collapse)<{ isOpened: boolean }>`
  background-color: #fff;
  width: fit-content;
  font-size: 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 16px #e1e1e1;
  z-index: 1000;
`;

export const StyledListItem = styled(List.Item.Meta)`
  width: 130px;
`;
