import { type FC, type JSX } from 'react';

import type { InternalOnlineAppointment } from 'entity/online-appointment/types/internal-online-appointment';
import { OnlineAppointmentRejectedByPatientApproveByAdminDropdownMenuButton } from 'feature/online-appointment-rejected-by-patient-approve-by-admin/ui/online-appointment-rejected-by-patient-approve-by-admin-button-dropdown-menu-button';
import { OnlineAppointmentRejectedByPatientToTakeOnByAdminDropdownMenuButton } from 'feature/online-appointment-rejected-by-patient-to-take-on-by-admin/ui/online-appointment-rejected-by-patient-to-take-on-by-admin-dropdown-menu-button';
import { DropDownMenu } from 'shared/ui/dropdown-menu';
import { VerticalDotsIcon } from 'shared/ui/icons';

interface Props {
  onlineAppointment: InternalOnlineAppointment;
}

export const OnlineAppointmentRejectedByPatientTableDropdownMenu: FC<Props> = ({ onlineAppointment }) => {
  const { canBeCanceled, canBeProcessed, canBeAssigned, id } = onlineAppointment;

  const getNodeItems = (): JSX.Element[] => {
    const buttonsArray = [];

    if (canBeProcessed || canBeAssigned) {
      buttonsArray.push(
        <OnlineAppointmentRejectedByPatientToTakeOnByAdminDropdownMenuButton onlineAppointmentId={id} key={1} />,
      );
    }

    if (canBeCanceled) {
      buttonsArray.push(
        <OnlineAppointmentRejectedByPatientApproveByAdminDropdownMenuButton onlineAppointmentId={id} key={2} />,
      );
    }
    return buttonsArray;
  };

  const nodeItems = getNodeItems();

  return nodeItems.length > 0 ? (
    <DropDownMenu placement="bottomRight" nodeItems={nodeItems}>
      <VerticalDotsIcon />
    </DropDownMenu>
  ) : null;
};
