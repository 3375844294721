import { FaceToFaceMeetingIcon, HomeVisitIcon, OnlineMeetingIcon } from 'shared/ui/icons';

export enum ScheduleEventType {
  HOME = 'HOME',
  CLINIC = 'CLINIC',
  ONLINE = 'ONLINE',
  SLOT = 'SLOT',
}

export const ScheduleEventTypeIcons = {
  [ScheduleEventType.HOME]: <HomeVisitIcon />,
  [ScheduleEventType.CLINIC]: <FaceToFaceMeetingIcon />,
  [ScheduleEventType.ONLINE]: <OnlineMeetingIcon />,
  [ScheduleEventType.SLOT]: <OnlineMeetingIcon />,
};

export const ScheduleEventTypeTitle = {
  [ScheduleEventType.HOME]: 'Вызов на дом',
  [ScheduleEventType.CLINIC]: 'Очный',
  [ScheduleEventType.ONLINE]: 'Онлайн прием',
  [ScheduleEventType.SLOT]: 'Онлайн прием',
};
