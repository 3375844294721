import { useCallback } from 'react';

import { useAdminVisitsControllerAcceptHomeVisitMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  homeVisitId?: number;
  time: string;
  date: string;
  patientAddress: string;
  doctorId?: number;
  onSuccess?: () => void;
}
export const useHomeVisitEditByAdminMutation = () => {
  const [mutation] = useAdminVisitsControllerAcceptHomeVisitMutation();
  const action = useCallback(
    ({ homeVisitId, time, date, onSuccess, doctorId, patientAddress }: MutationHookOptions) => {
      if (!homeVisitId) {
        errorNotification('Невозможно изменить домашний визит');
        return;
      }
      mutation({ id: homeVisitId, callTime: time, callDate: date, doctorId, patientAddress })
        .unwrap()
        .then(() => {
          successNotification('Домашний визит изменен администратором');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при изменении домашнего визита администратором');
        });
    },
    [mutation],
  );
  return [action];
};
