import { Table } from 'antd';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 0 16px;
    height: 56px;
  }
  .ant-table :nth-child(7n) td {
    border-bottom: none;
  }
  background-color: ${({ theme }) => theme.colors.palette.white};
  .ant-table-cell {
    background-color: inherit;
  }
  .ant-table-cell {
    color: ${({ theme }) => theme.colors.mainText};
  }
  border-radius: 16px;
  overflow: hidden;
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    background-color: ${({ theme }) => theme.colors.palette.white};
  }

  .ant-pagination-item a {
    color: ${({ theme }) => theme.colors.palette.deadPixel};
    height: 24px;
    width: 24px;
  }
  .ant-pagination-item.ant-pagination-item-active a {
    color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }

  .ant-pagination-item:hover a {
    color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }

  .ant-pagination-item-link:hover {
    color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    margin: 0;
    .ant-pagination-item-link {
      border-style: none;
    }
  }

  &&& {
    .ant-table-cell-row-hover {
      background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};
    }
  }
  .ant-pagination-item {
    margin: 0;
    min-width: 24px;
    border-style: none;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-next,
  .ant-pagination-prev {
    height: 24px;
    width: 24px;
  }
  .ant-table-pagination.ant-pagination {
    margin: 12px 0;
  }
  .ant-table-thead > tr > th {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
  .ant-table-content {
    min-height: 448px;
    border-bottom: 1px solid #f0f0f0;
  }
` as typeof Table;

export const StyledSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 500px;
`;
