import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  &&& {
    background-color: ${({ theme }) => theme.colors.palette.superSilver};
    border-radius: 24px;
    .ant-card-body {
      padding: 10px 16px;
    }
  }
`;
