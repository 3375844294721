import React, { FC, useMemo } from 'react';

import { Avatar, TableColumnsType } from 'antd';
import { useParams } from 'react-router-dom';

import { useDoctorListQuery } from 'entity/doctor/hooks/use-doctor-list-query';
import type { InternalDoctor } from 'entity/doctor/types/internal-doctor';
import type { TimeSlot, TimeSlotAppointment } from 'entity/doctor-times/types/time-slot-appointment';
import { useHomeVisitDetailsQuery } from 'entity/home-visit/hooks/use-home-visit-details-query';
import { Tag } from 'shared/ui/tag';
import {
  StyledList,
  StyledTable,
  StyledTagContainer,
  StyledTagsContainer,
} from 'widgets/admin-chose-doctor-date-time-widget/ui/choice-doctor-time/styles';

interface Props {
  handleSlotPick: (value: { doctorName: string; doctorId: number; time: string; specialization: string }) => void;
  specialization?: string;
}

export const ChoiceDoctorTimeWidget: FC<Props> = ({ handleSlotPick, specialization }) => {
  const params = useParams();
  const { homeVisit } = useHomeVisitDetailsQuery(params.id);
  const { doctors } = useDoctorListQuery({ cityId: homeVisit?.cityId });

  const columns: TableColumnsType<TimeSlotAppointment> = [
    {
      title: 'Врач',
      dataIndex: 'doctor',
      ellipsis: true,
      render: (item: InternalDoctor) => {
        return (
          <StyledList
            avatar={<Avatar size={40} src={item.image} />}
            title={item.fullName}
            description={item.specialization}
          />
        );
      },
    },
    {
      title: 'Свободные слоты',
      dataIndex: 'timeSlots',
      ellipsis: true,
      render: (timeSlots: TimeSlot[], row) => {
        return (
          <StyledTagsContainer>
            {timeSlots?.map((timeSlot) => (
              <StyledTagContainer key={timeSlot.id}>
                <Tag
                  $tagSize="small"
                  onClick={() =>
                    handleSlotPick({
                      specialization: row.doctor.specialization,
                      doctorName: row.doctor.fullName,
                      doctorId: row.doctor.id,
                      time: timeSlot.time,
                    })
                  }
                  $tagType="lightGreen"
                  key={timeSlot.id}
                >
                  {timeSlot.time}
                </Tag>
              </StyledTagContainer>
            ))}
          </StyledTagsContainer>
        );
      },
    },
  ];

  const dataSources = useMemo(
    () =>
      doctors
        .filter((doctor) => {
          return !specialization || doctor.specialization === specialization;
        })
        .map((doctor) => {
          return {
            doctor,
            timeSlots: [
              {
                id: 1,
                time: '8:00 - 12:00',
              },
              {
                id: 2,
                time: '12:00 - 16:00',
              },
              {
                id: 3,
                time: '16:00 - 20:00',
              },
            ],
          };
        }),
    [doctors, specialization],
  );

  return <StyledTable tableLayout="auto" columns={columns} dataSource={dataSources} />;
};
