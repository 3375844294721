import { useMemo } from 'react';

import type { INotification, INotificationResponse } from 'entity/doctor/api/types';
import { useDoctorNotificationControllerGetNotificationQuery } from 'shared/api/rtk-api';
import { formatDateTime } from 'shared/lib/format';

const mapExternalDoctorNotificationToInternalDoctorNotification = (notification: INotification) => {
  return {
    id: notification.id,
    isViewed: notification.isViewed,
    message: notification.message,
    date: formatDateTime(notification.date),
    link: notification.homeVisit?.id ? `schedule/appointment-details/${notification.homeVisit?.id}` : '',
  };
};

export const useDoctorNotificationsQuery = () => {
  const { data, ...others } = useDoctorNotificationControllerGetNotificationQuery(undefined, {
    pollingInterval: 10000,
  });

  // TODO: remove this cast after fixing the api response
  const castedData = data as unknown as INotificationResponse | undefined;

  const notifications = useMemo(() => {
    return castedData?.notifications.map(mapExternalDoctorNotificationToInternalDoctorNotification);
  }, [castedData?.notifications]);

  return {
    notifications,
    ...others,
  };
};
