import { useCallback } from 'react';

import { useOnlineVisitAppointmentControllerAdminCancelAppointmentMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  id?: number;
  onSuccess?: () => void;
}
export const useOnlineAppointmentAcceptCancellationByAdminMutation = () => {
  const [mutation] = useOnlineVisitAppointmentControllerAdminCancelAppointmentMutation();
  const action = useCallback(
    ({ id, onSuccess }: MutationHookOptions) => {
      if (!id) {
        errorNotification('Невозможно подтвердить отмену онлайн визита');
        return;
      }
      mutation({
        internalOnlineVisitAppointmentId: id,
      })
        .unwrap()
        .then(() => {
          successNotification('Онлайн-визит отменен успешно');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при подтверждении отмены онлайн-визита');
        });
    },
    [mutation],
  );
  return [action];
};
