import { DatePicker } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export const StyledDatePicker = styled(DatePicker)`
  padding: 8px 16px;
  border-radius: 24px;
  border-style: none;
  height: 40px;
  &.ant-picker-focused {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
`;

export const DatePickerWrapperStyles = createGlobalStyle`
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
        background: ${({ theme }) => theme.colors.palette.columnOfOakGreen} !important;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
        border: 1px solid ${({ theme }) => theme.colors.palette.columnOfOakGreen};
    }

    .ant-picker-today-btn {
        color: ${({ theme }) => theme.colors.palette.columnOfOakGreen} !important;
    }
`;
