import React, { type FC } from 'react';

import { Space } from 'antd';
import { useParams } from 'react-router-dom';

import { useHomeVisitDetailsQuery } from 'entity/home-visit/hooks/use-home-visit-details-query';
import { TextRow } from 'shared/ui/row/text-row';

import { StyledCard } from './styles';

export const HomeVisitPatientInfoHorizontal: FC = () => {
  const params = useParams();
  const { homeVisit, isLoading } = useHomeVisitDetailsQuery(params.id);

  return (
    <StyledCard loading={isLoading}>
      <Space size={40}>
        <TextRow title="Пациент" text={homeVisit?.patientName ?? ''} />
        <TextRow title="Телефон" text={homeVisit?.patientPhone ?? ''} />
        <TextRow title="Тип приема" text="Вызов на дом" />
      </Space>
    </StyledCard>
  );
};
