import React, { FC, PropsWithChildren } from 'react';

import { Outlet } from 'react-router-dom';

import { GeneralLayout } from 'shared/ui/general-layout';
import { DoctorHeader } from 'widgets/doctor-header/ui/doctor-header';

import { PubNubWrapper } from './pub-nub';

export const DoctorLayout: FC<PropsWithChildren> = () => {
  return (
    <PubNubWrapper>
      <GeneralLayout headerSlot={<DoctorHeader />} pageSlot={<Outlet />} />
    </PubNubWrapper>
  );
};
