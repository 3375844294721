import React, { FC, useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useHomeVisitDeclineByDoctorMutation } from 'entity/home-visit/hooks/use-home-visit-decline-by-doctor-mutation';
import { HomeVisitDetails } from 'pages/doctor/home-visit-details/types';
import { routePatches } from 'shared/routes';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';

interface Props {
  appointment: HomeVisitDetails;
}

export const HomeVisitDeclineByDoctorButton: FC<Props> = ({ appointment }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFaultModal, setShowFaultModal] = useState(false);
  const navigate = useNavigate();

  const okClickFaultModalHandler = () => setShowFaultModal(false);

  const declineClickConfirmModalHandler = () => setShowConfirmModal(true);

  const rejectDeclineButtonHandler = () => setShowConfirmModal(false);
  const [rejectRequest] = useHomeVisitDeclineByDoctorMutation();

  const okClickSuccessModalHandler = useCallback(() => {
    setShowSuccessModal(false);
    navigate(routePatches.DoctorSchedule.path());
  }, [navigate]);

  const okClickConfirmModalHandler = () => {
    setShowConfirmModal(false);
    rejectRequest({
      homeVisitId: appointment.homeVisitId,
      onSuccess: () => setShowSuccessModal(true),
      onError: () => setShowFaultModal(true),
    });
  };

  return (
    <>
      <CustomButton onClick={declineClickConfirmModalHandler} $buttonType="decline">
        Отклонить
      </CustomButton>
      <CustomModal
        title="Вы уверены, что хотите отклонить заявку?"
        visible={showConfirmModal}
        onClose={rejectDeclineButtonHandler}
        actionButtons={[
          <CustomButton key={1} onClick={rejectDeclineButtonHandler} $buttonType="declineWhite">
            Отмена
          </CustomButton>,
          <CustomButton key={2} onClick={okClickConfirmModalHandler} $buttonType="submit">
            Отклонить
          </CustomButton>,
        ]}
      />
      <CustomModal
        title="Вы успешно отклонили заявку"
        visible={showSuccessModal}
        onClose={okClickSuccessModalHandler}
        actionButtons={[
          <CustomButton key={1} onClick={okClickSuccessModalHandler} $buttonType="submit">
            Понятно
          </CustomButton>,
        ]}
      />
      <CustomModal
        title="Данная заявка уже обработана"
        visible={showFaultModal}
        onClose={okClickFaultModalHandler}
        actionButtons={[
          <CustomButton key={1} onClick={okClickFaultModalHandler} $buttonType="submit">
            Понятно
          </CustomButton>,
        ]}
      />
    </>
  );
};
