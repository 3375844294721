/* eslint @typescript-eslint/no-misused-promises: "off" */
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Col, Form, List, Row, Spin } from 'antd';
import { Navigate, useLocation } from 'react-router-dom';

import { useUpdateDoctorAccountMutation } from 'entity/admin/api';
import { IAdminDoctorInterface } from 'entity/admin/api/types/doctors';
import { IUpdateDoctorFormFields, LoginFormFieldsEnum } from 'entity/auth/api/types';
import {
  AdminDoctorEditCancelButton,
  AdminDoctorEditForm,
  AdminDoctorEditFormButtonsRow,
  AdminDoctorEditPageContainer,
} from 'pages/admin/doctor/ui/edit/styles';
import { AdminDoctorAvatar, AvatarLoader } from 'pages/admin/doctors/ui/styles';
import { AppAlert } from 'shared/ui/alert';
import { AppButton } from 'shared/ui/button2';
import { AppInput } from 'shared/ui/input2';
import { LinkButton } from 'shared/ui/link-button';

export const EditAdminDoctorPage: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { state } = useLocation();
  const doctor = state as IAdminDoctorInterface;
  const [updateAccount, { isLoading, isError, isSuccess }] = useUpdateDoctorAccountMutation();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IUpdateDoctorFormFields>({
    defaultValues: { email: doctor?.account?.email },
  });
  const { email } = watch();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSubmit: SubmitHandler<IUpdateDoctorFormFields> = ({ email }) => {
    /* eslint-disable-next-line  @typescript-eslint/no-floating-promises */
    updateAccount({ email, doctorId: doctor.id });
  };

  return !doctor ? (
    <Navigate to="../" replace />
  ) : (
    <>
      {isSuccess && <AppAlert message="Изменения сохранены" type="success" closable />}
      {isError && <AppAlert message="Что-то пошло не так" type="error" closable />}
      <AdminDoctorEditPageContainer>
        <Row justify="start" align="middle">
          <Col span={4}>
            <AdminDoctorAvatar
              width={40}
              height={40}
              src={doctor?.image}
              placeholder={<Spin size="large" style={{ background: '#FFF' }} indicator={<AvatarLoader />} />}
              fallback="https://samara.medguard.ru/local/templates/medguard/images/default.png"
            />
          </Col>
          <Col span={20}>
            <List.Item.Meta title={doctor?.name} description={doctor?.specialization} />
          </Col>
          <Col span={24}>
            <AdminDoctorEditForm name="edit-doctor-account">
              <Form.Item
                validateStatus={errors?.[LoginFormFieldsEnum.EMAIL] ? 'error' : ''}
                help={errors?.[LoginFormFieldsEnum.EMAIL] && 'Введите email!'}
              >
                <Controller
                  name={LoginFormFieldsEnum.EMAIL}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <AppInput {...field} size="large" placeholder="email" allowClear />}
                />
              </Form.Item>
            </AdminDoctorEditForm>
          </Col>

          <Col span={24}>
            <AdminDoctorEditFormButtonsRow gutter={[16, 0]} justify="center" align="middle">
              <Col span={14}>
                {/* eslint-disable-next-line deprecation/deprecation */}
                <AppButton
                  htmlType="submit"
                  disabled={!email || isLoading}
                  loading={isLoading}
                  onClick={handleSubmit(onSubmit)}
                >
                  Сохранить
                </AppButton>
              </Col>
              <Col span={10}>
                <AdminDoctorEditCancelButton type="text" block>
                  <LinkButton to="../">Отменить</LinkButton>
                </AdminDoctorEditCancelButton>
              </Col>
            </AdminDoctorEditFormButtonsRow>
          </Col>
        </Row>
      </AdminDoctorEditPageContainer>
    </>
  );
};
