import { type PastHomeVisitFormResponse } from 'shared/api/rtk-api';
import { formatDateDMAndCallTime, formatPhone } from 'shared/lib/format';

import { InternalPastHomeVisitDetails } from '../types/internal-past-home-visit-details';

export const externalPastHomeVisitDetailsToInternalPastHomeVisitDetailsMapper = (
  response: PastHomeVisitFormResponse,
): InternalPastHomeVisitDetails => {
  return {
    id: response.id,
    patient: response.patientName,
    phone: formatPhone(response.patientPhone),
    specialization: response.specialization,
    dateAndTime: formatDateDMAndCallTime(response.callDate, response.callTime),
    doctor: response.doctorName ?? '',
    stars: 5,
    comment: response.comment,
    doctorId: response.doctorId,
    extraFacilities: response.extraFacilities.join(', '),
    review: response.review,
    rating: response.rating,
  };
};
