import { type FC } from 'react';

import type { InternalChatSupport } from 'entity/chat-support/types/internal-chat-support';
import { AdminHealthAssistanceDetailsContainer } from 'entity/health-assistance/ui/health-assistance-details-container/admin-health-assistance-details-container';
import { getDayWord } from 'shared/lib/format';
import { TextRow } from 'shared/ui/row/text-row';

type Props = {
  chatSupport: InternalChatSupport;
};

export const ChatSupportAppointmentDetailsForm: FC<Props> = ({ chatSupport }) => {
  const { patient, phone, specialization, duration, cost } = chatSupport;

  return (
    <AdminHealthAssistanceDetailsContainer>
      <TextRow title="Тип приема" text="Чат-сопровождение" />
      <TextRow title="Пациент" text={patient} />
      <TextRow title="Телефон" text={phone} />
      <TextRow title="Специализация" text={specialization} />
      <TextRow title="Период доступности" text={`${duration} ${getDayWord(duration)}`} />
      <TextRow title="Стоимость" text={cost ? `${cost} ₽` : 'Не указана'} />
    </AdminHealthAssistanceDetailsContainer>
  );
};
