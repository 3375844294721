import { FC, useState } from 'react';

import { CustomRow } from 'shared/ui/row/custom-row';
import { Tag } from 'shared/ui/tag';

export interface TimeTagRowProps {
  tags: string[];
  onChange?: ({ selectedTag, selectedTagIndex }: { selectedTag: string; selectedTagIndex: number }) => void;
  defaultSelectedTagIndex?: number | null;
  disabled?: boolean;
}

export const TimeTagRow: FC<TimeTagRowProps> = ({ tags, onChange, disabled, defaultSelectedTagIndex }) => {
  const [selectedTagIndex, setSelectedTagIndex] = useState<number | null>(defaultSelectedTagIndex ?? null);
  return (
    <CustomRow title="Выберите время">
      {tags.map((tag, index) => {
        const calculateTagType = () => {
          if (disabled) return 'grey';
          return selectedTagIndex === index ? 'darkGreen' : 'white';
        };
        const tagType = calculateTagType();

        return (
          <Tag
            key={tag}
            onClick={
              disabled
                ? undefined
                : () => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    onChange && onChange({ selectedTag: tag, selectedTagIndex: index });
                    setSelectedTagIndex(index);
                  }
            }
            $tagType={tagType}
          >
            {tag}
          </Tag>
        );
      })}
    </CustomRow>
  );
};
