import { type FC } from 'react';

import { Row, Typography } from 'antd';

import { ScheduleEventTypeTitle } from 'entity/schedule/constants';
import type { InternalScheduleHomeVisitEvent } from 'entity/schedule/types/internal-schedule-home-visit-event';
import { CloseIcon } from 'shared/ui/icons';

import { PatientDocuments } from '../documents';
import { CloseIconWrapper, ScheduleSlotPopoverContentContainer } from './styles';

const { Title } = Typography;

type Props = {
  event: InternalScheduleHomeVisitEvent;
  closeModal: () => void;
};

export const HomeVisitEventDetails: FC<Props> = ({ event, closeModal }) => {
  const { type, documents, canAddProtocol } = event;

  return (
    <ScheduleSlotPopoverContentContainer>
      <Row justify="space-between">
        <Title level={4}>{ScheduleEventTypeTitle[type]}</Title>
        <CloseIconWrapper onClick={closeModal}>
          <CloseIcon />
        </CloseIconWrapper>
      </Row>

      {documents?.length && <PatientDocuments documents={documents} canAddProtocol={canAddProtocol} />}
    </ScheduleSlotPopoverContentContainer>
  );
};
