import { externalHomeVisitListRejectedByPatientToInternalHomeVisitListRejectedByPatientMapper } from 'entity/home-visit/mappers/external-home-visit-list-rejected-by-patient-to-internal-home-visit-list-rejected-by-patient-mapper';
import { useAdminVisitsControllerViewRejectByPatientQuery } from 'shared/api/rtk-api';

interface HomeVisitDetailQuery {
  page: number;
  pageSize: number;
  statuses?: ('New' | 'InProgressByCurrentAdmin' | 'InProgressByAnotherAdmin')[];
}

export const useHomeVisitRejectedByPatientListQuery = ({ page, pageSize, statuses }: HomeVisitDetailQuery) => {
  const { data, ...others } = useAdminVisitsControllerViewRejectByPatientQuery({
    statuses,
    limit: pageSize,
    offset: (page - 1) * pageSize || undefined,
  });

  const homeVisits = data?.data.map(
    externalHomeVisitListRejectedByPatientToInternalHomeVisitListRejectedByPatientMapper,
  );
  return {
    homeVisits,
    total: data?.total,
    ...others,
  };
};
