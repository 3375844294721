import { useCallback } from 'react';

import { useAdminVisitsControllerRejectHomeVisitMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  homeVisitId?: number;
  onSuccess?: () => void;
  onError?: () => void;
}
export const useHomeVisitRejectedByPatientAcceptByAdminMutation = () => {
  const [mutation] = useAdminVisitsControllerRejectHomeVisitMutation();
  const action = useCallback(
    ({ homeVisitId, onSuccess, onError }: MutationHookOptions) => {
      if (!homeVisitId) {
        errorNotification('Невозможно подтвердить отклонение домашнего визита');
        onError?.();
        return;
      }
      mutation({ id: homeVisitId })
        .unwrap()
        .then(() => {
          successNotification('Отклонение домашнего визита подтверждено');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при подтверждении отклонения домашнего визита');
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
