import { ChildType } from 'shared/api/rtk-api';

import { ChildrenOptionsOptions } from '../constants';

const childrenOptionsMap = new Map<ChildType, ChildrenOptionsOptions>([
  [ChildType.Yes, ChildrenOptionsOptions.YES],
  [ChildType.No, ChildrenOptionsOptions.NO],
]);
export const getInternalPushNotificationChildrenOptions = (childrenOption: ChildType): ChildrenOptionsOptions => {
  const internalChildrenOption = childrenOptionsMap.get(childrenOption);
  if (!internalChildrenOption) throw new Error(`Unknown children option: ${childrenOption}`);
  return internalChildrenOption;
};
