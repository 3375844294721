import { FC } from 'react';

import { Typography } from 'antd';

import { EmptyDialogIcon } from 'shared/ui/icons';

import { ChatBody, EmptyDialogWrapper } from './styles';

export const ChatDetailsNoActiveChats: FC = () => {
  return (
    <ChatBody>
      <EmptyDialogWrapper>
        <EmptyDialogIcon />
        <Typography.Text>У вас нет активных чатов</Typography.Text>
      </EmptyDialogWrapper>
    </ChatBody>
  );
};
