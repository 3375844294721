import styled from 'styled-components';

import { Select } from 'shared/ui/select';

export const StyledSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid ${({ theme }) => theme.colors.palette.columnOfOakGreen};

    .ant-select-selection-placeholder {
      padding-top: 5px;
    }
  }
`;

export const SpecialistActionButton = styled.div`
  margin-bottom: 10px;

  cursor: pointer;

  span {
    color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
`;
