import { type FC } from 'react';

import { Col, Typography } from 'antd';
import { type Moment } from 'moment';

import { addDaysToDate } from 'shared/lib/format';

import { WeekDays } from '../constants';
import { ScheduleHeadCol, ScheduleHeadRow } from './styles';

const { Text } = Typography;

type Props = {
  startDate: Moment;
};

export const ScheduleChooseDateWidget: FC<Props> = ({ startDate }) => {
  return (
    <ScheduleHeadRow justify="space-between" align="stretch">
      <Col span={2} />
      {Object.keys(WeekDays).map((el, ind) => {
        const date = addDaysToDate(startDate, ind).format('DD.MM');

        return (
          <ScheduleHeadCol span={3} key={`schedule-head${date}`}>
            <Text strong>{WeekDays[el as keyof typeof WeekDays]}</Text>
            <Text style={{ display: 'block' }}> {date}</Text>
          </ScheduleHeadCol>
        );
      })}
    </ScheduleHeadRow>
  );
};
