import { Link, useParams } from 'react-router-dom';

import { AdminHealthAssistanceDetailsContainer } from 'entity/health-assistance/ui/health-assistance-details-container/admin-health-assistance-details-container';
import { usePastHomeVisitDetailsQuery } from 'entity/home-visit/hooks/use-past-home-visit-details-query';
import { routePatches } from 'shared/routes';
import { CustomRow } from 'shared/ui/row/custom-row';
import { TextRow } from 'shared/ui/row/text-row';
import { UserFeedback } from 'shared/ui/user-feedback';

import { StyledText } from './styles';

export const AdminPastHomeVisitDetails = () => {
  const { id } = useParams();
  const { pastHomeVisit, isLoading } = usePastHomeVisitDetailsQuery(id);

  if (isLoading) return <div>loading...</div>;
  if (!pastHomeVisit) return <div>not found</div>;

  const { patient, phone, specialization, doctor, doctorId, dateAndTime, extraFacilities, rating, review } =
    pastHomeVisit;

  return (
    <AdminHealthAssistanceDetailsContainer>
      <TextRow title="Пациент" text={patient} />
      <TextRow title="Телефон" text={phone} />
      <TextRow title="Специализация" text={specialization} />
      <CustomRow title="Врач">
        <StyledText>
          <Link to={routePatches.AdminDoctorsPage.path(doctorId)}>{doctor}</Link>
        </StyledText>
      </CustomRow>
      <TextRow title="Дата и время" text={dateAndTime} />
      <TextRow title="Дополнительные услуги" text={extraFacilities} />
      {rating && <UserFeedback rating={rating} review={review} tagTitle="вызов на дом" />}
    </AdminHealthAssistanceDetailsContainer>
  );
};
