import { Col, Row } from 'antd';
import styled from 'styled-components';

export const ScheduleHeadRow = styled(Row)`
  width: 100%;
  height: 65px;
  border-bottom: 0.5px solid #f0f0f0;
`;

export const ScheduleHeadCol = styled(Col)`
  height: inherit;
  border-left: 0.5px solid #f0f0f0;
  padding: 10px;
  text-align: center;
`;
