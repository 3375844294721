import { type FC, useState } from 'react';

import { Col, Empty, Row } from 'antd';

import { useConfig } from 'entity/config/hooks/use-config';
import { useScheduleEventsQuery } from 'entity/schedule/hooks/use-schedule-events-query';
import { ScheduleCreateNewSlotByDoctorButton } from 'feature/schedule-create-new-slot-by-doctor/ui';
import {
  SchedulePageContainer,
  SchedulePageContent,
  SchedulePageHead,
  SchedulePageTitle,
} from 'pages/doctor/schedule/ui/styles';
import { getEndOfTheCurrentWeek, getStartOfTheCurrentWeek } from 'shared/lib/format';
import { EmptyIcon } from 'shared/ui/icons';
import { type Week, WeekPicker } from 'shared/ui/week-picker';
import { ScheduleWidget } from 'widgets/doctor-schedule-widget/ui';

type Props = {
  canCreateOnlineVisitSlot?: boolean;
};

export const SchedulePage: FC<Props> = ({ canCreateOnlineVisitSlot }) => {
  const { config } = useConfig();
  const isCalendarHidden = !config?.INTERNAL_FEATURE_CALENDAR;

  document.title = 'Календарь';

  const [week, setWeek] = useState<Week>({
    firstDay: getStartOfTheCurrentWeek(),
    lastDay: getEndOfTheCurrentWeek(),
  });

  const { scheduleEvents } = useScheduleEventsQuery({
    fromDateTimeIso: week.firstDay.toISOString(),
    toDateTimeIso: week.lastDay.toISOString(),
  });

  return (
    <SchedulePageContainer>
      {isCalendarHidden && (
        <Empty
          style={{ marginTop: 150 }}
          description="Модуль в разработке."
          image={<EmptyIcon height={90} width={90} />}
        />
      )}
      {!isCalendarHidden && (
        <>
          <SchedulePageHead>
            <Row justify="space-between">
              <Col>
                <Row align="middle" gutter={15}>
                  <Col>
                    <SchedulePageTitle>Календарь</SchedulePageTitle>
                  </Col>
                  <Col>
                    <WeekPicker onChange={(value) => setWeek(value)} />
                  </Col>
                </Row>
              </Col>
              <Col>{canCreateOnlineVisitSlot && <ScheduleCreateNewSlotByDoctorButton />}</Col>
            </Row>
          </SchedulePageHead>
          <SchedulePageContent>
            <ScheduleWidget data={scheduleEvents} startDate={week.firstDay} />
          </SchedulePageContent>
        </>
      )}
    </SchedulePageContainer>
  );
};
