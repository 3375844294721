import { getInternalHomeVisitRejectedByPatientStatus } from 'entity/home-visit/mappers/external-rejected-by-patient-home-visit-status-to-internal-rejected-by-patient-home-visit-status-mapper';
import type { InternalHomeVisitRejectedByPatientDetails } from 'entity/home-visit/types/internal-home-visit-rejected-by-patient-details';
import { InternalHomeVisitStatusRejectedByPatientEnum } from 'entity/home-visit/types/internal-home-visit-status-rejected-by-patient-enum';
import { type HomeVisitRefuseResponse } from 'shared/api/rtk-api';
import { formatDateDMAndCallTime, formatDateDMY, formatPhone } from 'shared/lib/format';

export const externalHomeVisitListRejectedByPatientToInternalHomeVisitListRejectedByPatientMapper = (
  response: HomeVisitRefuseResponse,
): InternalHomeVisitRejectedByPatientDetails => {
  const status = getInternalHomeVisitRejectedByPatientStatus(response.status);

  return {
    id: response.id,
    patientName: response.patientName,
    patientPhone: formatPhone(response.patientPhone),
    specialization: response.specialization,
    date: formatDateDMY(response.callDate),
    time: response.callTime,
    dateAndTime: formatDateDMAndCallTime(response.callDate, response.callTime),
    doctor: response.doctorName ?? '',
    patientAddress: response.patientAddress,
    comments: response.cancelComment,
    canBeTakenIntoProcessing: status === InternalHomeVisitStatusRejectedByPatientEnum.New,
    status,
  };
};
