import React, { type ChangeEvent, type FC } from 'react';

import { SearchIcon } from 'shared/ui/icons';

import { SearchInput } from './styles';

interface SearchProps {
  disabled?: boolean;
  searchValue: string;
  searchHandler: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const DoctorChatSearch: FC<SearchProps> = ({ disabled, searchValue, searchHandler }) => {
  return (
    <SearchInput
      disabled={disabled}
      prefix={<SearchIcon style={{ marginRight: 12 }} />}
      type="text"
      value={searchValue}
      placeholder="Введите ФИО"
      onChange={(event) => searchHandler(event)}
    />
  );
};
