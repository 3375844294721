import React, { FC, useCallback, useMemo } from 'react';

import moment, { type Moment } from 'moment';

import { useSpecializationListQuery } from 'entity/specialization/hooks/use-specialization-list-query';
import { ReturnToHomeVisitDetailsButton } from 'feature/admin-return-to-home-visit-details/ui/return-to-home-visit-details-button';
import { Select } from 'shared/ui/select';
import { ChoiceDoctorTimeWidget } from 'widgets/admin-chose-doctor-date-time-widget/ui/choice-doctor-time/choice-doctor-time-widget';
import { HomeVisitPatientInfoHorizontal } from 'widgets/home-visit-patient-info-horisontal/ui';

import { StyledDatePicker, StyledFlex, StyledSpace } from './styles';

interface Props {
  handleSlotPick: (value: { doctorName: string; doctorId: number; time: string; specialization: string }) => void;
  hideChangeDoctorTime: () => void;
  setDate: (date: Moment) => void;
  date: string;
}

export const ChoseDoctorDateTimeWidget: FC<Props> = ({ hideChangeDoctorTime, handleSlotPick, setDate, date }) => {
  const { specializations } = useSpecializationListQuery();
  const [specialization, setSpecialization] = React.useState<string | undefined>(undefined);

  const specializationOptions = useMemo(() => {
    const mappedSpecializations = specializations.map((item) => ({
      value: item,
      label: item,
    }));
    return [{ value: '', label: 'Все специализации' }, ...mappedSpecializations];
  }, [specializations]);

  const specializationChange = useCallback((newSpecialization: unknown) => {
    if (typeof newSpecialization !== 'string') {
      return;
    }
    setSpecialization(newSpecialization);
  }, []);

  const handleDateChange = useCallback(
    (newDate: Moment) => {
      setDate(newDate);
    },
    [setDate],
  );

  return (
    <StyledSpace size={32} direction="vertical">
      <ReturnToHomeVisitDetailsButton onClick={hideChangeDoctorTime} />
      <HomeVisitPatientInfoHorizontal />
      <StyledFlex>
        <Select
          defaultValue={specializationOptions[0]}
          onSelect={specializationChange}
          options={specializationOptions}
        />
        <div>
          <StyledDatePicker onSelect={handleDateChange} value={moment(date)} clearIcon={null} />
        </div>
      </StyledFlex>
      <ChoiceDoctorTimeWidget handleSlotPick={handleSlotPick} specialization={specialization} />
    </StyledSpace>
  );
};
