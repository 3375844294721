import { Checkbox } from 'antd';
import styled from 'styled-components';

import { SelectRow } from 'shared/ui/row/select-row';

export const StyledSelectRow = styled(SelectRow)`
  &.ant-select {
    width: 194px;

    .ant-select-selector {
      padding: 0 15px;
      align-items: center;
    }
    .ant-select-arrow {
      right: 20px;
    }

    .ant-select-selection-placeholder {
      font-size: ${({ theme }) => theme.fontSizes.default};
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-wrapper:hover + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }

  .ant-checkbox-checked:after {
    border: 1px solid ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }
`;
