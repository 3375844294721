import { Image, Input } from 'antd';
import styled from 'styled-components';

import { LoadingOutlined } from '@ant-design/icons';

export const AdminDoctorsPageContainer = styled.div``;

export const AdminDoctorsSearchInput = styled(Input)`
  padding: 8px 16px;
  border-radius: 24px;
  width: 320px;
`;

export const AdminDoctorsTableContainer = styled.div`
  background-color: #fff;
  border-radius: 16px;
  min-height: 60vh;
  padding: 8px;
`;

export const AdminDoctorsTableHeaderText = styled.span`
  font-weight: 700;
`;

export const AdminDoctorsPageTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  margin-top: 32px;
  margin-bottom: 24px;
`;

export const AvatarLoader = styled(LoadingOutlined)`
  color: ${(props) => props.theme.colors.c1};
`;

export const AdminDoctorAvatar = styled(Image)`
  border-radius: 50%;
`;
