import { type FC } from 'react';

import { ScheduleEventType } from 'entity/schedule/constants';
import { InternalScheduleEvent } from 'entity/schedule/types';

import { ClinicAppointmentEventCard } from './clinic-appointment-event-card';
import { HomeVisitEventCard } from './home-visit-event-card';
import { OnlineAppointmentEventCard } from './online-appointment-event-card';
import { SlotEventCard } from './slot-event-card';

type Props = {
  event: InternalScheduleEvent;
};

export const ScheduleCardEvent: FC<Props> = ({ event }) => {
  const { type } = event;

  if (type === ScheduleEventType.HOME) return <HomeVisitEventCard event={event} />;
  if (type === ScheduleEventType.ONLINE) return <OnlineAppointmentEventCard event={event} />;
  if (type === ScheduleEventType.CLINIC) return <ClinicAppointmentEventCard event={event} />;
  if (type === ScheduleEventType.SLOT) return <SlotEventCard event={event} />;

  return null;
};
