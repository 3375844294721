import { Popover, Typography } from 'antd';
import styled from 'styled-components';

export const StyledPopover = styled(Popover)`
  width: '2200px';
`;
export const StyledTypographyTitle = styled(Typography.Title)`
  &&& {
    margin: 0;
    padding: 10px;
  }
`;
