import { useLocation, useNavigate } from 'react-router-dom';

import { useConfig } from 'entity/config/hooks/use-config';
import { MenuUrlPaths } from 'entity/health-assistance/constants';
import type { AppointmentTypes } from 'entity/health-assistance/types';
import { routes } from 'shared/routes';
import { UnderlinedMenu, UnderlinedMenuProps } from 'shared/ui/underlined-menu';

const appointmentMenuLabels: { label: string; key: AppointmentTypes }[] = [
  { label: 'Вызовы на дом', key: MenuUrlPaths.HomeVisit },
  { label: 'Онлайн-приемы', key: MenuUrlPaths.OnlineAppointment },
];

const filteredMenuLabels: { label: string; key: AppointmentTypes }[] = [
  { label: 'Вызовы на дом', key: MenuUrlPaths.HomeVisit },
];

export const HealthAssistanceRejectedByPatientNavigationMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { config } = useConfig();
  const isOnlineAppointmentsHidden = !config?.INTERNAL_FEATURE_ONLINE_VISIT;

  const selectedRouteFromUrl = location.pathname.replace(/\/page\/.*$/g, '');
  const getSelectedKeyFromUrl = (route: string): AppointmentTypes => {
    switch (route) {
      case routes.AdminRejectedByPatientHomeVisitList:
        return MenuUrlPaths.HomeVisit;
      case routes.AdminRejectedByPatientOnlineAppointmentList:
        return MenuUrlPaths.OnlineAppointment;
      default:
        throw new Error('Invalid route');
    }
  };

  const getUriFromSelectedKey = (key: AppointmentTypes): string => {
    switch (key) {
      case MenuUrlPaths.HomeVisit:
        return routes.AdminRejectedByPatientHomeVisitList;
      case MenuUrlPaths.OnlineAppointment:
        return routes.AdminRejectedByPatientOnlineAppointmentList;
      default:
        throw new Error('Invalid key');
    }
  };

  const selectedKeyFromUrl = getSelectedKeyFromUrl(`${selectedRouteFromUrl}`);

  const handleMenuClick: UnderlinedMenuProps['onClick'] = (e) => {
    navigate(`${getUriFromSelectedKey(e.key as AppointmentTypes)}`);
  };

  return (
    <UnderlinedMenu
      onClick={handleMenuClick}
      items={isOnlineAppointmentsHidden ? filteredMenuLabels : appointmentMenuLabels}
      selectedKeys={[selectedKeyFromUrl]}
    />
  );
};
