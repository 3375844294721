import { type FC } from 'react';

import type { ButtonProps } from 'antd/lib/button/button';
import { useNavigate } from 'react-router-dom';

import { routePatches } from 'shared/routes';
import { ReturnButton } from 'shared/ui/return-button';

interface Props extends ButtonProps {}

export const AdminReturnToPastOnlineAppointmentsListButton: FC<Props> = ({ ...props }) => {
  const navigate = useNavigate();

  const onClickHandler = () => navigate(routePatches.AdminPastOnlineAppointmentsList.path());

  return (
    <ReturnButton {...props} onClick={onClickHandler}>
      История приемов
    </ReturnButton>
  );
};
