import { useAdminVisitsControllerGetPastHomeVisitsQuery } from 'shared/api/rtk-api';

import { externalPastHomeVisitToInternalPastHomeVisitMapper } from '../mappers/external-past-home-visits-list-to-internal-past-home-visits-list-mapper';

interface PastHomeVisitDetailQuery {
  page: number;
  pageSize: number;
}

export const usePastHomeVisitsListQuery = ({ page, pageSize }: PastHomeVisitDetailQuery) => {
  const { data, ...others } = useAdminVisitsControllerGetPastHomeVisitsQuery({
    limit: pageSize,
    offset: (page - 1) * pageSize || undefined,
  });

  const pastHomeVisits = data?.data.map(externalPastHomeVisitToInternalPastHomeVisitMapper);

  return {
    pastHomeVisits,
    total: data?.total,
    ...others,
  };
};
