import { Card } from 'antd';
import styled from 'styled-components';

export const DoctorHealthAssistanceDetailsStyledCard = styled(Card)`
  background-color: transparent;
  border-style: hidden;
  padding: 32px;

  .ant-card-body {
    padding: 0;
  }
`;

export const AdminHealthAssistanceDetailsStyledCard = styled(Card)`
  background-color: transparent;
  border-style: hidden;
  margin-top: 20px;
  .ant-card-body {
    padding: 0;
  }
`;
