import { FC, useCallback } from 'react';

import { SegmentedValue } from 'antd/lib/segmented';
import { useLocation, useNavigate } from 'react-router-dom';

import { Segmented } from 'shared/ui/segment';

type Props = {
  pages: { path: string; name: string }[];
};

export const Navigation: FC<Props> = ({ pages }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pageName = pages.find(({ path }) => pathname.startsWith(path))?.name;

  const onSegmentedChange = useCallback(
    (value: SegmentedValue) => {
      const page = pages.find((el) => el.name === value);
      navigate(page?.path ?? '#');
    },
    [pages, navigate],
  );

  return <Segmented value={pageName} onChange={onSegmentedChange} options={pages.map(({ name }) => name)} block />;
};
