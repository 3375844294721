import { FC, PropsWithChildren } from 'react';

interface PublicComponentProps {
  component?: FC;
  layout: FC<PropsWithChildren>;
}

export const PublicComponent: FC<PropsWithChildren<PublicComponentProps>> = ({
  component: Component,
  layout: Layout,
  children,
}) => <Layout>{Component ? <Component /> : children}</Layout>;
