import { type FC } from 'react';

import { useDaily, useLocalSessionId } from '@daily-co/daily-react';
import { useParticipantAudioVideoTrack } from 'entity/daily/hooks/use-participant-audio-video-track';
import { MutedOnlineAppointmentCameraIcon, OnlineAppointmentCameraIcon } from 'shared/ui/icons';

import { ActionButton } from './styles';

export const DoctorOnlineAppointmentVideoButton: FC = () => {
  const callFrame = useDaily();
  const participantId = useLocalSessionId();

  const { mutedVideo } = useParticipantAudioVideoTrack({
    participantId,
  });

  const toggleVideo = () => callFrame?.setLocalVideo(mutedVideo);

  return (
    <ActionButton shape="round" onClick={toggleVideo}>
      {!mutedVideo ? <OnlineAppointmentCameraIcon /> : <MutedOnlineAppointmentCameraIcon />}
    </ActionButton>
  );
};
