import { FC, PropsWithChildren } from 'react';

import { StyledButton, type StyledButtonProps } from 'shared/ui/button/styles';

export const Button: FC<PropsWithChildren<StyledButtonProps>> = ({ children, ...props }) => {
  return (
    <StyledButton shape="round" {...props}>
      {children}
    </StyledButton>
  );
};
