import { Space } from 'antd';
import styled from 'styled-components';

export const PageTitleWrapper = styled(Space)`
  padding: 20px 0;
`;

export const PageTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: 0;
`;

export const DurationBadge = styled.div`
  background-color: ${({ theme }) => theme.colors.c4};
  padding: 10px 16px;
  border-radius: ${({ theme }) => theme.radii.large};
  display: flex;
  justify-content: center;

  span {
    line-height: 16px;
    font-size: ${({ theme }) => theme.fontSizes.default};
  }
`;
