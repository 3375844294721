/* eslint @typescript-eslint/no-misused-promises: 0 */
/* eslint @typescript-eslint/no-floating-promises: 0 */

import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Alert, Col, Form, Row } from 'antd';

import { ILoginFormFields, LoginFormFieldsEnum } from 'entity/auth/api/types';
import { refreshTokenThunk } from 'entity/auth/store/thunks/refresh-token.thunk';
import { tokenThunk } from 'entity/auth/store/thunks/token.thunk';
import {
  LoginFormButton,
  LoginFormContainer,
  LoginFormForgotPasswordLink,
  LoginFormInputPassword,
  LoginTitle,
} from 'pages/auth/login/ui/styles';
import { useInternalAuthControllerLoginMutation } from 'shared/api/enchance-endpoints';
import { rtkQueryApi } from 'shared/api/rtk-api';
import { useAppDispatch } from 'shared/lib/store';
import { AppInput } from 'shared/ui/input2';
import { errorNotification } from 'shared/utils/notifications';

export const LoginPage: FC = () => {
  const dispatch = useAppDispatch();

  const [login, { isLoading, isError }] = useInternalAuthControllerLoginMutation();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<ILoginFormFields>();
  const { email, password } = watch();

  const onSubmit: SubmitHandler<ILoginFormFields> = (values) => {
    login(values).then((res) => {
      const { data } = res as unknown as { data: { accessToken: string; refreshToken: string } };
      if (!data) {
        errorNotification('Ошибка авторизации');
        return;
      }
      localStorage.setItem('token', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      dispatch(rtkQueryApi.util.invalidateTags(['Auth (Internal)']));
      dispatch(tokenThunk(data.accessToken));
      dispatch(refreshTokenThunk(data.refreshToken));
    });
  };

  return (
    <LoginFormContainer name="login">
      <Form.Item>
        <LoginTitle>Авторизация</LoginTitle>
      </Form.Item>
      <Form.Item
        validateTrigger="onBlur"
        validateStatus={errors?.[LoginFormFieldsEnum.EMAIL] ? 'error' : ''}
        help={errors?.[LoginFormFieldsEnum.EMAIL] && 'Введите email!'}
      >
        <Controller
          name={LoginFormFieldsEnum.EMAIL}
          control={control}
          rules={{ required: true }}
          render={({ field }) => <AppInput {...field} size="large" placeholder="email" allowClear />}
        />
      </Form.Item>
      <Form.Item
        validateStatus={errors?.[LoginFormFieldsEnum.PASSWORD] && 'error'}
        help={
          <Row justify="space-between">
            <Col>{errors?.[LoginFormFieldsEnum.PASSWORD] && 'Введите пароль!'}</Col>
            <Col>
              <LoginFormForgotPasswordLink to="forgot-password" state={{ email }}>
                Забыли пароль ?
              </LoginFormForgotPasswordLink>
            </Col>
          </Row>
        }
      >
        <Controller
          name={LoginFormFieldsEnum.PASSWORD}
          control={control}
          rules={{ required: true }}
          render={({ field }) => <LoginFormInputPassword {...field} size="large" placeholder="пароль" />}
        />
      </Form.Item>

      <Form.Item>
        <LoginFormButton
          htmlType="submit"
          loading={isLoading}
          disabled={isLoading || !email || !password}
          onClick={handleSubmit(onSubmit)}
        >
          Войти
        </LoginFormButton>
      </Form.Item>

      {isError && <Alert message="Неверный логин или пароль" type="error" closable banner />}
    </LoginFormContainer>
  );
};
