import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAuthUserPayload } from 'entity/auth/api/types';

type InitialState = {
  isLogin: boolean | null;
  user: IAuthUserPayload | null;
  token: string | null;
  refreshToken: string | null;
};

const initialState: InitialState = {
  user: null,
  isLogin: null,
  token: null,
  refreshToken: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<IAuthUserPayload | null>) => {
      // eslint-disable-next-line no-param-reassign
      state.user = action.payload;
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isLogin = action.payload;
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      // eslint-disable-next-line no-param-reassign
      state.token = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string | null>) => {
      // eslint-disable-next-line no-param-reassign
      state.refreshToken = action.payload;
    },
  },
});

export const { setIsLogin, setCurrentUser, setToken, setRefreshToken } = authSlice.actions;

export const authReducer = authSlice.reducer;
