import { type OnlineVisitAdminAppointmentResponseDto } from 'shared/api/rtk-api';
import { formatDateDMY, formatPhone, formatPrice, getTimeFromDate } from 'shared/lib/format';

import { InternalOnlineAppointment } from '../types/internal-online-appointment';
import { InternalOnlineAppointmentStatusEnum } from '../types/internal-online-appointment-status-enum';
import { getInternalOnlineAppointmentStatus } from './external-online-appointment-status-to-internal-online-appointment-status-mapper';

export const externalOnlineAppointmentToInternalOnlineAppointmentListMapper = (
  onlineAppointment: OnlineVisitAdminAppointmentResponseDto,
): InternalOnlineAppointment => {
  const internalOnlineAppointmentStatus = getInternalOnlineAppointmentStatus(onlineAppointment.status);

  const isInUseByAnotherAdmin =
    internalOnlineAppointmentStatus === InternalOnlineAppointmentStatusEnum.AssignedByAnotherAdmin ||
    internalOnlineAppointmentStatus === InternalOnlineAppointmentStatusEnum.ProcessingByAnotherAdmin;

  const isAccepted = internalOnlineAppointmentStatus === InternalOnlineAppointmentStatusEnum.AcceptedByAdmin;

  const canBeChanged = internalOnlineAppointmentStatus !== InternalOnlineAppointmentStatusEnum.NewForAdmin;

  const appointmentTime = `${getTimeFromDate(onlineAppointment.startDateTimeIso)} - ${getTimeFromDate(onlineAppointment.endDateTimeIso)}`;
  const onlineAppointmentSlotId = onlineAppointment.mainDoctorInternalOnlineVisitSlotId;

  return {
    id: onlineAppointment.internalOnlineVisitAppointmentId,
    onlineAppointmentSlotId,
    patient: onlineAppointment.patientFullName,
    phone: formatPhone(onlineAppointment.patientPhone),
    specialization: onlineAppointment.doctorOnlineVisitSpecialization,
    time: onlineAppointmentSlotId ? appointmentTime : '',
    date: formatDateDMY(onlineAppointment.startDateTimeIso),
    doctor: onlineAppointment.doctorFullName,
    doctorId: onlineAppointment.internalOnlineVisitDoctorId,
    status: internalOnlineAppointmentStatus,
    rating: onlineAppointment.patientFeedbackRating,
    review: onlineAppointment.patientFeedbackReview,
    price: formatPrice(onlineAppointment.onlineAppointmentPrice / 100),
    canBeProcessed: onlineAppointment.canBeProcessed,
    canBeRejected: onlineAppointment.canBeRejected,
    canBeCanceled: onlineAppointment.canBeCancelled,
    canBeAssigned: onlineAppointment.canBeAssigned,
    canBeAccepted: onlineAppointment.canBeAccepted,
    isInUseByAnotherAdmin,
    canBeChanged,
    isAccepted,
  };
};
