import { FC, useEffect, useState } from 'react';

import { Button, Col, Row, Typography } from 'antd';

import { useDeactivateDoctorAccountMutation } from 'entity/admin/api';
import { AddAccountModalText, AddAccountModalTitle } from 'pages/admin/doctors/ui/add-account-modal/style';
import { DeactivateModalContainer } from 'pages/admin/doctors/ui/deactivate-account-button/styles';
import { AppButton } from 'shared/ui/button2';
import { GeneralModalCancelButton } from 'shared/ui/general-question-modal/style';
import { AppModal } from 'shared/ui/modal';
import { errorNotification, successNotification } from 'shared/utils/notifications';

type Props = {
  doctorId: number;
  disabled?: boolean;
};

export const DeactivateAccountButton: FC<Props> = ({ doctorId, disabled }) => {
  const [deactivate, { isError, isLoading, isSuccess }] = useDeactivateDoctorAccountMutation();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  useEffect(() => {
    if (isError) {
      errorNotification('Не получилось деактивировать аккаунт!');
    } else if (isSuccess) {
      successNotification('Аккаунт успешно деактивирован!');
    }
  }, [isError, isSuccess]);

  const clickHandler = () => {
    /* eslint-disable-next-line  @typescript-eslint/no-floating-promises */
    deactivate({ doctorId });
    setIsModalOpened(false);
  };

  return (
    <>
      <AppModal visible={isModalOpened} onClose={() => setIsModalOpened(false)} key={`deactivate${doctorId}`}>
        <DeactivateModalContainer>
          <Row gutter={[24, 24]} justify="center" align="middle">
            <Col span={24}>
              <AddAccountModalTitle>Вы уверены, что хотите деактивировать аккаунт врача ?</AddAccountModalTitle>
            </Col>
            <Col span={24}>
              <AddAccountModalText>
                Данные для авторизации пользователя в Личном кабинете будут удалены навсегда.
              </AddAccountModalText>
            </Col>

            <Col span={24}>
              <Row gutter={[16, 0]} justify="center" align="middle">
                <Col span={12}>
                  {/* eslint-disable-next-line deprecation/deprecation */}
                  <AppButton onClick={clickHandler}>Деактивировать</AppButton>
                </Col>
                <Col span={8}>
                  <GeneralModalCancelButton type="text" onClick={() => setIsModalOpened(false)}>
                    <Typography.Text type="secondary">Отменить</Typography.Text>
                  </GeneralModalCancelButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </DeactivateModalContainer>
      </AppModal>
      <Button
        type="link"
        onClick={() => setIsModalOpened(true)}
        disabled={!!disabled || isLoading}
        loading={isLoading}
        style={{ textAlign: 'left', padding: 0, height: 'fit-content' }}
      >
        Деактивировать аккаунт
      </Button>
    </>
  );
};
