import { useEffect, useMemo, useState } from 'react';

import debounce from 'lodash.debounce';

// eslint-disable-next-line @typescript-eslint/ban-types
export const useSearch = <T extends {}>(initialSearch: T) => {
  const [searchTerm, setSearchTerm] = useState<T>(initialSearch);
  const [searchValue, setSearchValue] = useState<T>(initialSearch);

  const debouncedSave = useMemo(() => debounce((newValue: T) => setSearchTerm(newValue), 500), []);

  const handleClearSearch = () => {
    setSearchTerm({} as T);
    setSearchValue({} as T);
  };

  useEffect(() => {
    debouncedSave(searchValue);
  }, [debouncedSave, searchValue]);

  useEffect(
    () => () => {
      debouncedSave.cancel();
    },
    [debouncedSave],
  );

  return { searchTerm, searchValue, setSearchValue, setSearchTerm, handleClearSearch };
};
