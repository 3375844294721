import { useCallback } from 'react';

import { useOnlineVisitAppointmentControllerAdminAssignAppointmentMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  id?: number;
  onSuccess?: () => void;
}
export const useOnlineAppointmentAssignAppointmentByAdminMutation = () => {
  const [mutation] = useOnlineVisitAppointmentControllerAdminAssignAppointmentMutation();
  const action = useCallback(
    ({ id, onSuccess }: MutationHookOptions) => {
      if (!id) {
        errorNotification('Невозможно взять в работу онлайн визита');
        return;
      }
      mutation({
        internalOnlineVisitAppointmentId: id,
      })
        .unwrap()
        .then(() => {
          successNotification('Онлайн-визит взят в работу успешно');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при взятии в работу онлайн-визита');
        });
    },
    [mutation],
  );
  return [action];
};
