import { Col, Row } from 'antd';
import styled from 'styled-components';

import { LinkButton } from 'shared/ui/link-button';

export const BackButton = styled(LinkButton)`
  color: #969aa0 !important;
  margin-bottom: 36px;
`;

export const NavigationContainer = styled(Row)`
  height: 80px;
  width: 220px;
`;
export const NavigationContent = styled(Col)`
  height: 40px;
  width: 220px;
`;
