import { FC } from 'react';

import { HealthAssistanceRejectedByPatientNavigationMenu } from 'feature/health-assistance-rejected-by-patient-navigation';
import { ListLayout } from 'shared/ui/list-layout';
import { OnlineAppointmentRejectedByPatientTable } from 'widgets/admin-online-appointment-rejected-by-patient-table/ui/online-appointment-rejected-by-patient-table';

export const AdminOnlineAppointmentRejectedByPatientListPage: FC = () => {
  return (
    <ListLayout
      title="Заявки на отмену приема"
      Menu={HealthAssistanceRejectedByPatientNavigationMenu}
      Table={OnlineAppointmentRejectedByPatientTable}
    />
  );
};
