import { useAudioTrack, useVideoTrack } from '@daily-co/daily-react';

type Props = { participantId: string };

export const useParticipantAudioVideoTrack = ({ participantId }: Props) => {
  const mutedVideo = useVideoTrack(participantId).isOff;
  const mutedAudio = useAudioTrack(participantId).isOff;

  return { mutedVideo, mutedAudio };
};
