import styled from 'styled-components';

export const AdminChangePasswordPageContainer = styled.div`
  margin-top: 10px;
  width: 320px;
`;

export const AdminChangePasswordPageTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 32px;
`;
