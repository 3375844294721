import { FC, useCallback, useState } from 'react';

import { Avatar, Col, Collapse, Menu, Row, Skeleton, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { DownOutlined } from '@ant-design/icons';
import { useCurrentUserQuery } from 'entity/auth/hooks/use-current-user-query';
import { rtkQueryApi, useInternalAuthControllerLogoutMutation } from 'shared/api/rtk-api';
import { useAppDispatch } from 'shared/lib/store';
import { routePatches } from 'shared/routes';
import { GeneralQuestionModal } from 'shared/ui/general-question-modal';
import { AvatarIcon } from 'shared/ui/icons';
import { LinkButton } from 'shared/ui/link-button';
import { StyledListItem, UserMenuArrow, UserMenuContainer } from 'widgets/user-menu/styles';

export const UserMenu: FC = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [logout, { isLoading }] = useInternalAuthControllerLogoutMutation();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>();
  const [isLogoutModalOpened, setIsLogoutModalOpened] = useState<boolean>(false);
  const { currentUser } = useCurrentUserQuery();

  const changeHandler = useCallback(() => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  const logoutHandler = useCallback(() => {
    setIsLogoutModalOpened(false);
    logout()
      .unwrap()
      .finally(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(rtkQueryApi.util.resetApiState());
        navigate(routePatches.Auth.path());
      });
  }, [logout, dispatch, navigate]);

  const logoutModalCloseHandler = useCallback(() => {
    setIsOpened(false);
    setIsLogoutModalOpened(false);
  }, []);

  if (!currentUser) {
    return null;
  }

  return (
    <div
      style={{ maxHeight: '72px' }}
      onMouseEnter={() => {
        setIsOpened(true);
      }}
      onMouseLeave={() => {
        setIsOpened(false);
      }}
    >
      <UserMenuContainer
        bordered={false}
        isOpened={isOpened}
        onChange={changeHandler}
        activeKey={isOpened ? '1' : undefined}
      >
        <Collapse.Panel
          showArrow={false}
          header={
            <Row gutter={[16, 0]} justify="space-between" align="middle">
              <Col span={5}>
                {currentUser.avatar ? (
                  <Avatar src={currentUser.avatar} size={40} />
                ) : (
                  <Avatar icon={<AvatarIcon />} size={40} />
                )}
              </Col>
              <Col span={14}>
                <StyledListItem
                  title={
                    <Typography.Text strong>
                      {currentUser.name
                        .trim()
                        .split(' ')
                        .map((el, i) => (i === 0 ? el : `${el[0].toUpperCase()}.`))
                        .join(' ')}
                    </Typography.Text>
                  }
                  description={<Typography.Text ellipsis>{currentUser.description}</Typography.Text>}
                />
              </Col>
              <Col span={4}>
                <UserMenuArrow size={25} icon={<DownOutlined />} />
              </Col>
            </Row>
          }
          key="1"
        >
          <Menu
            selectable={false}
            selectedKeys={selectedItem ? [selectedItem] : []}
            style={{ border: 'none', borderRadius: '16px' }}
            mode="vertical"
            items={[
              {
                key: '1',
                onMouseEnter: () => {
                  setSelectedItem('1');
                },
                onMouseLeave: () => {
                  setSelectedItem(undefined);
                },
                label: (
                  <LinkButton style={{ color: '#000' }} to="/user/change-password">
                    Изменить пароль
                  </LinkButton>
                ),
              },
              {
                key: '2',
                onMouseEnter: () => {
                  setSelectedItem('2');
                },
                onMouseLeave: () => {
                  setSelectedItem(undefined);
                },
                label: (
                  <>
                    <GeneralQuestionModal
                      question="Вы уверены что хотите выйти?"
                      okText="Выйти"
                      cancelText="Отменить"
                      visible={isLogoutModalOpened}
                      onOk={logoutHandler}
                      onClose={logoutModalCloseHandler}
                    />
                    {isLoading ? (
                      <Skeleton.Button active block />
                    ) : (
                      <LinkButton style={{ color: '#000' }} to="#" onClick={() => setIsLogoutModalOpened(true)}>
                        Выйти
                      </LinkButton>
                    )}
                  </>
                ),
              },
            ]}
          />
        </Collapse.Panel>
      </UserMenuContainer>
    </div>
  );
};
