import { FC } from 'react';

import type { AppointmentStatusLabel } from 'entity/health-assistance/types/appointment-status-label';
import { InternalOnlineAppointmentStatusEnum } from 'entity/online-appointment/types/internal-online-appointment-status-enum';

import { StyledTag, StyledTagText } from './styles';

const calculateText = (type?: InternalOnlineAppointmentStatusEnum): AppointmentStatusLabel => {
  switch (type) {
    case InternalOnlineAppointmentStatusEnum.NewForAdmin:
      return 'Новая';
    case InternalOnlineAppointmentStatusEnum.AssignedByCurrentAdmin:
      return 'В обработке';
    case InternalOnlineAppointmentStatusEnum.AssignedByAnotherAdmin:
      return 'В работе';
    case InternalOnlineAppointmentStatusEnum.AcceptedByAdmin:
      return 'Подтверждена';
    default:
      throw new Error('Unknown tag type');
  }
};

export interface TagProps {
  appointmentStatus: InternalOnlineAppointmentStatusEnum;
}

export const OnlineAppointmentStatusTag: FC<TagProps> = ({ appointmentStatus }) => {
  const text = calculateText(appointmentStatus);
  return (
    <StyledTag $appointmentStatus={appointmentStatus}>
      <StyledTagText $type={appointmentStatus}>{text}</StyledTagText>
    </StyledTag>
  );
};
