import { type FC, type ReactNode, useEffect } from 'react';

import Pubnub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import { useCurrentUserQuery } from 'entity/auth/hooks/use-current-user-query';
import { useConfig } from 'entity/config/hooks/use-config';
import { useDoctorConversationListQuery } from 'entity/doctor-chat/hooks/use-doctor-conversation-list-query';
import { UsePubnubSdk } from 'entity/pubnub/hook/use-pubnub-sdk';
import { rtkQueryApi } from 'shared/api/rtk-api';
import Emitter from 'shared/lib/emitter';
import { useAppDispatch } from 'shared/lib/store';

type Props = {
  children: ReactNode;
};

export const ConversationPubNubWrapper: FC<Props> = ({ children }: Props) => {
  const { currentUser } = useCurrentUserQuery();
  const { config } = useConfig();
  const dispatch = useAppDispatch();

  const { pubNubSDK } = UsePubnubSdk({
    pubNubUserId: currentUser?.pubNubUserId,
    publishKey: config?.INTERNAL_PUBNUB_PUBLISH_KEY,
    subscribeKey: config?.INTERNAL_PUBNUB_SUBSCRIBE_KEY,
  });

  const { doctorConversations } = useDoctorConversationListQuery();

  useEffect(() => {
    if (!pubNubSDK) return;

    if (!doctorConversations?.length) return;

    const channelsIds = doctorConversations.map((chats) => chats.eTag);

    const timer = setTimeout(() => {
      pubNubSDK.subscribe({ channels: channelsIds });

      pubNubSDK.addListener({
        message(event: Pubnub.MessageEvent) {
          Emitter.emit('newMessage', event);
        },
      });
    }, 1000);

    return () => {
      pubNubSDK.unsubscribeAll();
      clearTimeout(timer);
    };
  }, [pubNubSDK, doctorConversations]);

  useEffect(() => {
    const func = (event: Pubnub.MessageEvent) => {
      if (event.publisher !== currentUser?.pubNubUserId) {
        // TODO: Fix the function to properly clean up from the emitter.
        // infoNotification(`Новое сообщение`);
      }
      setTimeout(() => {
        dispatch(rtkQueryApi.util.invalidateTags(['Doctor-Panel']));
      }, 1000);
    };
    Emitter.on('newMessage', func);
    return () => {
      Emitter.off('newMessage', func);
    };
  }, [currentUser?.pubNubUserId, dispatch]);

  if (!pubNubSDK) return null;

  return <PubNubProvider client={pubNubSDK}>{children}</PubNubProvider>;
};
