import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { MoreOutlined } from '@ant-design/icons';
import { IAdminRecordingInterface } from 'entity/admin/api/types/recordings';
import { AdminRecordingsTableHeaderText } from 'widgets/recordings-table/styles';

export const AdminDoctorTableData: ColumnsType<IAdminRecordingInterface> = [
  {
    title: <AdminRecordingsTableHeaderText>ФИО пациента</AdminRecordingsTableHeaderText>,
    dataIndex: 'patient',
    key: 'patientName',
    width: '20%',
  },
  {
    title: <AdminRecordingsTableHeaderText>Услуга</AdminRecordingsTableHeaderText>,
    dataIndex: 'serviceName',
    key: 'serviceName',
    width: '20%',
  },
  {
    title: <AdminRecordingsTableHeaderText>Дата визита</AdminRecordingsTableHeaderText>,
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: <AdminRecordingsTableHeaderText>Время визита</AdminRecordingsTableHeaderText>,
    dataIndex: 'time',
    key: 'time',
  },
  {
    title: <AdminRecordingsTableHeaderText>Врач</AdminRecordingsTableHeaderText>,
    dataIndex: 'doctor',
    key: 'doctor',
    width: '25%',
  },
  {
    key: 'actions',
    width: 80,
    align: 'center',

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render: (_, record) => (
      <Space size="middle">
        <Button type="text" icon={<MoreOutlined />} />
      </Space>
    ),
  },
];
