import { getInternalHomeVisitRejectedByPatientStatus } from 'entity/home-visit/mappers/external-rejected-by-patient-home-visit-status-to-internal-rejected-by-patient-home-visit-status-mapper';
import type { InternalHomeVisitRejectedByPatientList } from 'entity/home-visit/types/internal-home-visit-rejected-by-patient-list';
import { type HomeVisitRefuseResponse } from 'shared/api/rtk-api';
import { formatDateDMY, formatPhone } from 'shared/lib/format';

export const externalHomeVisitListRejectedByPatientToInternalHomeVisitListRejectedByPatientMapper = (
  response: HomeVisitRefuseResponse,
): InternalHomeVisitRejectedByPatientList => {
  const status = getInternalHomeVisitRejectedByPatientStatus(response.status);

  return {
    id: response.id,
    patient: response.patientName,
    phone: formatPhone(response.patientPhone),
    specialization: response.specialization,
    date: formatDateDMY(response.callDate),
    time: response.callTime,
    doctor: response.doctorName ?? '',
    address: response.patientAddress,
    status,
  };
};
