import styled from 'styled-components';

export const ScheduleSlotContainer = styled.div<{
  disabled?: boolean;
  isSlot?: boolean;
}>`
  padding: 5px 7px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: ${({ theme, isSlot }) =>
    isSlot ? theme.colors.palette.operaGlass : theme.colors.palette.whiteSmoke};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.25)' : 'none')};
`;

export const SlotLine = styled.div<{ strong?: boolean }>`
  line-height: 12px;
  font-size: ${({ theme }) => theme.fontSizes.theSmallest};
  font-weight: ${({ strong, theme }) => (strong ? theme.fontWeights.bold : theme.fontWeights.regular)};
`;
