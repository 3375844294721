import { FC, useState } from 'react';

import { ButtonProps } from 'antd';

import { useDeletePushNotificationByManagerMutation } from 'entity/marketing/hooks/use-delete-push-notification-by-manager-mutation';
import { CustomButton } from 'shared/ui/custom-button';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

import { StyledCustomModal } from './styles';

type Props = {
  notificationId: number;
};
export const MarketingRemovePushNotificationButton: FC<Props & ButtonProps> = ({ notificationId, ...props }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleCloseModal = () => setIsModalVisible(false);

  const handleOpenModal = () => setIsModalVisible(true);

  const [deleteNotification] = useDeletePushNotificationByManagerMutation();

  const onSubmit = () => {
    deleteNotification({ id: notificationId });
    handleCloseModal();
  };

  return (
    <>
      <DropdownMenuButton onClick={handleOpenModal} {...props}>
        <TypographyText>Удалить</TypographyText>
      </DropdownMenuButton>

      <StyledCustomModal
        title="Вы уверены, что хотите удалить пуш-уведомление?"
        description="Данные будут удалены безвозвратно."
        visible={isModalVisible}
        onClose={handleCloseModal}
        actionButtons={[
          <CustomButton key={1} onClick={handleCloseModal} $buttonType="declineWhite">
            Отмена
          </CustomButton>,
          <CustomButton key={2} onClick={onSubmit} $buttonType="submit">
            Удалить
          </CustomButton>,
        ]}
      />
    </>
  );
};
