import React, { type FC, PropsWithChildren } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { UserRolesEnum } from 'entity/auth/api/types';
import { useCurrentUserQuery } from 'entity/auth/hooks/use-current-user-query';

interface ProtectedRouteProps extends PropsWithChildren {
  allowedRoles?: UserRolesEnum[];
  redirectPath?: string;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ allowedRoles, redirectPath = '/auth' }) => {
  const { currentUser } = useCurrentUserQuery();
  const currentUserRole = currentUser?.role;
  const needRoles = Boolean(allowedRoles?.length);
  const hasNeededRole =
    allowedRoles?.length === 0 || Boolean(currentUserRole && allowedRoles?.includes(currentUserRole));
  const accessDenied = needRoles && !hasNeededRole;
  if (!currentUser || accessDenied) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};
