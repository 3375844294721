import { type FC, type JSX } from 'react';

import type { InternalOnlineAppointment } from 'entity/online-appointment/types/internal-online-appointment';
import { OnlineAppointmentApproveByAdminDropdownMenuButton } from 'feature/online-appointment-approve-by-admin/ui/online-appointment-approve-by-admin-dropdown-menu-button';
import { OnlineAppointmentCancelAppointmentByAdminDropdownMenuButton } from 'feature/online-appointment-cancel-appointment-by-admin/ui/online-appointment-cancel-appointment-by-admin-dropdown-menu-button';
import { OnlineAppointmentRejectAppointmentByAdminDropdownMenuButton } from 'feature/online-appointment-reject-appointment-by-admin/online-appointment-reject-appointment-by-admin-dropdown-menu-button';
import { OnlineAppointmentToTakeInEditByAdminDropdownMenuButton } from 'feature/online-appointment-to-take-in-edit-by-admin/online-appointment-to-take-in-edit-by-admin-dropdown-menu-button';
import { OnlineAppointmentToTakeInProgressByAdminDropdownMenuButton } from 'feature/online-appointment-to-take-in-progress-by-admin/online-appointment-to-take-in-progress-by-admin-dropdown-menu-button';
import { DropDownMenu } from 'shared/ui/dropdown-menu';
import { VerticalDotsIcon } from 'shared/ui/icons';

interface Props {
  onlineAppointment: InternalOnlineAppointment;
}

export const OnlineAppointmentTableDropdownMenu: FC<Props> = ({ onlineAppointment }) => {
  const { canBeCanceled, canBeRejected, canBeAssigned, canBeAccepted, canBeChanged, id } = onlineAppointment;

  const canBeApproved = canBeAccepted && !canBeCanceled;

  const getNodeItems = (): JSX.Element[] => {
    const buttonsArray = [];
    if (canBeCanceled) {
      buttonsArray.push(
        <OnlineAppointmentCancelAppointmentByAdminDropdownMenuButton onlineAppointmentId={id} key={1} />,
      );
    }
    if (canBeRejected) {
      buttonsArray.push(
        <OnlineAppointmentRejectAppointmentByAdminDropdownMenuButton onlineAppointmentId={id} key={2} />,
      );
    }
    if (canBeAssigned) {
      buttonsArray.push(
        <OnlineAppointmentToTakeInProgressByAdminDropdownMenuButton onlineAppointmentId={id} key={3} />,
      );
    }
    if (canBeChanged) {
      buttonsArray.push(<OnlineAppointmentToTakeInEditByAdminDropdownMenuButton onlineAppointmentId={id} key={4} />);
    }

    if (canBeApproved) {
      buttonsArray.push(
        <OnlineAppointmentApproveByAdminDropdownMenuButton onlineAppointmentData={onlineAppointment} key={5} />,
      );
    }
    return buttonsArray;
  };

  const nodeItems = getNodeItems();
  return nodeItems.length > 0 ? (
    <DropDownMenu placement="bottomRight" nodeItems={nodeItems}>
      <VerticalDotsIcon />
    </DropDownMenu>
  ) : null;
};
