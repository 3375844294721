import { GenderApiType } from 'shared/api/rtk-api';

import { SexOptions } from '../constants';

const sexOptionsMap = new Map<GenderApiType, SexOptions>([
  [GenderApiType.Male, SexOptions.MALE],
  [GenderApiType.Female, SexOptions.FEMALE],
]);
export const getInternalPushNotificationSexOptions = (sexOption: GenderApiType): SexOptions => {
  const internalSexOption = sexOptionsMap.get(sexOption);
  if (!internalSexOption) throw new Error(`Unknown sex option: ${sexOption}`);
  return internalSexOption;
};
