import { FC } from 'react';

import { Space } from 'antd';

import { HeaderLayout } from 'shared/ui/header-layout';
import { LinkButton } from 'shared/ui/link-button';
import { Logo } from 'shared/ui/logo';
import { UserMenu } from 'widgets/user-menu';

export const MarketingHeader: FC = () => {
  return (
    <HeaderLayout
      left={
        <LinkButton to="/">
          <Logo />
        </LinkButton>
      }
      middle={<></>}
      right={
        <Space size="large">
          <UserMenu />
        </Space>
      }
    />
  );
};
