import { type Dispatch, type FC, type SetStateAction, useMemo, useState } from 'react';

import { Typography } from 'antd';

import { useOnlineAppointmentDoctorListQuery } from 'entity/doctor/hooks/use-online-appointment-doctor-list-query';
import { useScheduleAddSpecialistByDoctorMutation } from 'entity/schedule/hooks/use-add-apecialist-by-doctor-mutation';
import type { InternalScheduleOnlineAppointmentEvent } from 'entity/schedule/types/internal-schedule-online-appointment-event';
import { addSecondToDate, convertToISO } from 'shared/lib/format';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';

import { SpecialistActionButton, StyledSelect } from './styles';

const { Text } = Typography;

type BodyProps = {
  event: InternalScheduleOnlineAppointmentEvent;
  setNewSpecialistId: Dispatch<SetStateAction<number | null>>;
};

const BodyContent: FC<BodyProps> = ({ event, setNewSpecialistId }) => {
  const { start, mainDoctorId } = event;

  const { doctorSlots } = useOnlineAppointmentDoctorListQuery({
    fromDateTimeIso: convertToISO(start),
    toDateTimeIso: addSecondToDate(start),
  });

  const doctorsOptions = useMemo(
    () =>
      doctorSlots
        .filter((doctor) => doctor.doctor.id !== mainDoctorId)
        .map((doctor) => ({
          value: doctor.doctor.id,
          label: doctor.doctor.fullName,
        })),
    [doctorSlots, mainDoctorId],
  );

  const handleSelectSpecialistId = (value: unknown) => {
    if (typeof value === 'number') setNewSpecialistId(value);
  };

  return (
    <StyledSelect
      allowClear
      placeholder={doctorsOptions.length ? 'Выберите специалиста' : 'Нет доступных специалистов'}
      onSelect={handleSelectSpecialistId}
      options={doctorsOptions}
      onClear={() => setNewSpecialistId(null)}
      disabled={!doctorsOptions.length}
    />
  );
};

type Props = {
  event: InternalScheduleOnlineAppointmentEvent;
};

export const ScheduleAddSpecialistByDoctorButton: FC<Props> = ({ event }) => {
  const [addSpecialistModal, setAddSpecialistModal] = useState(false);
  const [newSpecialistId, setNewSpecialistId] = useState<number | null>(null);

  const [addSpecialist] = useScheduleAddSpecialistByDoctorMutation();

  const { id } = event;

  const handleAddSpecialist = () => {
    if (newSpecialistId)
      addSpecialist({
        internalOnlineVisitAppointmentId: id,
        internalOnlineVisitDoctorId: newSpecialistId,
      });
  };

  const handleOpenSpecialistModal = () => setAddSpecialistModal(true);
  const handleCloseSpecialistModal = () => setAddSpecialistModal(false);

  return (
    <>
      <SpecialistActionButton onClick={handleOpenSpecialistModal}>
        <Text>Добавить специалиста</Text>
      </SpecialistActionButton>

      <CustomModal
        title="Выберите специалиста"
        visible={addSpecialistModal}
        onClose={handleCloseSpecialistModal}
        actionButtons={[
          <CustomButton onClick={handleAddSpecialist} $buttonType="declineWhite" disabled={!newSpecialistId}>
            Подтвердить
          </CustomButton>,
          <CustomButton onClick={handleCloseSpecialistModal} $buttonType="submit">
            Отменить
          </CustomButton>,
        ]}
        bodyContent={<BodyContent event={event} setNewSpecialistId={setNewSpecialistId} />}
      />
    </>
  );
};
