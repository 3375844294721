import { type FC } from 'react';

import { ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useHealthAssistanceEditModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-admin-health-assistance-edit-modal';
import { useOnlineAppointmentEditAppointmentByAdminMutation } from 'entity/online-appointment/hooks/use-online-appointment-edit-by-admin';
import type { InternalOnlineAppointmentDetails } from 'entity/online-appointment/types/internal-online-appointment-details';
import { routePatches } from 'shared/routes';
import { Button } from 'shared/ui/button';
import { CheckMarkIcon } from 'shared/ui/icons';

interface Props {
  onlineAppointmentData: InternalOnlineAppointmentDetails;
  onUpdate: () => void;
}

export const OnlineAppointmentEditByAdminButton: FC<Props & ButtonProps> = ({
  onlineAppointmentData,
  onUpdate,
  ...props
}) => {
  const navigate = useNavigate();

  const [editAppointment] = useOnlineAppointmentEditAppointmentByAdminMutation();

  const { id, doctorId, onlineAppointmentSlotId } = onlineAppointmentData;

  const successHandler = () => {
    onUpdate();
    navigate(routePatches.AdminOnlineAppointmentList.path());
  };

  const applyButtonHandler = () => {
    editAppointment({
      id,
      doctorId,
      onlineVisitSlotId: onlineAppointmentSlotId,
      onSuccess: successHandler,
    });
  };

  const { showEditModal, EditModal } = useHealthAssistanceEditModal();

  return (
    <>
      <Button
        icon={<CheckMarkIcon />}
        onClick={showEditModal(applyButtonHandler)}
        $buttonType="submit"
        type="primary"
        size="large"
        {...props}
      >
        Сохранить
      </Button>
      <EditModal />
    </>
  );
};
