import { FC, useState } from 'react';

import { DropdownProps } from 'antd';

import { StyledCheckbox, StyledMenu } from './styles';

export interface SelectItem<T> {
  label: string;
  value: T;
}

interface Props extends Omit<DropdownProps, 'overlay'> {
  items: SelectItem<string>[];
  onChange?: (value: string[]) => void;
  defaultChecked?: string[];
}

export const CheckList: FC<Props> = ({ onChange, items, defaultChecked }) => {
  const [checkedList, setCheckedList] = useState<Set<string>>(defaultChecked ? new Set(defaultChecked) : new Set());

  const manageItemInSet = (set: Set<string>, value: string) => {
    if (set.has(value)) {
      set.delete(value);
    } else {
      set.add(value);
    }
    return set;
  };

  // eslint-disable-next-line consistent-return
  const updateCheckedList = (itemValue: string) => {
    setCheckedList((prev) => {
      return manageItemInSet(new Set(prev), itemValue);
    });

    if (onChange) {
      const newSet = manageItemInSet(new Set(checkedList), itemValue);
      onChange(Array.from(newSet));
      return newSet;
    }
  };

  return (
    <StyledMenu
      style={{ cursor: 'pointer' }}
      disabled
      items={items.map((item, index) => ({
        key: index,
        label: (
          <StyledCheckbox
            value={item}
            onChange={(e) => {
              e.stopPropagation();
              updateCheckedList(item.value);
            }}
            checked={checkedList.has(item.value)}
            style={{ cursor: 'pointer' }}
          >
            {item.label}
          </StyledCheckbox>
        ),
      }))}
    />
  );
};
