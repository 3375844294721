import { type FC } from 'react';

import { type ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useHealthAssistanceRejectModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-reject-modal';
import { useOnlineAppointmentAcceptCancellationByAdminMutation } from 'entity/online-appointment/hooks/use-online-appointment-accept-cancellation-by-admin';
import { routePatches } from 'shared/routes';
import { Button } from 'shared/ui/button';
import { CloseIcon } from 'shared/ui/icons';

type Props = {
  onlineAppointmentId: number;
};

export const OnlineAppointmentCancelAppointmentByAdminButton: FC<ButtonProps & Props> = ({
  onlineAppointmentId,
  disabled,
  ...props
}) => {
  const navigate = useNavigate();

  const [cancelAppointment] = useOnlineAppointmentAcceptCancellationByAdminMutation();

  const succeededActionModelHandler = () => navigate(routePatches.AdminOnlineAppointmentList.path());

  const onClickHandler = () =>
    cancelAppointment({
      id: onlineAppointmentId,
      onSuccess: succeededActionModelHandler,
    });

  const { showRejectModal, RejectModal } = useHealthAssistanceRejectModal();

  return (
    <>
      <Button
        icon={<CloseIcon />}
        onClick={showRejectModal(onClickHandler)}
        disabled={disabled}
        $buttonType="decline"
        type="primary"
        size="large"
        {...props}
      >
        Отменить запись
      </Button>
      <RejectModal />
    </>
  );
};
