import { FC } from 'react';

import { Outlet } from 'react-router-dom';

import { BackButtonSubheader } from 'feature/navigation/ui/back-button-subheader';
import { AdminDoctorPageContainer } from 'pages/admin/doctor/ui/styles';

export const AdminDoctorPage: FC = () => {
  document.title = 'Врачи';
  return (
    <AdminDoctorPageContainer>
      <BackButtonSubheader />
      <Outlet />
    </AdminDoctorPageContainer>
  );
};
