import { type FC, JSX } from 'react';

import { InternalPushNotificationStatusEnum } from 'entity/marketing/types/internal-push-notification';
import { MarketingEditPushNotificationButton } from 'feature/marketing-edit-push-notification/ui';
import { MarketingRemovePushNotificationButton } from 'feature/marketing-remove-push-notification/ui';
import { DropDownMenu } from 'shared/ui/dropdown-menu';
import { VerticalDotsIcon } from 'shared/ui/icons';

interface Props {
  status: InternalPushNotificationStatusEnum;
  notificationId: number;
}

export const PushNotificationsListDropdownMenu: FC<Props> = ({ status, notificationId }) => {
  const getNodeItems = (): JSX.Element[] => {
    return [
      <MarketingEditPushNotificationButton status={status} notificationId={notificationId} key={1} />,
      <MarketingRemovePushNotificationButton notificationId={notificationId} key={2} />,
    ];
  };

  const nodeItems = getNodeItems();

  return nodeItems.length > 0 ? (
    <DropDownMenu placement="bottomRight" nodeItems={nodeItems}>
      <VerticalDotsIcon />
    </DropDownMenu>
  ) : null;
};
