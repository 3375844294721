import { type FC } from 'react';

import { Col } from 'antd';
import { type Moment } from 'moment';

import { getHoursFromDate, isDateBigger, isTheSameTimeStamp } from 'entity/schedule/lib/format';
import { InternalScheduleEvent } from 'entity/schedule/types';
import { addDaysToDate } from 'shared/lib/format';
import { Scrollable } from 'shared/ui/scrollable';
import { ScheduleGridEvents } from 'widgets/doctor-schedule-widget/ui/grid/events';
import {
  ScheduleGridCol,
  ScheduleGridContainer,
  ScheduleGridRow,
  ScheduleGridRowTime,
} from 'widgets/doctor-schedule-widget/ui/grid/styles';

type Props = {
  data: InternalScheduleEvent[];
  startDate: Moment;
};

export const ScheduleGridWidget: FC<Props> = ({ data, startDate }) => {
  const daysOfWeek = Array.from({ length: 7 }, (_, ind) => addDaysToDate(startDate, ind));

  const times = data.map(({ start }) => getHoursFromDate(start)).sort((a, b) => +a - +b);
  const timesSet = Array.from(new Set(times));

  return (
    <ScheduleGridContainer>
      <Scrollable>
        {timesSet.map((hours) => {
          return (
            <ScheduleGridRow justify="space-between" align="stretch" key={`schedule-grid-row${hours}`}>
              <Col span={2}>
                <ScheduleGridRowTime>{`${hours}.00`}</ScheduleGridRowTime>
              </Col>
              {daysOfWeek.map((day) => {
                const events = data
                  .filter(({ start }) => isTheSameTimeStamp(start, day, hours))
                  .sort((a, b) => (isDateBigger(a.start, b.start) ? 1 : -1));

                return (
                  <ScheduleGridCol span={3} key={`schedule-grid-col${day.toISOString()}`}>
                    <ScheduleGridEvents events={events} />
                  </ScheduleGridCol>
                );
              })}
            </ScheduleGridRow>
          );
        })}
      </Scrollable>
    </ScheduleGridContainer>
  );
};
