import { type FC, useState } from 'react';

import { type TableColumnsType } from 'antd';
import { useNavigate } from 'react-router-dom';

import { SearchParams } from 'entity/health-assistance/constants';
import { useUrlStatusesArray } from 'entity/health-assistance/hooks/use-url-statuses-array';
import { HealthAssistanceStatusDropdownCheckList } from 'entity/health-assistance/ui/health-assistance-status-dropdown-check-list/health-assistance-status-dropdown-check-list';
import { onlineAppointmentStatusOptions } from 'entity/online-appointment/constants';
import { useOnlineAppointmentQuery } from 'entity/online-appointment/hooks/use-online-appointment-list-query';
import type { InternalOnlineAppointment } from 'entity/online-appointment/types/internal-online-appointment';
import { InternalOnlineAppointmentStatusEnum } from 'entity/online-appointment/types/internal-online-appointment-status-enum';
import { OnlineAppointmentStatusTag } from 'entity/online-appointment/ui/online-appointment-status-tag';
import { useUrlPagination } from 'shared/hooks/use-url-pagination';
import { useUrlSearch } from 'shared/hooks/use-url-search';
import { routePatches } from 'shared/routes';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';
import { Table } from 'shared/ui/table';

import { OnlineAppointmentTableDropdownMenu } from './online-appointment-table-dropdown-menu';

const DEFAULT_PAGE_SIZE = 7;
const DEFAULT_PAGE = 1;
const DEFAULT_STATUSES = [
  InternalOnlineAppointmentStatusEnum.NewForAdmin,
  InternalOnlineAppointmentStatusEnum.AssignedByAnotherAdmin,
  InternalOnlineAppointmentStatusEnum.AssignedByCurrentAdmin,
  InternalOnlineAppointmentStatusEnum.AcceptedByAdmin,
];

export const OnlineAppointmentTable: FC = () => {
  const { setPage, page } = useUrlPagination();
  const navigate = useNavigate();
  const [showInProgressModal, setShowInProgressModal] = useState(false);
  const { search } = useUrlSearch(SearchParams.Search);
  const { search: date } = useUrlSearch(SearchParams.Date);
  const { statuses } = useUrlStatusesArray(onlineAppointmentStatusOptions);

  const columns: TableColumnsType<InternalOnlineAppointment> = [
    {
      title: 'Пациент',
      dataIndex: 'patient',
      ellipsis: true,
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      ellipsis: true,
    },
    {
      title: 'Специализация',
      dataIndex: 'specialization',
      ellipsis: true,
    },
    {
      title: 'Дата Приема',
      dataIndex: 'date',
      ellipsis: true,
    },
    {
      title: 'Время Приема',
      dataIndex: 'time',
      ellipsis: true,
    },
    {
      title: 'Врач',
      dataIndex: 'doctor',
      ellipsis: true,
    },
    {
      title: 'Стоимость, ₽',
      dataIndex: 'price',
      ellipsis: true,
    },
    {
      title: <HealthAssistanceStatusDropdownCheckList items={onlineAppointmentStatusOptions} />,
      dataIndex: 'status',
      width: 130,
      render: (_, row) => {
        const { status } = row;

        return <OnlineAppointmentStatusTag appointmentStatus={status} />;
      },
    },
    {
      width: 60,
      render: (_, row) => {
        return <OnlineAppointmentTableDropdownMenu onlineAppointment={row} />;
      },
    },
  ];

  const { onlineAppointments, total } = useOnlineAppointmentQuery({
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    search,
    date,
    statuses: statuses ?? DEFAULT_STATUSES,
  });

  const inProgressModalHandler = () => setShowInProgressModal(false);

  return (
    <>
      <Table
        columns={columns}
        dataSource={onlineAppointments}
        rowKey={(row) => row.id}
        onRow={({ isInUseByAnotherAdmin, id }) => {
          return {
            onClick: () => {
              if (isInUseByAnotherAdmin) {
                setShowInProgressModal(true);
              } else {
                navigate(routePatches.AdminOnlineAppointmentDetails.path(id));
              }
            },
          };
        }}
        pagination={{
          current: page ?? DEFAULT_PAGE,
          total,
          onChange: (e) => setPage(e),
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
        }}
      />
      <CustomModal
        title="Данная заявка взята в работу другим администратором"
        visible={showInProgressModal}
        onClose={inProgressModalHandler}
        actionButtons={[
          <CustomButton key={1} onClick={inProgressModalHandler} $buttonType="submit">
            Закрыть
          </CustomButton>,
        ]}
      />
    </>
  );
};
