import { InternalChatSupportStatusEnum } from 'entity/chat-support/types/internal-chat-support-status-enum';
import { ChatSupportStatusEnum } from 'shared/api/rtk-api';

const statusMap = new Map<ChatSupportStatusEnum, InternalChatSupportStatusEnum>([
  [ChatSupportStatusEnum.New, InternalChatSupportStatusEnum.New],
  [ChatSupportStatusEnum.AcceptByAdmin, InternalChatSupportStatusEnum.AcceptByAdmin],
  [ChatSupportStatusEnum.InProgressByCurrentAdmin, InternalChatSupportStatusEnum.InProgressByCurrentAdmin],
  [ChatSupportStatusEnum.InProgressByAnotherAdmin, InternalChatSupportStatusEnum.InProgressByAnotherAdmin],
]);
export const getInternalChatSupportStatus = (status: ChatSupportStatusEnum): InternalChatSupportStatusEnum => {
  const internalStatus = statusMap.get(status);
  if (!internalStatus) throw new Error(`Unknown status: ${status}`);
  return internalStatus;
};
