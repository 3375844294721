import { type FC } from 'react';

import { Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { DocumentResponseDto } from 'shared/api/rtk-api';
import { EditFileIcon, PlusIcon } from 'shared/ui/icons';

import { AddProtocolButtonWrapper, DocumentsWrapper } from './styles';

const { Title } = Typography;

type Props = { documents: DocumentResponseDto[]; canAddProtocol?: boolean };

export const PatientDocuments: FC<Props> = ({ documents, canAddProtocol }) => {
  return (
    <DocumentsWrapper>
      <Title level={5}>Документы</Title>

      {documents.map(({ name, owner, url }) => (
        <Row gutter={[15, 20]} key={name}>
          <Col>
            <Link target="_blank" to={url}>
              {name}
            </Link>
          </Col>

          {owner && (
            <Col>
              <Link to="#">
                <EditFileIcon />
              </Link>
            </Col>
          )}
        </Row>
      ))}
      {canAddProtocol && (
        <AddProtocolButtonWrapper>
          <PlusIcon /> <span>Добавить протокол</span>
        </AddProtocolButtonWrapper>
      )}
    </DocumentsWrapper>
  );
};
