import { type FC } from 'react';

import { Col, Row } from 'antd';

import type { InternalScheduleEvent } from 'entity/schedule/types';
import { ScheduleCardEvent } from 'widgets/doctor-schedule-event-card-widget/ui';

type Props = {
  events: InternalScheduleEvent[];
};

export const ScheduleGridEvents: FC<Props> = ({ events }) => {
  return (
    <Row gutter={[0, 10]} wrap>
      {events.map((event) => (
        <Col span={24} key={`schedule-grid-col-event${event.id}`}>
          <ScheduleCardEvent event={event} />
        </Col>
      ))}
    </Row>
  );
};
