import { externalChatSupportToInternalChatSupportMapper } from 'entity/chat-support/mappers/external-chat-support-to-internal-chat-support-mapper';
import type { InternalChatSupport } from 'entity/chat-support/types/internal-chat-support';
import { useAdminVisitsControllerGetChatAssistingQuery } from 'shared/api/rtk-api';

interface HomeVisitDetailQuery {
  page: number;
  pageSize: number;
  statuses?: ('New' | 'InProgressByCurrentAdmin' | 'AcceptByAdmin' | 'InProgressByAnotherAdmin')[];
  date?: string;
  name?: string;
}

export const useChatSupportListQuery = ({ page, pageSize, statuses, date, name }: HomeVisitDetailQuery) => {
  const { data, ...others } = useAdminVisitsControllerGetChatAssistingQuery({
    name,
    date,
    statuses,
    limit: pageSize,
    offset: (page - 1) * pageSize || undefined,
  });

  const chatSupports: InternalChatSupport[] = (data?.data ?? []).map(externalChatSupportToInternalChatSupportMapper);
  return {
    chatSupports,
    total: data?.total,
    ...others,
  };
};
