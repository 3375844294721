import styled from 'styled-components';

export const BlackScreenWrapper = styled.div<{ isBlack?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.extraSmall};
  background-color: ${({ theme, isBlack }) => (isBlack ? theme.colors.palette.black : theme.colors.palette.deadPixel)};
`;

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.palette.white};
  font-size: ${({ theme }) => theme.fontSizes.default};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;
