import { type FC, useCallback } from 'react';

import type { ButtonProps } from 'antd/lib/button/button';
import { useNavigate } from 'react-router-dom';

import { routePatches } from 'shared/routes';
import { ReturnButton } from 'shared/ui/return-button';

interface Props extends ButtonProps {}

export const AdminReturnToHomeVisitRejectedByClientListButton: FC<Props> = ({ ...props }) => {
  const navigate = useNavigate();
  const onClickHandler = useCallback(() => {
    navigate(routePatches.AdminRejectedByPatientHomeVisitList.path());
  }, [navigate]);

  return (
    <ReturnButton {...props} onClick={onClickHandler}>
      Заявки на отмену приема
    </ReturnButton>
  );
};
