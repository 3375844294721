import type { SelectItem } from 'shared/ui/check-list';

import { InternalOnlineAppointmentStatusEnum } from '../types/internal-online-appointment-status-enum';

export const onlineAppointmentStatusOptions: SelectItem<InternalOnlineAppointmentStatusEnum>[] = [
  { label: 'Новая', value: InternalOnlineAppointmentStatusEnum.NewForAdmin },
  {
    label: 'В работе',
    value: InternalOnlineAppointmentStatusEnum.AssignedByAnotherAdmin,
  },
  {
    label: 'В обработке',
    value: InternalOnlineAppointmentStatusEnum.AssignedByCurrentAdmin,
  },
  {
    label: 'Подтверждена',
    value: InternalOnlineAppointmentStatusEnum.AcceptedByAdmin,
  },
];

export const onlineAppointmentRejectedByPatientStatusOptions: SelectItem<InternalOnlineAppointmentStatusEnum>[] = [
  {
    label: 'Новая',
    value: InternalOnlineAppointmentStatusEnum.PendingCancellation,
  },
  {
    label: 'В работе',
    value: InternalOnlineAppointmentStatusEnum.ProcessingByAnotherAdmin,
  },
  {
    label: 'В обработке',
    value: InternalOnlineAppointmentStatusEnum.ProcessingByCurrentAdmin,
  },
];
