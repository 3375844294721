import type { DoctorConversationListResponseDtoData } from 'shared/api/rtk-api';

import type { DoctorConversationList } from '../types/doctor-conversation-list';

export const externalDoctorConversationListToInternalDoctorConversationListMapper = (
  response: DoctorConversationListResponseDtoData,
): DoctorConversationList => {
  return {
    internalConversationId: response.internalConversationId,
    conversationName: response.conversationName,
    lastReadDateTimeIso: response.lastReadDateTimeIso,
    eTag: response.pubnubChannelName,
  };
};
