import styled from 'styled-components';

export const AddAccountModalTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
`;

export const AddAccountModalText = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

export const AddAccountModalContainer = styled.div`
  width: 320px;
`;
