import { type FC } from 'react';

import { Col, Row } from 'antd';

import { ScheduleEventTypeIcons, ScheduleEventTypeTitle } from 'entity/schedule/constants';
import type { InternalScheduleClinicAppointmentEvent } from 'entity/schedule/types/internal-schedule-clinic-appointment-event';
import { getTimeFromDate } from 'shared/lib/format';

import { ScheduleSlotContainer, SlotLine } from './styles';

type Props = {
  event: InternalScheduleClinicAppointmentEvent;
};

export const ClinicAppointmentEventCard: FC<Props> = ({ event }) => {
  const { start } = event;

  return (
    <ScheduleSlotContainer>
      <Row>
        <Col span={4}>{ScheduleEventTypeIcons.CLINIC}</Col>
        <Col span={20}>
          <SlotLine strong>{ScheduleEventTypeTitle.CLINIC}</SlotLine>
          <SlotLine>{getTimeFromDate(start)}</SlotLine>
        </Col>
      </Row>
    </ScheduleSlotContainer>
  );
};
