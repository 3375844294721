import { useMemo } from 'react';

import { externalHomeVisitListRejectedByPatientToInternalHomeVisitListRejectedByPatientMapper } from 'entity/home-visit/mappers/external-home-visit-rejected-by-patient-details-to-internal-home-visit-rejected-by-patient-details-mapper';
import { useAdminVisitsControllerViewRejectByPatientByIdQuery } from 'shared/api/rtk-api';

export const useHomeVisitRejectedByPatientDetailsQuery = (homeVisitId?: string) => {
  const id = Number(homeVisitId);
  if (!id) throw new Error('id is not defined');
  const { data, ...others } = useAdminVisitsControllerViewRejectByPatientByIdQuery(id);

  const homeVisit = useMemo(() => {
    return data
      ? externalHomeVisitListRejectedByPatientToInternalHomeVisitListRejectedByPatientMapper(data)
      : undefined;
  }, [data]);

  return {
    homeVisit,
    ...others,
  };
};
