import { Menu } from 'antd';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)`
  color: ${({ theme }) => theme.colors.palette.deadPixel};
  &.ant-menu-horizontal {
    line-height: 34px !important;
  }
  background-color: transparent;
  width: max-content !important;
  .ant-menu-item-selected {
    color: ${({ theme }) => theme.colors.palette.columnOfOakGreen}!important;

    :after {
      border-bottom-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen} !important;
    }
  }
  .ant-menu-item-active {
    color: ${({ theme }) => theme.colors.palette.columnOfOakGreen}!important;
    :after {
      border-bottom-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen} !important;
    }
  }
  ant-menu-title-content {
    :hover {
      color: ${({ theme }) => theme.colors.palette.columnOfOakGreen}!important;
    }
  }
  .ant-menu-item:after,
  .ant-menu-submenu:after {
    left: 1px !important;
    right: 1px !important;
  }
  .ant-menu-item {
    font-size: ${({ theme }) => theme.fontSizes.default};
    :hover {
      color: ${({ theme }) => theme.colors.palette.columnOfOakGreen}!important;
    }
  }
`;
