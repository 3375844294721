import React, { FC, useCallback, useState } from 'react';

import { HealthAssistanceDetailsAsideContainer, ShadowBox, StyledTitle } from 'entity/health-assistance/styles';
import { DoctorHealthAssistanceDetailsContainer } from 'entity/health-assistance/ui/health-assistance-details-container/doctor-health-assistance-details-container';
import { SymptomTagsRow } from 'entity/health-assistance/ui/symptoms-tag-row';
import { TimeTagRow } from 'entity/health-assistance/ui/time-tag-row';
import { HomeVisitApplyByDoctorButton } from 'feature/home-visit-apply-by-doctor/ui/home-visit-apply-by-doctor-button';
import { HomeVisitDeclineByDoctorButton } from 'feature/home-visit-decline-by-doctor/ui/home-visit-decline-by-doctor-button';
import { HomeVisitDetails } from 'pages/doctor/home-visit-details/types';
import { AvailabilityForDoctor } from 'shared/api/rtk-api';
import { CustomButtonFooter } from 'shared/ui/custom-button-footer';
import { TextAreaRow } from 'shared/ui/row/text-area-row';
import { TextRow } from 'shared/ui/row/text-row';
import { StyledScrollableContainer } from 'shared/ui/scrollable/styles';

interface Props {
  homeVisit: HomeVisitDetails;
}

export const HomeVisitDetailsForm: FC<Props> = ({ homeVisit }) => {
  const [timeIndex, setTimeIndex] = useState<number | null>(null);

  const onChangeTimeHandler = useCallback(({ selectedTagIndex }: { selectedTag: string; selectedTagIndex: number }) => {
    setTimeIndex(selectedTagIndex);
  }, []);

  const isFinished = homeVisit.availabilityForDoctor === AvailabilityForDoctor.NotAvailable;

  return (
    <HealthAssistanceDetailsAsideContainer>
      <StyledScrollableContainer>
        <DoctorHealthAssistanceDetailsContainer>
          <StyledTitle level={5}> Заявка на вызов на дом</StyledTitle>
          <TextRow title="ФИО пациента" text={homeVisit.patientName} />
          <TextRow title="Дата" text={homeVisit.date} />
          <TextRow title="Адрес вызова" text={homeVisit.address} />
          <TextRow title="Стоимость" text={homeVisit.price} />
          <TimeTagRow
            tags={homeVisit.timeRanges}
            onChange={onChangeTimeHandler}
            disabled={isFinished}
            defaultSelectedTagIndex={timeIndex}
          />
          <SymptomTagsRow tags={homeVisit.symptoms} />
          {homeVisit.comments && <TextAreaRow title="Комментарий" text={homeVisit.comments} />}
        </DoctorHealthAssistanceDetailsContainer>
      </StyledScrollableContainer>
      {!isFinished && (
        <CustomButtonFooter>
          <HomeVisitApplyByDoctorButton appointment={homeVisit} timeIndex={timeIndex} />
          <HomeVisitDeclineByDoctorButton appointment={homeVisit} />
        </CustomButtonFooter>
      )}
      <ShadowBox />
    </HealthAssistanceDetailsAsideContainer>
  );
};
