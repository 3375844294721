/* eslint @typescript-eslint/no-misused-promises: "off" */
import { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Form } from 'antd';

import {
  ChangePasswordFormFieldsEnum,
  IChangePasswordFormFields,
} from 'entity/admin/api/types/account/change-password-form-fields';
import { BackButtonSubheader } from 'feature/navigation/ui/back-button-subheader';
import { AdminChangePasswordPageContainer, AdminChangePasswordPageTitle } from 'pages/admin/change-password/ui/styles';
import { useInternalAuthControllerChangePasswordMutation } from 'shared/api/rtk-api';
import { config } from 'shared/config';
import { AppAlert } from 'shared/ui/alert';
import { AppButton } from 'shared/ui/button2';
import { AppInputPassword } from 'shared/ui/input2';

export const AdminChangePasswordPage: FC = () => {
  document.title = 'Смена пароля';
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IChangePasswordFormFields>();
  const { newPassword, newPasswordConfirm, prevPassword } = watch();
  const [changePassword, { isSuccess, isLoading, isError }] = useInternalAuthControllerChangePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      setValue(ChangePasswordFormFieldsEnum.NEW_PASSWORD, '');
      setValue(ChangePasswordFormFieldsEnum.NEW_PASSWORD_CONFIRM, '');
      setValue(ChangePasswordFormFieldsEnum.PREV_PASSWORD, '');
    }
  }, [isSuccess, setValue]);

  const onSubmit: SubmitHandler<IChangePasswordFormFields> = (values) => {
    /* eslint-disable-next-line  @typescript-eslint/no-floating-promises */
    changePassword({
      oldPassword: values.prevPassword,
      password: values.newPassword,
      confirmPassword: values.newPasswordConfirm,
    });
  };

  return (
    <AdminChangePasswordPageContainer>
      <BackButtonSubheader />
      {isError && <AppAlert type="error" message="При смене пароля, что-то пошло не так!" closable />}
      {isSuccess && <AppAlert type="success" message="Пароль успешно изменен" closable />}
      <AdminChangePasswordPageTitle>Изменить пароль</AdminChangePasswordPageTitle>
      <Form name="admin-reset-password">
        <Form.Item
          validateTrigger="onBlur"
          validateStatus={errors?.[ChangePasswordFormFieldsEnum.PREV_PASSWORD] ? 'error' : ''}
          help={errors?.[ChangePasswordFormFieldsEnum.PREV_PASSWORD] && 'Введите старый пароль!'}
        >
          <Controller
            name={ChangePasswordFormFieldsEnum.PREV_PASSWORD}
            control={control}
            rules={{ required: true }}
            render={({ field }) => <AppInputPassword {...field} size="large" placeholder="текущий пароль" />}
          />
        </Form.Item>
        <Form.Item
          validateTrigger="onBlur"
          validateStatus={
            /* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */
            errors?.[ChangePasswordFormFieldsEnum.NEW_PASSWORD] ||
            errors?.[ChangePasswordFormFieldsEnum.NEW_PASSWORD_CONFIRM]
              ? 'error'
              : ''
          }
          help={
            errors?.[ChangePasswordFormFieldsEnum.NEW_PASSWORD] &&
            'Пароль должен состоять из цифр и латинских букв верхнего и нижнего регистра !'
          }
        >
          <Controller
            name={ChangePasswordFormFieldsEnum.NEW_PASSWORD}
            control={control}
            rules={{ pattern: config.validationPatterns.password }}
            render={({ field }) => <AppInputPassword {...field} size="large" placeholder="новый пароль" />}
          />
        </Form.Item>
        <Form.Item
          validateTrigger="onBlur"
          validateStatus={errors?.[ChangePasswordFormFieldsEnum.NEW_PASSWORD_CONFIRM] ? 'error' : ''}
          help={errors?.[ChangePasswordFormFieldsEnum.NEW_PASSWORD_CONFIRM] && 'Пароли не совпадают!'}
        >
          <Controller
            name={ChangePasswordFormFieldsEnum.NEW_PASSWORD_CONFIRM}
            control={control}
            rules={{ validate: (value) => value === newPassword }}
            render={({ field }) => <AppInputPassword {...field} size="large" placeholder="повторите новый пароль" />}
          />
        </Form.Item>

        <Form.Item>
          {/* eslint-disable-next-line deprecation/deprecation */}
          <AppButton
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading || !newPassword || !newPasswordConfirm || !prevPassword}
            onClick={handleSubmit(onSubmit)}
          >
            Изменить
          </AppButton>
        </Form.Item>
      </Form>
    </AdminChangePasswordPageContainer>
  );
};
