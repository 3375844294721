import { FC } from 'react';

import { AdminRecordingsTable, AdminRecordingsTableContainer } from 'widgets/recordings-table/styles';
import { AdminDoctorTableData } from 'widgets/recordings-table/table-data';

type Props = {
  invisibleFields?: string[];
};

export const RecordingsTable: FC<Props> = ({ invisibleFields }) => {
  return (
    <AdminRecordingsTableContainer>
      <AdminRecordingsTable
        pagination={{ position: ['bottomLeft'] }}
        rowKey={(recording) => recording.id}
        columns={AdminDoctorTableData.filter((el) =>
          invisibleFields ? !invisibleFields.includes(el.key as string) : true,
        )}
      />
    </AdminRecordingsTableContainer>
  );
};
