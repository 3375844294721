import React from 'react';

import moment from 'moment';
import ReactDOM from 'react-dom/client';

import App from 'app/app';

import 'moment/locale/ru';

const root = ReactDOM.createRoot(document.getElementById('root')!);
moment.locale('ru');

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
