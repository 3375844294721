import { useMemo } from 'react';

import { useInternalAuthControllerGetDoctorQuery, UserRoleEnum } from 'shared/api/rtk-api';

import { type IAuthUserPayload, UserRolesEnum } from '../api/types';

function mapExternalRoleToUserRolesEnum(role: UserRoleEnum): UserRolesEnum {
  switch (role) {
    case UserRoleEnum.Admin:
      return UserRolesEnum.ADMIN;
    case UserRoleEnum.Doctor:
      return UserRolesEnum.DOCTOR;
    case UserRoleEnum.Manager:
      return UserRolesEnum.MANAGER;
    default:
      throw new Error(`Unknown role: ${role}`);
  }
}

export const useCurrentUserQuery = () => {
  const { data: user, isError, ...rest } = useInternalAuthControllerGetDoctorQuery();

  const mapped: IAuthUserPayload | null = useMemo(
    () =>
      user && !isError
        ? {
            id: user.id,
            pubNubUserId: user.doctorAccount?.gatewayId,
            role: mapExternalRoleToUserRolesEnum(user.role),
            email: user.email,
            avatar: user.avatar,
            name: user.name,
            description: user.description,
            canCreateOnlineVisitSlot: user.doctorAccount?.canCreateOnlineVisitSlot,
            pushToken: user.pushToken,
          }
        : null,
    [user, isError],
  );

  return {
    currentUser: mapped,
    isError,
    ...rest,
  };
};
