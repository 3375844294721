import React, { FC } from 'react';

import { Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';
import { HealthAssistanceDetailsContainer } from 'entity/health-assistance/styles';
import { useHomeVisitDetailsQueryByDoctor } from 'entity/home-visit/hooks/use-home-visit-details-query-by-doctor';
import { HomeVisitDetailsForm } from 'pages/doctor/home-visit-details/ui/home-visit-details-form';
import { YandexMap } from 'pages/doctor/home-visit-details/ui/yandex-map';
import { SplitScreenContainer } from 'pages/doctor/home-visit-details/ui/yandex-map/styles';
import { routePatches } from 'shared/routes';
import { SplitScreen } from 'shared/ui/split-screen';

export const HomeVisitDetailsPage: FC = () => {
  const { homeVisitId } = useParams<{
    homeVisitId: string;
  }>();

  if (!homeVisitId) {
    throw new Error('homeVisitId is not defined');
  }

  document.title = 'Заявка на вызов на дом';
  const { homeVisit, isLoading, error } = useHomeVisitDetailsQueryByDoctor(homeVisitId);

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <SplitScreenContainer>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </SplitScreenContainer>
    );
  }

  if (error) {
    navigate(routePatches.DoctorSchedule.path());
  }

  if (!homeVisit) {
    return null;
  }

  return (
    <HealthAssistanceDetailsContainer>
      <SplitScreen
        Left={<HomeVisitDetailsForm homeVisit={homeVisit} />}
        Right={
          <div>
            <YandexMap address={homeVisit.address} />
          </div>
        }
      />
    </HealthAssistanceDetailsContainer>
  );
};
