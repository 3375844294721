import { useMemo } from 'react';

import { useConversationControllerDoctorConversationListQuery } from 'shared/api/rtk-api';

import { externalDoctorConversationListToInternalDoctorConversationListMapper } from '../mappers/external-doctor-conversation-list-to-internal-doctor-conversation-list';

export const useDoctorConversationListQuery = () => {
  const { data, ...others } = useConversationControllerDoctorConversationListQuery();

  const doctorConversations = useMemo(() => {
    return (data?.data ?? []).map((conversation) =>
      externalDoctorConversationListToInternalDoctorConversationListMapper(conversation),
    );
  }, [data]);

  return {
    doctorConversations,
    ...others,
  };
};
