import { SlotResponseDto } from 'shared/api/rtk-api';

import { ScheduleEventType } from '../constants';
import { InternalScheduleSlotEvent } from '../types/internal-schedule-slot-event';

export const externalSlotEventToInternalSlotEventMapper = (event: SlotResponseDto): InternalScheduleSlotEvent => {
  return {
    id: event.id,
    start: event.startIsoDateTime,
    end: event.endIsoDateTime,
    type: ScheduleEventType.SLOT,
  };
};
