import { type FC } from 'react';

import { type MessageEnvelope } from '@pubnub/react-chat-components';
import { Avatar } from 'antd';
import FileSaver from 'file-saver';

import type { ConversationParticipant } from 'entity/doctor-chat/types/doctor-conversation-info';
import { useDownloadChatFileQuery } from 'entity/files/files/use-download-chat-file-query';
import { formatDateRelative } from 'entity/pubnub/lib/format';
import { messageHelperByChannel } from 'entity/pubnub/lib/utils/message-helper-payload';
import theme from 'shared/theme';
import { AvatarIcon, FileIcon } from 'shared/ui/icons';
import { downloadChatFile } from 'widgets/doctor-analysis-results/download-file-api';
import type { ConversationMessageType } from 'widgets/doctor-conversation-details-widget/types';
import { getParticipantAvatar } from 'widgets/doctor-conversation-details-widget/utils';

import {
  FileIconWrapper,
  Message,
  MessageGroup,
  MessageTime,
  StyledDateWrapper,
  StyledMessageListElementContainer,
  TypographyText,
} from './styles';

type Props = {
  currentMessage: MessageEnvelope;
  conversationMessages: ConversationMessageType[];
  participants: Map<string, ConversationParticipant>;
};

export const ConversationMessage: FC<Props> = ({ currentMessage, conversationMessages, participants }) => {
  const [downloadFileByFileId] = useDownloadChatFileQuery();

  function doDownloadFileByUrl(url: string, fileName: string | undefined) {
    /* eslint-disable-next-line  @typescript-eslint/no-floating-promises */
    downloadChatFile(url).then((file) => {
      // eslint-disable-next-line deprecation/deprecation
      FileSaver.saveAs(file, fileName);
    });
  }

  const doDownloadFileByFileId = (id: string, fileName: string) => downloadFileByFileId({ fileId: id, fileName });

  const { timetoken, message, uuid, publisher } = currentMessage;

  const formattedDateOfCurrentMessage = formatDateRelative(timetoken);

  const messagesWithEqualDate = conversationMessages.filter(
    (conversationMessage) => conversationMessage.date === formattedDateOfCurrentMessage,
  );
  const messagesSortedById = messagesWithEqualDate
    .slice()
    .sort((messageA, messageB) => (messageA.id > messageB.id ? 1 : -1));

  const firstMessageInDate = messagesSortedById[0];

  const isCurrentElementFirstInThatDate =
    messagesWithEqualDate.length < 2 || firstMessageInDate?.id === String(timetoken);

  const { userMessage, time, fileName, url, fileId } = messageHelperByChannel(message);

  const participantPubNubId = uuid ?? publisher;
  const participantAvatar = getParticipantAvatar(participants, participantPubNubId);

  return (
    <StyledMessageListElementContainer>
      {isCurrentElementFirstInThatDate && (
        <StyledDateWrapper>
          <TypographyText>{formattedDateOfCurrentMessage}</TypographyText>
        </StyledDateWrapper>
      )}
      <div className="pn-message__container">
        <Avatar size="large" icon={<AvatarIcon fill={theme.colors.mainBackground} />} src={participantAvatar} />
        <div className="pn-message__custom">
          <MessageGroup>
            {url && (
              <FileIconWrapper onClick={() => doDownloadFileByUrl(url, fileName)}>
                <FileIcon />
              </FileIconWrapper>
            )}
            {fileId && (
              <FileIconWrapper onClick={() => doDownloadFileByFileId(fileId, userMessage)}>
                <FileIcon />
              </FileIconWrapper>
            )}
            {fileName && <Message>{fileName}</Message>}
            <Message>{userMessage}</Message>
          </MessageGroup>
          <MessageTime>{time}</MessageTime>
        </div>
      </div>
    </StyledMessageListElementContainer>
  );
};
