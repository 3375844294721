import { Row, Select } from 'antd';
import styled from 'styled-components';

import { DatePicker } from 'shared/ui/data-picker';

export const Label = styled.p`
  margin-bottom: 4px;
`;

export const CloseIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;

  svg path {
    stroke: ${({ theme }) => theme.colors.palette.deadPixel};
  }
`;

export const StyledPickersRow = styled(Row)`
  margin-bottom: 20px;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 194px;
  border-radius: ${({ theme }) => theme.radii.small};

  &.ant-picker {
    background: ${({ theme }) => theme.colors.palette.whiteSmoke};

    .ant-picker-clear {
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};

      .ant-icon,
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
`;

export const StyledSelect = styled(Select)`
  .custom-time-picker-popup {
    width: 100px;
  }

  .ant-select-arrow {
    height: 14px;
  }

  .ant-select-clear {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};

    .ant-icon,
    svg {
      width: 14px;
      height: 14px;
    }
  }

  &&& {
    .ant-select-selection-item {
      line-height: 40px;
      color: ${({ theme }) => theme.colors.palette.deadPixel};

      div {
        justify-content: flex-start;

        span:first-child:after {
          content: ':';
        }
      }
    }
    .ant-select-selector {
      background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};

      height: 40px;

      &:focus {
        border-color: ${({ theme, disabled }) => (disabled ? 'transparent' : theme.colors.palette.columnOfOakGreen)};
      }
    }
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: ${({ theme }) => theme.radii.small};
    border-color: transparent;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
    box-shadow: 0 0;
  }

  &.ant-select:not(.ant-select-disabled):focus .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.palette.columnOfOakGreen};
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
`;
