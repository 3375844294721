import { type FC } from 'react';

import { useParams } from 'react-router-dom';

import { usePushNotificationDetailsQuery } from 'entity/marketing/hooks/use-push-notification-details-query';

import { PushNotificationForm } from './form';

export const MarketingPushNotificationFormPage: FC = () => {
  document.title = 'Пуш-уведомление';

  const { id } = useParams();

  const { pushNotification } = usePushNotificationDetailsQuery(String(id ?? ''));

  return <PushNotificationForm defaultValues={pushNotification} />;
};
