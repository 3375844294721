import { type OnlineVisitAdminAppointmentResponseDto } from 'shared/api/rtk-api';
import { formatPhone, formatPrice, getTimeFromDate } from 'shared/lib/format';

import type { InternalOnlineAppointmentDetails } from '../types/internal-online-appointment-details';
import { InternalOnlineAppointmentStatusEnum } from '../types/internal-online-appointment-status-enum';
import { getInternalOnlineAppointmentStatus } from './external-online-appointment-status-to-internal-online-appointment-status-mapper';

export function externalOnlineAppointmentDetailsToInternalOnlineAppointmentDetailsMapper(
  onlineAppointment: OnlineVisitAdminAppointmentResponseDto,
): InternalOnlineAppointmentDetails {
  const internalOnlineAppointmentStatus = getInternalOnlineAppointmentStatus(onlineAppointment.status);

  const isAccepted = internalOnlineAppointmentStatus === InternalOnlineAppointmentStatusEnum.AcceptedByAdmin;

  const canBeChanged = internalOnlineAppointmentStatus !== InternalOnlineAppointmentStatusEnum.NewForAdmin;

  const appointmentTime = `${getTimeFromDate(onlineAppointment.startDateTimeIso)} - ${getTimeFromDate(onlineAppointment.endDateTimeIso)}`;
  const onlineAppointmentSlotId = onlineAppointment.mainDoctorInternalOnlineVisitSlotId;

  return {
    id: onlineAppointment.internalOnlineVisitAppointmentId,
    onlineAppointmentSlotId,
    patientName: onlineAppointment.patientFullName,
    patientPhone: formatPhone(onlineAppointment.patientPhone),
    price: formatPrice(onlineAppointment.onlineAppointmentPrice / 100),
    date: onlineAppointment.startDateTimeIso,
    time: onlineAppointmentSlotId ? appointmentTime : '',
    specialization: onlineAppointment.doctorOnlineVisitSpecialization,
    doctorName: onlineAppointment.doctorFullName,
    doctorId: onlineAppointment.internalOnlineVisitDoctorId,
    status: internalOnlineAppointmentStatus,
    rating: onlineAppointment.patientFeedbackRating,
    review: onlineAppointment.patientFeedbackReview,
    cancelReason: onlineAppointment.patientCancelReason,
    canBeProcessed: onlineAppointment.canBeProcessed,
    canBeRejected: onlineAppointment.canBeRejected,
    canBeCanceled: onlineAppointment.canBeCancelled,
    canBeAssigned: onlineAppointment.canBeAssigned,
    canBeAccepted: onlineAppointment.canBeAccepted,
    canBeChanged,
    isAccepted,
  };
}
