import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import { IPatientInfo } from 'entity/doctor/api/types';
import { PatientsTableHeadText } from 'pages/doctor/patient-list/ui/styles';

export const PatientsTableData: ColumnsType<IPatientInfo> = [
  {
    title: <PatientsTableHeadText>ФИО</PatientsTableHeadText>,
    key: 'name',
    dataIndex: 'patientName',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    render: (data) => data,
  },
  {
    title: <PatientsTableHeadText>Телефон</PatientsTableHeadText>,
    key: 'phoneNumber',
    dataIndex: 'patientPhone',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    render: (data) => data,
  },
  {
    title: <PatientsTableHeadText>Дата крайнего приема</PatientsTableHeadText>,
    key: 'visitTime',
    dataIndex: 'callDate',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    render: (data) => moment(data).format('YYYY-MM-DD'),
  },
  {
    title: <PatientsTableHeadText>Документы</PatientsTableHeadText>,
    key: 'files',
    dataIndex: 'loadedFiles',
    width: 210,
    // eslint-disable-next-line @typescript-eslint/ban-types
    render: (loadedFiles: number) => `${loadedFiles} файлов`,
  },
];
