import { type FC } from 'react';

import { ButtonProps } from 'antd';

import { useHealthAssistanceRejectModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-reject-modal';
import { useOnlineAppointmentAcceptCancellationByAdminMutation } from 'entity/online-appointment/hooks/use-online-appointment-accept-cancellation-by-admin';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

interface Props {
  onlineAppointmentId: number;
}

export const OnlineAppointmentCancelAppointmentByAdminDropdownMenuButton: FC<Props & ButtonProps> = ({
  onlineAppointmentId,
  ...props
}) => {
  const [cancelAppointment] = useOnlineAppointmentAcceptCancellationByAdminMutation();

  const handleOnclick = () => cancelAppointment({ id: onlineAppointmentId });

  const { showRejectModal, RejectModal } = useHealthAssistanceRejectModal();

  return (
    <>
      <DropdownMenuButton onClick={showRejectModal(handleOnclick)} {...props}>
        <TypographyText>Отменить запись</TypographyText>
      </DropdownMenuButton>
      <RejectModal />
    </>
  );
};
