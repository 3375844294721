import { useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';

export const useUrlSearch = (searchKey: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get(searchKey) ?? undefined;

  const setSearch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (search: string) => {
      searchParams.set(searchKey, search);
      if (!search) {
        searchParams.delete(searchKey);
      }
      setSearchParams(searchParams);
    },
    [searchParams, searchKey, setSearchParams],
  );

  return { search, setSearch };
};
