import { InternalHomeVisitStatusEnum } from 'entity/home-visit/types/internal-home-visit-status-enum';
import { AdminHomeVisitStatusEnum } from 'shared/api/rtk-api';

export const externalHomeVisitStatusToInternalHomeVisitStatusMapper = new Map<
  AdminHomeVisitStatusEnum,
  InternalHomeVisitStatusEnum
>([
  [AdminHomeVisitStatusEnum.New, InternalHomeVisitStatusEnum.New],
  [AdminHomeVisitStatusEnum.AcceptByDoctor, InternalHomeVisitStatusEnum.AcceptByDoctor],
  [AdminHomeVisitStatusEnum.AcceptByAdmin, InternalHomeVisitStatusEnum.AcceptByAdmin],
  [AdminHomeVisitStatusEnum.InProgressByCurrentAdmin, InternalHomeVisitStatusEnum.InProgressByCurrentAdmin],
  [AdminHomeVisitStatusEnum.InProgressByAnotherAdmin, InternalHomeVisitStatusEnum.InProgressByAnotherAdmin],
]);

export const getInternalHomeVisitStatus = (status: AdminHomeVisitStatusEnum): InternalHomeVisitStatusEnum => {
  const internalStatus = externalHomeVisitStatusToInternalHomeVisitStatusMapper.get(status);
  if (!internalStatus) throw new Error(`Unknown status: ${status}`);
  return internalStatus;
};
