import { type FC } from 'react';

import { useDoctorConnectToConversationQuery } from 'entity/doctor-chat/hooks/use-doctor-connect-to-conversation-query';

import { ConversationContent } from './content';

type Props = {
  conversationId: number;
  onCloseConversation?: () => void;
};

export const DoctorConversationDetailsWidget: FC<Props> = ({ conversationId, onCloseConversation }: Props) => {
  const { conversationInfo } = useDoctorConnectToConversationQuery(conversationId);

  if (!conversationInfo) return null;

  return (
    <ConversationContent
      conversationId={conversationId}
      conversationInfo={conversationInfo}
      onCloseConversation={onCloseConversation}
    />
  );
};
