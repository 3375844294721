import { getInternalChatSupportStatus } from 'entity/chat-support/mappers/external-chat-support-status-to-internal-chat-support-status-mappper';
import type { InternalChatSupport } from 'entity/chat-support/types/internal-chat-support';
import { InternalChatSupportStatusEnum } from 'entity/chat-support/types/internal-chat-support-status-enum';
import { type ChatVisitResponse } from 'shared/api/rtk-api';
import { formatPhone, getDayWord } from 'shared/lib/format';

export const externalChatSupportToInternalChatSupportMapper = (response: ChatVisitResponse): InternalChatSupport => {
  const chatSupportStatus = getInternalChatSupportStatus(response.status);
  const isAccepted = chatSupportStatus === InternalChatSupportStatusEnum.AcceptByAdmin;
  const canBeRejected =
    chatSupportStatus === InternalChatSupportStatusEnum.InProgressByCurrentAdmin ||
    chatSupportStatus === InternalChatSupportStatusEnum.New;
  const canBeTakenInProgress = chatSupportStatus === InternalChatSupportStatusEnum.New;
  const canBeAccepted =
    (chatSupportStatus === InternalChatSupportStatusEnum.InProgressByCurrentAdmin ||
      chatSupportStatus === InternalChatSupportStatusEnum.New) &&
    Boolean(response.doctorName) &&
    response.duration > 0;

  return {
    id: response.id,
    patient: response.patientName,
    phone: formatPhone(response.patientPhone),
    specialization: response.specialization ?? '',
    doctor: response.doctorName,
    firstAvailability: response.duration ? `${response.duration} ${getDayWord(response.duration)}` : '',
    cost: response.cost ? String(response.cost) : '',
    status: chatSupportStatus,
    duration: response.duration,
    canBeTakenInProgress,
    canBeRejected,
    isAccepted,
    canBeAccepted,
    isPaid: response.isPaid,
  };
};
