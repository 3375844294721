/* eslint @typescript-eslint/no-misused-promises: "off" */

import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Col, Form, Row } from 'antd';

import { useCreateDoctorAccountMutation } from 'entity/admin/api';
import { ILoginFormFields, LoginFormFieldsEnum } from 'entity/auth/api/types';
import {
  AddAccountModalContainer,
  AddAccountModalText,
  AddAccountModalTitle,
} from 'pages/admin/doctors/ui/add-account-modal/style';
import { config } from 'shared/config';
import { AppButton } from 'shared/ui/button2';
import { GeneralModalCancelButton } from 'shared/ui/general-question-modal/style';
import { AppInput } from 'shared/ui/input2';
import { LinkButton } from 'shared/ui/link-button';
import { AppModal } from 'shared/ui/modal';
import { errorNotification } from 'shared/utils/notifications';

type Props = {
  doctorId: number;
};

export const CreateAccountModal: FC<Props> = ({ doctorId }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [createAccount, { isLoading, isError, isSuccess }] = useCreateDoctorAccountMutation();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<ILoginFormFields>();
  const { email, password } = watch();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onSubmit: SubmitHandler<ILoginFormFields> = ({ email, password }) => {
    /* eslint-disable-next-line  @typescript-eslint/no-floating-promises */
    createAccount({ email, password, doctorId });
  };

  useEffect(() => {
    if (isError) {
      errorNotification('Не удалось привязать аккаунт!');
    } else if (isSuccess) {
      setIsOpened(false);
    }
  }, [isError, isSuccess]);

  return (
    <>
      <LinkButton to="#" onClick={() => setIsOpened(true)}>
        Создать
      </LinkButton>
      <AppModal visible={isOpened} onClose={() => setIsOpened(false)} key={`add-doctor${doctorId}`}>
        <AddAccountModalContainer>
          <Row gutter={[24, 14]} justify="center" align="middle">
            <Col span={24}>
              <AddAccountModalTitle>Создать личный кабинет врача</AddAccountModalTitle>
            </Col>
            <Col span={24}>
              <AddAccountModalText>Введите данные для авторизации пользователя в Личном кабинете.</AddAccountModalText>
            </Col>
            <Col span={24}>
              <Form name="add-doctor-account">
                <Form.Item
                  validateStatus={errors?.[LoginFormFieldsEnum.EMAIL] ? 'error' : ''}
                  help={errors?.[LoginFormFieldsEnum.EMAIL] && 'Введите email!'}
                >
                  <Controller
                    name={LoginFormFieldsEnum.EMAIL}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <AppInput {...field} size="large" placeholder="email" allowClear />}
                  />
                </Form.Item>
                <Form.Item
                  validateStatus={errors?.[LoginFormFieldsEnum.PASSWORD] ? 'error' : ''}
                  help={
                    errors?.[LoginFormFieldsEnum.PASSWORD] &&
                    'Пароль должен состоять из цифр и латинских букв верхнего и нижнего регистра !'
                  }
                >
                  <Controller
                    name={LoginFormFieldsEnum.PASSWORD}
                    control={control}
                    rules={{ pattern: config.validationPatterns.password }}
                    render={({ field }) => <AppInput {...field} size="large" placeholder="пароль" allowClear />}
                  />
                </Form.Item>
              </Form>
            </Col>

            <Col span={24}>
              <Row gutter={[16, 0]} justify="center" align="middle">
                <Col span={14}>
                  {/* eslint-disable-next-line deprecation/deprecation */}
                  <AppButton
                    htmlType="submit"
                    disabled={!email || !password || isLoading}
                    loading={isLoading}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Добавить
                  </AppButton>
                </Col>
                <Col span={10}>
                  <GeneralModalCancelButton type="text" onClick={() => setIsOpened(false)}>
                    Отменить
                  </GeneralModalCancelButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </AddAccountModalContainer>
      </AppModal>
    </>
  );
};
