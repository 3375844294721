import React, { type FC, useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useHealthAssistanceRejectModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-reject-modal';
import { useHomeVisitCancelAppointmentByAdminMutation } from 'entity/home-visit/hooks/use-home-visit-cancel-appointment-by-admin-mutation';
import { useHomeVisitDetailsQuery } from 'entity/home-visit/hooks/use-home-visit-details-query';
import { routePatches } from 'shared/routes';
import { Button } from 'shared/ui/button';
import { CloseIcon } from 'shared/ui/icons';

export const HomeVisitCancelAppointmentByAdminButton: FC = () => {
  const params = useParams();
  const { homeVisit, isLoading } = useHomeVisitDetailsQuery(params.id);
  const [rejectHomeVisit] = useHomeVisitCancelAppointmentByAdminMutation();

  const navigate = useNavigate();

  const succeededActionModelHandler = useCallback(() => {
    navigate(routePatches.AdminHomeVisitList.path());
  }, [navigate]);
  const onClickHandler = () => {
    rejectHomeVisit({ id: homeVisit?.homeVisitId, onSuccess: succeededActionModelHandler });
  };
  const { showRejectModal, RejectModal } = useHealthAssistanceRejectModal();

  return (
    <>
      <Button
        loading={isLoading}
        icon={<CloseIcon />}
        onClick={showRejectModal(onClickHandler)}
        disabled={!homeVisit?.canBeCanceled}
        $buttonType="decline"
        type="primary"
        size="large"
      >
        Отменить запись
      </Button>
      <RejectModal />
    </>
  );
};
