import React, { type FC, useCallback } from 'react';

import { ButtonProps } from 'antd';

import { useChatSupportRejectRequestByAdminMutation } from 'entity/chat-support/hooks/use-chat-support-reject-request-by-admin-mutation';
import { useHealthAssistanceRejectModal } from 'entity/health-assistance/hooks/health-assistance-modals/use-health-assistance-reject-modal';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

interface Props {
  chatSupportId?: string;
}

export const ChatSupportRejectRequestByAdminDropdownMenuButton: FC<Props & ButtonProps> = ({
  chatSupportId,
  ...props
}) => {
  const [rejectHomeVisit] = useChatSupportRejectRequestByAdminMutation();

  const handleOnclick = useCallback(() => {
    rejectHomeVisit({ id: chatSupportId });
  }, [rejectHomeVisit, chatSupportId]);
  const { showRejectModal, RejectModal } = useHealthAssistanceRejectModal();

  return (
    <>
      <DropdownMenuButton onClick={showRejectModal(handleOnclick)} {...props}>
        <TypographyText>Отклонить</TypographyText>
      </DropdownMenuButton>
      <RejectModal />
    </>
  );
};
