import { type FC } from 'react';

import { Typography } from 'antd';

import { WaitScreenWrapper } from './styles';

const { Title } = Typography;

export const OnlineAppointmentWaitScreen: FC = () => {
  return (
    <WaitScreenWrapper>
      <Title level={3}>Ожидание остальных участников</Title>
    </WaitScreenWrapper>
  );
};
