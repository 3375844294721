import { useCallback } from 'react';

import { useConversationControllerDoctorReadConversationMutation } from 'shared/api/rtk-api';
import { errorNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  chatUUID: number;
}

export const useDoctorReadConversationMutation = () => {
  const [mutation] = useConversationControllerDoctorReadConversationMutation();
  const action = useCallback(
    ({ chatUUID }: MutationHookOptions) => {
      mutation({ internalConversationId: chatUUID })
        .unwrap()
        .catch(() => {
          errorNotification('Ошибка при прочтении сообщений');
        });
    },
    [mutation],
  );
  return [action];
};
