import { Link, useParams } from 'react-router-dom';

import { AdminHealthAssistanceDetailsContainer } from 'entity/health-assistance/ui/health-assistance-details-container/admin-health-assistance-details-container';
import { useOnlineAppointmentDetailsQuery } from 'entity/online-appointment/hooks/use-online-appointment-details-query';
import { formatDateDMAndCallTime } from 'shared/lib/format';
import { routePatches } from 'shared/routes';
import { CustomRow } from 'shared/ui/row/custom-row';
import { TextRow } from 'shared/ui/row/text-row';
import { UserFeedback } from 'shared/ui/user-feedback';

import { StyledText } from './styles';

export const AdminPastOnlineAppointmentDetails = () => {
  const { id } = useParams();

  const { onlineAppointment, isLoading } = useOnlineAppointmentDetailsQuery(id);

  if (isLoading) return <div>loading...</div>;
  if (!onlineAppointment) return <div>not found</div>;

  const { patientName, patientPhone, specialization, doctorName, doctorId, date, time, rating, review } =
    onlineAppointment;

  return (
    <AdminHealthAssistanceDetailsContainer>
      <TextRow title="Пациент" text={patientName} />
      <TextRow title="Телефон" text={patientPhone} />
      <TextRow title="Специализация" text={specialization} />
      <CustomRow title="Врач">
        <StyledText>
          <Link to={`${routePatches.AdminDoctorsPage.path(doctorId)}`}>{doctorName}</Link>
        </StyledText>
      </CustomRow>
      <TextRow title="Дата и время" text={formatDateDMAndCallTime(date, time)} />

      {rating && <UserFeedback rating={rating} review={review} tagTitle="oнлайн-прием" />}
    </AdminHealthAssistanceDetailsContainer>
  );
};
