import { useCallback } from 'react';

import { useInternalAuthControllerSendResetPasswordLinkMutation } from 'shared/api/enchance-endpoints';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  email?: string;
}
export const useSendResetPasswordLinkMutation = (): [
  ({ email }: MutationHookOptions) => unknown,
  {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
  },
] => {
  const [mutation, { isLoading, isError, isSuccess }] = useInternalAuthControllerSendResetPasswordLinkMutation();
  const action = useCallback(
    ({ email }: MutationHookOptions) => {
      if (!email) {
        errorNotification('Не указан email');
        return;
      }
      mutation({ email })
        .unwrap()
        .then(() => {
          successNotification('Вам на почту отправлена ссылка для восстановления пароля.');
        })
        .catch(() => {
          errorNotification('Не удалось отправить ссылку для восстановления пароля');
        });
    },
    [mutation],
  );
  return [action, { isLoading, isError, isSuccess }];
};
