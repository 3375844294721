import styled from 'styled-components';

export const EmptyDialogWrapper = styled.div`
  position: absolute;
  top: 32%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
