import { useEffect, useState } from 'react';

import moment from 'moment';

import { getDurationBetweenDates, isFirstDateAfter } from 'shared/lib/format';

type Props = { startISODate: string; endISODate: string };

export const useCalculateOnlineAppointmentTimePassed = ({ startISODate, endISODate }: Props) => {
  const [timePassed, setTimePassed] = useState<string | null>(null);

  useEffect(() => {
    const calculateTimePassed = () => {
      const currentDate = moment();

      if (isFirstDateAfter(startISODate, endISODate)) {
        setTimePassed(null);
        return;
      }

      const duration = getDurationBetweenDates(currentDate, startISODate);

      const minutes = duration.minutes();
      const seconds = duration.seconds();

      setTimePassed(`${minutes}:${seconds}`);
    };

    calculateTimePassed();

    const interval = setInterval(calculateTimePassed, 1000);

    return () => clearInterval(interval);
  }, [startISODate, endISODate]);

  return { timePassed };
};
