import { Layout } from 'antd';
import styled from 'styled-components';

export const LayoutContainer = styled(Layout)`
  min-height: 100%;
`;
export const LayoutContentContainer = styled(Layout.Content)`
  background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};
  padding-bottom: 20px;
`;

export const HeaderContainer = styled(Layout.Header)`
  z-index: 10;
  &&&.ant-layout-header {
    padding: 0 30px;
  }
  height: 100px;
  background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};
`;
