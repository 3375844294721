import { Form, Input } from 'antd';
import styled from 'styled-components';

export const PasswordResetTitle = styled.div`
  font-weight: 700;
  font-size: 27px;
  line-height: 28.6px;
  color: #3b3a3a;
`;

export const PasswordResetFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
`;

export const PasswordResetFormInput = styled(Input.Password)`
  border-radius: 24px;
  width: 320px;
  height: 40px;
`;
