import { type FC } from 'react';

import { useParams } from 'react-router-dom';

import { useChatSupportDetailsQuery } from 'entity/chat-support/hooks/use-chat-support-details-query';
import { HealthAssistanceDetailsLayout } from 'entity/health-assistance/ui/health-assistance-details-layout';
import { AdminReturnToHomeVisitListButton } from 'feature/admin-return-to-home-visit-list/ui/admin-return-to-home-visit-list-button';
import { ChatSupportApproveRequestByAdminButton } from 'feature/chat-support-approve-by-admin/ui/chat-support-approve-request-by-admin-button';
import { ChatSupportRejectRequestByAdminButton } from 'feature/chat-support-reject-request-by-admin/ui/chat-support-reject-request-by-admin-button';
import { ChatSupportToTakeOnProgressByAdminButton } from 'feature/chat-support-to-take-on-progress-by-admin/ui/chat-support-to-take-on-progress-by-admin-button';
import { ChatSupportAppointmentDetailsForm } from 'widgets/admin-chat-support-details';

export const AdminChatSupportDetailsPage: FC = () => {
  const { id } = useParams();
  const { chatSupport, isLoading } = useChatSupportDetailsQuery(id);

  if (isLoading) return null;
  if (!chatSupport) return <div>not found</div>;

  const { specialization, duration } = chatSupport;

  return (
    <HealthAssistanceDetailsLayout
      ReturnButton={<AdminReturnToHomeVisitListButton />}
      ActionButtons={[
        <ChatSupportRejectRequestByAdminButton key={1} />,
        <ChatSupportToTakeOnProgressByAdminButton key={2} />,
        <ChatSupportApproveRequestByAdminButton key={3} duration={duration} specialization={specialization} />,
      ]}
      LeftSide={<ChatSupportAppointmentDetailsForm chatSupport={chatSupport} />}
    />
  );
};
