import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';

import theme from 'shared/theme';

export type ButtonPropsType =
  | 'submit'
  | 'decline'
  | 'disabled'
  | 'declineWhite'
  | 'submitWhite'
  | 'submitWhiteDisabled';

const calculateColor = ({ $buttonType }: { $buttonType: ButtonPropsType }) => {
  switch ($buttonType) {
    case 'submit':
      return theme.colors.palette.white;
    case 'decline':
      return theme.colors.palette.purehearted;
    case 'disabled':
      return theme.colors.palette.white;
    case 'declineWhite':
      return theme.colors.palette.columnOfOakGreen;
    case 'submitWhite':
      return theme.colors.palette.columnOfOakGreen;
    case 'submitWhiteDisabled':
      return theme.colors.palette.dustblu;
    default:
      return undefined;
  }
};

const calculateBackgroundColor = ({ $buttonType }: { $buttonType: ButtonPropsType }) => {
  switch ($buttonType) {
    case 'submit':
      return theme.colors.palette.columnOfOakGreen;
    case 'decline':
      return theme.colors.palette.aquarelleRed;
    case 'declineWhite':
      return theme.colors.palette.white;
    case 'submitWhite':
      return theme.colors.palette.operaGlass;
    case 'disabled':
      return theme.colors.palette.dustblu;
    case 'submitWhiteDisabled':
      return theme.colors.palette.superSilver;
    default:
      return undefined;
  }
};

const calculateBorderColor = ({ $buttonType }: { $buttonType: ButtonPropsType }) => {
  switch ($buttonType) {
    case 'declineWhite':
      return theme.colors.palette.columnOfOakGreen;
    default:
      return undefined;
  }
};

export interface StyledButtonProps extends ButtonProps {
  $buttonType: ButtonPropsType;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  &&&& {
    > span {
      display: flex;
      align-items: center;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => calculateColor(props)};
    background-color: ${(props) => calculateBackgroundColor(props)};
    border-color: ${(props) => calculateBorderColor(props)};
    font-size: 16px;
    min-height: 40px;
  }
`;
