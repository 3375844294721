import type { FC, ReactNode } from 'react';

import { LeftContainer, MiddleContainer, RightContainer, StyledContainer } from 'shared/ui/header-layout/style';

interface Props {
  left: ReactNode;
  middle: ReactNode;
  right: ReactNode;
}

export const HeaderLayout: FC<Props> = ({ left, middle, right }) => {
  return (
    <StyledContainer>
      <LeftContainer>{left}</LeftContainer>
      <MiddleContainer>{middle}</MiddleContainer>
      <RightContainer>{right}</RightContainer>
    </StyledContainer>
  );
};
