import React, { FC, useCallback, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useHomeVisitRejectedByPatientAcceptByAdminMutation } from 'entity/home-visit/hooks/use-home-visit-rejected-by-patient-accept-by-admin-mutation';
import { useHomeVisitRejectedByPatientDetailsQuery } from 'entity/home-visit/hooks/use-home-visit-rejected-by-patient-details-query';
import { InternalHomeVisitStatusRejectedByPatientEnum } from 'entity/home-visit/types/internal-home-visit-status-rejected-by-patient-enum';
import { routePatches } from 'shared/routes';
import { Button } from 'shared/ui/button';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';
import { CheckMarkIcon } from 'shared/ui/icons';

interface Props {}

export const HomeVisitRejectedByPatientApproveByAdminButton: FC<Props> = () => {
  const [showApproveModal, setShowApproveModal] = useState(false);

  const applyButtonHandler = useCallback(() => {
    setShowApproveModal(true);
  }, []);

  const rejectActionHandler = () => setShowApproveModal(false);

  const params = useParams();
  const { homeVisit, isLoading } = useHomeVisitRejectedByPatientDetailsQuery(params.id);
  const navigate = useNavigate();
  const navigateToRejectList = useCallback(() => {
    navigate(routePatches.AdminRejectedByPatientHomeVisitList.path());
  }, [navigate]);

  const [onClickHandler] = useHomeVisitRejectedByPatientAcceptByAdminMutation();

  const confirmActionHandler = () => {
    setShowApproveModal(false);
    onClickHandler({ homeVisitId: homeVisit?.id, onSuccess: navigateToRejectList, onError: navigateToRejectList });
  };
  const isInProgressByAnotherAdmin =
    homeVisit?.status === InternalHomeVisitStatusRejectedByPatientEnum.InProgressByAnotherAdmin;
  const isDisabled =
    homeVisit?.status !== InternalHomeVisitStatusRejectedByPatientEnum.New &&
    homeVisit?.status !== InternalHomeVisitStatusRejectedByPatientEnum.InProgressByCurrentAdmin;

  return (
    <>
      <Button
        loading={isLoading}
        icon={<CheckMarkIcon />}
        onClick={applyButtonHandler}
        disabled={isDisabled}
        $buttonType="submit"
        type="primary"
        size="large"
      >
        Подтвердить отмену
      </Button>
      <CustomModal
        title="Подтвердить запись"
        description="Вы уверены, что хотите подтвердить заявку на отмену?"
        visible={showApproveModal}
        onClose={rejectActionHandler}
        actionButtons={[
          <CustomButton key={1} onClick={rejectActionHandler} $buttonType="declineWhite">
            Отмена
          </CustomButton>,
          <CustomButton key={2} onClick={confirmActionHandler} $buttonType="submit">
            Подтвердить
          </CustomButton>,
        ]}
      />
      <CustomModal
        title="Заявка на отмену в работе у другого администратора"
        visible={isInProgressByAnotherAdmin}
        onClose={navigateToRejectList}
        actionButtons={[
          <CustomButton key={2} onClick={navigateToRejectList} $buttonType="submit">
            Закрыть
          </CustomButton>,
        ]}
      />
    </>
  );
};
