import { type FC } from 'react';

import { ButtonProps } from 'antd';

import { useOnlineAppointmentAcceptCancellationByAdminMutation } from 'entity/online-appointment/hooks/use-online-appointment-accept-cancellation-by-admin';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

interface Props {
  onlineAppointmentId?: number;
}

export const OnlineAppointmentRejectedByPatientApproveByAdminDropdownMenuButton: FC<Props & ButtonProps> = ({
  onlineAppointmentId,
  ...props
}) => {
  const [acceptCancellation] = useOnlineAppointmentAcceptCancellationByAdminMutation();

  const handleClick = () => acceptCancellation({ id: onlineAppointmentId });

  return (
    <DropdownMenuButton onClick={handleClick} {...props}>
      <TypographyText>Подтвердить отмену</TypographyText>
    </DropdownMenuButton>
  );
};
