import { FC, useState } from 'react';

import { CustomButton } from 'shared/ui/custom-button';
import { CrossIcon } from 'shared/ui/icons';

import { ButtonWrapper, StyledCustomModal } from './styles';

type Props = {
  onRemoveFile: () => void;
};

export const MarketingRemovePushNotificationRecipientsFileButton: FC<Props> = ({ onRemoveFile }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleCloseModal = () => setIsModalVisible(false);

  const handleOpenModal = () => setIsModalVisible(true);

  const onSubmit = () => {
    onRemoveFile();
    handleCloseModal();
  };

  return (
    <>
      <ButtonWrapper onClick={handleOpenModal}>
        <CrossIcon />
      </ButtonWrapper>

      <StyledCustomModal
        title="Вы уверены, что хотите удалить файл?"
        visible={isModalVisible}
        onClose={handleCloseModal}
        actionButtons={[
          <CustomButton key={1} onClick={onSubmit} $buttonType="declineWhite">
            Удалить
          </CustomButton>,
          <CustomButton key={2} onClick={handleCloseModal} $buttonType="submit">
            Отменить
          </CustomButton>,
        ]}
      />
    </>
  );
};
