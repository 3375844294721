import styled from 'styled-components';

export const MainVideoWrapper = styled.div`
  border-radius: ${({ theme }) => theme.radii.extraSmall};
  display: flex;
  flex: 1;
  position: relative;
  overflow: hidden;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SecondaryVideoWrapper = styled.div`
  position: relative;
  width: 340px;
  height: 190px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radii.extraSmall};
`;

export const SecondaryVideosBlockWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 25px;
  bottom: 20px;
  left: 20px;
`;
