import { useCallback } from 'react';

import { useDoctorNotificationControllerViewNotificationsMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  id?: string;
  onSuccess?: () => void;
}
export const useViewDoctorNotificationsMutation = () => {
  const [mutation] = useDoctorNotificationControllerViewNotificationsMutation();
  const action = useCallback(
    ({ id, onSuccess }: MutationHookOptions) => {
      if (!id) {
        errorNotification('Не удалось получить id уведомления');
        return;
      }
      mutation({ notificationsIds: [id] })
        .unwrap()
        .then(() => {
          successNotification('Уведомление прочитано');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Не удалось прочитать уведомление');
        });
    },
    [mutation],
  );
  return [action];
};
