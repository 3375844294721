import { FC } from 'react';

import { Row, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import { LeftOutlined } from '@ant-design/icons';
import { BackButton } from 'feature/navigation/ui/styles';

type Props = {
  title?: string;
};

export const BackButtonSubheader: FC<Props> = ({ title = 'Назад' }) => {
  const navigate = useNavigate();

  return (
    <Row>
      <BackButton to="#" onClick={() => navigate(-1)}>
        <Space>
          <LeftOutlined />
          {title}
        </Space>
      </BackButton>
    </Row>
  );
};
