import { type FC, type ReactNode, useState } from 'react';

import { Popover } from 'antd';

import { ScheduleEventType } from 'entity/schedule/constants';
import type { InternalScheduleHomeVisitEvent } from 'entity/schedule/types/internal-schedule-home-visit-event';
import type { InternalScheduleOnlineAppointmentEvent } from 'entity/schedule/types/internal-schedule-online-appointment-event';

import { HomeVisitEventDetails } from './details/home-visit-event-details';
import { OnlineAppointmentEventDetails } from './details/online-appointment-event-details';

type ContentProps = {
  event: InternalScheduleHomeVisitEvent | InternalScheduleOnlineAppointmentEvent;

  closeModal: () => void;
};

type Props = {
  event: InternalScheduleHomeVisitEvent | InternalScheduleOnlineAppointmentEvent;
  children: ReactNode;
};

const Content: FC<ContentProps> = ({ event, closeModal }) => {
  const isOnline = event.type === ScheduleEventType.ONLINE;

  if (isOnline) return <OnlineAppointmentEventDetails event={event} closeModal={closeModal} />;

  return <HomeVisitEventDetails event={event} closeModal={closeModal} />;
};

export const DoctorScheduleEventDetailsPopover: FC<Props> = ({ children, event }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleClickModal = () => setIsModalVisible((prev) => !prev);

  return (
    <Popover
      overlayClassName="schedule-event-details-popover"
      placement="right"
      trigger="click"
      open={isModalVisible}
      onOpenChange={(open) => setIsModalVisible(open)}
      destroyTooltipOnHide
      content={<Content event={event} closeModal={handleClickModal} />}
    >
      {children}
    </Popover>
  );
};
