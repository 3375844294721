import React, { type FC, useState } from 'react';

import { type TableColumnsType } from 'antd';
import { useNavigate } from 'react-router-dom';

import { SearchParams } from 'entity/health-assistance/constants';
import { useUrlStatusesArray } from 'entity/health-assistance/hooks/use-url-statuses-array';
import { HealthAssistanceStatusDropdownCheckList } from 'entity/health-assistance/ui/health-assistance-status-dropdown-check-list/health-assistance-status-dropdown-check-list';
import { homeVisitStatusOptions } from 'entity/home-visit/constant';
import { useHomeVisitListQuery } from 'entity/home-visit/hooks/use-home-visit-list-query';
import type { InternalHomeVisitList } from 'entity/home-visit/types/internal-home-visit-list';
import { InternalHomeVisitStatusEnum } from 'entity/home-visit/types/internal-home-visit-status-enum';
import { HomeVisitStatusTag } from 'entity/home-visit/ui/home-visit-status-tag';
import { useUrlPagination } from 'shared/hooks/use-url-pagination';
import { useUrlSearch } from 'shared/hooks/use-url-search';
import { routePatches } from 'shared/routes';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';
import { Table } from 'shared/ui/table';
import { HomeVisitTableDropdownMenu } from 'widgets/admin-home-visit-table/ui/home-visit-table-dropdown-menu';

const DEFAULT_PAGE_SIZE = 7;
const DEFAULT_PAGE = 1;

export const HomeVisitTable: FC = () => {
  const { setPage, page } = useUrlPagination();
  const navigate = useNavigate();
  const [showInProgressModal, setShowInProgressModal] = useState(false);
  const { search: name } = useUrlSearch(SearchParams.Search);
  const { search: date } = useUrlSearch(SearchParams.Date);
  const { statuses } = useUrlStatusesArray(homeVisitStatusOptions);

  const columns: TableColumnsType<InternalHomeVisitList> = [
    {
      title: 'Пациент',
      dataIndex: 'patient',
      ellipsis: true,
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      ellipsis: true,
    },
    {
      title: 'Специализация',
      dataIndex: 'specialization',
      ellipsis: true,
    },
    {
      title: 'Дата Приема',
      dataIndex: 'date',
      ellipsis: true,
    },
    {
      title: 'Время Приема',
      dataIndex: 'time',
      ellipsis: true,
    },
    {
      title: 'Врач',
      dataIndex: 'doctor',
      ellipsis: true,
    },
    {
      title: 'Адрес вызова',
      dataIndex: 'address',
      ellipsis: true,
    },
    {
      title: 'Стоимость, ₽',
      dataIndex: 'cost',
      ellipsis: true,
    },
    {
      title: 'Способ оплаты',
      dataIndex: 'paymentMethod',
      ellipsis: true,
    },
    {
      title: 'Оплачено',
      dataIndex: 'isPaid',
      ellipsis: true,
      render: (isPaid: boolean) => (isPaid ? 'Да' : 'Нет'),
    },
    {
      title: <HealthAssistanceStatusDropdownCheckList items={homeVisitStatusOptions} />,
      dataIndex: 'status',
      width: 130,
      render: (_, row) => {
        const { status } = row;

        return <HomeVisitStatusTag appointmentStatus={status} />;
      },
    },
    {
      width: 60,
      render: (_, row) => {
        return <HomeVisitTableDropdownMenu homeVisit={row} />;
      },
    },
  ];

  const { homeVisits, total } = useHomeVisitListQuery({
    page,
    pageSize: DEFAULT_PAGE_SIZE,
    name,
    date,
    statuses,
  });

  const inProgressModalHandler = () => {
    setShowInProgressModal(false);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={homeVisits}
        rowKey={(row) => row.id}
        onRow={(record) => {
          return {
            onClick: () => {
              if (record.status === InternalHomeVisitStatusEnum.InProgressByAnotherAdmin) {
                setShowInProgressModal(true);
              } else {
                navigate(routePatches.AdminHomeVisitDetails.path(record.id));
              }
            },
          };
        }}
        pagination={{
          current: page ?? DEFAULT_PAGE,
          total,
          onChange: (e) => setPage(e),
          pageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: false,
        }}
      />
      <CustomModal
        title="Данная заявка взята в работу другим администратором"
        visible={showInProgressModal}
        onClose={inProgressModalHandler}
        actionButtons={[
          <CustomButton key={1} onClick={inProgressModalHandler} $buttonType="submit">
            Закрыть
          </CustomButton>,
        ]}
      />
    </>
  );
};
