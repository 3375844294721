import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { routePatches } from 'shared/routes';
import { AddButton } from 'shared/ui/add-button';

export const MarketingCreateNewPushNotificationButton: FC = () => {
  const navigate = useNavigate();

  const handleClick = () => navigate(routePatches.ManagerPushNotificationForm.path());

  return <AddButton onClick={handleClick}>Создать пуш-уведомление</AddButton>;
};
