import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { PushNotificationFormType } from '../../types';
import { StyledCheckbox, StyledSelectRow } from './styles';

const { Option } = Select;

type Options = DefaultOptionType[];

type Props = {
  control: Control<PushNotificationFormType>;
  options: Options;
  fieldName: keyof PushNotificationFormType;
  title: string;
};

export const FormSelect: FC<Props> = ({ control, options, fieldName, title }) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <StyledSelectRow {...field} optionLabelProp="label" title={title}>
          {options.map((item) => (
            <Option key={item.value} value={item.value} label={item.label}>
              <StyledCheckbox checked={field.value === item.value}>{item.label}</StyledCheckbox>
            </Option>
          ))}
        </StyledSelectRow>
      )}
    />
  );
};
