import { type FC } from 'react';

import { CameraIcon } from 'shared/ui/icons';

import { useCalculateOnlineAppointmentTimePassed } from '../hooks/use-calculate-online-appointment-time-passed';
import { DurationBadge, PageTitle, PageTitleWrapper } from './styles';

const START_DATE = '2024-09-06T09:00:00.000Z';
const END_DATE = '2024-09-06T08:30:00.000Z';

export const DoctorOnlineAppointmentHeaderWidget: FC = () => {
  const { timePassed } = useCalculateOnlineAppointmentTimePassed({
    startISODate: START_DATE,
    endISODate: END_DATE,
  });

  return (
    <PageTitleWrapper direction="horizontal" size={20}>
      <CameraIcon />

      <PageTitle>Онлайн-прием</PageTitle>

      {timePassed && (
        <DurationBadge>
          <span>{timePassed}</span>
        </DurationBadge>
      )}
    </PageTitleWrapper>
  );
};
