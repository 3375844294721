import styled from 'styled-components';

export const SchedulePageContainer = styled.div`
  height: 100%;
`;

export const SchedulePageHead = styled.div`
  padding: 0 30px;
`;

export const SchedulePageContent = styled.div`
  height: 90%;
`;

export const SchedulePageTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
