import { getInternalHomeVisitStatus } from 'entity/home-visit/mappers/external-home-visit-status-to-internal-home-visit-status-mapper';
import type { InternalHomeVisitList } from 'entity/home-visit/types/internal-home-visit-list';
import { InternalHomeVisitStatusEnum } from 'entity/home-visit/types/internal-home-visit-status-enum';
import { AdminHomeVisitStatusEnum, type HomeVisitFormResponse } from 'shared/api/rtk-api';
import { formatDateDMY, formatPhone } from 'shared/lib/format';

export const externalHomeVisitListToInternalHomeVisitListMapper = (
  homeVisitForm: HomeVisitFormResponse,
): InternalHomeVisitList => {
  const internalHomeVisitStatus = getInternalHomeVisitStatus(homeVisitForm.status);
  const inProgress =
    internalHomeVisitStatus === InternalHomeVisitStatusEnum.InProgressByCurrentAdmin ||
    internalHomeVisitStatus === InternalHomeVisitStatusEnum.InProgressByAnotherAdmin;
  const { isAccepted } = homeVisitForm;

  const canBeRejected = !isAccepted && internalHomeVisitStatus !== InternalHomeVisitStatusEnum.InProgressByAnotherAdmin;
  const canBeCanceled = isAccepted && internalHomeVisitStatus !== InternalHomeVisitStatusEnum.InProgressByAnotherAdmin;
  const canBeTakenInProgress = homeVisitForm.status === AdminHomeVisitStatusEnum.New;
  const canBeTakenInEdit = isAccepted && !inProgress;

  return {
    id: homeVisitForm.id,
    patient: homeVisitForm.patientName,
    phone: formatPhone(homeVisitForm.patientPhone),
    specialization: homeVisitForm.specialization,
    date: formatDateDMY(homeVisitForm.callDate),
    time: homeVisitForm.callTime,
    doctor: homeVisitForm.doctorName ?? '',
    address: homeVisitForm.patientAddress,
    cost: homeVisitForm.cost,
    paymentMethod: homeVisitForm.payMethod,
    isPaid: homeVisitForm.isPaid,
    status: internalHomeVisitStatus,
    canBeTakenInProgress,
    canBeRejected,
    canBeCanceled,
    canBeTakenInEdit,
    isAccepted,
  };
};
