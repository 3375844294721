import { Button, Row } from 'antd';
import styled from 'styled-components';

export const PatientInfoRow = styled(Row)`
  margin: 0px !important;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px 8px;
`;

export const PatientInfoButton = styled(Button)<{ width?: number }>`
  background-color: #e5f1eb !important;
  border-radius: 32px;
  color: ${({ theme }) => theme.colors.c1};
  height: 40px;
  width: ${(props) => `${props.width}px` || 'auto'};
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: 400;

  :hover {
    color: ${({ theme }) => theme.colors.c1} !important;
    background-color: #c5efda !important;
  }

  span {
    vertical-align: text-top;
    line-height: 22px;
  }
`;
