import { type FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Col, Form, Row, Typography } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import moment, { Moment } from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { MAX_DESCRIPTION_LENGTH, MAX_TITLE_LENGTH, RecipientType, statusQueries } from 'entity/marketing/constants';
import { useCreatePushNotificationByManagerWithListMutation } from 'entity/marketing/hooks/use-create-push-nitification-by-manager-with-list';
import { useCreatePushNotificationByManagerManuallyMutation } from 'entity/marketing/hooks/use-create-push-notification-by-manager-manually';
import { useEditPushNotificationByManagerManuallyMutation } from 'entity/marketing/hooks/use-edit-push-notification-by-manager-manually-mutation';
import { useEditPushNotificationByManagerWithListMutation } from 'entity/marketing/hooks/use-edit-push-notification-by-manager-with-list-mutation';
import { getStartOfTheDate, setHoursAndMinutes } from 'shared/lib/format';
import { routePatches } from 'shared/routes';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomRow } from 'shared/ui/row/custom-row';

import {
  ageOptions,
  childrenOptions,
  initialValues,
  recipientsOptions,
  requestAgeOptions,
  requestChildrenOptions,
  requestSexOptions,
  sexOptions,
} from '../../constants';
import { PushNotificationFormType } from '../../types';
import { FormSelect } from '../form-select';
import { FormUploader } from '../form-uploader';
import { ReturnButtonBlock } from '../return-button-block';
import { UploadedFile } from '../uploaded-file';
import {
  ContentWrapper,
  StyledDatePicker,
  StyledForm,
  StyledFormItem,
  StyledRadio,
  StyledTextArea,
  StyledTimePicker,
} from './styles';

const { Title } = Typography;
const { Item } = Form;

type Props = {
  defaultValues?: PushNotificationFormType;
};

export const PushNotificationForm: FC<Props> = ({ defaultValues }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formMode = searchParams.get('mode');

  const isReuse = formMode === statusQueries.sended;

  const [createManually] = useCreatePushNotificationByManagerManuallyMutation();
  const [createWithList] = useCreatePushNotificationByManagerWithListMutation();
  const [editWithList] = useEditPushNotificationByManagerWithListMutation();
  const [editManually] = useEditPushNotificationByManagerManuallyMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
    formState,
  } = useForm<PushNotificationFormType>({
    defaultValues: initialValues,
  });

  const isFormUpdated = Object.keys(formState.dirtyFields).length > 0;

  useEffect(() => {
    reset(defaultValues);
    if (isReuse) {
      setValue('time', null);
      setValue('date', moment());
    }
  }, [defaultValues, isReuse, setValue, reset]);

  const { recipients, fileName } = watch();

  const isManualRecipients = recipients === RecipientType.MANUAL;
  const isListRecipients = recipients === RecipientType.LIST;

  const handleReturnToMain = () => navigate(routePatches.ManagerPushNotificationList.path());

  const handleReturnBack = () => navigate(-1);

  const onSubmit: SubmitHandler<PushNotificationFormType> = (data) => {
    let isoSendDateTime = '';

    if (data.time && data.date) {
      const [hh, mm] = data.time.split('.');
      isoSendDateTime = setHoursAndMinutes(getStartOfTheDate(data.date), +hh, +mm).toISOString();
    }

    if (isManualRecipients) {
      const notificationDTO = {
        name: data.title ?? '',
        description: data.description ?? '',
        isoSendDateTime,
        genderFilter: data.sex ? requestSexOptions[data.sex] : [],
        ageFilter: data.age ? requestAgeOptions[data.age] : [],
        childFilter: data.children ? requestChildrenOptions[data.children] : [],
      };

      if (data.id && !isReuse) {
        editManually({
          requestDto: {
            id: data.id,
            updateMarketingMailingByFiltersRequestDto: notificationDTO,
          },
          onSuccess: handleReturnBack,
        });
      } else {
        createManually({
          requestDto: notificationDTO,
          onSuccess: handleReturnToMain,
        });
      }
    }

    if (isListRecipients) {
      const notificationDTO = {
        name: data.title ?? '',
        description: data.description ?? '',
        isoSendDateTime,
        aggregatedPatientExternalIds: data.aggregatedPatientExternalIds ?? [],
        fileName: data.fileName ?? '',
      };

      if (data.id && !isReuse) {
        editWithList({
          requestDto: {
            id: data.id,
            updateMarketingMailingByIdsRequestDto: notificationDTO,
          },
          onSuccess: handleReturnBack,
        });
      } else {
        createWithList({
          requestDto: notificationDTO,
          onSuccess: handleReturnToMain,
        });
      }
    }
  };

  const disabledDate: RangePickerProps<Moment>['disabledDate'] = (current) => current < moment().startOf('day');

  const handleRemoveFile = () => {
    setValue('file', undefined);
    setValue('fileName', undefined);
    setValue('aggregatedPatientExternalIds', undefined);
  };

  return (
    <ContentWrapper>
      <ReturnButtonBlock isFormUpdated={isFormUpdated} />

      <StyledForm name="push-notification-form">
        <Row>
          <Col>
            <StyledFormItem validateStatus={errors.title ? 'error' : ''}>
              <Controller
                name="title"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomRow title="Название">
                    <StyledTextArea
                      showCount
                      maxLength={MAX_TITLE_LENGTH}
                      placeholder="Введите название пуш-уведомления"
                      autoSize={{ maxRows: 2 }}
                      {...field}
                    />
                  </CustomRow>
                )}
              />
            </StyledFormItem>
          </Col>
        </Row>

        <Row>
          <Col>
            <StyledFormItem validateStatus={errors.description ? 'error' : ''}>
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomRow title="Описание">
                    <StyledTextArea
                      showCount
                      maxLength={MAX_DESCRIPTION_LENGTH}
                      placeholder="Введите описание пуш-уведомления"
                      resize="vertical"
                      {...field}
                    />
                  </CustomRow>
                )}
              />
            </StyledFormItem>
          </Col>
        </Row>

        <Row gutter={25}>
          <Col>
            <StyledFormItem validateStatus={errors.date ? 'error' : ''}>
              <Controller
                name="date"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomRow title="Дата отправки">
                    <StyledDatePicker placeholder="Выберите дату" disabledDate={disabledDate} {...field} />
                  </CustomRow>
                )}
              />
            </StyledFormItem>
          </Col>
          <Col>
            <StyledFormItem validateStatus={errors.time ? 'error' : ''}>
              <Controller
                name="time"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomRow title="Время отправки">
                    <StyledTimePicker allowClear placeholder="Выберите время" {...field} />
                  </CustomRow>
                )}
              />
            </StyledFormItem>
          </Col>
        </Row>

        <Title level={4}>Получатели</Title>

        <Row>
          <Col>
            <StyledFormItem validateStatus={errors.recipients ? 'error' : ''}>
              <Controller
                name="recipients"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <StyledRadio options={recipientsOptions} {...field} />}
              />
            </StyledFormItem>
          </Col>
        </Row>

        {isListRecipients && !fileName && (
          <Item
            validateStatus={errors.file ? 'error' : ''}
            validateTrigger="onBlur"
            help={errors.file && 'Нужно загрузить файл!'}
          >
            <FormUploader control={control} fieldName="file" isRequire={isListRecipients} setValue={setValue} />
          </Item>
        )}

        {fileName && isListRecipients && <UploadedFile onRemoveFile={handleRemoveFile} fileName={fileName} />}

        {isManualRecipients && (
          <Row gutter={25}>
            <Col>
              <StyledFormItem validateStatus={errors.sex ? 'error' : ''}>
                <FormSelect control={control} options={sexOptions} fieldName="sex" title="Пол" />
              </StyledFormItem>
            </Col>

            <Col>
              <StyledFormItem validateStatus={errors.age ? 'error' : ''}>
                <FormSelect control={control} options={ageOptions} fieldName="age" title="Возраст" />
              </StyledFormItem>
            </Col>

            <Col>
              <StyledFormItem validateStatus={errors.children ? 'error' : ''}>
                <FormSelect control={control} options={childrenOptions} fieldName="children" title="Наличие детей" />
              </StyledFormItem>
            </Col>
          </Row>
        )}

        <Row gutter={25}>
          <Col>
            <CustomButton type="text" $buttonType="declineWhite" loading={false} onClick={handleReturnBack}>
              Отменить
            </CustomButton>
          </Col>
          <Col>
            <CustomButton
              type="text"
              $buttonType="submit"
              htmlType="submit"
              loading={false}
              /* eslint-disable-next-line @typescript-eslint/no-misused-promises, deprecation/deprecation */
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </CustomButton>
          </Col>
        </Row>
      </StyledForm>
    </ContentWrapper>
  );
};
