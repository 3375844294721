import React, { type FC, useCallback, useState } from 'react';

import type { ButtonProps } from 'antd/lib/button/button';
import { useNavigate } from 'react-router-dom';

import { routePatches } from 'shared/routes';
import { CustomButton } from 'shared/ui/custom-button';
import { CustomModal } from 'shared/ui/custom-modal';
import { ReturnButton } from 'shared/ui/return-button';

export const AdminReturnToHomeVisitListButtonWithConfirmation: FC<ButtonProps> = ({ ...props }) => {
  const navigate = useNavigate();

  const [showSaveModal, setShowSaveModal] = useState(false);

  const onClickHandler = useCallback(() => {
    setShowSaveModal(true);
  }, []);

  const rejectActionHandler = () => setShowSaveModal(false);

  const confirmActionHandler = () => {
    navigate(routePatches.AdminHomeVisitDetails.path());
  };

  return (
    <div>
      <ReturnButton {...props} onClick={onClickHandler}>
        Записи
      </ReturnButton>
      <CustomModal
        title="Покинуть страницу"
        description="Есть несохраненные изменения. Вы уверены, что хотите покинуть страницу?"
        visible={showSaveModal}
        onClose={rejectActionHandler}
        actionButtons={[
          <CustomButton key={1} onClick={confirmActionHandler} $buttonType="declineWhite">
            Покинуть страницу
          </CustomButton>,
          <CustomButton key={2} onClick={rejectActionHandler} $buttonType="submit">
            Остаться
          </CustomButton>,
        ]}
      />
    </div>
  );
};
