import { FC } from 'react';

import { Map, Placemark, YMaps, ZoomControl } from '@pbe/react-yandex-maps';
import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import useGeocoder from 'pages/doctor/home-visit-details/hooks/use-geo-coder';
import { SplitScreenContainer } from 'pages/doctor/home-visit-details/ui/yandex-map/styles';

interface YandexMapProps {
  address: string;
}

export const YandexMap: FC<YandexMapProps> = ({ address }) => {
  const { coordinates, loading, error, longitude, latitude } = useGeocoder({ address });
  if (loading || !coordinates) {
    return (
      <SplitScreenContainer>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </SplitScreenContainer>
    );
  }

  if (error) {
    return <div>Ошибка: {error}</div>;
  }

  return (
    <YMaps>
      <Map defaultState={{ center: [latitude, longitude], zoom: 15 }} width="100%" height="100%">
        <Placemark geometry={[latitude, longitude]} />
        <ZoomControl
          options={{
            size: 'small',
            position: {
              bottom: '50vh',
              right: 10,
            },
          }}
        />
      </Map>
    </YMaps>
  );
};
