import { FilePayload, MessageEnvelope, MessagePayload } from '@pubnub/react-chat-components';
import moment from 'moment-timezone';

import { timeConvertor } from 'shared/lib/format';

export enum MessageEnum {
  EMPTY,
  DEFAULT_MESSAGE,
  FILE,
}

export enum MessageType {
  TEXT = 'text',
  FILE = 'file',
}

interface MessageHelperPayload {
  time: string;
  userMessage: string;
  type: MessageEnum;
  url: string;
  fileName?: string;
  fileId?: string;
}

type MessageEnvelopeType = MessageEnvelope[] | MessageEnvelope;
type MessagePayloadType = MessagePayload | FilePayload;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const messageConvertor = (message: any): MessageHelperPayload => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  if (message?.text?.type === MessageType.TEXT) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      userMessage: message?.text?.message,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-use-before-define
      time: moment(message.createdAt).format('HH:mm'),
      type: MessageEnum.DEFAULT_MESSAGE,
      url: '',
    };
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  if (message?.text?.type === MessageType.FILE) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      userMessage: message?.text?.filename,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-use-before-define
      time: moment(message.createdAt).format('HH:mm'),
      type: MessageEnum.DEFAULT_MESSAGE,
      url: '',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      fileId: message?.text?.fileId,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (message?.file) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      userMessage: message?.message?.text,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      fileName: message.file.name,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument,@typescript-eslint/no-use-before-define
      time: timeConvertor(message?.message?.createdAt),
      type: MessageEnum.FILE,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
      url: message?.file.url,
    };
  }
  // eslint-disable-next-line no-param-reassign
  message = message as MessagePayload;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (message?.text) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      userMessage: message.text as string,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
      time: moment(message.createdAt).format('HH:mm'),
      type: MessageEnum.DEFAULT_MESSAGE,
      url: '',
    };
  }
  return {
    userMessage: '',
    time: '',
    url: '',
    type: MessageEnum.EMPTY,
  };
};

export const messageHelperByChannel = (
  messageEnvelope: MessageEnvelopeType | MessagePayloadType,
): MessageHelperPayload => {
  if (Array.isArray(messageEnvelope) && messageEnvelope.length) {
    const { message } = messageEnvelope.at(-1)!;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return messageConvertor(message);
  }

  return messageConvertor(messageEnvelope);
};
