import { FC } from 'react';

import { ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { buttonTitles, statusQueries } from 'entity/marketing/constants';
import { InternalPushNotificationStatusEnum } from 'entity/marketing/types/internal-push-notification';
import { routePatches } from 'shared/routes';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

type Props = {
  status: InternalPushNotificationStatusEnum;
  notificationId: number;
};

export const MarketingEditPushNotificationButton: FC<Props & ButtonProps> = ({ status, notificationId, ...props }) => {
  const navigate = useNavigate();

  const handleClick = () =>
    navigate(`${routePatches.ManagerPushNotificationForm.path(notificationId)}?mode=${statusQueries[status]}`);

  return (
    <DropdownMenuButton onClick={handleClick} {...props}>
      <TypographyText>{buttonTitles[status]}</TypographyText>
    </DropdownMenuButton>
  );
};
