import { AgeType } from 'shared/api/rtk-api';

import { AgeOptionsOptions } from '../constants';

const ageOptionsMap = new Map<AgeType, AgeOptionsOptions>([
  [AgeType.$1825, AgeOptionsOptions.EIGHTEEN],
  [AgeType.$2635, AgeOptionsOptions.TWENTY],
  [AgeType.$3655, AgeOptionsOptions.THIRTY],
  [AgeType.$5665, AgeOptionsOptions.FIFTY],
  [AgeType.$66, AgeOptionsOptions.SIXTY],
]);
export const getInternalPushNotificationAgeOptions = (ageOption: AgeType): AgeOptionsOptions => {
  const internalAgeOption = ageOptionsMap.get(ageOption);
  if (!internalAgeOption) throw new Error(`Unknown age option: ${ageOption}`);
  return internalAgeOption;
};
