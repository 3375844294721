import { useMemo } from 'react';

import { useMarketingMailingControllerFindOneQuery } from 'shared/api/rtk-api';

import { externalPushNotificationToInternalPushNotificationFormMapper } from '../mappers/external-push-notification-details-to-internal-push-notification-details-mapper';

export const usePushNotificationDetailsQuery = (id: string) => {
  const { data, ...others } = useMarketingMailingControllerFindOneQuery(+id, {
    skip: !id,
  });

  const pushNotification = useMemo(() => {
    return data ? externalPushNotificationToInternalPushNotificationFormMapper(data) : undefined;
  }, [data]);

  return {
    pushNotification,
    ...others,
  };
};
