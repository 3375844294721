import styled from 'styled-components';

import { CustomModal } from 'shared/ui/custom-modal';

export const StyledCustomModal = styled(CustomModal)`
  .ant-modal-content {
    width: 460px;
  }
`;

export const WarningWrapper = styled.div`
  padding: 16px 28px;
  border-radius: ${({ theme }) => theme.radii.default};
  border: 1px solid ${({ theme }) => theme.colors.palette.purehearted};
  background-color: #fff5f6;
  font-size: ${({ theme }) => theme.fontSizes.default};
`;
