import { Row } from 'antd';
import styled from 'styled-components';

import { CustomTimePicker } from 'shared/ui/custom-time-picker';
import { DatePicker } from 'shared/ui/data-picker';

export const Label = styled.p`
  margin-bottom: 4px;
`;

export const CloseIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;

  svg path {
    stroke: ${({ theme }) => theme.colors.palette.deadPixel};
  }
`;

export const StyledPickersRow = styled(Row)`
  margin-bottom: 20px;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 194px;
  border-radius: ${({ theme }) => theme.radii.small};

  &.ant-picker {
    background: ${({ theme }) => theme.colors.palette.whiteSmoke};

    .ant-picker-clear {
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background-color: ${({ theme }) => theme.colors.palette.whiteSmoke};

      .ant-icon,
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
`;

export const StyledCustomTimePicker = styled(CustomTimePicker)`
  width: 194px;
`;
