import { type FC, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useDoctorConnectToOnlineAppointmentQuery } from 'entity/online-appointment/hooks/use-doctor-connect-to-online-appointment-query';
import { OnlineAppointmentChatIcon } from 'shared/ui/icons';
import { DoctorConversationDetailsWidget } from 'widgets/doctor-conversation-details-widget/ui';

import { ActionButton, ConversationDetailsWrapper } from './styles';

export const DoctorOnlineAppointmentConversationButton: FC = () => {
  const { appointmentId } = useParams();

  const [isChatVisible, setIsChatVisible] = useState<boolean>(false);

  const { conversationId } = useDoctorConnectToOnlineAppointmentQuery(appointmentId);

  const toggleChatVisibility = () => setIsChatVisible((prev) => !prev);

  return (
    <>
      <ActionButton shape="round" onClick={toggleChatVisibility}>
        <OnlineAppointmentChatIcon />
      </ActionButton>
      {isChatVisible && conversationId && (
        <ConversationDetailsWrapper>
          <DoctorConversationDetailsWidget onCloseConversation={toggleChatVisibility} conversationId={conversationId} />
        </ConversationDetailsWrapper>
      )}
    </>
  );
};
