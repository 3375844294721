/* eslint react/no-unstable-nested-components: 0 */
import React, { FC, PropsWithChildren } from 'react';

import { Modal } from 'antd';

import { ModalBodyContainer } from 'shared/ui/modal/styles';

type Props = PropsWithChildren<{
  visible: boolean;
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
}>;

export const AppModal: FC<Props> = ({ visible, onClose, children }) => {
  return (
    <Modal
      centered
      maskClosable
      destroyOnClose
      closable={false}
      footer={null}
      onCancel={onClose}
      visible={visible}
      bodyStyle={{ borderRadius: '20px' }}
      modalRender={() => <ModalBodyContainer>{children}</ModalBodyContainer>}
      onOk={() => {}}
    />
  );
};
