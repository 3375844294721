import React, { type FC } from 'react';

import { StyledTitle } from './styles';

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
}

export const Title: FC<Props> = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  return <StyledTitle level={1}>{props.children}</StyledTitle>;
};
