import { type FC } from 'react';

import { ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useOnlineAppointmentAssignCancellationByAdminMutation } from 'entity/online-appointment/hooks/use-online-appointment-assing-cancellation-by-admin';
import { routePatches } from 'shared/routes';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

interface Props {
  onlineAppointmentId: number;
}

export const OnlineAppointmentRejectedByPatientToTakeOnByAdminDropdownMenuButton: FC<Props & ButtonProps> = ({
  onlineAppointmentId,
  ...props
}) => {
  const navigate = useNavigate();

  const [assignCancellation] = useOnlineAppointmentAssignCancellationByAdminMutation();

  const successHandler = () =>
    navigate(routePatches.AdminRejectedByPatientOnlineAppointmentDetails.path(onlineAppointmentId));

  const handleClick = () => assignCancellation({ id: onlineAppointmentId, onSuccess: successHandler });

  return (
    <DropdownMenuButton onClick={handleClick} {...props}>
      <TypographyText>Взять в работу</TypographyText>
    </DropdownMenuButton>
  );
};
