import { useCallback } from 'react';

import { useOnlineVisitSlotControllerDeleteSlotMutation } from 'shared/api/rtk-api';
import { errorNotification, successNotification } from 'shared/utils/notifications';

interface MutationHookOptions {
  slotId: number;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useScheduleDeleteSlotByDoctorMutation = () => {
  const [mutation] = useOnlineVisitSlotControllerDeleteSlotMutation();
  const action = useCallback(
    ({ slotId, onSuccess, onError }: MutationHookOptions) => {
      mutation({ slotId })
        .unwrap()
        .then(() => {
          successNotification('Слот успешно удален');
          onSuccess?.();
        })
        .catch(() => {
          errorNotification('Ошибка при удалении слота');
          onError?.();
        });
    },
    [mutation],
  );
  return [action];
};
