import { FC, PropsWithChildren } from 'react';

import { Outlet } from 'react-router-dom';

import { GeneralLayout } from 'shared/ui/general-layout';
import { MarketingHeader } from 'widgets/marketing-header/ui';

import { StyledDiv } from './styles';

export const MarketingLayout: FC<PropsWithChildren> = () => {
  return (
    <GeneralLayout
      headerSlot={<MarketingHeader />}
      pageSlot={
        <StyledDiv>
          <Outlet />
        </StyledDiv>
      }
    />
  );
};
