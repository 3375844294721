import { type FC } from 'react';

import { useDaily, useLocalSessionId } from '@daily-co/daily-react';
import { useParticipantAudioVideoTrack } from 'entity/daily/hooks/use-participant-audio-video-track';
import { MutedOnlineAppointmentMicrophoneIcon, OnlineAppointmentMicrophoneIcon } from 'shared/ui/icons';

import { ActionButton } from './styles';

export const DoctorOnlineAppointmentMicrophoneButton: FC = () => {
  const callFrame = useDaily();
  const participantId = useLocalSessionId();

  const { mutedAudio } = useParticipantAudioVideoTrack({
    participantId,
  });

  const toggleAudio = () => callFrame?.setLocalAudio(mutedAudio);

  return (
    <ActionButton shape="round" onClick={toggleAudio}>
      {!mutedAudio ? <OnlineAppointmentMicrophoneIcon /> : <MutedOnlineAppointmentMicrophoneIcon />}
    </ActionButton>
  );
};
