import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query/react';

import { externalToInternalDoctorMapper } from 'entity/doctor/mappers/external-to-internal-doctor-mapper';
import { useHomeVisitControllerFindDoctorsQuery } from 'shared/api/rtk-api';

export const useDoctorListQuery = ({ cityId }: { cityId?: number }) => {
  const { data, ...others } = useHomeVisitControllerFindDoctorsQuery(
    cityId
      ? {
          cityId,
        }
      : skipToken,
  );

  const doctors = useMemo(() => data?.data.map(externalToInternalDoctorMapper) ?? [], [data]);

  return {
    doctors,
    ...others,
  };
};
