import { Col, Row } from 'antd';
import styled from 'styled-components';

export const ScheduleGridContainer = styled.div`
  height: calc(100% - 65px);
`;

export const ScheduleGridRow = styled(Row)`
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.palette.snowflake};
`;

export const ScheduleGridCol = styled(Col)`
  padding: 5px;
  border-left: 0.5px solid ${({ theme }) => theme.colors.palette.snowflake};
  align-items: stretch;
`;

export const ScheduleGridRowTime = styled.div`
  text-align: center;
`;
