import { FC, ReactNode } from 'react';

import { StyledTag, StyledText, type TagSize, type TagType } from 'shared/ui/tag/styles';

interface TagProps {
  children?: ReactNode;
  $tagType?: TagType;
  $tagSize?: TagSize;
  onClick?: () => void;
}

export const Tag: FC<TagProps> = ({ children, $tagType, ...props }) => {
  return (
    <StyledTag $tagType={$tagType} {...props}>
      <StyledText $tagSize={props.$tagSize} $tagType={$tagType}>
        {children}
      </StyledText>
    </StyledTag>
  );
};
