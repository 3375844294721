import React, { type FC } from 'react';

import { ButtonProps } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useHomeVisitTakeToOneProgressByAdminMutation } from 'entity/home-visit/hooks/use-home-visit-take-to-one-progress-by-admin-mutation';
import { routePatches } from 'shared/routes';
import { DropdownMenuButton } from 'shared/ui/dropdown-menu/dropdown-menu-button';
import { TypographyText } from 'shared/ui/typography';

interface Props {
  homeVisitId?: number;
}

export const HomeVisitToTakeOnProgressByAdminDropdownMenuButton: FC<Props & ButtonProps> = ({
  homeVisitId,
  ...props
}) => {
  const navigate = useNavigate();

  const [takeToOneMutation] = useHomeVisitTakeToOneProgressByAdminMutation({
    id: homeVisitId,
    onSuccess: () => navigate(routePatches.AdminHomeVisitDetails.path(homeVisitId)),
  });

  return (
    <DropdownMenuButton onClick={takeToOneMutation} {...props}>
      <TypographyText>Взять в работу</TypographyText>
    </DropdownMenuButton>
  );
};
