import React from 'react';

import { TableProps } from 'antd';

import { SearchEmpty } from 'shared/ui/table/search-empty';
import { StyledTable } from 'shared/ui/table/styles';

export const Table = <T extends object>({ pagination, ...props }: TableProps<T>) => {
  return (
    <StyledTable
      locale={{
        emptyText: <SearchEmpty />,
      }}
      pagination={{ position: ['bottomLeft'], ...pagination }}
      {...props}
    />
  );
};
