import { FC } from 'react';

import type { AppointmentStatusLabel } from 'entity/health-assistance/types/appointment-status-label';
import { InternalHomeVisitStatusEnum } from 'entity/home-visit/types/internal-home-visit-status-enum';
import { StyledTag, StyledTagText } from 'entity/home-visit/ui/home-visit-status-tag/styles';

const calculateText = (type?: InternalHomeVisitStatusEnum): AppointmentStatusLabel => {
  switch (type) {
    case InternalHomeVisitStatusEnum.New:
      return 'Новая';
    case InternalHomeVisitStatusEnum.InProgressByCurrentAdmin:
      return 'В обработке';
    case InternalHomeVisitStatusEnum.InProgressByAnotherAdmin:
      return 'В работе';
    case InternalHomeVisitStatusEnum.AcceptByAdmin:
      return 'Подтверждена';
    case InternalHomeVisitStatusEnum.AcceptByDoctor:
      return 'Принята врачом';
    default:
      throw new Error('Unknown tag type');
  }
};

export interface TagProps {
  appointmentStatus: InternalHomeVisitStatusEnum;
}

export const HomeVisitStatusTag: FC<TagProps> = ({ appointmentStatus }) => {
  const text = calculateText(appointmentStatus);
  return (
    <StyledTag $appointmentStatus={appointmentStatus}>
      <StyledTagText $type={appointmentStatus}>{text}</StyledTagText>
    </StyledTag>
  );
};
